import { ContentContainer, Layout, StatusText } from '~/eds';

import { LibraryModelVersion } from '../types';

interface Props {
  isDraft: boolean;
  models: LibraryModelVersion[];
}

export const ModelPublishStates = ({ isDraft, models }: Props) => {
  const displayStates = models.map((model) => {
    if (model) {
      const { label, publishErrorMessage, publishState, testCaseState } = model;
      switch (publishState) {
        case 'done':
          if (testCaseState === 'error') {
            return (
              <StatusText
                key={model.libraryModelId}
                icon="status-warning"
                iconColor="status.warning"
                iconSpacing="m"
                text={label}
                tooltip={`The model was successfully ${
                  isDraft ? 'saved ' : 'published'
                }, but there was an error initializing the test case.`}
              />
            );
          }
          if (testCaseState === 'pending') {
            return (
              <StatusText
                key={model.libraryModelId}
                icon="loading"
                iconColor="status.info"
                iconSpacing="m"
                text={label}
              />
            );
          }
          return (
            <StatusText
              key={model.libraryModelId}
              icon="status-success"
              iconColor="status.success"
              iconSpacing="m"
              text={label}
            />
          );
        case 'error':
          return (
            <StatusText
              key={model.libraryModelId}
              icon="status-reject"
              iconColor="status.danger"
              iconSpacing="m"
              text={label}
              tooltip={publishErrorMessage}
            />
          );
        case 'pending':
        default:
          return (
            <StatusText
              key={model.libraryModelId}
              icon="loading"
              iconColor="status.info"
              iconSpacing="m"
              text={label}
            />
          );
      }
    }
    return null;
  });

  return (
    <ContentContainer
      loadingContent={{ isLoading: displayStates.length === 0 }}
    >
      <Layout direction="column" spacing={2}>
        {displayStates}
      </Layout>
    </ContentContainer>
  );
};
