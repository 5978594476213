import React, { memo } from 'react';

import { Box, Icon, Layout, MarkedText, TruncateText } from '~/eds';
import { useDrag } from '~/features/drag-and-drop';

type Props = {
  id: string;
  label: string;
  search?: string;
};

const FilterItemComponent = ({ id, label, search }: Props) => {
  const { handleDragStart, handleDragEnd } = useDrag();
  return (
    <div
      draggable
      onDragStart={() => handleDragStart({ id })}
      onDragEnd={handleDragEnd}
    >
      <Layout
        p={4}
        borderRadius="m"
        h="header.height.s"
        align="center"
        bg="white"
        onClick={() => {}}
        justify="space-between"
        styles={styles.draggableContainer}
      >
        <TruncateText>
          <MarkedText search={search} text={label} />
        </TruncateText>
        <Box data-icon-container>
          <Icon icon="reorder" />
        </Box>
      </Layout>
    </div>
  );
};

export const FilterItem = memo(FilterItemComponent);

const styles = {
  draggableContainer: {
    userSelect: 'none',
    '& [data-icon-container]': {
      opacity: 0,
    },
    '&:hover [data-icon-container]': {
      opacity: 1,
    },
  },
};
