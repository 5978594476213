import { useState } from 'react';

import { FormField, Modal, TextInput, useToast } from '~/eds';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';

interface Props {
  isVisible: boolean;
  document: { id: PilotId; folderId: PilotId; name: string };
  onHide: () => void;
  onSuccess?: (newName: string) => void;
}
export const RenameActionModal = ({
  document,
  isVisible,
  onHide,
  onSuccess,
}: Props) => {
  const { id: documentId, folderId, name } = document;
  const [
    renameDocument,
    { isLoading },
  ] = api.endpoints.renameDocument.useMutation();
  const [newName, setNewName] = useState<Nullable<string>>(name);
  const { toast } = useToast();

  const rename = async () => {
    if (newName) {
      try {
        await renameDocument({ documentId, folderId, name: newName }).unwrap();

        toast({ message: 'Document renamed successfully', status: 'success' });
        onSuccess?.(newName);
        onHide();
      } catch (error: unknown) {
        toast({ message: (error as Error).message, status: 'danger' });
      }
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      title="Rename Document"
      onHide={onHide}
      primaryAction={{ text: 'Rename', onClick: rename, isLoading }}
    >
      <FormField<string, false>
        name="document-rename"
        placeholder="Enter new document name"
        label="Document Name"
        value={newName}
        input={TextInput}
        onChange={setNewName}
      />
    </Modal>
  );
};
