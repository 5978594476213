import { getResources } from '~/redux/api/ApiTypes';
import { UserAttributes } from '~/redux/api/methods';
import { rolodex } from '~/services';

// TODO: We should point to rolodex types from spec file once it has been updated.
interface RolodexUserAttributes extends UserAttributes {
  is_external: boolean;
}

export const fetchUser: getResources<
  'user',
  RolodexUserAttributes
> = async () => {
  return rolodex.get('/user/self');
};
