import { Filter } from '~/evifields';

import { AttemptStatus } from './types';

export const WEBHOOKS_DESCRIPTION_LENGTH = 10000;

export const DEFAULT_PAGE_SIZE_ATTEMPTS = 500;
export const DEFAULT_LIMIT_ATTEMPTS = 50000;
export const DEFAULT_PAGE_SIZE_ENDPOINTS = 500;
export const DEFAULT_LIMIT_ENDPOINTS = 500;

export const initialEndpointsTableSettings = {
  columnOrder: [
    'url',
    'status',
    'description',
    'events',
    'dateCreated',
    'dateModified',
    'createdBy',
    'modifiedBy',
  ],
  sortBy: {
    id: 'dateModified',
    desc: true,
  },
};
export const initialAttemptsTableSettings = {
  pageSize: DEFAULT_PAGE_SIZE_ATTEMPTS,
  columnOrder: ['url', 'eventType', 'status', 'messageId', 'dateDelivered'],
  sortBy: {
    id: 'dateDelivered',
    desc: true,
  },
};

const statusOptions: Record<AttemptStatus, string> = {
  success: 'success',
  failed: 'failed',
};

export const getInitialDateValue = () => {
  const sixDays = [
    {
      unit: 'days',
      value: 6,
    },
  ];

  return sixDays;
};

export const initialFilters: Filter[] = [
  {
    id: 'endpointUrl',
    fieldId: 'endpointUrl',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'eventType',
    fieldId: 'eventType',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'status',
    fieldId: 'status',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'dateDelivered',
    fieldId: 'dateDelivered',
    operatorId: 'date_in_the_last',
    values: getInitialDateValue(),
    fieldType: 'date',
  },
];

export const statusTypeOptions = Object.entries(
  statusOptions,
).map(([value, label]) => ({ value, label }));
