import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { ListItems, Panel, types } from '~/eds';
import { Filter } from '~/evifields';
import { slices } from '~/redux';
import { Nullable } from '~/types';
import { sortStrings } from '~/utils';

import { useGetLatestLibraryModelVersions } from '../hooks';
import { LibraryModelVersion } from '../types';

interface Props {
  filters: Filter[];
  search: Nullable<string>;
}

export const Categories = ({ filters, search }: Props) => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    slices.xRayLibrary.selectors.selectCategory,
  );
  const { models } = useGetLatestLibraryModelVersions({});
  const { models: filteredModels } = useGetLatestLibraryModelVersions({
    filters,
    search,
  });
  const categoryLabels = getSortedCategoryLabels(models, filteredModels);
  if (!activeCategory) {
    const firstCategory = categoryLabels.keys().next().value;
    if (firstCategory) {
      dispatch(
        slices.xRayLibrary.actions.setCategory({
          label: firstCategory,
          type: firstCategory,
        }),
      );
    }
  }
  const items: types.ListItem[] = [];
  categoryLabels.forEach((count, label) => {
    items.push({
      title: `${label} (${count})`,
      isSelected: activeCategory?.label === label,
      onClick: () =>
        dispatch(
          slices.xRayLibrary.actions.setCategory({ label, type: label }),
        ),
    });
  });

  return (
    <Panel enableContentPadding={false} title="Categories" width="280px">
      <ListItems enablePadding as="ul" items={items} />
    </Panel>
  );
};
const getSortedCategoryLabels = (
  models: LibraryModelVersion[],
  modelsInSearch: LibraryModelVersion[],
): Map<string, number> => {
  const categoryLabels = new Map<string, number>();
  models.forEach((model) => {
    const labels = model.tags?.length
      ? uniq(model.tags.map((tag) => tag.label))
      : ['Uncategorized'];
    const hasSearchMatch = Boolean(
      modelsInSearch.find((m) => model.libraryModelId === m.libraryModelId),
    );
    labels.forEach((label) => {
      if (categoryLabels.has(label)) {
        const count = categoryLabels.get(label) ?? 0;
        if (hasSearchMatch) {
          categoryLabels.set(label, count + 1);
        }
      } else {
        const count = hasSearchMatch ? 1 : 0;
        categoryLabels.set(label, count);
      }
    });
  });
  const labels = Array.from(categoryLabels.entries());
  const sortedLabels = labels.sort((a, b) => sortStrings(a[0], b[0]));

  return new Map<string, number>(sortedLabels);
};
