import { Fragment } from 'react';

import { ContentContainer, DateText, Layout, Markdown, Text } from '~/eds';
import { Uuid } from '~/types';

import { useGetLibraryModelVersions } from '../../hooks';

interface Props {
  libraryModelId: Uuid;
}

export const VersionHistory = ({ libraryModelId }: Props) => {
  const { versions, isLoading } = useGetLibraryModelVersions({
    libraryModelId,
  });
  const sortedVersions = [...versions].sort((a, b) =>
    a.createdDate < b.createdDate ? 1 : -1,
  );
  return (
    <ContentContainer loadingContent={{ isLoading }}>
      <Layout direction="column" preset="subsections">
        <Text variant="body-medium">What’s new</Text>
        {sortedVersions.map(({ changelog, createdDate, version }) => (
          <Fragment key={version}>
            <Text>
              Version {version} (<DateText date={createdDate} format="medium" />
              )
            </Text>
            {changelog && <Markdown text={changelog} />}
          </Fragment>
        ))}
      </Layout>
    </ContentContainer>
  );
};
