import React from 'react';

import { HtmlEntityType } from '../../../enums';
import { Nullable, StatusType } from '../../../types';
import { formatNumber } from '../../../utils';
import { Chip } from '../Chip';

interface Props {
  count: Nullable<number>;
  countStatus?: StatusType;
}

export const CountChip = ({ count, countStatus }: Props) => {
  return (
    <Chip
      mode="tag"
      text={count ? formatNumber(count) : HtmlEntityType.Dash}
      status={countStatus}
    />
  );
};
