import React from 'react';

import { FlagType, useFlag } from '~/flags';
import { FlexLayout } from '~/ui';

import CrmIntegrations from './CrmIntegrations';
import EmailIntegrations from './EmailIntegrations';
import ESignatureIntegrations from './ESignatureIntegrations';
import StorageIntegrations from './StorageIntegrations';

export default function ThirdPartyIntegrations() {
  const multipleSFDCIntegrations = useFlag(
    FlagType.SupportMultipleSFDCOrganizations,
  );

  return (
    <FlexLayout flexDirection="column" space={10}>
      <ESignatureIntegrations />
      {multipleSFDCIntegrations || <CrmIntegrations />}
      <EmailIntegrations />
      <StorageIntegrations />
    </FlexLayout>
  );
}
