export const PAGE_SIZE = 20;
export const UI_TABLE_PAGE_SIZE = 10;
export const PAGE_SIZE_LIST = [20, 30, 50, 100];
export const PAGE_START = 1;

/** TODO: this is not ideal, but the app needs to fully adopt eds.PageLayout for next steps to internalize this detail */
export const PAGE_HEIGHT_WITHOUT_NAV = 'calc(100vh - 64px)';
export const PAGE_HEIGHT_WITHOUT_NAV_AND_SUBNAVS = 'calc(100vh - 116px)';
export const NAV_AND_SUBNAV_HEIGHT = 116;
export const NAV_HEIGHT = 64;
