import { EnumValue, Filter } from '~/evifields';
import { DashboardEntityType } from '~/redux/api/methods';
import { DropdownFieldValue, SearchFilter } from '~/types';

import { getAsyncOptionsContainer } from './AsyncOptionsContainer';

export const sortFilters = (
  filters: Filter[],
  pinnedFiltersIds: (string | number)[],
) => {
  return [...filters].sort((a, b) => {
    const aFieldId = Number(a.fieldId) || a.fieldId;
    const bFieldId = Number(b.fieldId) || b.fieldId;

    const isAPinned = pinnedFiltersIds.indexOf(aFieldId) !== -1;
    const isBPinned = pinnedFiltersIds.indexOf(bFieldId) !== -1;

    return isAPinned === isBPinned ? 0 : isAPinned ? -1 : 1;
  });
};

export const updateSettings = (searchFilter: SearchFilter): SearchFilter => {
  const newSearchFilter = {
    ...searchFilter,
  };
  switch (searchFilter.type) {
    case 'enum':
    case 'enum_set':
    case 'folder':
      break;

    default:
      return newSearchFilter;
  }

  const shouldEnableSelectAll = (filter: Filter<EnumValue>) => {
    return (
      searchFilter.type === 'enum_set' &&
      (filter.operatorId === 'contains_any' ||
        filter.operatorId === 'not_contains_any')
    );
  };

  newSearchFilter.settings = {
    ...newSearchFilter.settings,
    __testEnableSelectAll: shouldEnableSelectAll,
    options: [],
  };

  return newSearchFilter;
};

export const attachRenderer = (
  searchFilter: SearchFilter,
  type?: DashboardEntityType,
): SearchFilter => {
  searchFilter.render = getAsyncOptionsContainer(searchFilter, type);

  return searchFilter;
};

export const attachSerializer = ({
  searchFilter,
}: {
  searchFilter: SearchFilter;
  values?: DropdownFieldValue[];
}): SearchFilter => {
  const filterSerializer = (filter: Filter) => {
    const nonNumericValues = filter.values.filter((value: any) => isNaN(value));
    return nonNumericValues.length ? nonNumericValues.join(',') : undefined;
  };

  searchFilter.serialize = filterSerializer;

  return searchFilter;
};

export const attachRenderersAndSerializers = ({
  filters,
  type,
}: {
  filters: SearchFilter[];
  type: DashboardEntityType | undefined;
}) => {
  const entries = filters
    .map((filter: SearchFilter) => updateSettings(filter))
    .map((filter) => attachRenderer(filter, type))
    .map((filter) =>
      attachSerializer({
        searchFilter: filter,
      }),
    );

  return entries;
};
