import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import {
  ContentContainer,
  exportXlsx,
  Layout,
  StatusMessage,
  useToast,
} from '~/eds';
import { TableContextType } from '~/enums';
import { Filter } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { useTableSettings } from '~/hooks';
import { api, coerceRtkqError, slices } from '~/redux';

import {
  DEFAULT_LIMIT_ATTEMPTS,
  initialAttemptsTableSettings,
} from '../constants';
import { AttemptsFilters } from '../Filters/AttemptsFilters';
import { Attempt, AttemptType } from '../types';
import { getAttemptsDisabledTooltip, getStatusChip } from '../utils';

export const Attempts = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const enableWebhookExtraFeatures = useFlag(FlagType.EnableWebhooksComponents);

  const { tableSettings = initialAttemptsTableSettings } = useTableSettings(
    TableContextType.Attempts,
  );

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(tableSettings.pageSize);
  const [sortBy, setSortBy] = useState<
    { id: string; desc: boolean } | undefined
  >(tableSettings.sortBy);

  const filters = useSelector(slices.webhooks.selectors.selectedFilters);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingAttempts,
    error: attemptsError,
  } = api.endpoints.getAttempts.useQuery({
    filters,
    page: pageIndex,
    pageSize,
    sortBy,
  });

  const [
    getAttemptsExport,
    { isLoading: isLoadingExportAttempts },
  ] = api.endpoints.getAttemptsExport.useLazyQuery();

  const columns = [
    {
      key: 'url',
      title: 'Endpoint URL',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.endpointUrl,
      }),
    },
    {
      key: 'eventType',
      title: 'Event Type',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.eventType,
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (attempt: Attempt) => {
        const { text, status } = getStatusChip(attempt.status);
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'messageId',
      title: 'Message ID',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.messageId,
      }),
    },
    {
      key: 'dateDelivered',
      title: 'Date Sent',
      cellType: 'datetime',
      mapCellProps: (attempt: Attempt) => ({
        datetime: attempt.dateSent,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setPageIndex(pageIndex);
    },
    [pageIndex],
  );

  const handleUpdate = useCallback(
    (state: any, action?: any) => {
      if (action?.type === 'toggleSortBy') {
        const updatedSortBy = state.sortBy[0];

        setSortBy(updatedSortBy);
      }
      if (action?.type === 'setPageSize') {
        setPageSize(action.pageSize);
      }
    },
    [sortBy],
  );

  const handleUpdateAttempt = (attempt: Attempt, action: AttemptType) => {
    dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
    dispatch(slices.webhooks.actions.setActiveAttemptType(action));
  };

  useEffect(() => {
    if (coerceRtkqError(attemptsError)?.response?.data?.errors[0].detail) {
      toast({
        status: 'danger',
        message: `${
          coerceRtkqError(attemptsError).response.data.errors[0].detail
        }.`,
      });
    }
  }, [attemptsError]);

  const placeholderContent = attemptsError
    ? {
        title: 'An error occurred',
        description: 'Please try again.',
        image: 'error-page-error',
      }
    : data && !data.results.length && !isLoadingAttempts
    ? { title: 'No attempts found', description: 'Try changing your filters.' }
    : undefined;

  const disabledTooltip = getAttemptsDisabledTooltip(isLoadingExportAttempts);
  const getRowActions = useCallback(
    (activeAttempt: Attempt) => {
      const actions = [];
      if (activeAttempt.status === 'failed') {
        actions.push({
          key: 'resend',
          label: 'Resend',
          onClick: (attempt: Attempt) => {
            handleUpdateAttempt(attempt, 'resend');
          },
        });
      }

      if (enableWebhookExtraFeatures) {
        actions.push({
          key: 'archive',
          label: 'Archive',
          onClick: (attempt: Attempt) => {
            handleUpdateAttempt(attempt, 'archive');
          },
        });
      }

      return actions;
    },
    [enableWebhookExtraFeatures, isLoadingAttempts, data],
  );

  return (
    <Layout preset="sections">
      <AttemptsFilters
        filters={filters}
        onChange={(updatedFilters: Filter[]) => {
          dispatch(slices.webhooks.actions.setFilters(updatedFilters));
        }}
      />
      {data.total > DEFAULT_LIMIT_ATTEMPTS && (
        <StatusMessage
          message="The maximum results is 50,000 and the current results are over the limit.  Change the filters to get 50,000 or less results."
          status="warning"
        />
      )}
      <ContentContainer placeholderContent={placeholderContent}>
        <PersistedTable
          actions={
            enableWebhookExtraFeatures
              ? [
                  {
                    label: 'Export',
                    icon: 'download',
                    disabled: isLoadingExportAttempts,
                    tooltip: disabledTooltip,
                    onClick: (tableState: any) => {
                      const { columnOrder, name } = tableState;

                      getAttemptsExport({
                        filters,
                        sortBy,
                      }).then((response) => {
                        const prepareResponse = (
                          response.data?.results ?? []
                        ).map((record) => {
                          return columnOrder.reduce(
                            (newRecord: any, key: string) => {
                              if (key in record) {
                                newRecord[key] = record[key as keyof Attempt];
                              }

                              return newRecord;
                            },
                            {},
                          );
                        });

                        try {
                          exportXlsx({
                            data: prepareResponse,
                            name,
                          });
                          toast({
                            status: 'success',
                            message: 'Attempts exported successfully.',
                          });
                        } catch (error) {
                          toast({
                            message:
                              'An error occurred while exporting the data',
                            status: 'danger',
                          });
                        }
                      });
                    },
                  },
                ]
              : []
          }
          columns={columns}
          context={TableContextType.Attempts}
          data={data.results}
          isLoading={isLoadingAttempts}
          options={{
            enableManageColumns: true,
            enableSelectRows: false,
            enableExportXlsx: false,
            enablePagination: true,
            enablePageSizeSelect: true,
            pageSizes: [50, 100, 250, 500],
          }}
          getRowActions={getRowActions}
          rowDetails={{
            onClick: (attempt: Attempt) => {
              handleUpdateAttempt(attempt, 'view');
            },
          }}
          onPaginate={handlePaginate}
          onUpdate={handleUpdate}
          reactTableOptions={{
            manualSortBy: true,
          }}
          state={{
            pageIndex,
            pageSize,
            sortBy: [sortBy],
          }}
          totalCount={
            data.total <= DEFAULT_LIMIT_ATTEMPTS
              ? data.total
              : DEFAULT_LIMIT_ATTEMPTS
          }
        />
      </ContentContainer>
    </Layout>
  );
};
