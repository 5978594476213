import { noop } from 'lodash';
import { useEffect, useState } from 'react';

import { Panel_DEPRECATED, types } from '~/eds';
import { ClauseContent, Nullable, Uuid } from '~/types';

import { Clauses } from './Clauses';
import Tasks from './Tasks';

interface Props {
  title: string;
  version: string;
  versionHasAi: boolean;
  actions?: types.Action[];
  activeClauseId?: Nullable<number>;
  id?: Uuid;
  isHighlightsCreated?: boolean;
  isLoadingHighlights?: boolean;
  isClausesPanelEnabled?: boolean;
  isReadyToPoll?: boolean;
  width?: 'm' | 'l' | 'fill';
  onClauseClick?: (
    activeClause: ClauseContent,
    clauseGroup?: ClauseContent[],
  ) => void;
  onRiskClick?: (riskId: string) => void;
  onClearHighlight?: (ids?: number[]) => void;
  showHighlights?: () => void;
}

export const DocumentDetails = ({
  actions,
  activeClauseId,
  versionHasAi,
  id,
  isLoadingHighlights,
  isClausesPanelEnabled = true,
  isReadyToPoll = true,
  title,
  version,
  width = 'm',
  onClauseClick,
  onClearHighlight,
  onRiskClick,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState('Tasks');

  const tabs = [
    {
      label: 'Tasks',
      panel: <Tasks onRiskClick={onRiskClick} />,
      value: 'Tasks',
    },
    {
      label: 'Clauses',
      panel: (
        <Clauses
          activeClauseId={activeClauseId}
          id={id}
          isLoading={isLoadingHighlights}
          versionHasAi={versionHasAi}
          onClauseClick={onClauseClick}
          onClearHighlight={onClearHighlight}
          isReadyToPoll={isReadyToPoll}
        />
      ),
      tooltip: !isClausesPanelEnabled
        ? 'Clauses are not available when comparing documents'
        : undefined,
      value: 'Clauses',
      disabled: !isClausesPanelEnabled,
    },
  ];

  useEffect(() => {
    if (!isClausesPanelEnabled && selectedTab === 'Clauses') {
      setSelectedTab('Tasks');
    }
  }, [isClausesPanelEnabled]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={actions}
      selectedTab={selectedTab}
      onSelectTab={setSelectedTab}
      chip={{ text: version, preset: 'info' }}
      isEmbedded
      isVisible
      tabs={tabs}
      title={title}
      width={width}
      onHide={noop}
    />
  );
};
