import { Filter } from '~/evifields';
import { api } from '~/redux';

export const useGetDocumentCount = ({
  fieldId,
  filters,
}: {
  fieldId: number | null;
  filters: Filter[];
}) => {
  const filtersWithValues = filters.filter(
    (filter) =>
      filter.values.length > 0 ||
      OPERATORS_WITH_NO_VALUES.has(filter.operatorId!),
  );

  const {
    data: docsInScope = 0,
    isFetching: isLoadingDocsInScope,
    error: errorDocsInScope,
  } = api.endpoints.getDocsInScope.useQuery(
    {
      fieldId: fieldId!,
      filters: filtersWithValues,
    },
    { skip: !fieldId },
  );

  return {
    docsInScope,
    isLoadingDocsInScope,
    errorDocsInScope,
  };
};

const OPERATORS_WITH_NO_VALUES = new Set([
  'is_blank',
  'is_not_blank',
  'is_true',
  'is_false',
]);
