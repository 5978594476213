import React from 'react';

import { Box, Mark } from '~/eds';

import { InteractionPosition } from '../types';
import { getInteractionPosition } from '../utils';
import { INTERACTION_HIGHLIGHT_ACTIONS_MENU_OFFSET_TOP } from './constants';
import { PdfHighlight } from './types';

export interface HighlightProps {
  isActive?: boolean;
  pdfHighlight: PdfHighlight;
  containerRef?: React.RefObject<HTMLDivElement>;
  onClick?: (pdfHighlight: PdfHighlight, position: InteractionPosition) => void;
}

export const HighlightElement = ({
  isActive,
  containerRef,
  pdfHighlight,
  onClick,
}: HighlightProps) => {
  const { highlighter, id, position } = pdfHighlight;
  return (
    <Mark highlighter={highlighter} id={id} isActive={isActive}>
      {position.rects.map((rect, index) => {
        const { left = 0, height, top = 0, width } = rect;
        return (
          <Box
            key={`${id}_${index}`}
            styles={{
              backgroundColor: 'inherit',
              backgroundImage: 'inherit',
              height,
              left,
              position: 'absolute',
              top,
              width,
            }}
            onClick={
              onClick
                ? ({ clientX, clientY, target }: MouseEvent) => {
                    if (target instanceof HTMLElement) {
                      const position = getInteractionPosition({
                        clientX,
                        clientY,
                        containerRef,
                        initialOffsetY: INTERACTION_HIGHLIGHT_ACTIONS_MENU_OFFSET_TOP,
                      });
                      onClick(pdfHighlight, position);
                    }
                  }
                : undefined
            }
          />
        );
      })}
    </Mark>
  );
};
