import { sortBy } from 'lodash';
import React, { memo } from 'react';

import { ChipsGroups, enquote, types } from '~/eds';

import {
  ClauseValue,
  DateValue,
  Field,
  FieldId,
  Filter,
  NumberValue,
} from '../types';
import {
  coerceFormatDate,
  coerceFormatNumber,
  getLabeledOperator,
  serializeClauseFilter,
} from '../utils';

interface Props {
  /** Fields spec (normalized) */
  fields: Record<FieldId, Field>;
  /** Applied filters */
  filters: Filter[];
}

const previewLimit = 3;

export const FiltersPreview = memo(({ fields, filters }: Props) => {
  const groups = filters.map((filter) => {
    const { fieldId, operatorId } = filter;
    const field = fields[fieldId];

    const chips = serializeFilterValues({ field, filter }).map((text) => ({
      text,
    }));
    const operator = getLabeledOperator(field, operatorId);
    const operatorText =
      operator && operator.cardinality > 0 ? ` ${operator?.label}` : '';
    const title = `${field.label}${operatorText}`;

    return {
      chips,
      limit: previewLimit,
      title,
    };
  });

  return <ChipsGroups groups={sortBy(groups, 'title')} />;
});

const serializeFilterValues = ({
  field,
  filter,
}: {
  field: Field;
  filter: Filter;
}): string[] => {
  const { serialize, type } = field;
  const { operatorId, values } = filter;

  // return specific values for 0-cardinality operators
  switch (operatorId) {
    case 'is_blank':
      return ['Is blank'];
    case 'is_not_blank':
      return ['Is not blank'];
    case 'is_true':
      return ['true'];
    case 'is_false':
      return ['false'];
  }

  switch (type) {
    // no values
    case 'boolean':
    case 'bool_text_search':
    case 'file':
    case 'document_group_id':
    case 'clause_v2':
      return [];
    // date
    case 'date': {
      const [value1, value2] = values as DateValue[];
      return values.length === 1
        ? [coerceFormatDate(value1)]
        : [`${coerceFormatDate(value1)} and ${coerceFormatDate(value2)}`];
    }
    // enum (supporting custom serializers)
    case 'enum':
    case 'enum_set':
    case 'folder': {
      const { options } = (field as Field<'enum'>).settings;
      const optionsLabelLookup = Object.fromEntries(
        options.map(({ label, value }) => [value, label]),
      );
      return values.map((value) => {
        /**
         * Serialization Priority
         * 1. Use the provided serializer
         * 2. Lookup the option label
         * 3. Use the stringified value
         */
        return (
          serialize?.(filter) ||
          optionsLabelLookup[value as types.RecordKey] ||
          String(value)
        );
      });
    }
    // String-able
    case 'text':
    case 'text_search':
      return (values as string[]).map(enquote);
    case 'age':
    case 'number': {
      const [value1, value2] = values as NumberValue[];
      return values.length === 1
        ? [coerceFormatNumber(value1)]
        : [`${coerceFormatNumber(value1)} and ${coerceFormatNumber(value2)}`];
    }
    // clause
    case 'clause': {
      const {
        provisionText,
        clauseSearchOperatorLabel,
        clauseSearchText,
      } = serializeClauseFilter(filter as Filter<ClauseValue>);

      const texts = [provisionText];
      if (clauseSearchOperatorLabel && clauseSearchText) {
        texts.push(clauseSearchOperatorLabel);
        texts.push(enquote(clauseSearchText));
      }

      return [texts.join(' ')];
    }
  }
};
