import { saveAs } from 'file-saver';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { trackSegment } from '~/components/SegmentAnalytics';
import { Panel, types, useToast } from '~/eds';
import { FeatureFlagType } from '~/enums';
import {
  DeleteActionModal,
  RenameActionModal,
} from '~/features/document-handler';
import { useClientSubDomain, useCurrentUser } from '~/hooks';
import { testHasFlag } from '~/permissions';
import { api } from '~/redux';
import { Field } from '~/redux/api/methods';
import { ClauseContent, PilotId, SearchTextMatch } from '~/types';
import { openInNewTab } from '~/utils/browser';

import Clauses from './Clauses/Clauses';
import { Fields } from './Fields';
import { SearchMatches } from './SearchMatches';

type ActionConfigs<S = unknown> = {
  /** callback called when clicking on the header action. */
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  /** callback will be called when the default action (e.g. download or rename) has succeeded. if default action is overriden
   * by `onClick` callback, this will not be triggered. */
  onSuccess?: (param?: S) => void;
};

interface Props {
  actionsConfig?: {
    /** flag to override configure download action. */
    download?: ActionConfigs;
    /** Flag to indicate if navigation to document is available */
    navigate?: ActionConfigs;
    /** callback to show the next document. If not provided, action will be hidden */
    next?: ActionConfigs;
    /** callback to next document action. If not provided, action will be hidden */
    previous?: ActionConfigs;
    /** callback to delete document action. If not provided, action will be hidden */
    delete?: ActionConfigs;
    /** callback to rename document action. If not provided, action will be hidden */
    rename?: ActionConfigs<string>;
    /** callback to upload document action. If not provided, action will be hidden */
    upload?: ActionConfigs<File>;
  };
  /** When provided, will enable the Keyword Matches tab. */
  searchKeyworkMatches?: SearchTextMatch;
  /** When provided, will expand the accordion item of the clause and highlight the title */
  featuredClauses?: string[];
  /** document handler id or all duplicate handler ids. */
  docHandlerId?: PilotId | PilotId[];
  /** Panel title */
  documentName?: string;
  /** if true, the Panel will be used embedded in a SideBar. */
  isEmbedded?: boolean;
  /** callback will be called when hiding the panel.*/
  onHide: () => void;
  /** When provided, will enable Field highlighting */
  onFieldHighlight?: (highlights: Field) => void;
  /** When provided, will enable clause highlighting */
  onClauseHighlight?: (clause: ClauseContent) => void;
}

const enabledByDefault: ActionConfigs = { hidden: false };
type Tabs = 'fields' | 'clauses' | 'matches';
export const DocumentDetailsPanel = ({
  actionsConfig,
  docHandlerId: docHandlerIdParam,
  documentName,
  isEmbedded = false,
  searchKeyworkMatches,
  featuredClauses,
  onClauseHighlight,
  onFieldHighlight,
  onHide,
}: Props) => {
  const {
    download = enabledByDefault,
    navigate = enabledByDefault,
    rename = enabledByDefault,
    next,
    previous,
    upload,
    delete: deleteAction = enabledByDefault,
  } = actionsConfig ?? {
    download: enabledByDefault,
    navigate: enabledByDefault,
    rename: enabledByDefault,
    delete: enabledByDefault,
  };
  const client = useClientSubDomain();
  const currentUser = useCurrentUser();
  const docHandlerId = Array.isArray(docHandlerIdParam)
    ? docHandlerIdParam[0]
    : docHandlerIdParam;

  const { getInputProps, open } = useDropzone({
    onDrop: (files) => upload?.onSuccess?.(files[0]),
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
  });
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const hasEditClauseFlag = testHasFlag(FeatureFlagType.EditClause)(
    currentUser,
  );
  const [tab, setTab] = useState<Tabs>('fields');

  const { toast } = useToast();
  api.endpoints.getDocumentInformation.useQuery(docHandlerId!, {
    skip: !docHandlerId,
  });
  const {
    data,
    isFetching: isFetchingDocument,
    refetch,
  } = api.endpoints.getDocumentHandler.useQuery(docHandlerId!, {
    skip: !docHandlerId,
  });
  const hasDownloadPermission =
    data?.visibilityLevel !== 'OPEN_NO_EDIT_DOWNLOAD';
  const canEdit = data?.visibilityLevel === 'OPEN';
  const endpoint = hasEditClauseFlag
    ? 'getAllProvisionsGrouped'
    : 'getDocumentDetailClauses';

  api.endpoints[endpoint].useQuery(
    hasEditClauseFlag
      ? { docId: docHandlerId!, page: 1, pageSize: 100 }
      : ({ documentId: docHandlerId! } as any),
    {
      skip: !docHandlerId,
    },
  );
  const [
    getDocumentOriginal,
    { isFetching: isFetchingDocumentOriginal },
  ] = api.endpoints.getDocumentOriginal.useLazyQuery();

  const searchMatches = useMemo(() => {
    if (searchKeyworkMatches) {
      return searchKeyworkMatches.map((item) => ({
        content: [item],
        name: '',
      }));
    }
    return null;
  }, [searchKeyworkMatches]);

  async function defaultOnDownloadClick() {
    if (docHandlerId && data) {
      try {
        const doc = await getDocumentOriginal(docHandlerId).unwrap();
        if (doc) {
          saveAs(doc, `${data.documentName}.${data.fileType}`);
          trackSegment('selectDownloadDocument', {
            name: docHandlerId,
          });
          download.onSuccess?.();
        }
      } catch {
        toast({
          status: 'danger',
          message: `Something went wrong with downloading ${data.documentName}`,
        });
      }
    }
  }

  function defaultOnNavigateToDocClick() {
    openInNewTab(`/${client}/document/${docHandlerId}`);
    trackSegment('selectDocument', {
      name: docHandlerId,
    });
  }
  const tabs = {
    selectedTab: tab,
    onSelectTab: (tab: string) => setTab(tab as Tabs),
    enableContentPadding: false,
    tabs: [
      {
        label: 'Fields',
        value: 'fields',
        panel: (
          <Fields
            docHandlerId={docHandlerId}
            fileType={data?.fileType}
            onFieldHighlight={onFieldHighlight}
          />
        ),
      },
      {
        label: 'Clauses',
        value: 'clauses',
        panel: (
          <Clauses
            docHandlerId={docHandlerId}
            featuredClauses={featuredClauses}
            onClauseHighlight={onClauseHighlight}
          />
        ),
      },
      ...(searchMatches
        ? [
            {
              label: 'Keyword Matches',
              value: 'matches',
              panel: <SearchMatches matches={searchMatches!} />,
            },
          ]
        : []),
    ],
  };

  const actions: types.UserAction[] = [
    ...(!navigate.hidden
      ? [
          {
            icon: 'open-in-new' as const,
            tooltip: 'Open Document',
            text: 'Open Document',
            onClick: navigate.onClick ?? defaultOnNavigateToDocClick,
            disabled: navigate.disabled,
          },
        ]
      : []),
    ...(upload && !upload.hidden && canEdit
      ? [
          {
            icon: 'upload' as const,
            disabled: upload.disabled,
            tooltip: 'Upload',
            text: 'Upload',
            onClick: () => {
              if (upload.onClick) {
                upload.onClick();
              } else {
                open();
              }
              trackSegment('selectUploadNewVersion', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
    ...(!download.hidden
      ? [
          {
            icon: 'download' as const,
            tooltip:
              !hasDownloadPermission && data?.id === docHandlerId
                ? 'You do not have permission to download this document'
                : 'Download',
            text: 'Download',
            onClick: download.onClick ?? defaultOnDownloadClick,
            disabled: download.disabled || !hasDownloadPermission,
            isLoading: isFetchingDocument || isFetchingDocumentOriginal,
          },
        ]
      : []),
    ...(previous && !previous.hidden
      ? [
          {
            disabled: previous.disabled,
            icon: 'chevron-left' as const,
            tooltip: 'Previous',
            text: 'Previous',
            onClick: () => {
              previous.onClick?.();
              trackSegment('selectPreviousDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
    ...(next && !next.hidden
      ? [
          {
            disabled: next.disabled,
            icon: 'chevron-right' as const,
            tooltip: 'Next',
            text: 'Next',
            onClick: () => {
              next.onClick?.();
              trackSegment('selectNextDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
  ];

  const moreActions: types.UserAction[] = [
    ...(rename && !rename.hidden && canEdit
      ? [
          {
            icon: 'edit' as const,
            disabled: rename.disabled,
            tooltip: 'Rename',
            text: 'Rename',
            onClick: () => {
              setIsRenameModalVisible(true);
              trackSegment('selectRenameDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
    ...(deleteAction && !deleteAction.hidden && canEdit
      ? [
          {
            disabled: deleteAction.disabled,
            tooltip: 'Delete',
            text: 'Delete',
            onClick: () => {
              if (deleteAction.onClick) {
                deleteAction.onClick();
              } else {
                setIsDeleteModalVisible(true);
              }
              trackSegment('selectDeleteDocument', {
                name: docHandlerId,
              });
            },
          },
        ]
      : []),
  ];
  if (!Boolean(docHandlerId)) return null;
  return (
    <>
      {upload && !upload.hidden && (
        <input {...getInputProps()} style={{ display: 'none' }} />
      )}
      {isRenameModalVisible && docHandlerId && data && (
        <RenameActionModal
          document={{
            id: docHandlerId,
            folderId: data.path[data.path.length - 1].id,
            name: data.documentName,
          }}
          isVisible={isRenameModalVisible}
          onHide={() => setIsRenameModalVisible(false)}
          onSuccess={(newName: string) => {
            rename?.onSuccess?.(newName);
            refetch();
          }}
        />
      )}
      {isDeleteModalVisible && docHandlerId && data && (
        <DeleteActionModal
          isVisible={isDeleteModalVisible}
          documentIds={
            Array.isArray(docHandlerIdParam)
              ? docHandlerIdParam
              : [docHandlerId]
          }
          onHide={() => setIsDeleteModalVisible(false)}
          onSuccess={() => {
            deleteAction?.onSuccess?.();
            onHide();
          }}
        />
      )}
      <Panel
        loadingContent={{ isLoading: isFetchingDocument }}
        actions={actions}
        moreActions={moreActions}
        hidden={{ isHidden: false, onHide }}
        title={documentName ?? data?.documentName ?? ''}
        position={!isEmbedded ? 'right' : undefined}
        tabs={tabs}
        width={!isEmbedded ? 'l' : undefined}
      />
    </>
  );
};

export default DocumentDetailsPanel;
