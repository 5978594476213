import { FieldType, OperatorId } from './types';

type OperatorLabels = Record<OperatorId, string>;

const {
  between,
  contains_all,
  contains_any,
  date_after,
  date_before,
  date_between,
  date_in_the_last,
  date_in_the_next,
  date_on,
  equals,
  greater_than,
  greater_than_equals,
  less_than,
  less_than_equals,
  is_blank,
  is_false,
  is_not_blank,
  is_true,
  not_contains_any,
  not_equals,
  text_contains_any,
  text_not_contains_any,
}: OperatorLabels = {
  between: 'is between',
  contains_all: 'contains all of',
  contains_any: 'contains any of',
  date_after: 'is after',
  date_before: 'is before',
  date_between: 'is between',
  date_in_the_last: 'in the last',
  date_in_the_next: 'in the next',
  date_on: 'on',
  equals: 'is equal to',
  greater_than: 'is greater than',
  greater_than_equals: 'is greater than or equal to',
  less_than: 'is less than',
  less_than_equals: 'is less than or equal to',
  in_folder: 'contained in',
  is_blank: 'is blank',
  is_false: 'is false',
  is_not_blank: 'is not blank',
  is_true: 'is true',
  not_contains_any: 'does not contain any of',
  not_equals: 'is not equal to',
  text_contains_any: 'contains any', // changed by text search
  text_not_contains_any: 'does not contain any', // changed by text search
  text_contains_all_of_these_words: 'contains all of these words',
  text_contains_any_of_these_words: 'contains any of these words',
  text_contains_exact_phrase: 'contains exact phrase',
  text_not_contains_all_of_these_words: 'does not contain all of these words',
  text_not_contains_any_of_these_words: 'does not contain any of these words',
  text_not_contains_exact_phrase: 'does not contain exact phrase',
};

/**
 * Operator labels lookup by FieldType and OperatorId.  Some assignments prefer a non-default operator label that is indicated with an explicit string label assigment.
 */
export const operatorLabelsByFieldType: Record<
  FieldType,
  Partial<OperatorLabels>
> = {
  age: {
    equals,
    greater_than,
    greater_than_equals,
    less_than,
    less_than_equals,
    between,
  },
  boolean: {
    is_blank,
    is_not_blank,
    is_true,
    is_false,
  },
  clause: {
    contains_any: 'contains',
    not_contains_any: 'does not contain',
  },
  date: {
    date_before,
    date_on,
    date_after,
    date_between,
    date_in_the_last,
    date_in_the_next,
    is_blank,
    is_not_blank,
  },
  enum: {
    equals,
    not_equals,
    is_blank,
    is_not_blank,
  },
  enum_set: {
    contains_any,
    contains_all,
    not_contains_any,
    is_blank,
    is_not_blank,
  },
  file: {
    is_blank: 'has no files attached',
    is_not_blank: 'has files attached',
  },
  folder: {
    in_folder: 'contained in',
  },
  number: {
    equals,
    greater_than,
    greater_than_equals,
    less_than,
    less_than_equals,
    between,
    is_blank,
    is_not_blank,
  },
  text: {
    text_contains_any,
    text_not_contains_any,
    is_blank,
    is_not_blank,
  },
  text_search: {
    text_contains_all_of_these_words: 'contains all of these words',
    text_contains_any_of_these_words: 'contains any of these words',
    text_contains_exact_phrase: 'contains exact phrase',
    text_not_contains_all_of_these_words: 'does not contain all of these words',
    text_not_contains_any_of_these_words: 'does not contain any of these words',
    text_not_contains_exact_phrase: 'does not contain exact phrase',
  },
  document_group_id: {},
  clause_v2: {},
  bool_text_search: {},
};
