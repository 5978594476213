import { toString } from 'lodash';

import { RecentSearch } from '~/features/advanced-search';
import { pilot } from '~/services';
import { PilotId } from '~/types';

import { SearchableFieldId } from './alert';

interface PinnedFiltersResponse {
  pinnedFilters?: {
    value?: Array<SearchableFieldId | PilotId>;
  };
}

interface PinnedFiltersReturnValue {
  data?: Array<SearchableFieldId>;
}

interface PinnedFiltersParams {
  context: string;
  pinnedFilters: Array<SearchableFieldId>;
}

interface RecentSearchesResponse {
  recentSearches?: {
    value?: Array<RecentSearch>;
  };
}

export type ContextType = 'FOLDER' | 'SEARCH' | 'GROUP' | 'EVISEARCH_SEARCH';

export const getPinnedFilters = async (
  context: ContextType,
): Promise<PinnedFiltersReturnValue> => {
  const response: PinnedFiltersResponse = await pilot.get(
    '/user/preferences/',
    { params: { context } },
  );
  return { data: response?.pinnedFilters?.value?.map(toString) };
};

export const updatePinnedFilters = ({
  context,
  pinnedFilters,
}: PinnedFiltersParams): Promise<{}> => {
  return pilot.put(
    '/user/preferences/',
    { value: pinnedFilters },
    { params: { context, key: 'pinnedFilters' } },
  );
};

export const getRecentSearches = async ({
  context,
}: {
  context: ContextType;
}) => {
  const response: RecentSearchesResponse = await pilot.get(
    '/user/preferences/',
    {
      params: { context, key: 'recentSearches' },
    },
  );
  return { data: response?.recentSearches?.value };
};

export const updateRecentSearches = ({
  context,
  recentSearches,
}: {
  context: ContextType;
  recentSearches: RecentSearch[];
}): Promise<{}> => {
  return pilot.put(
    '/user/preferences/',
    { value: recentSearches },
    { params: { context, key: 'recentSearches' } },
  );
};
