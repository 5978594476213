import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  types as edsTypes,
  FormField,
  Layout,
  Markdown,
  Modal,
  StatusMessage,
  TextInput,
  useToast,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api, slices } from '~/redux';
import { Nullable } from '~/types';

import { useResolveTargetEntityDetails } from '../hooks';
import { resolveModalProps } from '../utils';
import { ConfirmResumePublishModal } from './ConfirmResumePublishModal';

export const ModelModal = () => {
  const dispatch = useDispatch();

  const { toast } = useToast();

  const enableXrayNewPricingModel = useFlag(
    FlagType.DocumentXrayNewPricingModel,
  );

  const [deleteInput, setDeleteInput] = useState<Nullable<string>>('');

  const [
    deleteFieldModel,
    { isLoading: isDeletingModel },
  ] = api.endpoints.deletePromptModel.useMutation();

  const [
    suspendFieldModel,
    { isLoading: isSuspendingModel },
  ] = api.endpoints.suspendPromptModel.useMutation();

  const modelAction = useSelector(slices.xRay.selectors.selectModelAction);

  const isLoading = isDeletingModel || isSuspendingModel;

  const [
    targetEntityDetails,
    isResolvingTargetEntity,
  ] = useResolveTargetEntityDetails(modelAction?.model.entity ?? null);

  const [modalActionProps, setModalActionProps] = useState<{
    description: string;
    toastMessage: string;
    variant: edsTypes.ButtonVariant;
  }>({ description: '', toastMessage: '', variant: 'primary' });

  useEffect(() => {
    if (isResolvingTargetEntity || !modelAction) {
      return;
    }
    if (targetEntityDetails.label && modelAction.action !== 'resume') {
      const resolvedModalProps = resolveModalProps(
        modelAction.action,
        targetEntityDetails,
      );
      setModalActionProps(resolvedModalProps);
    }
  }, [targetEntityDetails, modelAction]);

  if (modelAction === null) {
    return null;
  }

  const { model, action } = modelAction;
  const isResumeAction = action === 'resume';
  const isDeleteAction = action === 'delete';
  const hasNewDeleteModal = enableXrayNewPricingModel && isDeleteAction;

  const handleHide = () => {
    if (isDeleteAction) {
      setDeleteInput('');
    }
    dispatch(slices.xRay.actions.setModelAction(null));
  };

  const handleModelActionConfirm = () => {
    if (isResumeAction) return;

    const queries = {
      delete: deleteFieldModel,
      suspend: suspendFieldModel,
    };

    const query = queries[action];

    query({
      modelId: model.id,
      version: model.version,
    })
      ?.unwrap()
      .then(() => {
        toast({
          message: modalActionProps.toastMessage,
          status: 'success',
        });
      })
      .catch(() =>
        toast({
          message: `Failed to ${action} **${targetEntityDetails.label}**. Please try again.`,
          status: 'danger',
        }),
      )
      .finally(handleHide);
  };

  const modalProps = {
    children: hasNewDeleteModal ? (
      <Layout preset="form-fields">
        <Markdown
          text={`Please confirm that you would like to delete ${capitalize(
            targetEntityDetails.type,
          )} Model “**${
            targetEntityDetails.label
          }**”.\n\nThis will not delete all values previously found by the model, but will remove this model and stop it from running on any new documents that fit within the model scope. **You will not receive** a refund for this model and this action cannot be undone.`}
        />
        <FormField<string, false>
          input={TextInput}
          label="Type Delete to delete model."
          name="Delete Model"
          onChange={(value) => {
            setDeleteInput(value);
          }}
          value={deleteInput}
        />
      </Layout>
    ) : (
      <Layout preset="form-fields">
        <Markdown text={modalActionProps.description} />{' '}
        {enableXrayNewPricingModel && (
          <StatusMessage
            message="Suspended models still count towards your model quota."
            status="warning"
          />
        )}
      </Layout>
    ),
    disableHideOnEscape: true,
    isVisible: true,
    primaryAction: {
      isLoading,
      text: 'Confirm',
      variant: modalActionProps.variant,
      onClick: handleModelActionConfirm,
      disabled: hasNewDeleteModal && deleteInput !== 'Delete',
      tooltip:
        hasNewDeleteModal && deleteInput !== 'Delete'
          ? 'Type Delete to delete model.'
          : undefined,
    },
    title: `${capitalize(action)} ${capitalize(
      targetEntityDetails.type,
    )} Model`,
    onHide: handleHide,
  };

  return isResumeAction ? (
    <ConfirmResumePublishModal
      modelId={model.id}
      version={model.version}
      targetEntityDetails={targetEntityDetails}
    />
  ) : (
    <Modal {...modalProps} />
  );
};
