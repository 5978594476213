import { formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { getUniqueFieldValues } from '~/utils/document.utils';

export const processSectionItems = (sections) => {
  let returnObject = {};

  if (!sections || !sections.length) return returnObject;

  sections.forEach((section) => {
    const formattedFields = section.fields.reduce((total, field) => {
      const newObject = {};

      if (
        [DataFieldType.ARRAY_MULTIPLE, DataFieldType.ATTACHMENT].includes(
          field.type,
        )
      ) {
        newObject[field.id] = getUniqueFieldValues(field.value).map((val) => ({
          label: val.display_value,
          value: val.value,
        }));
      } else if (field.type === DataFieldType.ARRAY_SINGLE) {
        newObject[field.id] = field.value
          ? { label: field.value.display_value, value: field.value.value }
          : null;
      } else if (field.type === DataFieldType.DATE) {
        newObject[field.id] = field.value
          ? formatDate(parseDate(field.value.value, true), 'iso_date')
          : null;
      } else {
        newObject[field.id] = field.value ? field.value.value : null;
      }

      return { ...total, ...newObject };
    }, {});

    returnObject = { ...returnObject, ...formattedFields };
  });

  return returnObject;
};

export const preprocessEditData = (data) =>
  Object.keys(data).map((dataKey) => {
    let newItem = {};
    newItem.id = dataKey;
    newItem.value = data[dataKey];

    if (newItem.value instanceof Array) {
      newItem.value = data[dataKey].map((i) => i.value);
    } else if (newItem.value instanceof Object) {
      newItem.value = data[dataKey].value;
    }

    return newItem;
  });

export const capitalizeString = (string) => {
  let returnString = string;

  if (typeof returnString !== 'string') {
    returnString = returnString.toString();
  }

  return returnString.charAt(0).toUpperCase() + returnString.slice(1);
};

export const booleanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
