import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ManageFilters, {
  OptionsType,
} from '~/components/Shared/Filters/ManageFilters';
import { DASHBOARD_CHARTS_LIMIT } from '~/constants/max_lengths';
import { StatusMessage, Text } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api, selectors } from '~/redux';
import { Dashboard } from '~/redux/api/methods';
import dashboardV2, {
  convertFilterToChart,
  convertFilterTypeTochartType,
  getNonFilterCharts,
} from '~/redux/slices/dashboardV2';
import { Chart, FiltersCount, SearchFilter } from '~/types';
import { getValidEntity } from '~/utils/object';

interface Props {
  entity?: Dashboard['entity'];
}

const getSelectedChartsByGroup = (chartFilters: Chart[]) => {
  return chartFilters.reduce((acc, prev) => {
    const chartID = acc[prev.section as keyof typeof acc];
    return {
      ...acc,
      [prev.section]: chartID === undefined ? [prev.id] : [...chartID, prev.id],
    };
  }, {});
};

export const EditCharts = ({ entity }: Props) => {
  const hasDeprecatePilotSearch = useFlag(FlagType.DeprecatePilotSearch);
  const shouldUseEvisearch =
    entity === 'ticket' || entity === 'workflow' || hasDeprecatePilotSearch;
  const { data: searchFilters } = api.endpoints[
    shouldUseEvisearch ? 'getFiltersV2' : 'getFilters'
  ].useQuery({
    type: entity,
  });
  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );

  const groupsOrder = useMemo(
    () => sections?.fieldGroups.map((group) => group.id),
    [sections?.fieldGroups],
  );

  const chartFilters = useSelector(selectors.selectDashboardCharts);
  const activeDashboard = useSelector(selectors.selectActiveDashboard);

  const nonFilterCharts: OptionsType[] = Object.values(
    getNonFilterCharts(activeDashboard),
  );

  let chartableFilters: OptionsType[] = Object.values(
    searchFilters || {},
  ).filter((field) => field.is_chartable);

  if (shouldUseEvisearch) {
    //Concatenate non filter charts if dashboard is pre sig.
    chartableFilters = chartableFilters.concat(nonFilterCharts);
  }

  const selectedCharts = getSelectedChartsByGroup(chartFilters);
  const getActionButtonTooltip = ({ selectedFiltersCount }: FiltersCount) => {
    if (selectedFiltersCount === 0) {
      return 'Please select at least one chart.';
    } else if (selectedFiltersCount > DASHBOARD_CHARTS_LIMIT) {
      return `The total number of charts selected cannot exceed ${DASHBOARD_CHARTS_LIMIT}.`;
    } else {
      return '';
    }
  };

  const renderLimitHeader = () => {
    return (
      <Text variant="body-bold">
        Select up to {DASHBOARD_CHARTS_LIMIT} fields for your dashboard
      </Text>
    );
  };

  const renderHelpText = ({ selectedFiltersCount }: FiltersCount) => {
    if (selectedFiltersCount > DASHBOARD_CHARTS_LIMIT) {
      return (
        <StatusMessage
          status="info"
          message={`You have added the maximum number of fields (${DASHBOARD_CHARTS_LIMIT}) to your dashboard.`}
        />
      );
    }
    return null;
  };

  const dispatch = useDispatch();

  const applyChartFilters = useCallback(
    (selectedFieldIds: string[]) => {
      const chartableFiltersMap = Object.fromEntries(
        chartableFilters.map((chart) => [chart.id, chart]),
      );
      const filterCharts: SearchFilter[] = getValidEntity(
        chartableFiltersMap,
        selectedFieldIds,
      );
      const charts = filterCharts.map((filterChart) => ({
        ...convertFilterToChart(filterChart),
        type:
          chartFilters.find((chart) => chart.id === filterChart.id)?.type ??
          convertFilterTypeTochartType(filterChart),
      }));
      dispatch(dashboardV2.actions.setChartFilters(charts));
      dispatch(dashboardV2.actions.setDashboardDirty(true));
    },
    [chartableFilters, chartFilters],
  );

  return (
    <ManageFilters
      preset="edit-charts"
      getActionButtonTooltip={getActionButtonTooltip}
      groupsOrder={groupsOrder || []}
      limit={DASHBOARD_CHARTS_LIMIT}
      renderHelpText={renderHelpText}
      renderLimitHeader={renderLimitHeader}
      searchFilters={chartableFilters}
      selectedFilters={selectedCharts}
      onApply={applyChartFilters}
    />
  );
};
