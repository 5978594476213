import { uniqBy } from 'lodash';

import { Chips, Layout, Text, types } from '~/eds';
import { getFieldTypeLabel } from '~/features/fields/utils';
import { mapDataFieldType } from '~/redux/api/methods/sylvanus/transformers';
import { Nullable } from '~/types';
import { sortStrings } from '~/utils';

import { LibraryModelVersion, Tag } from '../types';

export const getEntityTypeLabel = (model: LibraryModelVersion): string => {
  switch (model.targetEntityDetails?.type) {
    // Extensible as more entity types are supported
    case 'field':
    default:
      return getFieldTypeLabel(mapDataFieldType(model.config));
  }
};

export const getSortedCategoryModels = (
  models: LibraryModelVersion[],
): Map<string, LibraryModelVersion[]> => {
  const categoryModels = new Map<string, LibraryModelVersion[]>();
  models.forEach((model) => {
    const label = model.tags?.[0]?.label ?? 'Uncategorized';
    if (categoryModels.has(label)) {
      categoryModels.set(label, [...(categoryModels.get(label) ?? []), model]);
    } else {
      categoryModels.set(label, [model]);
    }
  });
  const labels = Array.from(categoryModels.entries());
  const sortedLabels = labels.sort((a, b) => sortStrings(a[0], b[0]));

  return new Map<string, LibraryModelVersion[]>(sortedLabels);
};

export const getCategoryChips = (
  model: LibraryModelVersion,
  category: Nullable<Tag>,
): React.ReactNode => {
  const chips = uniqBy(
    model.tags
      .filter((tag) => category && tag.label !== category.label)
      .map((tag) => ({
        text: tag.label,
      })),
    'text',
  );
  return chips?.length ? (
    <Layout align="center" justify="flex-start" spacing={2}>
      <Text whiteSpace="nowrap" variant="tiny">
        Also in
      </Text>
      <Chips chips={chips} />
    </Layout>
  ) : undefined;
};

export const getChip = (
  status: LibraryModelVersion['promptModelPublishStatus'],
): Nullable<types.SharedChipProps> => {
  if (status === null) {
    return null;
  }

  return getChipProps(status);
};

const getChipProps = (
  status: LibraryModelVersion['promptModelPublishStatus'],
) => {
  switch (status) {
    case 'draft':
      return {
        text: 'Draft',
        status: 'warning' as const,
      };
    case 'published':
      return {
        text: 'Published',
        status: 'success' as const,
      };
    case 'error':
      return {
        text: 'Error',
        status: 'danger' as const,
      };
    case 'suspended':
      return {
        text: 'Suspended',
        status: 'danger' as const,
      };
    case 'in_progress':
    default:
      return {
        text: 'In Progress',
        status: 'info' as const,
      };
  }
};

export const testIsModelPublished = ({
  promptModelPublishStatus,
}: LibraryModelVersion): boolean => {
  return (
    promptModelPublishStatus === 'published' ||
    promptModelPublishStatus === 'error' ||
    promptModelPublishStatus === 'suspended' ||
    promptModelPublishStatus === 'in_progress' ||
    promptModelPublishStatus === 'draft'
  );
};
