import React, { memo } from 'react';

import { CurrencyType } from '../../../enums';
import { Option, SharedSelectProps } from '../../../types';
import { Select } from '../Select';

interface Props<M> extends SharedSelectProps<CurrencyType, M> {
  onFilterOption?: (option: Option<CurrencyType>) => boolean;
}

export const CurrencySelect = memo(
  <M extends boolean>({ value, onChange, ...rest }: Props<M>) => (
    <Select
      {...rest}
      options={options}
      placeholder="Select currency..."
      value={value}
      onChange={onChange}
    />
  ),
);

export const options = [
  {
    value: CurrencyType.Usd,
    label: 'United States Dollar',
    description: 'USD ($)',
  },
  {
    value: CurrencyType.Aud,
    label: 'Australian Dollar',
    description: 'AUD ($)',
  },
  {
    value: CurrencyType.Cad,
    label: 'Canadian Dollar',
    description: 'CAD ($)',
  },
  {
    value: CurrencyType.Chf,
    label: 'Swiss Franc',
    description: 'CHF (fr.)',
  },
  {
    value: CurrencyType.Cny,
    label: 'Chinese Yuan (Renminbi)',
    description: 'CNY (¥)',
  },
  {
    value: CurrencyType.Dkk,
    label: 'Danish Krone',
    description: 'DKK (kr.)',
  },
  {
    value: CurrencyType.Eur,
    label: 'European Euro',
    description: 'EUR (€)',
  },
  {
    value: CurrencyType.Gbp,
    label: 'Great Britain (UK) Pound',
    description: 'GBP (£)',
  },
  {
    value: CurrencyType.Hkd,
    label: 'Hong Kong Dollar',
    description: 'HKD ($)',
  },
  {
    value: CurrencyType.Ils,
    label: 'Israeli Shekel',
    description: 'ILS (₪)',
  },
  { value: CurrencyType.Inr, label: 'Indian Rupee', description: 'INR (₹)' },
  { value: CurrencyType.Jpy, label: 'Japanese Yen', description: 'JPY (¥)' },
  {
    value: CurrencyType.Krw,
    label: 'South Korean Won',
    description: 'KRW (₩)',
  },
  { value: CurrencyType.Mxn, label: 'Mexican Peso', description: 'MXN ($)' },
  {
    value: CurrencyType.Nok,
    label: 'Norweigian Kroner',
    description: 'NOK (kr)',
  },
  {
    value: CurrencyType.Nzd,
    label: 'New Zealand Dollar',
    description: 'NZD ($)',
  },
  {
    value: CurrencyType.Rub,
    label: 'Russian Ruble',
    description: 'RUB (₽)',
  },
  {
    value: CurrencyType.Sek,
    label: 'Swedish Krona',
    description: 'SEK (kr)',
  },
  {
    value: CurrencyType.Sgd,
    label: 'Singapore Dollar',
    description: 'SGD ($)',
  },
  {
    value: CurrencyType.Thb,
    label: 'Thailand Baht',
    description: 'THB (฿)',
  },
  {
    value: CurrencyType.Vnd,
    label: 'Vietnamese dong',
    description: 'VND (₫)',
  },
  {
    value: CurrencyType.Zar,
    label: 'South African Rand',
    description: 'ZAR (R)',
  },
];
