import uuid from 'uuid';

import { EnumValue, FieldId, Filter } from '~/evifields';
import { DropdownFieldValue, PilotId, SearchFilter } from '~/types';

import getAsyncOptionsContainer from './AsyncOptionsContainer';

type SearchFilterRecord = Record<FieldId, SearchFilter>;

export const FIELDS_WITH_COMPLEX_VALUES: string[] = [
  'document_group_id',
  'folder',
];

export const updateSettings = (searchFilter: SearchFilter): SearchFilter => {
  const newSearchFilter = {
    ...searchFilter,
  };
  switch (searchFilter.type) {
    case 'enum':
    case 'enum_set':
    case 'folder':
      break;

    default:
      return newSearchFilter;
  }

  const shouldEnableSelectAll = (filter: Filter<EnumValue>) => {
    if (
      searchFilter.type === 'folder' ||
      // TODO: Disable once V3 search is deprecated
      searchFilter.id === 'document_group_id'
    ) {
      return false;
    }

    return (
      searchFilter.type === 'enum_set' &&
      (filter.operatorId === 'contains_any' ||
        filter.operatorId === 'not_contains_any')
    );
  };

  newSearchFilter.settings = {
    ...newSearchFilter.settings,
    __testEnableSelectAll: shouldEnableSelectAll,
    options: [],
  };

  return newSearchFilter;
};

export const attachRenderer = (searchFilter: SearchFilter): SearchFilter => {
  switch (searchFilter.type) {
    case 'enum':
    case 'enum_set':
    case 'clause':
    case 'folder':
      break;
    default:
      return searchFilter;
  }

  searchFilter.render = getAsyncOptionsContainer(searchFilter);

  return searchFilter;
};

export const attachSerializer = ({
  searchFilter,
  values,
}: {
  searchFilter: SearchFilter;
  values?: DropdownFieldValue[];
}): SearchFilter => {
  if (!FIELDS_WITH_COMPLEX_VALUES.includes(searchFilter.id)) {
    return searchFilter;
  }

  const filterSerializer = (filter: Filter) => {
    return values?.find((value) => value.value === filter.values[0])
      ?.display_value;
  };

  searchFilter.serialize = filterSerializer;

  return searchFilter;
};

export const attachRenderersAndSerializers = ({
  filters,
  fieldValues,
}: {
  filters: SearchFilter[];
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
}) => {
  const entries = filters
    .map((filter: SearchFilter) => updateSettings(filter))
    .map((filter) => attachRenderer(filter))
    .map((filter) =>
      attachSerializer({
        searchFilter: filter,
        values: fieldValues[filter.id],
      }),
    );

  return entries;
};

export const attachFilterType = (
  filters: Filter[],
  searchFilters: SearchFilterRecord = {},
) =>
  filters.map((filter) => {
    const searchFilter = searchFilters[filter.fieldId];
    const updatedFilter = { ...filter };
    if (searchFilter) {
      updatedFilter.fieldType = searchFilter.type;
    }
    return updatedFilter;
  });

export const createWhiteListFilters = (
  searchFilters: SearchFilterRecord,
  whiteListFilters: Array<PilotId | string>,
) =>
  Object.keys(searchFilters).reduce<SearchFilterRecord>((acc, curr) => {
    const currId = Number.isNaN(Number(curr)) ? curr : Number(curr);
    if (whiteListFilters.includes(currId)) {
      acc[curr] = searchFilters[curr];
      return acc;
    }
    return acc;
  }, {});

export const createRestrictedListFilters = (
  searchFilters: SearchFilterRecord,
  restrictedFieldIds: Array<PilotId | string>,
) =>
  Object.keys(searchFilters).reduce<SearchFilterRecord>((acc, curr) => {
    const currId = Number.isNaN(Number(curr)) ? curr : Number(curr);
    if (!restrictedFieldIds.includes(currId)) {
      acc[curr] = searchFilters[curr];
      return acc;
    }
    return acc;
  }, {});

export const getDefaultFilters = (
  defaultFilterIds: Array<PilotId | string> = [],
  searchFilters: SearchFilterRecord = {},
) =>
  Object.values(searchFilters)
    .filter(
      (searchFilter) =>
        defaultFilterIds.includes(searchFilter.id) ||
        defaultFilterIds.includes(searchFilter.label),
    )
    .map((searchFilter) => ({
      id: uuid.v4(),
      fieldId: searchFilter.id,
      operatorId: null,
      values: [],
    }));
