import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  documentId: PilotId;
  reason?: string;
  extraDetails?: string;
}
export const deleteDocumentHandler = ({
  documentId,
  reason,
  extraDetails,
}: Request) => {
  try {
    return pilot.remove(`document/${documentId}/`, {
      data: { reason, extra_details: extraDetails },
    });
  } catch {
    throw new Error('An error occurred while deleting the document');
  }
};
