import React from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import {
  Button,
  Card,
  Grid,
  HtmlEntityType,
  Layout,
  Markdown,
  Text,
} from '~/eds';
import { AribaIntegrationType, ManageSyncPairStageType } from '~/enums';

import AribaFooter from '../AribaFooter';

function FileConfigSetup({
  // connected,
  activeSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { provider } = activeSyncPair;

  const oneTimeImportDescription = `**Select files for a one-time import**
  - Upload CSV with Ariba documents
  - Select fields to transfer between the two systems
  - Evisort does not monitor for changes in Ariba

  **Best for:** historical contracts where the files or data are not changing.`;

  const continuousSyncDescription = `**Select files for continuous data sync**
  - Use filters to discover new Contract Workspaces as they're created
  - Select fields to transfer between the two systems
  - Evisort will monitor for changes in Ariba

  **Best for:** situations where you want to monitor and process new Contract Workspaces.`;

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.MultiAPIs);
  };

  return (
    <Layout align="center" direction="column" spacing={8}>
      <Layout align="center" py={2} spacing={6}>
        <ProviderLogo provider="evisort" />
        <Text color="text.quiet">{HtmlEntityType.DoubleDash}</Text>
        <ProviderLogo provider={provider} />
      </Layout>
      <Grid columnSpacing={8} columns={2}>
        <Card status="info" mode="bordered" px={8} py={4}>
          <Layout direction="column" py={2} spacing={6}>
            <Markdown text={oneTimeImportDescription} />
            <Layout>
              <Button
                text="Upload"
                variant="secondary"
                onClick={() => {
                  integrationsSetActiveSyncPair({
                    ...activeSyncPair,
                    syncType: AribaIntegrationType.ONE_TIME,
                  });
                  integrationsSetManageSyncPairStage(
                    ManageSyncPairStageType.FolderAriba,
                  );
                }}
              />
            </Layout>
          </Layout>
        </Card>
        <Card status="info" mode="bordered" px={8} py={4}>
          <Layout direction="column" py={2} spacing={6}>
            <Markdown text={continuousSyncDescription} />
            <Layout>
              <Button
                text="Sync"
                variant="secondary"
                onClick={() => {
                  integrationsSetActiveSyncPair({
                    ...activeSyncPair,
                    syncType: AribaIntegrationType.CONTINUOUS,
                  });
                  integrationsSetManageSyncPairStage(
                    ManageSyncPairStageType.FolderAriba,
                  );
                }}
              />
            </Layout>
          </Layout>
        </Card>
      </Grid>
      <AribaFooter
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(FileConfigSetup);
