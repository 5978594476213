import React, { memo } from 'react';

import { SharedDescriptionListProps, UserAction } from '../../types';
import { Actions } from '../Actions';
import { Box } from '../Box';
import { CollapsibleText } from '../CollapsibleText';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { MarkedText } from '../MarkedText';
import { Text } from '../Text';

interface Props extends SharedDescriptionListProps {
  /** Terms */
  terms: {
    title: string;
    action?: UserAction;
    tooltip?: string;
    description: string;
  }[];
}

export const DescriptionList = memo(
  ({ columns, highlighter, limit, mode, search, terms }: Props) => {
    const isCardMode = mode === 'card';
    const border = isCardMode ? 'border' : undefined;
    const p = isCardMode ? 4 : undefined;
    const isCondensedMode = mode === 'condensed';

    return (
      <Grid
        as="dl"
        border={border}
        columns={columns}
        columnSpacing={8}
        m={0}
        p={p}
        rowSpacing={isCondensedMode ? 3 : 6}
      >
        {terms.map(({ title, description, tooltip, action }) => (
          <div key={title} role="gridcell">
            <Layout as="dt" direction="row" align="center">
              <Text
                color={isCondensedMode ? 'text.primary' : 'text.secondary'}
                variant={isCondensedMode ? 'body' : 'tiny'}
              >
                <MarkedText text={title} search={search} />
              </Text>
              {tooltip && (
                <Icon icon="info" tooltip={tooltip} color="inherit" />
              )}
              {action && (
                <Layout>
                  <Actions actions={[action]} size="xs" />
                </Layout>
              )}
            </Layout>
            <Box as="dd" m={0}>
              <CollapsibleText
                highlighter={highlighter}
                limit={limit}
                search={search}
                text={description}
                variant={isCondensedMode ? 'tiny' : 'body-medium'}
                color={isCondensedMode ? 'text.secondary' : 'text.primary'}
                whiteSpace="pre-wrap"
              />
            </Box>
          </div>
        ))}
      </Grid>
    );
  },
);
