import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentContainer, Layout, Text, useToast } from '~/eds';
import { testIsTurnTrackingColumn } from '~/features/turn-tracking';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import { Dashboard } from '~/redux/api/methods/dashboardV2';
import { buildQuery } from '~/redux/api/methods/searchV3';
import dashboardV2 from '~/redux/slices/dashboardV2';
import {
  DEFAULT_TABLE_SETTINGS,
  DEFAULT_TABLE_SORT_COLUMN_PRE_SIGN,
} from '~/utils/table';
import { testIsAdmin } from '~/utils/user';

import DashboardV2Charts from '../DashboardV2/DashboardV2Charts';
import { EditCharts } from '../DashboardV2/EditCharts';
import SaveDashboard from '../DashboardV2/SaveDashboard';
import { DashboardV3Filters } from './DashboardV3Filters';
import { DashboardV3SearchResults } from './DashboardV3SearchResults/DashboardV3SearchResults';
import { FIRST_COLUMN_PREFIX, getFirstColumnKey } from './utils';

type Props = {
  dashboard: Dashboard;
};

export const DashboardV3 = ({ dashboard }: Props) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const user = useCurrentUser();

  //Redux Selectors
  const page = useSelector(selectors.selectDashboardPage);
  const pageSize = useSelector(selectors.selectDashboardPageSize);
  const sortBy = useSelector(selectors.selectDashboardSortBy);
  const columns = useSelector(selectors.selectDashboardColumnOrder);
  const activeDashboard = useSelector(selectors.selectActiveDashboard);

  //Query Selectors
  const searchText = useSelector(selectors.selectDashboardSearchText);
  const activeFilters = useSelector(selectors.selectDashboardActiveFilters);
  const crossFilters = useSelector(selectors.selectDashboardCrossFilters);
  const intervals = useSelector(selectors.selectDashboardIntevals);
  const chartWidths = useSelector(selectors.selectDashboardChartWidths);
  const selectedFilters = useSelector(selectors.selectDashboardSelectedFilters);
  const hasDataChanges = useSelector(selectors.selectDashboardDirty);
  const canSaveDashboard = testIsAdmin(user) || dashboard.creatorId === user.id;
  //Charts
  const charts = useSelector(selectors.selectDashboardCharts);

  const isCurrentDashboardActive = activeDashboard.id === dashboard.id;
  // flag checking
  const isTurnTrackingEnabled = useFlag(FlagType.TurnTracking);

  //RTQK
  const {
    data: searchFilters,
    isError: isGetFilterError,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFiltersV2.useQuery({
    type: dashboard.entity ?? 'ticket',
  });

  const query = useMemo(() => {
    if (activeFilters?.length || crossFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText,
        crossFilters,
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, crossFilters, searchFilters, searchText]);

  const testAvailableFilters = (a: string) => {
    return isTurnTrackingEnabled || testIsTurnTrackingColumn(a);
  };

  const {
    tableSettings = {
      ...DEFAULT_TABLE_SETTINGS,
      columnOrder: dashboard.columns || [],
      sortBy: DEFAULT_TABLE_SORT_COLUMN_PRE_SIGN,
    },
    updateTableSettings,
  } = useTableSettings(`DASHBOARD:${dashboard.id}`);

  const queryToSave = useMemo(() => {
    if (activeFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText,
        crossFilters: [],
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, crossFilters, searchFilters, searchText]);

  const dashboardData = useMemo(
    () => ({
      charts: charts.map((item, index) => ({
        filter_id: item.id,
        sorted_index: index,
        interval: intervals[item.id],
        width: chartWidths[item.id] ? chartWidths[item.id] : undefined,
        type: item.type,
      })),
      columns: columns || [],
      filters: selectedFilters
        .filter((f) => !f.values.length)
        .map((filter) => filter.fieldId.toString()),
      id: dashboard.id,
      name: dashboard.name,
      entity: dashboard.entity,
      query,
      is_default: !!dashboard.is_default,
      creatorId: dashboard.creatorId,
    }),
    [dashboard, charts, columns, selectedFilters, intervals, chartWidths],
  );

  const firstColumn = useMemo(() => {
    if (searchFilters) {
      return (
        Object.entries(searchFilters).find(([key]) =>
          key.startsWith(FIRST_COLUMN_PREFIX),
        )?.[0] ?? ''
      );
    }
    return '';
  }, [searchFilters]);

  // useEffects
  useEffect(() => {
    if (searchFilters) {
      const defaultSortBy = getFirstColumnKey(dashboard.entity ?? '');
      dispatch(
        dashboardV2.actions.initDashboard({
          dashboard,
          searchFilters,
          tableSettings,
          defaultSortBy: { id: defaultSortBy, desc: false },
        }),
      );
    }
  }, [searchFilters, dashboard]);

  useEffect(() => {
    if (selectedFilters?.length)
      dispatch(dashboardV2.actions.setPersistedFilters(selectedFilters));
  }, [selectedFilters]);

  const availableFilters = (columns.indexOf(firstColumn) >= 0
    ? [...columns]
    : [firstColumn, ...columns]
  ).filter(testAvailableFilters);

  const searchParams = {
    page,
    pageSize,
    sortBy: { ...sortBy },
    type: dashboard.entity ?? 'ticket',
    query,
    filters: availableFilters,
  };
  const {
    data: searchResponse,
    isLoading: isLoadingSearch,
    isFetching: isSearchFetching,
    isError: isSearchError,
  } = api.endpoints.search.useQuery(searchParams, {
    skip: !isCurrentDashboardActive,
  });

  const isLoading = isFetchingFilters || isLoadingSearch;

  useEffect(() => {
    if (isSearchError || isGetFilterError) {
      toast({
        message: 'An error occurred when fetching data for this dashboard.',
        status: 'danger',
      });
    }
  }, [isSearchError, isGetFilterError]);

  const onSaveSuccess = () => {
    updateTableSettings({
      ...tableSettings,
      columnOrder: columns,
    });
    dispatch(dashboardV2.actions.setDashboardDirty(false));
  };

  const loadingContent = {
    isLoading: !isCurrentDashboardActive,
    message: 'Loading Dashboard...',
  };
  const loadingTableContent = { isLoading };

  return (
    <ContentContainer loadingContent={loadingContent}>
      <>
        <Layout align="center" justify="space-between" spacing={8}>
          <Text as="h1" m={0} variant="title">
            {dashboard.name}
          </Text>

          <Layout align="center" spacing={4}>
            <EditCharts entity={dashboard.entity} />
            <SaveDashboard
              dashboardData={dashboardData}
              query={queryToSave}
              isDashboardDirty={hasDataChanges}
              hasSavePermission={canSaveDashboard}
              onSaveSucess={onSaveSuccess}
            />
          </Layout>
        </Layout>

        <DashboardV3Filters entityType={dashboard.entity} />
        <DashboardV2Charts
          charts={charts}
          queryParams={{
            booleanQuery: searchText,
            filters: activeFilters,
            crossFilters,
            searchFilters,
            queryBuilder: undefined,
          }}
          entity={dashboard.entity}
          searchParams={searchParams}
        />
        {!isSearchError && (
          <ContentContainer loadingContent={loadingTableContent}>
            <DashboardV3SearchResults
              dashboard={dashboard}
              searchFilters={searchFilters || {}}
              isLoading={isSearchFetching}
              data={searchResponse}
              query={query}
            />
          </ContentContainer>
        )}
      </>
    </ContentContainer>
  );
};
