import React from 'react';

import { BaseItem, Item } from '../../types';
import { Layout } from '../Layout';
import { AccordionItem } from './AccordionItem';
import { BorderMode, PaddingMode } from './types';

interface Props<D = unknown> {
  /** Props used to render accordion headers and panels */
  items: Item<D>[];
  /** Border configuration */
  border?: BorderMode;
  /** Chip position*/
  chipPosition?: 'left' | 'right';
  /** If true, disables content padding */
  disableContentPadding?: boolean;
  /** If true, adds a small gap between the items */
  hasItemsGap?: boolean;
  /** Min width */
  minW?: string;
  /** If true, indents the details content relative to the summary */
  isIndented?: boolean;
  /** Header style */
  mode?: 'default' | 'compact';
  /** Padding configuration */
  padding?: PaddingMode;
  /** Search text for accordion title and search */
  search?: string;
  /** Toggle position */
  togglePosition?: 'left' | 'right';
  /** Width of the Accordion */
  w?: string;
  /** On toggle according section */
  onToggle?: (isExpanded: boolean, item: BaseItem<D>) => void;
}

export const Accordion = <D,>({
  disableContentPadding = false,
  border = 'all',
  mode = 'default',
  minW = 'accordion.min-width',
  hasItemsGap = false,
  isIndented = false,
  items,
  onToggle,
  padding = 'all',
  togglePosition,
  search,
  w = '100%',
  chipPosition = 'left',
}: Props<D>) => {
  return (
    <Layout direction="column" minW={minW} w={w} spacing={hasItemsGap ? 2 : 0}>
      {items.map((item) => (
        <AccordionItem<D>
          key={item.title}
          border={border}
          disableContentPadding={disableContentPadding}
          isIndented={isIndented}
          item={item}
          mode={mode}
          padding={padding}
          search={search}
          togglePosition={togglePosition}
          onToggle={(isExpanded) => {
            onToggle?.(isExpanded, item);
          }}
          chipPosition={chipPosition}
        />
      ))}
    </Layout>
  );
};
