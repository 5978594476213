import React from 'react';

import { Box, CollapsibleText, Layout, Mark, types } from '~/eds';
import { ClauseContent } from '~/types';

interface Props {
  activeClauseId?: types.PilotId;
  clauses: ClauseContent[];
  onClick?: (clause: ClauseContent) => void;
}

export const Clause = ({ activeClauseId, clauses, onClick }: Props) => {
  return (
    <Layout direction="column">
      {clauses.map((clause) => {
        const isActive = clause.id === activeClauseId;
        const Component = (
          <Box
            aria-current={isActive ? 'true' : undefined}
            onClick={() => onClick?.(clause)}
            key={clause.id}
            px={6}
            py={4}
            role="listitem"
          >
            <CollapsibleText
              key={clause.id}
              text={clause.content}
              limit={180}
            />
          </Box>
        );

        return isActive ? (
          <Mark key={clause.id} highlighter="ai" isActive>
            {Component}
          </Mark>
        ) : (
          Component
        );
      })}
    </Layout>
  );
};
