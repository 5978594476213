import { SEARCH_COUNT_SINGLE_VALUE_ID } from '~/components/Shared/Charts/constants';
import { getUserName, types } from '~/eds';
import { SaveSearchPermissionType, UserRoleType } from '~/enums';
import { FilterViewType } from '~/evifields';
import { SearchQuery } from '~/features/advanced-search';
import {
  getResource,
  getResources,
  JsonApiCreateResponse,
} from '~/redux/api/ApiTypes';
import { pilotV3 } from '~/services';
import {
  ChartType,
  ColumnSortOrder,
  EntityQuery,
  PilotId,
  User,
} from '~/types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import { toSortParam } from '../utils';

export type DashboardEntityType = 'ticket' | 'workflow' | 'document';
export type DashboardListAttribute = {
  created_date: string;
  creator_id: number;
  creator_name: string;
  last_modified_by_id: number | null;
  last_modified_by_name: string | null;
  entity_type: DashboardEntityType;
  modified_date: string | null;
  name: string;
  is_default: boolean;
  visibility: SaveSearchPermissionType;
};

export type DashboardTypesQueryParam = 'mine' | 'shared';

export interface DashboardChart {
  filter_id: number | string;
  sorted_index: number;
  interval?: string;
  width?: string;
  type?: ChartType;
}

export interface DashboardTableColumn {
  filter_id: number | string;
  column_index: number;
}

export interface Dashboard {
  charts: DashboardChart[];
  columns: string[];
  filters: Array<number | string>;
  filtersView?: Record<types.PilotId, FilterViewType>;
  id: string;
  name: string;
  query: EntityQuery[];
  is_default: boolean;
  userIds?: PilotId[];
  creatorId: PilotId;
  departmentIds?: PilotId[];
  visibility?: SaveSearchPermissionType;
  /**
   * TODO: make 'entity' mandatory once we have the backend support.
   * Currently, this is only available in pre sig dashboards mocks.
   */
  entity?: DashboardEntityType;
}

export interface DashboardAndPathname extends Dashboard {
  pathname: string;
}

export type DashboardAttributes = {
  entity_type?: DashboardEntityType;
  charts: DashboardChart[];
  filters: Array<number | string>;
  name: string;
  query: EntityQuery[];
  result_table: DashboardTableColumn[];
  is_default: boolean;
  user_ids?: PilotId[];
  department_ids?: PilotId[];
  visibility?: SaveSearchPermissionType;
  creator_id: PilotId;
};

export type DashboardEntityData = {
  attributes: DashboardAttributes;
  id?: string;
  type: string;
};

type DashboardEntity = {
  uuid: string;
  data: DashboardEntityData;
};

type DashboardEntityPartial = {
  uuid: string;
  attributes: Partial<DashboardAttributes>;
};

export interface DashboardListAttributeId extends DashboardListAttribute {
  id: string;
  type: string;
}

export type DashboardMetadata = {
  attributes: DashboardListAttribute;
  id: string;
  type: string;
};

export type DashboardsResponse = {
  data: DashboardMetadata[];
  link: {
    next: string;
    previous: string;
  };
  meta: SavedDashboardsMeta;
};

export interface SavedDashboardsParams {
  page: number;
  pageSize: number;
  return_defaults?: boolean;
  sortBy?: ColumnSortOrder;
  types?: DashboardTypesQueryParam[];
}

export interface SavedDashboard extends DashboardListAttribute {
  id: string;
}

export interface SavedDashboardsMeta {
  page: {
    count: number;
    current: number;
  };
  total: number;
}

export const createDefaultDashboards = (): Promise<[]> =>
  pilotV3.post('/dashboards/defaults/');

export const fetchDashboardV2: getResource<
  'dashboard',
  DashboardAttributes
> = ({ uuid }) => {
  // [DashboardMocks] - TODO remove mock once we create the pre sig dashboards in pilot.
  if (uuid === 'open_tickets' || uuid === 'completed_tickets') {
    return preSigDashboardMocks[uuid] as any;
  }
  return pilotV3.get(`/dashboards/dashboard/${uuid}/`);
};

export const generateDashboardToSave = (
  dashboard: Dashboard,
  dashboardId: string,
  query: SearchQuery,
) => {
  return {
    attributes: {
      charts: dashboard
        ? dashboard.charts?.map((d, i) => {
            return {
              filter_id: d.filter_id,
              sorted_index: i,
              interval: d.interval,
              width: d.width,
              type: d.type,
            } as DashboardChart;
          })
        : [],
      result_table: dashboard
        ? dashboard.columns?.map((f, i) => {
            return {
              filter_id: f,
              column_index: i,
            } as DashboardTableColumn;
          })
        : [],
      filters: dashboard?.filters || [],
      name: dashboard?.name || '',
      query: query || [],
      is_default: !!dashboard?.is_default,
      entity_type: dashboard?.entity,
      creator_id: dashboard.creatorId,
    },
    id: dashboardId,
    type: 'dashboard',
  };
};

export const saveDashboardChanges = async ({
  uuid,
  data,
}: DashboardEntity): Promise<any> =>
  await pilotV3.put(`/dashboards/dashboard/${uuid}/`, { data });

export const updateDashboard = async ({
  uuid,
  attributes,
}: DashboardEntityPartial): Promise<
  JsonApiCreateResponse<'dashboard', DashboardEntityPartial>
> =>
  await pilotV3.patch(`dashboards/dashboard/${uuid}/`, {
    data: { attributes, id: uuid, type: 'dashboard' },
  });

export const saveAsDashboard = async (
  data: DashboardEntityData,
): Promise<JsonApiCreateResponse<'save-as-dashboard', DashboardEntityData>> =>
  await pilotV3.post('/dashboards/dashboard/', { data });

export const fetchSavedDashboards: getResources<
  'dashboard_metadata',
  DashboardListAttribute,
  SavedDashboardsParams,
  SavedDashboardsMeta
> = ({
  page,
  pageSize,
  return_defaults = false,
  sortBy = DEFAULT_TABLE_SORT_COLUMN_ORDER,
  types,
}) => {
  return pilotV3.get('/dashboards/', {
    params: {
      'page[number]': page,
      'page[size]': pageSize,
      return_defaults,
      sort: toSortParam(sortBy),
      types,
    },
  });
};

export const deleteDashboard = ({ uuid }: { uuid: string }): Promise<void> =>
  pilotV3.remove(`dashboards/dashboard/${uuid}/`);

const preSigDashboardMocks = {
  open_tickets: {
    meta: {
      is_complex_query: false,
    },
    data: {
      id: 'open_tickets',
      type: 'dashboard',
      attributes: {
        name: 'Open Tickets',
        entity_type: 'ticket',
        charts: [
          {
            filter_id: SEARCH_COUNT_SINGLE_VALUE_ID,
            sorted_index: 0,
            width: '3',
          },
          {
            filter_id: 'ticket_age_Ticket',
            sorted_index: 1,
          },
          {
            filter_id: 'internal_turns_age_Ticket',
            sorted_index: 2,
          },
          {
            filter_id: 'external_turns_age_Ticket',
            sorted_index: 3,
          },
          {
            filter_id: 'current_party_type_Ticket',
            sorted_index: 4,
          },
          {
            filter_id: 'workflow_name_Ticket',
            sorted_index: 5,
          },
          {
            filter_id: 'submitter_department_Ticket',
            sorted_index: 6,
          },
          {
            filter_id: 'assignee_name_Ticket',
            sorted_index: 7,
          },
          {
            filter_id: 'stage_Ticket',
            sorted_index: 8,
          },
          {
            filter_id: 'ticket_last_activity_date_Ticket',
            sorted_index: 9,
          },
        ],
        filters: [
          'name_Ticket',
          'ticket_submit_date_Ticket',
          'submitter_Ticket',
          'submitter_department_Ticket',
          'ticket_last_activity_date_Ticket',
          'stage_Ticket',
        ],
        query: [
          {
            type: 'filter',
            id: 'ticket_completed_date_Ticket',
            operator: 'is_blank',
            value: {
              value_list: [],
              value_meta: {},
            },
          },
          {
            type: 'operator',
            value: 'and',
          },
          {
            type: 'filter',
            id: 'ticket_cancelled_date_Ticket',
            operator: 'is_blank',
            value: {
              value_list: [],
              value_meta: {},
            },
          },
        ],
        result_table: [
          {
            filter_id: 'name_Ticket',
            column_index: 0,
          },
          {
            filter_id: 'ticket_age_Ticket',
            column_index: 1,
          },
          {
            filter_id: 'internal_turns_age_Ticket',
            column_index: 2,
          },
          {
            filter_id: 'external_turns_age_Ticket',
            column_index: 3,
          },
          {
            filter_id: 'current_party_type_Ticket',
            column_index: 4,
          },
          {
            filter_id: 'ticket_submit_date_Ticket',
            column_index: 5,
          },
          {
            filter_id: 'submitter_Ticket',
            column_index: 6,
          },
          {
            filter_id: 'submitter_department_Ticket',
            column_index: 7,
          },
          {
            filter_id: 'ticket_last_activity_date_Ticket',
            column_index: 8,
          },
          {
            filter_id: 'stage_Ticket',
            column_index: 9,
          },
        ],
        is_default: true,
        visibility: 'PRIVATE',
        user_ids: [],
        department_ids: [],
      },
    },
  },
  completed_tickets: {
    meta: {
      is_complex_query: false,
    },
    data: {
      id: 'completed_tickets',
      type: 'dashboard',
      attributes: {
        entity_type: 'ticket',
        name: 'Completed Tickets',
        charts: [
          {
            filter_id: SEARCH_COUNT_SINGLE_VALUE_ID,
            sorted_index: 0,
            width: '3',
          },
          {
            filter_id: 'ticket_age_Ticket',
            sorted_index: 1,
          },
          {
            filter_id: 'internal_turns_age_Ticket',
            sorted_index: 2,
          },
          {
            filter_id: 'external_turns_age_Ticket',
            sorted_index: 3,
          },
          {
            filter_id: 'ticket_completed_date_Ticket',
            sorted_index: 4,
            type: 'line',
          },
          {
            filter_id: 'workflow_name_Ticket',
            sorted_index: 5,
          },
          {
            filter_id: 'submitter_department_Ticket',
            sorted_index: 6,
          },
        ],
        filters: [
          'ticket_submit_date_Ticket',
          'ticket_last_activity_date_Ticket',
          'workflow_name_Ticket',
          'submitter_Ticket',
          'submitter_department_Ticket',
        ],
        query: [
          {
            type: 'filter',
            id: 'ticket_completed_date_Ticket',
            operator: 'is_not_blank',
            value: {
              value_list: [],
              value_meta: {},
            },
          },
        ],
        result_table: [
          {
            filter_id: 'name_Ticket',
            column_index: 0,
          },
          {
            filter_id: 'ticket_age_Ticket',
            column_index: 1,
          },
          {
            filter_id: 'internal_turns_age_Ticket',
            column_index: 2,
          },
          {
            filter_id: 'external_turns_age_Ticket',
            column_index: 3,
          },
          {
            filter_id: 'ticket_submit_date_Ticket',
            column_index: 4,
          },
          {
            filter_id: 'submitter_Ticket',
            column_index: 5,
          },
          {
            filter_id: 'submitter_department_Ticket',
            column_index: 6,
          },
          {
            filter_id: 'ticket_last_activity_date_Ticket',
            column_index: 7,
          },
          {
            filter_id: 'stage_Ticket',
            column_index: 8,
          },
          {
            filter_id: 'ticket_completed_date_Ticket',
            column_index: 9,
          },
        ],
        is_default: true,
        visibility: 'PRIVATE',
        user_ids: [],
        department_ids: [],
      },
    },
  },
};

// [DashboardMocks] - TODO remove mock once we create the pre sig dashboards in pilot.
export const preSigMocks = (currentUser: User, isRBACEnabled: boolean) => {
  if (
    (!isRBACEnabled && currentUser.role === UserRoleType.PowerUserWithEdit) ||
    currentUser.role === UserRoleType.PowerUserWithReadOnly ||
    currentUser.role === UserRoleType.Reviewer ||
    currentUser.role === UserRoleType.WorkflowOnly
  ) {
    return [];
  }
  return [
    {
      name: 'Open Tickets',
      creator_id: currentUser.id,
      creator_name: getUserName(currentUser),
      created_date: '2023-06-16T16:15:26.459184-03:00',
      modified_date: '2023-06-16T16:15:26.459206-03:00',
      is_default: true,
      last_modified_by_id: null,
      last_modified_by_name: null,
      visibility: 'PRIVATE',
      id: 'open_tickets',
      entity_type: 'ticket',
    },
    {
      name: 'Completed Tickets',
      creator_id: currentUser.id,
      creator_name: getUserName(currentUser),
      created_date: '2023-06-16T16:15:26.459184-03:00',
      modified_date: '2023-06-16T16:15:26.459206-03:00',
      is_default: true,
      last_modified_by_id: null,
      last_modified_by_name: null,
      visibility: 'PRIVATE',
      id: 'completed_tickets',
      entity_type: 'ticket',
    },
  ];
};
