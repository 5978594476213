import React, { memo } from 'react';

import { BaseItem, SharedDescriptionListProps } from '../../../types';
import { DescriptionList } from '../../DescriptionList';
import { Accordion } from '../Accordion';

type DescriptionListProps = React.ComponentProps<typeof DescriptionList>;
interface Item extends BaseItem {
  terms: DescriptionListProps['terms'];
  isExpanded?: boolean;
}

interface Props extends SharedDescriptionListProps {
  /** Accordion items of description list terms */
  items: Item[];
  /** If passed renders component to be embedded in a parent component. */
  isEmbedded?: boolean;
  search?: string;
  onToggle?: (isExpanded: boolean, item: BaseItem) => void;
}

export const DescriptionListAccordion = memo(
  ({
    isEmbedded,
    items,
    search,
    onToggle,
    ...restDescriptionListProps
  }: Props) => {
    const mappedItems = items.map(({ title, terms, isExpanded }) => ({
      title,
      content: (
        <DescriptionList
          {...restDescriptionListProps}
          terms={terms}
          search={search}
        />
      ),
      isExpanded,
    }));

    return <Accordion isIndented items={mappedItems} onToggle={onToggle} />;
  },
);
