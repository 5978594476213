import { Members } from 'pusher-js';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePusherContext } from '~/contexts';
import { useCurrentUser } from '~/hooks';
import { actions, selectors } from '~/redux';
import { Uuid } from '~/types';

const events = {
  EDIT_EVENT: 'client-edit',
  EDIT_STOP_EVENT: 'client-edit-stop',
};

interface Member {
  id: string;
}

interface Props {
  /** Supported document editors */
  documentEditor: 'ej2' | 'wopi';
  /** Document version UUID */
  versionId: Uuid;
  /** Predicate to determine if the members (e.g. me) can edit */
  canEdit?: (members: Members) => boolean;
}

/**
 * This hook integrates with Pusher presence channel to track editors on document versions on specific document editors (e.g. ej2, wopi).
 *
 * It returns the `edit` and `stopEdit` callbacks to trigger editing and stop editing events on the appropriate Pusher channels by the `currentUserId`.
 *
 * It binds and listens to the presence channel and keeps editors (managed as a `Set`) in sync with redux store (keyed on `versionId` and `documentEditor`).
 *
 * Pusher note: We use a combination of private and presence channels to track editors. The private channel is used to broadcast edit and stop edit events, and the presence channel is used to track members (without ever being directly subscribed).
 */
export const useTrackDocumentEditors = ({
  canEdit = () => true,
  documentEditor,
  versionId,
}: Props) => {
  const { getPusher, getPresenceChannelMembers } = usePusherContext();
  const pusher = getPusher();
  const [isReady, setIsReady] = useState(false);

  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const currentUserId = currentUser.id.toString();

  const editors = useSelector(
    selectors.selectDocumentEditors({
      documentEditor,
      versionId,
    }),
  );

  const addEditor = (id: Uuid) => {
    dispatch(
      actions.addEditor({
        documentEditor,
        editor: id,
        versionId,
      }),
    );
  };

  const removeEditor = (id: Uuid) => {
    dispatch(
      actions.removeEditor({
        documentEditor,
        editor: id,
        versionId,
      }),
    );
  };

  const setEditors = (ids: Set<Uuid>) => {
    dispatch(
      actions.setEditors({
        documentEditor,
        editors: ids,
        versionId,
      }),
    );
  };

  const broadcastChannelName = `private-editors-${documentEditor}-${versionId}`;
  const presenceChannelName = `presence-editors-${documentEditor}-${versionId}`;

  const broadcastChannel = useMemo(() => {
    const channel = pusher.subscribe(broadcastChannelName);
    channel.bind(events.EDIT_EVENT, ({ id }: Member) => addEditor(id));
    channel.bind(events.EDIT_STOP_EVENT, ({ id }: Member) => removeEditor(id));
    return channel;
  }, [broadcastChannelName]);

  // load the initial editors (presence channel members) and cleanup appropriately
  useEffect(() => {
    getPresenceChannelMembers(pusher, presenceChannelName, true).then(
      (members) => {
        const initialEditors = new Set(Object.keys(members.members));
        // If the user just entered the channel to extract the editors, exclude the current user if it's in there less than 3 seconds
        const isOnlyExtractingEditors =
          new Date().getTime() - new Date(members.me.info.joinDate).getTime() <
          3000;
        // exclude the current/active presence member if not an editor
        if (
          isOnlyExtractingEditors &&
          members.me.id === currentUserId &&
          !editors.has(currentUserId)
        ) {
          initialEditors.delete(currentUserId);
        }
        setEditors(initialEditors);
        setIsReady(true);
      },
    );

    window.addEventListener('beforeunload', stopEdit);

    return () => {
      stopEdit();
      pusher.unsubscribe(broadcastChannelName);
      pusher.unsubscribe(presenceChannelName);
      window.removeEventListener('beforeunload', stopEdit);
    };
  }, []);

  const edit = () => {
    getPresenceChannelMembers(pusher, presenceChannelName, false).then(
      (members) => {
        if (canEdit(members)) {
          broadcastChannel.trigger(events.EDIT_EVENT, {
            id: currentUserId,
          });
          addEditor(currentUserId);
        }
      },
    );
  };

  const stopEdit = () => {
    broadcastChannel.trigger(events.EDIT_STOP_EVENT, {
      id: currentUserId,
    });
    removeEditor(currentUserId);
    pusher.unsubscribe(presenceChannelName);
  };

  return {
    isReady,
    editors,
    edit,
    stopEdit,
  };
};
