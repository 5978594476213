// There's a ton of file extensions out there, but these are the ones that are used by EDS in markup
export enum FileExtensionType {
  Csv = '.csv',
  Doc = '.doc',
  Docx = '.docx',
  Jpeg = '.jpeg',
  Jpg = '.jpg',
  Pdf = '.pdf',
  Png = '.png',
  Ppt = '.ppt',
  Pptm = '.pptm',
  Pptx = '.pptx',
  Tif = '.tif',
  Tiff = '.tiff',
  Webp = '.webp',
  Xls = '.xls',
  Xlsm = '.xlsm',
  Xlsx = '.xlsx',
}
