import { sylvanus } from '~/services';
import { PilotId } from '~/types';

import {
  DynamoDbPageTargetEntityResponseItem,
  TargetEntityResponseItem,
} from '../../types/sylvanus/noRbac';

const MAX_ITERATION_COUNT = 3;

export const getPromptModelFieldIds = async (): Promise<PilotId[]> => {
  let postDataCursor = null;

  const responses: TargetEntityResponseItem[] = [];
  let shouldEnd = false;
  let iterationCount = 0;

  while (!shouldEnd && iterationCount < MAX_ITERATION_COUNT) {
    const response: DynamoDbPageTargetEntityResponseItem = await sylvanus.get(
      `/model/target-entities?cursor=${postDataCursor}`,
    );

    const { items, cursor } = response;
    postDataCursor = cursor;

    shouldEnd = cursor === null;

    responses.push(...items);
    iterationCount++;
  }

  return responses.map((item) => Number(item.target_entity.id));
};
