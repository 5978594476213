import { FieldId } from '~/evifields';
import { createBooleanTextSearchFilter } from '~/features/filters/searchFiltersUtils';
import { SearchFilter } from '~/types';

import {
  fetchFiltersV2,
  SearchFilterApiResponse,
  SearchType,
} from '../methods';
import { toEviFilterType } from '../methods/searchV3';

export const getFiltersV2 = async ({ type }: { type: SearchType }) => {
  const response = await fetchFiltersV2({ type });

  const filterRecord: Record<FieldId, SearchFilter> = Object.fromEntries(
    response.data.map((filter) => [
      filter.id,
      convertSearchV2ToSearchFilter(filter.attributes, filter.id),
    ]),
  );
  if (type === 'document') {
    const booleanTextSearchFilter = createBooleanTextSearchFilter();
    filterRecord[booleanTextSearchFilter.id] = booleanTextSearchFilter;
  }

  return filterRecord;
};

function convertSearchV2ToSearchFilter(
  filterV2: SearchFilterApiResponse,
  id: FieldId,
): SearchFilter {
  const { clause_text_search } = filterV2;
  const settings = clause_text_search
    ? { textSearchFilter: clause_text_search }
    : {};
  return {
    id,
    is_chartable: filterV2.is_chartable,
    is_requestable_column: filterV2.is_requestable_column,
    operators: filterV2.operators.map((op) =>
      `${op.cardinality}` === 'infinity'
        ? { ...op, cardinality: Number.POSITIVE_INFINITY }
        : op,
    ),
    label: filterV2.label,
    section: filterV2.section,
    settings,
    type: toEviFilterType(filterV2.type),
  };
}
