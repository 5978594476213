import { capitalize, get, isEqual, union } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TODO: Try to move this import from DashboardV2
import { toFilter } from '~/components/DashboardV2/DashboardV2.utils';
import { EditCharts } from '~/components/DashboardV2/EditCharts';
import SaveDashboard from '~/components/DashboardV2/SaveDashboard';
import BaseLoadView from '~/components/Shared/BaseLoadView';
import BaseSaveView from '~/components/Shared/BaseSaveView';
import { Charts } from '~/components/Shared/Charts';
import { DEFAULT_SORT_BY, STATIC_COLUMNS } from '~/constants/search';
import { Box, ContentContainer, Layout, Text } from '~/eds';
import { TableViewContextType } from '~/enums';
import { ClauseValue, Filter } from '~/evifields';
import {
  useCurrentUser,
  useDocumentDetails,
  usePinnedFilters,
  useTableState,
} from '~/hooks';
import { api, selectors } from '~/redux';
import { SearchItem, SearchType } from '~/redux/api/methods';
import {
  Dashboard as DashboardEntity,
  Dashboard as DashboardType,
} from '~/redux/api/methods/dashboardV2';
import { buildQuery } from '~/redux/api/methods/searchV3';
import { chatbotSlice } from '~/redux/slices/chatbot';
import dashboardV2 from '~/redux/slices/dashboardV2';
import { Chart, CrossFilter, Nullable, QueryOperatorEntity } from '~/types';
import {
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
} from '~/types/modal.types';
import { testIsAdmin } from '~/utils/user';

import { QueryGroup } from '../advanced-search';
import { BooleanSearchBar } from '../advanced-search/boolean-search-bar';
import { CrossFilters } from '../advanced-search/cross-filters';
import {
  attachRenderersAndSerializers,
  Filters,
} from '../advanced-search/filters';
import { QueryBuilder } from '../advanced-search/query-builder';
import { ResultsTable } from '../advanced-search/results-table';
import { DEFAULT_CHAT_CONTEXT } from '../ask-anything';
import { useDocumentsBulkActionsPanel } from '../search';
import { ReadOnlyComplexQuery } from '../search/QueryBuilder';

export const Dashboard = ({ dashboard }: { dashboard: DashboardType }) => {
  const dispatch = useDispatch();

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFiltersV2.useQuery({
    type: dashboard.entity as SearchType,
  });
  const [
    fetchCurrentTableView,
    tableViewResult,
  ] = api.endpoints.getCurrentTableView.useLazyQuery();
  const { data: tableView, isFetching: isFetchingTableView } = tableViewResult;
  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const {
    getPinnedFilters,
    updatePinnedFilters,
    pinnedFiltersIds,
    isFetching: isFetchingPinnedFilters,
  } = usePinnedFilters(searchFilters, TableViewContextType.EVISEARCH_SEARCH);

  const {
    tableState,
    handleUpdateTableState,
    partialUpdateTableState,
    clearSelectedRows,
  } = useTableState({
    id: `ResultsTable:${dashboard.id}`,
    columnOrder: dashboard.columns,
    sortBy: DEFAULT_SORT_BY[dashboard.entity as 'document' | 'ticket'],
  });

  const user = useCurrentUser();
  const canSaveDashboard = testIsAdmin(user) || dashboard.creatorId === user.id;

  const activeDashboard = useSelector(selectors.selectActiveDashboard);
  const activeFilters = useSelector(selectors.selectDashboardActiveFilters);
  const queryBuilder = useSelector(selectors.selectQueryBuilder);
  const queryBuilderPanelVisible = useSelector(
    selectors.selectQueryBuilderPanelVisible,
  );
  const searchText = useSelector(selectors.selectDashboardSearchText);
  const selectedFilters = useSelector(selectors.selectDashboardSelectedFilters);
  const intervals = useSelector(selectors.selectDashboardIntevals);
  const chartWidths = useSelector(selectors.selectDashboardChartWidths);
  const charts = useSelector(selectors.selectDashboardCharts);
  const crossFilters = useSelector(selectors.selectDashboardCrossFilters);
  const currentChart = useSelector(selectors.selectDashboardCurrentChart);
  const hasDataChanges = useSelector(selectors.selectDashboardDirty);
  const resultsCache = useSelector(selectors.selectDashboardResultsCache);

  const featuredClauses = useMemo(() => {
    return (activeFilters.filter(
      (filter) =>
        filter.fieldId === 'clause' && filter.operatorId === 'contains_any',
    ) as Filter<ClauseValue>[]).map(
      (clauseFilter) => clauseFilter.values[0]?.provision ?? '',
    );
  }, [activeFilters]);

  const fieldsWithLazyLoad = useMemo(
    () =>
      attachRenderersAndSerializers({
        filters: Object.values(searchFilters || {}),
        type: dashboard.entity as SearchType,
      }),
    [searchFilters],
  );

  const [isLoadViewVisible, setIsLoadViewVisible] = useState(false);
  const [isSaveViewVisible, setIsSaveViewVisible] = useState(false);
  const [documentDetails, setDocumentDetails] = useState<Nullable<SearchItem>>(
    null,
  );

  const query = useMemo(() => {
    if (!searchFilters) return [];

    return (
      queryBuilder ??
      buildQuery({
        booleanQuery: searchText ?? '',
        filters: activeFilters,
        searchFilters,
      })
    );
  }, [searchText, activeFilters, queryBuilder, searchFilters]);

  const requestFilters = [
    ...STATIC_COLUMNS[dashboard.entity as 'document' | 'ticket'],
    ...tableState.columnOrder,
  ];

  const {
    data: searchResponse,
    isFetching: isFetchingSearch,
    refetch: performSearch,
  } = api.endpoints.search.useQuery({
    pageSize: tableState.pageSize,
    page: tableState.pageIndex,
    sortBy: tableState.sortBy,
    type: dashboard.entity as any,
    query,
    filters: requestFilters,
  });

  const {
    panel: bulkActionPanel,
    limitExceededModal,
    getBulkActions,
  } = useDocumentsBulkActionsPanel({
    selectedDocumentIds: tableState?.selectedIds,
    isAllSelected: tableState?.isAllSelected,
    totalCount: searchResponse?.meta?.total ?? 0,
    query,
    searchAPI: 'v3',
    onActionCompleted: () => {
      clearSelectedRows();
      performSearch();
    },
    resultsCache,
  });

  const { DetailsPanel, panelProps } = useDocumentDetails({
    index: searchResponse?.results?.indexOf(documentDetails!) ?? -1,
    count: searchResponse?.results?.length ?? 0,
    handlers: get(
      documentDetails,
      'selected_field_values.document_handler_id_Document.value_structured',
      [],
    ),
    featuredClauses,
    onHide: () => setDocumentDetails(null),
    onNavigate: (index) =>
      setDocumentDetails(searchResponse?.results?.[index] ?? null),
  });

  const dashboardData: DashboardEntity = useMemo(
    () => ({
      charts: charts.map((item, index) => ({
        filter_id: item.id,
        sorted_index: index,
        interval: intervals[item.id],
        width: chartWidths[item.id] ? chartWidths[item.id] : undefined,
        type: item.type,
      })),
      columns: tableState.columnOrder,
      filters: selectedFilters
        .filter((f) => !f.values.length && !f.operatorId)
        .map((f) => f.fieldId.toString()),
      id: dashboard.id,
      name: dashboard.name,
      entity: dashboard.entity,
      query,
      is_default: !!dashboard.is_default,
      creatorId: dashboard.creatorId,
    }),
    [
      dashboard,
      charts,
      tableState.columnOrder,
      selectedFilters,
      intervals,
      chartWidths,
    ],
  );

  const queryToSave = useMemo(() => {
    if (queryBuilder && searchFilters) {
      return queryBuilder;
    } else if (activeFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText,
        crossFilters: [],
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, crossFilters, queryBuilder, searchFilters, searchText]);

  const handleBooleanSearch = (value: string) => {
    dispatch(dashboardV2.actions.setSearchText(value));
    partialUpdateTableState({ pageIndex: 1 });
  };
  const handleColumnOrderChange = (columnOrder: string[]) => {
    partialUpdateTableState({ columnOrder });
    dispatch(dashboardV2.actions.setColumnOrder(columnOrder));
    dispatch(dashboardV2.actions.setDashboardDirty(true));
    if (dashboard.is_default) {
      updateTableView({
        context: TableViewContextType.EVISEARCH_SEARCH,
        fields: columnOrder.map((field_id, column_number) => ({
          field_id,
          column_number,
        })),
      });
    }
  };
  const handleFiltersChange = (filters: Filter[]) => {
    dispatch(dashboardV2.actions.setSelectedFilters(filters));
    partialUpdateTableState({ pageIndex: 1 });
    dispatch(dashboardV2.actions.setDashboardDirty(true));
  };
  const handleSearchQueryBuilder = (
    groupsEntity: (QueryGroup | QueryOperatorEntity)[],
  ) => {
    const queryBuilderPayload = buildQuery({
      booleanQuery: '',
      filters: [],
      searchFilters,
      queryBuilder: groupsEntity,
    });
    dispatch(dashboardV2.actions.setQueryBuilder(queryBuilderPayload));
    dispatch(dashboardV2.actions.setDashboardDirty(true));
    dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(false));
    dispatch(dashboardV2.actions.setSearchText(''));

    partialUpdateTableState({ pageIndex: 1 });
  };

  const handleRearrangeCharts = useCallback(
    (updatedFilters: Chart[]) => {
      if (
        !isEqual(
          charts.map((c) => c.id),
          updatedFilters.map((c) => c.id),
        )
      ) {
        dispatch(dashboardV2.actions.setDashboardDirty(true));
      }
      dispatch(dashboardV2.actions.setChartFilters(updatedFilters));
    },
    [charts],
  );

  const handleCrossFiltersChange = (filters: Filter[]) => {
    dispatch(dashboardV2.actions.setCrossFilters(filters));
  };

  const handleSelectCrossFilter = (
    crossFilters: CrossFilter[],
    fieldId: string,
  ) => {
    const newFilters = crossFilters.map((crossFilter) => {
      return toFilter(
        crossFilter.fieldId,
        crossFilter.value!,
        crossFilter.type,
      );
    });
    dispatch(dashboardV2.actions.setCrossFilters(newFilters));
    dispatch(dashboardV2.actions.setCurrentChart(fieldId));
  };

  useEffect(() => {
    if (!searchFilters) return;

    const initializeDashboard = (filters: string[] = []) => {
      dispatch(
        dashboardV2.actions.initDashboard({
          dashboard: {
            ...dashboard,
            filters: union(dashboard.filters, filters),
          },
          searchFilters,
          tableSettings: { ...tableState, columnWidths: {} },
          defaultSortBy:
            DEFAULT_SORT_BY[dashboard.entity as 'document' | 'ticket'],
        }),
      );
    };

    if (dashboard.is_default && dashboard.name === 'Documents') {
      getPinnedFilters()
        .unwrap()
        .then((res) => initializeDashboard(res?.data));

      fetchCurrentTableView({
        context: TableViewContextType.EVISEARCH_SEARCH,
      });
    } else {
      getPinnedFilters();
      initializeDashboard();
    }

    dispatch(
      chatbotSlice.actions.setContext([
        { ...DEFAULT_CHAT_CONTEXT, primary: true },
      ]),
    );
  }, [searchFilters, dashboard]);

  const isDocumentDashboard = dashboard.entity === 'document';

  useEffect(() => {
    if (isDocumentDashboard) {
      if (tableView?.length) {
        const tableViewFieldIds = tableView.map(
          (tableViewItem) => tableViewItem.field_id,
        ) as string[];

        partialUpdateTableState({ columnOrder: tableViewFieldIds });
      }
    }
  }, [tableView, isDocumentDashboard]);

  const isCurrentDashboardActive = activeDashboard.id === dashboard.id;

  const columnOrderIds = useMemo(
    () =>
      tableState.columnOrder.map((field_id, column_number) => ({
        field_id,
        column_number,
      })),
    [tableState.columnOrder],
  );

  useEffect(() => {
    if (searchResponse?.results) {
      dispatch(
        dashboardV2.actions.patchEvisearchResultsCache(searchResponse.results),
      );
      dispatch(dashboardV2.actions.setSearchText(searchText ?? ''));

      if (searchResponse.results.length > 0) {
        dispatch(
          chatbotSlice.actions.setContext([
            DEFAULT_CHAT_CONTEXT,
            {
              label: 'All Results',
              query,
              title: `All Results (${searchResponse?.meta?.total})`,
              icon: 'search',
              primary: true,
              searchEntity: dashboard.entity as SearchType,
              selectedIds: [],
              id: dashboard.id,
            },
          ]),
        );
      }
    }
  }, [searchResponse]);

  const isLoading =
    isFetchingFilters || isFetchingSearch || isFetchingTableView;

  return (
    <ContentContainer
      loadingContent={{
        isLoading: !isCurrentDashboardActive,
      }}
    >
      <>
        <Layout align="center" justify="space-between" spacing={8}>
          <Text as="h1" m={0} variant="title">
            {capitalize(dashboard.name)}
          </Text>
          <Layout align="center" spacing={4}>
            <EditCharts entity={dashboard.entity} />
          </Layout>
        </Layout>
        <Layout direction="row" spacing={4} justify="space-between">
          <Box w="100%">
            {isDocumentDashboard && (
              <BooleanSearchBar
                value={searchText ?? ''}
                onSubmit={handleBooleanSearch}
                isSubmitting={isLoading || !!queryBuilder}
              />
            )}
          </Box>

          <SaveDashboard
            dashboardData={dashboardData}
            query={queryToSave}
            isDashboardDirty={hasDataChanges}
            hasSavePermission={canSaveDashboard}
            onSaveSucess={() =>
              dispatch(dashboardV2.actions.setDashboardDirty(false))
            }
          />
        </Layout>

        {queryBuilder ? (
          <>
            <ReadOnlyComplexQuery
              queryBuilder={queryBuilder}
              isQueryBuilderSupported={true}
              searchFilters={searchFilters || {}}
              onClear={() => {
                dispatch(dashboardV2.actions.setQueryBuilder(undefined));
                dispatch(
                  dashboardV2.actions.setQueryBuilderPanelVisible(false),
                );
              }}
              onEdit={() => {
                dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(true));
              }}
            />

            <Layout align="center" spacing={2} role="group">
              <CrossFilters
                searchFilters={fieldsWithLazyLoad}
                filters={crossFilters}
                onChange={handleCrossFiltersChange}
              />
            </Layout>
          </>
        ) : (
          <Filters
            isLoading={isFetchingFilters || isFetchingPinnedFilters}
            selectedFilters={selectedFilters}
            searchFilters={fieldsWithLazyLoad}
            pinnedFiltersIds={pinnedFiltersIds}
            crossFilters={crossFilters}
            onChange={handleFiltersChange}
            onChangeCrossFilters={handleCrossFiltersChange}
            onQueryButtonClick={() => {
              dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(true));
            }}
            onUpdatePinnedFilters={(fieldIds) => updatePinnedFilters(fieldIds)}
          />
        )}
        <Charts
          charts={charts}
          entity={dashboard.entity as SearchType}
          intervals={intervals}
          chartWidths={chartWidths}
          onCrossFiltersChange={handleSelectCrossFilter}
          onIntervalChange={(action, filter) => {
            dispatch(
              dashboardV2.actions.setChartInterval({
                id: filter.id,
                value: action.value,
              }),
            );
          }}
          onChartWidthChange={(filter, newWidth) => {
            dispatch(
              dashboardV2.actions.setChartWidth({
                id: filter.id,
                value: newWidth,
              }),
            );
          }}
          onChatTypeChange={(filter, newType) => {
            dispatch(
              dashboardV2.actions.setChartType({
                id: filter.id,
                value: newType,
              }),
            );
          }}
          onRearrangeCharts={handleRearrangeCharts}
          onRemoveItem={(searchFilter) => {
            dispatch(dashboardV2.actions.deleteChart(searchFilter));
          }}
          queryParams={{
            booleanQuery: searchText,
            filters: activeFilters,
            crossFilters,
            searchFilters,
            queryBuilder,
          }}
          searchParams={{
            page: tableState.pageIndex,
            pageSize: tableState.pageSize,
            sortBy: tableState.sortBy,
            type: dashboard.entity as SearchType,
            query,
            filters: requestFilters,
          }}
          currentChart={currentChart}
        />
        <ResultsTable
          data={searchResponse}
          id={dashboard.id}
          isLoading={isLoading}
          enablePersistedTable={dashboard.is_default}
          entity={dashboard.entity as SearchType}
          query={query}
          searchFilters={searchFilters ?? {}}
          state={{
            columnOrder: tableState.columnOrder,
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
            sortBy: [tableState.sortBy],
            selectedRowIds: tableState.selectedIds,
          }}
          getBulkActions={isDocumentDashboard ? getBulkActions : undefined}
          onUpdateState={handleUpdateTableState}
          onSetColumnOrder={handleColumnOrderChange}
          onRowDetailsClick={
            isDocumentDashboard ? setDocumentDetails : undefined
          }
          onSaveView={
            isDocumentDashboard ? () => setIsSaveViewVisible(true) : undefined
          }
          onLoadView={
            isDocumentDashboard ? () => setIsLoadViewVisible(true) : undefined
          }
        />
        {DetailsPanel && <DetailsPanel {...panelProps} />}
        {queryBuilderPanelVisible && (
          <QueryBuilder
            query={queryBuilder}
            pinnedFiltersIds={pinnedFiltersIds}
            searchFilters={fieldsWithLazyLoad}
            onQueryChange={handleSearchQueryBuilder}
            onHide={() =>
              dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(false))
            }
          />
        )}

        {limitExceededModal}
        {bulkActionPanel}

        {isSaveViewVisible && (
          <BaseSaveView
            context={TableViewContextType.EVISEARCH_SEARCH}
            fields={columnOrderIds}
            hideModal={() => setIsSaveViewVisible(false)}
            modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE}
          />
        )}

        {isLoadViewVisible && (
          <BaseLoadView
            context={TableViewContextType.EVISEARCH_SEARCH}
            modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH}
            hideModal={() => setIsLoadViewVisible(false)}
            onViewSelected={() =>
              fetchCurrentTableView({
                context: TableViewContextType.EVISEARCH_SEARCH,
              })
            }
          />
        )}
      </>
    </ContentContainer>
  );
};
