import axios from 'axios';

import { getProvisionTypes } from '~/redux/api/methods/document';

import { pilot } from '../../../services';
import {
  getAlert,
  getAlertCustomFieldsByClient,
  getFiltersFields,
} from '../../AlertsPage/AlertsPage.data';

export const getAlertModalData = (userClient, alertId) => {
  const requestsArray = [
    getAlertCustomFieldsByClient(userClient),
    getFiltersFields(),
    getProvisionTypes(),
  ];

  if (alertId) requestsArray.push(getAlert(alertId));

  return pilot
    .concurrentRequests(requestsArray)
    .then(
      axios.spread((userFieldsRes, sectionsRes, provisionsRes, alertRes) => ({
        customFieldsRes: userFieldsRes,
        sections: sectionsRes,
        provisionOptions: provisionsRes,
        alertData: alertRes,
      })),
    )
    .catch((err) => {
      throw err;
    });
};
