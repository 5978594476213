import { noop } from 'lodash';
import {
  GlobalWorkerOptions,
  PDFDocumentProxy,
  version as pdfjsVersion,
} from 'pdfjs-dist/legacy/build/pdf';
import React, { useEffect, useMemo, useState } from 'react';
import { ScaledPosition } from 'react-pdf-highlighter';
import { v4 as uuid } from 'uuid';

import { Box, Icon, Layout, Text, types } from '~/eds';
import { AlgorithmStatusType } from '~/enums';
import { Highlight as HighlightType, ScaledHighlight } from '~/types';
import { onEventBusEvent } from '~/utils/pdfjs';

import { ContextMenu, ContextMenuType } from '../ContextMenu';
import LoadingSpinner from '../Icons/LoadingSpinner';
import Highlight from './components/Highlight';
import PdfHighlighter from './components/PdfHighlighter';
import { usePdfHighlighterContext } from './components/PdfHighlighterContext';
import { mapPdfHighlights } from './utils/bridge';

if (typeof window !== 'undefined' && 'Worker' in window) {
  GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`;
}
interface Props {
  /** sets isActive to highlight component.
   * this prop is only used if renderHighlight function prop is provided.  */
  activeHighlightId?: types.Uuid;
  /** Flag to indicate an error occurred when loading the document */
  errorLoadDocument: boolean;
  /** Highlights to be displayed */
  highlights: HighlightType[];
  /** PDF file */
  pdfFile: PDFDocumentProxy;
  /** PDF scale value */
  pdfScaleValue: string;
  /** Will have visual indication on about the status of the OCR. */
  OCRStatus: AlgorithmStatusType;
  /** function to override the default highlight rendering function. */
  renderHighlight?: (highlight: ScaledHighlight) => JSX.Element;
  /** function to indicate the the PDF has been loaded. */
  setIsDocumentLoading: (isLoading: boolean) => void;
  /** onHighlightClick */
  onHighlightClick?: (highlightId: string) => void;
  /** onMouseDownHandler on the viewer. */
  onMouseDownHandler?: () => void;
  /** function used to render the ContextMenu. Components rendered in the ContextMenuType[] array can access the text and the highlight
   * coordinates on the params of the function.
   */
  contextMenu?: (
    position: ScaledPosition,
    text: string,
    hideTipAndSelection: () => void,
  ) => ContextMenuType[];
  /** queued status */
  queued?: boolean;
}

const PdfViewerHighlighter = ({
  contextMenu,
  activeHighlightId,
  errorLoadDocument,
  highlights,
  pdfFile,
  pdfScaleValue,
  OCRStatus,
  renderHighlight,
  setIsDocumentLoading,
  onHighlightClick,
  onMouseDownHandler,
}: Props) => {
  const [isLoading, setIsLoading] = useState(!pdfFile);
  const isOcrError = OCRStatus === AlgorithmStatusType.Failed;
  const isOcrReady =
    OCRStatus === AlgorithmStatusType.Success ||
    OCRStatus === AlgorithmStatusType.Fallback;
  const { eventBus } = usePdfHighlighterContext();

  useEffect(() => {
    if (!pdfFile) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [pdfFile]);

  useEffect(() => {
    if (errorLoadDocument) {
      setIsLoading(false);
    }
  }, [errorLoadDocument]);

  useEffect(() => {
    if (eventBus) {
      onEventBusEvent(eventBus, 'textlayerrendered', () => {
        setIsDocumentLoading?.(isLoading);
      });
    }

    return () => {
      setIsDocumentLoading?.(true);
    };
  }, [isLoading, eventBus]);

  const mappedHighlights = useMemo(() => highlights.map(mapPdfHighlights), [
    highlights,
  ]);

  const renderMessage = (
    { header, body }: { header: string; body?: string },
    { color, icon }: { color?: types.IconColor; icon: types.IconType } = {
      color: 'status.warning',
      icon: 'status-warning',
    },
  ) => {
    return (
      <Layout p={8} justify="center">
        <Layout align="center" direction="column">
          <Icon color={color} icon={icon} />
          <Text color="text.secondary" variant="subtitle">
            {header}
          </Text>
          {body && (
            <Text px={8} color="text.quiet" textAlign="center">
              {body}
            </Text>
          )}
        </Layout>
      </Layout>
    );
  };

  const onSelectionFinished = (
    position: ScaledPosition,
    content: {
      text?: string;
      image?: string;
    },
    hideTipAndSelection: () => void,
    transformSelection: () => void,
  ) => {
    if (!content.text || !contextMenu) {
      return null;
    }

    const menu = contextMenu(position, content.text, hideTipAndSelection);

    if (!menu.length || !menu.filter((el) => !el.hidden).length) {
      return null;
    }

    return <ContextMenu onOpen={transformSelection} options={menu} />;
  };

  const renderHighlightDefault = (highlight: ScaledHighlight) => {
    const isActive = activeHighlightId === highlight.id;
    return (
      <Highlight
        variant={!highlight.id ? 'search' : 'default'}
        key={highlight.id || uuid()}
        onClick={onHighlightClick}
        id={highlight.id}
        isActive={isActive}
        position={highlight.position}
      />
    );
  };

  const renderOCRStatusWarningAndErrorMessages = () => {
    let message;
    if (OCRStatus === AlgorithmStatusType.Failed) {
      message = renderMessage({
        header:
          'We could not perform optical character recognition on this document.',
        body: `OCR failed because of an issue with this document.
          Please open the original file using the “Download” button and contact support if you require further assistance.`,
      });
    }
    return message;
  };

  if (errorLoadDocument && isOcrReady) {
    return (
      <Layout pt={3} justify="center">
        <Layout align="center" direction="column">
          <Icon color="status.warning" icon="status-warning" />
          <Text preset="error">
            There has been an error while loading this PDF file.
          </Text>
        </Layout>
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout justify="center" mt="100">
        <LoadingSpinner size="medium" />
      </Layout>
    );
  }

  return isOcrError ? (
    renderOCRStatusWarningAndErrorMessages()
  ) : (
    <Box
      // unfortunatly we have to manually include EDS color here because these targeted classes are from PDF.js
      styles={{
        '& span.highlight': {
          backgroundColor: 'highlight.search',
        },
        '& span.highlight.selected': {
          backgroundColor: 'highlight.search',
          backgroundImage: 'linear-gradient(rgba( 0, 0, 0, 0.05) 0 0)',
        },
        '& .textLayer': { zIndex: 'auto' },
        ':hover': {
          backgroundImage: 'none',
        },
        ':active': {
          backgroundImage: 'none',
        },
        cursor: 'default',
      }}
      bg="background.quiet"
      position="relative"
      h="100%"
      onMouseDown={onMouseDownHandler}
    >
      <PdfHighlighter
        enableAreaSelection={() => false}
        pdfDocument={pdfFile as any}
        pdfScaleValue={pdfScaleValue}
        onScrollChange={noop}
        scrollRef={noop}
        onSelectionFinished={isOcrReady ? onSelectionFinished : (noop as any)}
        highlightTransform={
          renderHighlight ? renderHighlight : renderHighlightDefault
        }
        highlights={mappedHighlights}
      />
    </Box>
  );
};

export default PdfViewerHighlighter;
