import { link } from './link';

export const unset = {
  background: 'none',
  border: 'unset',
  cursor: 'pointer',
  flex: 'none',
  height: 'unset',
  padding: 'unset',
};

const tertiary = {
  backgroundColor: 'transparent',
  color: 'text.primary',
};

export const button = {
  unset,
  base: {
    ...unset,
    borderRadius: 'm',
    borderColor: 'none',
    whiteSpace: 'nowrap',
  },
  ai: {
    backgroundColor: 'brand.ai',
    color: 'inverse.text.primary',
  },
  cta: {
    backgroundColor: 'unset',
    color: 'inverse.text.primary',
    background: 'linear-gradient(91.38deg, #4F73FF 13.49%, #6B4AFF 99.18%)',
  },
  'cta-ai': {
    backgroundColor: 'unset',
    color: 'inverse.text.primary',
    background: 'linear-gradient(92.4deg, #815FE2 -33.9%, #4273D9 142%)',
  },
  primary: {
    backgroundColor: 'action.primary',
    color: 'inverse.text.primary',
  },
  secondary: {
    backgroundColor: 'background',
    border: 'border',
    color: 'text.primary',
  },
  danger: {
    backgroundColor: 'status.danger',
    color: 'inverse.text.primary',
  },
  tertiary,
  subtle: {
    ...tertiary,
    color: 'text.secondary',
  },
  'secondary-active': {
    border: 'border',
    borderColor: 'action.link',
    color: 'action.link',
  },
  action: {
    ...unset,
    ...link({}),
  },
  onboarding: {
    backgroundColor: 'brand.onboarding',
    borderRadius: 'pill',
  },
  dark: {
    cta: {
      backgroundColor: 'unset',
      color: 'inverse.text.primary',
      background: 'linear-gradient(91.38deg, #4F73FF 13.49%, #6B4AFF 99.18%)',
    },
    'cta-ai': {
      backgroundColor: 'unset',
      color: 'inverse.text.primary',
      background: 'linear-gradient(92.4deg, #815FE2 -33.9%, #4273D9 142%)',
    },
    primary: {
      backgroundColor: 'inverse.action.primary',
      color: 'inverse.text.primary',
    },
    tertiary: {
      ...tertiary,
      color: 'inverse.text.primary',
    },
    subtle: {
      ...tertiary,
      color: 'inverse.text.secondary',
    },
    'secondary-active': {
      border: 'border',
      color: 'inverse.text.primary',
      borderColor: 'inverse.action.primary',
    },
    action: {
      ...unset,
      ...link({}),
      color: 'inverse.text.link',
    },
  },
};
