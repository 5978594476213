import React from 'react';

import { Box, Icon } from '~/eds';

import { black4 } from '../../assets/shared-styles/general';
import DocumentFilledIcon from '../Shared/Icons/DocumentFilledIcon';
import DocumentTypeImgIcon from '../Shared/Icons/DocumentTypeImgIcon';
import DocumentTypeMovIcon from '../Shared/Icons/DocumentTypeMovIcon';
import DocumentTypeMusicIcon from '../Shared/Icons/DocumentTypeMusicIcon';
import DocumentTypePdfIcon from '../Shared/Icons/DocumentTypePdfIcon';

// TODO: use `eds.Filename`
export const documentTypeIcon = (type, size = '16') => {
  let returnValue;
  switch (type) {
    case '.doc':
    case '.docx':
      returnValue = (
        <Box display="inline-block">
          <Icon icon="file-doc" />
        </Box>
      );
      break;
    case '.csv':
    case '.htm':
    case '.xls':
    case '.xltx':
    case '.xlt':
    case '.xlsx':
      returnValue = (
        <Box display="inline-block">
          <Icon icon="file-spreadsheet" />
        </Box>
      );
      break;
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.svg':
    case '.tif':
    case '.tiff':
      returnValue = <DocumentTypeImgIcon size={size} />;
      break;
    case '.mov':
    case '.avi':
    case '.flv':
    case '.h264':
    case '.hevc':
    case '.mp4':
    case '.mpeg-2':
    case '.mpeg-4':
    case '.divx':
    case '.wmv':
      returnValue = <DocumentTypeMovIcon size={size} />;
      break;
    case '.mp3':
    case '.m3u':
    case '.wav':
      returnValue = <DocumentTypeMusicIcon size={size} />;
      break;
    case '.pdf':
      returnValue = <DocumentTypePdfIcon size={size} />;
      break;
    case '.ppt':
      returnValue = (
        <Box display="inline-block">
          <Icon icon="file-powerpoint" />
        </Box>
      );
      break;
    default:
      returnValue = <DocumentFilledIcon color={black4} size={size} />;
      break;
  }
  return returnValue;
};
