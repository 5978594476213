import ai from './svgs/ai';
import alert from './svgs/alert';
import alertOff from './svgs/alert-off';
import analyzer from './svgs/analyzer';
import arrowBack from './svgs/arrow-back';
import arrowDown from './svgs/arrow-down';
import arrowForward from './svgs/arrow-forward';
import arrowLeft from './svgs/arrow-left';
import arrowPrevious from './svgs/arrow-previous';
import arrowRight from './svgs/arrow-right';
import arrowUp from './svgs/arrow-up';
import attachment from './svgs/attachment';
import automation from './svgs/automation';
import automationGradient from './svgs/automation-gradient';
import block from './svgs/block';
import bookSparkle from './svgs/book-sparkle';
import bookmark from './svgs/bookmark';
import bookmarkFilled from './svgs/bookmark-filled';
import briefcase from './svgs/briefcase';
import caretDown from './svgs/caret-down';
import caretLeft from './svgs/caret-left';
import caretRight from './svgs/caret-right';
import caretUp from './svgs/caret-up';
import chatbot from './svgs/chatbot';
import check from './svgs/check';
import checkboxChecked from './svgs/checkbox-checked';
import checkboxEmpty from './svgs/checkbox-empty';
import checkboxIndeterminate from './svgs/checkbox-indeterminate';
import checklist from './svgs/checklist';
import chevronDown from './svgs/chevron-down';
import chevronLeft from './svgs/chevron-left';
import chevronRight from './svgs/chevron-right';
import chevronUp from './svgs/chevron-up';
import chip from './svgs/chip';
import circleStop from './svgs/circle-stop';
import clipboard from './svgs/clipboard';
import cloudDownload from './svgs/cloud-download';
import code from './svgs/code';
import collapseLeft from './svgs/collapse-left';
import collapseRight from './svgs/collapse-right';
import compress from './svgs/compress';
import condition from './svgs/condition';
import dash from './svgs/dash';
import dashboard from './svgs/dashboard';
import department from './svgs/department';
import doubleArrowLeft from './svgs/double-arrow-left';
import doubleArrowRight from './svgs/double-arrow-right';
import download from './svgs/download';
import drive from './svgs/drive';
import edit from './svgs/edit';
import email from './svgs/email';
import evisortAi from './svgs/evisort-ai';
import evisortAiAnimated from './svgs/evisort-ai-animated';
import evisortAiStatic from './svgs/evisort-ai-static';
import expand from './svgs/expand';
import expiring from './svgs/expiring';
import field from './svgs/field';
import fieldAddress from './svgs/field-address';
import fieldBoolean from './svgs/field-boolean';
import fieldClause from './svgs/field-clause';
import fieldDate from './svgs/field-date';
import fieldDropdown from './svgs/field-dropdown';
import fieldEmail from './svgs/field-email';
import fieldFile from './svgs/field-file';
import fieldFolder from './svgs/field-folder';
import fieldMonetary from './svgs/field-monetary';
import fieldMultipleChoice from './svgs/field-multiple-choice';
import fieldNumber from './svgs/field-number';
import fieldParagraph from './svgs/field-paragraph';
import fieldPercentage from './svgs/field-percentage';
import fieldShortResponse from './svgs/field-short-response';
import fieldTimePeriod from './svgs/field-time-period';
import file from './svgs/file';
import fileDoc from './svgs/file-doc';
import fileImage from './svgs/file-image';
import fileMagnifyingGlass from './svgs/file-magnifying-glass';
import filePdf from './svgs/file-pdf';
import filePowerpoint from './svgs/file-powerpoint';
import fileSpreadsheet from './svgs/file-spreadsheet';
import filter from './svgs/filter';
import flag from './svgs/flag';
import folder from './svgs/folder';
import folderMove from './svgs/folder-move';
import format from './svgs/format';
import formatAlignCenter from './svgs/format-align-center';
import formatAlignJustify from './svgs/format-align-justify';
import formatAlignLeft from './svgs/format-align-left';
import formatAlignRight from './svgs/format-align-right';
import formatBlockquote from './svgs/format-blockquote';
import formatBold from './svgs/format-bold';
import formatBulletedList from './svgs/format-bulleted-list';
import formatCode from './svgs/format-code';
import formatCodeBlock from './svgs/format-code-block';
import formatHeading1 from './svgs/format-heading-1';
import formatHeading2 from './svgs/format-heading-2';
import formatItalic from './svgs/format-italic';
import formatNumberedList from './svgs/format-numbered-list';
import formatStrikethrough from './svgs/format-strikethrough';
import formatUnderline from './svgs/format-underline';
import gear from './svgs/gear';
import globe from './svgs/globe';
import groups from './svgs/groups';
import help from './svgs/help';
import highlight from './svgs/highlight';
import inbox from './svgs/inbox';
import info from './svgs/info';
import key from './svgs/key';
import launch from './svgs/launch';
import layout from './svgs/layout';
import leftPanel from './svgs/left-panel';
import library from './svgs/library';
import lightbulb from './svgs/lightbulb';
import link from './svgs/link';
import loading from './svgs/loading';
import loadingContent from './svgs/loading-content';
import loadingContentDeprecated from './svgs/loading-content-deprecated';
import loadingDashed from './svgs/loading-dashed';
import lock from './svgs/lock';
import maximize from './svgs/maximize';
import mention from './svgs/mention';
import message from './svgs/message';
import minimize from './svgs/minimize';
import more from './svgs/more';
import notVisible from './svgs/not-visible';
import openInNew from './svgs/open-in-new';
import panel from './svgs/panel';
import pause from './svgs/pause';
import pin from './svgs/pin';
import plus from './svgs/plus';
import plusCircle from './svgs/plus-circle';
import pushPin from './svgs/push-pin';
import pushPinFilled from './svgs/push-pin-filled';
import question from './svgs/question';
import radioChecked from './svgs/radio-checked';
import radioEmpty from './svgs/radio-empty';
import reactionThumbsdown from './svgs/reaction-thumbsdown';
import reactionThumbsup from './svgs/reaction-thumbsup';
import reauthenticate from './svgs/reauthenticate';
import reconnect from './svgs/reconnect';
import reload from './svgs/reload';
import remove from './svgs/remove';
import reorder from './svgs/reorder';
import reply from './svgs/reply';
import report from './svgs/report';
import rerun from './svgs/rerun';
import restore from './svgs/restore';
import resume from './svgs/resume';
import review from './svgs/review';
import save from './svgs/save';
import saveAsVersion from './svgs/save-as-version';
import search from './svgs/search';
import searchGradient from './svgs/search-gradient';
import send from './svgs/send';
import share from './svgs/share';
import signature from './svgs/signature';
import sort from './svgs/sort';
import statusActive from './svgs/status-active';
import statusAi from './svgs/status-ai';
import statusComplete from './svgs/status-complete';
import statusCurrent from './svgs/status-current';
import statusDanger from './svgs/status-danger';
import statusInactive from './svgs/status-inactive';
import statusIndicatorDanger from './svgs/status-indicator-danger';
import statusIndicatorSuccess from './svgs/status-indicator-success';
import statusIndicatorWarning from './svgs/status-indicator-warning';
import statusInfo from './svgs/status-info';
import statusNew from './svgs/status-new';
import statusNewGradient from './svgs/status-new-gradient';
import statusNotStarted from './svgs/status-not-started';
import statusOptional from './svgs/status-optional';
import statusPending from './svgs/status-pending';
import statusReject from './svgs/status-reject';
import statusSuccess from './svgs/status-success';
import statusWarning from './svgs/status-warning';
import sync from './svgs/sync';
import tag from './svgs/tag';
import trash from './svgs/trash';
import trashPermanent from './svgs/trash-permanent';
import turnTracking from './svgs/turn-tracking';
import unlink from './svgs/unlink';
import upload from './svgs/upload';
import user from './svgs/user';
import view from './svgs/view';
import workflow from './svgs/workflow';
import wrench from './svgs/wrench';
import x from './svgs/x';

export const reactions = {
  thumbsdown: reactionThumbsdown,
  thumbsup: reactionThumbsup,
};

export const icons = {
  ai,
  alert,
  'alert-off': alertOff,
  analyzer,
  'arrow-back': arrowBack,
  'arrow-down': arrowDown,
  'arrow-forward': arrowForward,
  'arrow-left': arrowLeft,
  'arrow-previous': arrowPrevious,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  attachment,
  automation,
  'automation-gradient': automationGradient,
  block,
  'book-sparkle': bookSparkle,
  bookmark,
  'bookmark-filled': bookmarkFilled,
  briefcase,
  'caret-down': caretDown,
  'caret-left': caretLeft,
  'caret-right': caretRight,
  'caret-up': caretUp,
  chatbot,
  'checkbox-checked': checkboxChecked,
  'checkbox-empty': checkboxEmpty,
  'checkbox-indeterminate': checkboxIndeterminate,
  check,
  checklist,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'chevron-up': chevronUp,
  chip,
  'circle-stop': circleStop,
  clipboard,
  'cloud-download': cloudDownload,
  code,
  'collapse-left': collapseLeft,
  'collapse-right': collapseRight,
  compress,
  condition,
  dash,
  dashboard,
  department,
  'double-arrow-left': doubleArrowLeft,
  'double-arrow-right': doubleArrowRight,
  download,
  drive,
  edit,
  'evisort-ai': evisortAi,
  'evisort-ai-animated': evisortAiAnimated,
  'evisort-ai-static': evisortAiStatic,
  email,
  expand,
  expiring,
  field,
  'field-address': fieldAddress,
  'field-boolean': fieldBoolean,
  'field-clause': fieldClause,
  'field-date': fieldDate,
  'field-dropdown': fieldDropdown,
  'field-email': fieldEmail,
  'field-file': fieldFile,
  'field-folder': fieldFolder,
  'field-monetary': fieldMonetary,
  'field-multiple-choice': fieldMultipleChoice,
  'field-number': fieldNumber,
  'field-paragraph': fieldParagraph,
  'field-percentage': fieldPercentage,
  'field-short-response': fieldShortResponse,
  'field-time-period': fieldTimePeriod,
  file,
  'file-doc': fileDoc,
  'file-image': fileImage,
  'file-pdf': filePdf,
  'file-magnifying-glass': fileMagnifyingGlass,
  'file-powerpoint': filePowerpoint,
  'file-spreadsheet': fileSpreadsheet,
  filter,
  flag,
  folder,
  'folder-move': folderMove,
  format,
  'format-align-center': formatAlignCenter,
  'format-align-justify': formatAlignJustify,
  'format-align-left': formatAlignLeft,
  'format-align-right': formatAlignRight,
  'format-blockquote': formatBlockquote,
  'format-bold': formatBold,
  'format-bulleted-list': formatBulletedList,
  'format-code': formatCode,
  'format-code-block': formatCodeBlock,
  'format-heading-1': formatHeading1,
  'format-heading-2': formatHeading2,
  'format-italic': formatItalic,
  'format-numbered-list': formatNumberedList,
  'format-strikethrough': formatStrikethrough,
  'format-underline': formatUnderline,
  gear,
  globe,
  groups,
  help,
  highlight,
  inbox,
  info,
  'status-indicator-danger': statusIndicatorDanger,
  'status-indicator-success': statusIndicatorSuccess,
  'status-indicator-warning': statusIndicatorWarning,
  key,
  'left-panel': leftPanel,
  launch,
  layout,
  library,
  lightbulb,
  link,
  loading,
  'loading-content': loadingContent,
  'loading-content-deprecated': loadingContentDeprecated,
  'loading-dashed': loadingDashed,
  lock,
  maximize,
  mention,
  message,
  minimize,
  more,
  'not-visible': notVisible,
  'open-in-new': openInNew,
  panel,
  pin,
  pause,
  plus,
  'plus-circle': plusCircle,
  'push-pin': pushPin,
  'push-pin-filled': pushPinFilled,
  question,
  'radio-checked': radioChecked,
  'radio-empty': radioEmpty,
  'reaction-thumbsdown': reactions.thumbsdown,
  'reaction-thumbsup': reactions.thumbsup,
  reauthenticate,
  reconnect,
  reload,
  remove,
  reorder,
  reply,
  report,
  rerun,
  restore,
  resume,
  review,
  save,
  'save-as-version': saveAsVersion,
  search,
  'search-gradient': searchGradient,
  send,
  share,
  signature,
  sort,
  'status-active': statusActive,
  'status-ai': statusAi,
  'status-current': statusCurrent,
  'status-danger': statusDanger,
  'status-inactive': statusInactive,
  'status-info': statusInfo,
  'status-new': statusNew,
  'status-new-gradient': statusNewGradient,
  'status-not-started': statusNotStarted,
  'status-optional': statusOptional,
  'status-pending': statusPending,
  'status-reject': statusReject,
  'status-complete': statusComplete,
  'status-success': statusSuccess,
  'status-warning': statusWarning,
  sync,
  tag,
  'turn-tracking': turnTracking,
  trash,
  'trash-permanent': trashPermanent,
  unlink,
  upload,
  user,
  view,
  workflow,
  wrench,
  x,
};
