import { types } from '~/eds';
import { DocumentHandler } from '~/features/document-handler';
import { pilot } from '~/services';

import { DocumentServerResponse } from '../document';
import { mapResponse } from './mapper';

export const getDocumentHandler = async (
  id: types.PilotId,
): Promise<DocumentHandler> => {
  const docHandler: DocumentServerResponse = await pilot.get(`document/${id}/`);
  return mapResponse(docHandler);
};
