import React, { useEffect, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { Modal, TextInput } from '~/eds';
import { TableViewContextType } from '~/enums';
import { api } from '~/redux';
import { CreateTableViewSet } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

interface Props {
  context: TableViewContextType | undefined;
  fields: CreateTableViewSet;
  hideModal: () => void;
  modalType: string;
}

const BaseSaveView = ({ context, fields, hideModal, modalType }: Props) => {
  const [
    createTableView,
    createTableViewResult,
  ] = api.endpoints.createTableView.useMutation();
  const {
    isError: createTableViewError,
    isSuccess: createTableViewSuccess,
    isLoading,
    data,
  } = createTableViewResult;

  const [name, setName] = useState<Nullable<string>>('');

  useEffect(() => {
    if (createTableViewError) {
      showToast(ERROR, 'Save table view failed. Please try again.');
      hideModal();
    }
  }, [createTableViewError]);

  useEffect(() => {
    if (createTableViewSuccess) {
      showToast(SUCCESS, 'Table View has been successfully saved.');
      trackSegment('Saving table view', data);
      hideModal();
    }
  }, [createTableViewSuccess]);

  const handleSave = (name: string) => {
    createTableView({ context, name, fields });
  };

  if (modalType !== MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE) return null;

  return (
    <Modal
      title="Save View"
      onCancel={hideModal}
      isVisible={true}
      primaryAction={{
        text: 'Save',
        onClick: () => handleSave(name ?? ''),
        level: 'primary' as const,
        disabled: isLoading,
      }}
    >
      <TextInput
        placeholder="View name"
        name="name"
        value={name}
        onChange={setName}
      ></TextInput>
    </Modal>
  );
};

export default BaseSaveView;
