import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { AribaFieldSelect } from '~/components/Shared/AribaFieldSelect';
import { FieldMappingSelect } from '~/components/Shared/FieldMappingSelect';
import {
  CrudForm,
  FileInput,
  Files,
  FormField,
  HtmlEntityType,
  Layout,
  Select,
  useToast,
} from '~/eds';
import {
  AribaIntegrationType,
  AribaSyncType,
  FileExtensionType,
  FileMimeType,
  ManageSyncPairStageType,
} from '~/enums';
import { api } from '~/redux';
import { downloadFile } from '~/utils/files';

import { isInvalidMapping } from '../../util';

function FieldMapping({
  activeSyncPair,
  integrationsSetActiveSyncPair,
  manageSyncPairStage,
}) {
  const { toast } = useToast();
  const stage = ManageSyncPairStageType.FieldMapping;
  const { syncPair, syncType: syncTypePair } = activeSyncPair;
  const stageProperties = activeSyncPair[stage] ?? {};
  const mappingData = stageProperties['fieldsMapping'] ?? [];
  const csvFieldMapping = stageProperties['csvFieldMapping'] ?? null;

  const syncTypeOptions = [
    {
      value: AribaSyncType.INBOUND,
      label: HtmlEntityType.LeftArrow,
      tooltip: 'To Evisort',
    },
    {
      value: AribaSyncType.OUTBOUND,
      label: HtmlEntityType.RightArrow,
      tooltip: 'To Ariba',
    },
  ];

  if (syncTypePair === AribaIntegrationType.ONE_TIME) {
    // the bi-directional sync is only available for one time sync
    syncTypeOptions.push({
      value: AribaSyncType.BIDIRECTIONAL,
      label: HtmlEntityType.RightLeftArrow,
      tooltip: 'Bi-directional',
    });
  }

  const [validateCsv] = api.endpoints.validateFieldMappingCsv.useMutation();
  const {
    data: initialEvisortFieldsResponse,
    isFetching: isFetchingInitialEvisortFields,
  } = api.endpoints.getFields.useQuery({
    limit: 3,
    search: 'Renewal',
  });

  const setMappingData = (mapping) => {
    const areAllFieldsMapped = mapping.every((field) =>
      // if either evisort or ariba is selected, then all fields must be mapped
      // otherwise, the empty row is ignored
      field.evisort ?? field.ariba ?? field.sync
        ? field.ariba && field.evisort && field.sync
        : true,
    );
    const updatedValues = {
      ...stageProperties,
      fieldsMapping: mapping,
      areAllFieldsMapped,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  useEffect(() => {
    if (initialEvisortFieldsResponse && !mappingData.length) {
      setMappingData(
        initialEvisortFieldsResponse.results.map((evisortField) => ({
          evisort: evisortField.id,
          sync: AribaSyncType.OUTBOUND,
          ariba: null,
          meta: {
            evisortField,
          },
        })),
      );
    }
  }, [initialEvisortFieldsResponse]);

  const usedFields = useMemo(() => {
    return mappingData.reduce(
      (acc, { evisort, ariba, meta }) => {
        acc.evisort[evisort] = meta.evisortField;
        acc.ariba[ariba] = ariba;
        return acc;
      },
      {
        ariba: {},
        evisort: {},
      },
    );
  }, [mappingData]);

  const columns = [
    {
      key: 'evisort',
      input: FieldMappingSelect,
      createInputProps: (d) => {
        return {
          filterOption: (option) => {
            const isUsed = option.value in usedFields.evisort;
            return !isUsed;
          },
          isClearable: false,
          isSearchable: false,
          aribaFieldType: getAribaFieldType(d.meta),
        };
      },
      label: 'Evisort Fields',
      mapValue: (field) => field.id,
      name: 'evisort-fields',
      placeholder: 'Select Evisort fields',
    },
    {
      key: 'sync',
      label: 'Sync',
      info:
        'Sync data seamlessly in field mapping by choosing one-way or bi-directional.',
      input: Select,
      inputProps: {
        isClearable: false,
        isSearchable: false,
        options: syncTypeOptions,
        width: 'input.s.width',
      },
      name: 'sync',
      placeholder: 'Select sync mode',
    },
    {
      key: 'ariba',
      label: 'Ariba Fields',
      input: AribaFieldSelect,
      createInputProps: (d) => {
        return {
          isClearable: false,
          filterOption: (option) => {
            const isUsed = option.value in usedFields.ariba;
            return !isUsed;
          },
          noOptionsMessage:
            'No fields available to map to selected evisort field',
          evisortFieldType: getEvisortFieldType(d.meta),
          syncpair: syncPair,
        };
      },
      name: 'ariba-fields',
      placeholder: 'Select Ariba fields',
      mapValue: (field) => field.fieldId,
    },
  ];

  const getMetadataValues = (field, prevMeta) => {
    const newMeta = { ...prevMeta };
    if (field && typeof field === 'object') {
      if (field.hasOwnProperty('fieldId')) {
        newMeta.aribaField = field;
      } else {
        newMeta.evisortField = field;
      }
    }
    return newMeta;
  };

  const handleUpdateMappingData = (updatedMappingData, actionMeta) => {
    const { action, value, datum, index } = actionMeta;
    switch (action) {
      case 'update':
        // if it's an evisort field update, check if the ariba field is compatible
        if (value.hasOwnProperty('id') && datum.ariba) {
          // validate that the mapping is valid
          const evisortFieldType = value.type;
          const aribaFieldType = getAribaFieldType(datum.meta);
          if (isInvalidMapping(evisortFieldType, aribaFieldType)) {
            updatedMappingData[index].meta = {
              ...getMetadataValues(value, updatedMappingData[index].meta),
              aribaField: null,
            };
            updatedMappingData[index].ariba = null;
          }
        } else {
          updatedMappingData[index].meta = {
            ...getMetadataValues(value, updatedMappingData[index].meta),
          };
        }
        break;
      case 'create':
        updatedMappingData[index].meta = {
          ...getMetadataValues(value, updatedMappingData[index].meta),
        };
        break;
      default:
        break;
    }
    setMappingData(updatedMappingData);
  };

  const getEvisortFieldType = (field) => {
    return field.evisortField ? field.evisortField.type : null;
  };

  const getAribaFieldType = (field) => {
    return field.aribaField ? field.aribaField.fieldDataSubType : null;
  };

  const handleSelectedFile = (files) => {
    const file = files[0];
    validateCsv({
      file,
      syncPair,
    })
      .unwrap()
      .then((response) => {
        const fieldMappingFilePath = response.success;

        integrationsSetActiveSyncPair({
          ...activeSyncPair,
          [manageSyncPairStage]: {
            ...stageProperties,
            fieldMappingFilePath,
            csvFieldMapping: file,
          },
        });

        toast({
          status: 'success',
          message: `File processed successfully`,
        });
      })
      .catch((err) => {
        const message = err.response.data?.error;
        toast({
          status: 'danger',
          message: `Error processing file: ${message}`,
        });
      });
  };
  const setCSVFieldMapping = (file) => {
    const updatedValues = {
      ...stageProperties,
      csvFieldMapping: file,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };
  const downloadCsvTemplate = (e) => {
    // create a csv template
    const csvTemplate =
      'Ariba Field,Ariba Label,Ariba Unique Value,Evisort Field,Evisort Value\n';
    const csvBlob = new Blob([csvTemplate], { type: 'text/csv' });
    const filename = 'field_mapping_template.csv';
    downloadFile(csvBlob, filename);
    e.preventDefault();
  };

  const fieldMappingForm = (
    <CrudForm
      actions={{
        add: {
          text: 'Add another field',
          tooltip: 'Tooltip for add action',
        },
        delete: (d) => ({
          disabled: d.sync === 'inbound',
          text: 'Delete',
          tooltip:
            d.sync === 'inbound'
              ? 'Cannot delete inbound sync, please update the sync type to delete'
              : `Delete ${d.evisort} ${d.sync} ${d.ariba} integration?`,
        }),
      }}
      columns={columns}
      columnWidths={['1fr', 'auto', '1fr']}
      data={mappingData}
      loadingContent={{
        isLoading: isFetchingInitialEvisortFields,
        message: 'Retrieving field information',
      }}
      onUpdate={handleUpdateMappingData}
    />
  );
  return (
    <Layout preset="sections" direction="column">
      <Layout spacing={4} pb={4} direction="column">
        <FormField
          label="Upload transformation data"
          input={FileInput}
          value={stageProperties.csvFieldMapping}
          onChange={handleSelectedFile}
          inputProps={{
            accept: {
              [FileMimeType.Csv]: [FileExtensionType.Csv],
            },
            layout: 'condensed',
            enableDropzone: false,
          }}
          description={[
            'Upload a CSV file that maps Evisort field data to Ariba field data. Download a ',
            <a href="#/" onClick={downloadCsvTemplate}>
              CSV template
            </a>,
            ' to get started.',
          ]}
        />

        {csvFieldMapping && (
          <Files
            title="Uploaded file"
            files={[csvFieldMapping]}
            onRemoveFile={() => setCSVFieldMapping(null)}
          />
        )}
      </Layout>
      <FormField
        label="Map your fields"
        children={fieldMappingForm}
        description="Select your Evisort field type and map it to the corresponding Ariba
      field type."
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(FieldMapping);
