import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_DOCUMENT_SFDT = { type: TagType.Document, id: 'DOCUMENT_SFDT' };
export const TAG_CLAUSES = { type: TagType.Document, id: 'CLAUSES' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Document as TagType],
  endpoints: {
    getDocumentSfdt: {
      providesTags: [TAG_BY_DOCUMENT_SFDT],
    },
    getAllProvisionsGrouped: {
      providesTags: [TAG_CLAUSES],
    },
    getDocumentDetailClauses: {
      providesTags: [TAG_CLAUSES],
    },
  },
});
