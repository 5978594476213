import { LOCAL_DOMAIN } from '../constants/urls';

export function getStorageItem(key, defaultValue = null) {
  try {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export function setStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

/***
 *
 * @param location: React Dom Location
 * @param navigate: React Dom Navigate
 * @param key: the params to be removed from url
 *
 * Note: If your component is relying on query param states for updating
 * component state or hook effects, this may cause your component to re-render
 */
export function removeQueryParam(location, navigate, key) {
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete(key);
  navigate({ search: queryParams.toString() }, { replace: true });
}

export const getDocumentUrl = (client, documentId) => {
  if (!client || !documentId) return null;
  return `/${client}/document/${documentId}`;
};

export const openInNewTab = (url) => {
  if (!url) return;
  const newTab = window.open(url, '_blank');
  newTab.opener = null;
  newTab.focus();
};

export const getWildDomain = (host) => {
  if (host.includes(LOCAL_DOMAIN)) {
    return host;
  }

  return '.' + host.split('.').slice(-2).join('.');
};
