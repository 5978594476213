import { createContext, useCallback, useContext, useState } from 'react';

import { Nullable } from '~/types';

type DraggedItem = {
  id: string;
  payload?: any;
};

interface DragContextType {
  draggedItem: Nullable<DraggedItem>;
  handleDragStart: (draggedItem: DraggedItem) => void;
  handleDragEnd: () => void;
}

const DragContext = createContext<DragContextType | undefined>(undefined);

export const DragProvider = ({ children }: { children: React.ReactNode }) => {
  const [draggedItem, setDraggedItem] = useState<Nullable<DraggedItem>>(null);

  const handleDragStart = useCallback((draggedItem: DraggedItem) => {
    setDraggedItem(draggedItem);
  }, []);

  const handleDragEnd = useCallback(() => {
    setDraggedItem(null);
  }, []);

  return (
    <DragContext.Provider
      value={{
        draggedItem,
        handleDragStart,
        handleDragEnd,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

export const useDrag = () => {
  const context = useContext(DragContext);
  if (!context) {
    throw new Error('useDrag must be used within a DragProvider');
  }
  return context;
};
