import { compact, isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import uuid from 'uuid';

import { showToast } from '~/components/Shared/EcToast';
import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import {
  ActionsMenu,
  Button,
  EmptyPage,
  parseDate,
  Text,
  useToast,
} from '~/eds';
import {
  AutomationStageType,
  AutomationStatusType,
  FeatureFlagType,
  QueryParamType,
  TableContextType,
} from '~/enums';
import { SearchFiltersPreview } from '~/features/filters';
import { getRestrictFilterIds } from '~/features/quick-ai';
import { FlagType, useFlag } from '~/flags';
import {
  useClientSubDomain,
  useHasFeatureFlag,
  useResolveUsers,
  useTableSettings,
} from '~/hooks';
import { actions, api } from '~/redux';
import searchSlice, { isActiveFilter } from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { useRouting } from '~/routing';
import { redirectToPage } from '~/services/redirects';
import { ERROR, SUCCESS } from '~/types/toast.types';
import {
  FlexLayout,
  LoadingContainer,
  LoadingSpinner,
  TextInput,
  Tooltip,
  useModal,
} from '~/ui';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';
import { toLowercaseTrimmed } from '~/utils/strings';

import { buildClauseAnalyzerParams, getShortName } from '../util';
import { SampleContentCell } from './SampleContentCell';

const HIGHLIGHT_STATUS_ID = 'highlightStatus';

const HIGHLIGHT_STATUS_OPTIONS = [
  { label: 'Active', value: AutomationStatusType.ACTIVE },
  { label: 'Suspended', value: AutomationStatusType.SUSPENDED },
];

const ALL_STATUSES = [
  AutomationStatusType.ACTIVE,
  AutomationStatusType.SUSPENDED,
];

const filtersOptions = {
  disableManageFilters: true,
  disableRemoveFilter: true,
  disableClearFilters: true,
};

const AutomationHubQuickAiPage = ({
  clause,
  pagination,
  quickAiForm,
  setAutomationClause,
  setAutomationQuickAiDataForm,
  setAutomationManageStage,
  setAutomationSamplePageSize,
  setAutomationSamplePagination,
}) => {
  const { toast } = useToast();
  const [selected, setSelected] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState();

  const dispatch = useDispatch();
  const { navigate } = useRouting();

  const hasAdvancedSearch = useHasFeatureFlag(FeatureFlagType.SearchV2);
  const hasAdvancedSearchV3 = useFlag(FlagType.SearchV3);
  const enableQuickAiTableSorting = useFlag(FlagType.QuickAiTableSorting);
  const enableQuickAiFilters = useFlag(FlagType.QuickAiFilters);

  const { tableSettings } = useTableSettings(TableContextType.AutomationHub);
  useEffect(() => {
    if (tableSettings) {
      setAutomationSamplePageSize({ pageSize: tableSettings.pageSize });
      // TODO: set sorting from table settings when we plan to enable QuickAiTableSorting flag
    }
  }, [tableSettings]);

  const defaultStatus =
    clause?.status === AutomationStatusType.SUSPENDED
      ? ALL_STATUSES
      : [AutomationStatusType.ACTIVE];

  const [status, setStatus] = useState(defaultStatus);

  const subdomain = useClientSubDomain();
  const restrictedClauseFilterIds = getRestrictFilterIds();

  const clauseName = getPageSearchQueryByKey(
    QueryParamType.Clause,
    clause.name,
  );

  useEffect(() => {
    if (!!clauseName) setAutomationClause({ ...clause, name: clauseName });
  }, [clauseName]);

  const {
    data: idf,
    isFetching: isFetchingIdf,
  } = api.endpoints.getIdentifications.useQuery(
    { name: clauseName },
    { skip: !clauseName },
  );

  const INITIAL_FILTERS = idf?.filters ?? [];

  const [selectedFilters, setSelectedFilters] = useState(INITIAL_FILTERS);

  const {
    data: sectionsAndFolders,
    isFetching: isFetchingSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  useEffect(() => {
    if (!isFetchingIdf && idf) {
      setAutomationClause({ ...clause, status: idf.bulkTaggingEnabledStatus });

      if (idf.bulkTaggingEnabledStatus === AutomationStatusType.SUSPENDED) {
        setStatus(ALL_STATUSES);
      }
    }
  }, [idf, isFetchingIdf]);

  useEffect(() => {
    setSelectedFilters(INITIAL_FILTERS);
  }, [idf]);

  const {
    data: allSamples,
    isFetching: isFetchingAllSamples,
  } = api.endpoints.getSamples.useQuery({
    ...pagination,
    status: ALL_STATUSES,
    clause: clauseName,
  });

  const dataExists = !!allSamples && allSamples.count > 0;

  const { data, error, isFetching } = api.endpoints.getSamples.useQuery(
    {
      ...pagination,
      status,
      clause: clauseName,
    },
    { skip: !dataExists },
  );

  const { users: resolvedUsers, isFetching: isFetchingUsers } = useResolveUsers(
    {
      userIds: data?.results.map((sample) => sample.createdBy),
    },
  );

  const statusHasNoData = !!data && data.count <= 0;

  const pluralizeText = (number = 0, text) => {
    return `${number} ${pluralize(text, number)}`;
  };

  const buildClauseModalTitle = (op, name) => {
    if (name.length < 50) {
      return (
        <FlexLayout>
          {op} Clause: {name}?
        </FlexLayout>
      );
    }

    return (
      <FlexLayout>
        {op} Clause: <Tooltip content={name}>{getShortName(name, 50)}</Tooltip>?
      </FlexLayout>
    );
  };

  const buildSampleModalTitle = (op, name) => {
    if (name.length <= 0) {
      return <FlexLayout>{op} Sample?</FlexLayout>;
    }

    if (name.length < 50) {
      return (
        <FlexLayout>
          {op} Sample: {name}?
        </FlexLayout>
      );
    }

    return (
      <FlexLayout>
        {op} Sample: <Tooltip content={name}>{getShortName(name, 50)}</Tooltip>?
      </FlexLayout>
    );
  };

  /***** clause actions *****/
  const [
    deleteClause,
    deleteClauseResult,
  ] = api.endpoints.deleteClause.useMutation();
  const {
    isError: isDeleteClauseError,
    isSuccess: isDeleteClauseSuccess,
  } = deleteClauseResult;

  const [
    suspendClause,
    suspendClauseResult,
  ] = api.endpoints.suspendClause.useMutation();
  const {
    isError: isSuspendClauseError,
    isSuccess: isSuspendClauseSuccess,
  } = suspendClauseResult;

  const [
    resumeClause,
    resumeClauseResult,
  ] = api.endpoints.resumeClause.useMutation();
  const {
    isError: isResumeClauseError,
    isSuccess: isResumeClauseSuccess,
  } = resumeClauseResult;

  const [
    updateClauseFilters,
    updateClauseFiltersResults,
  ] = api.endpoints.updateClause.useMutation();

  const {
    isError: isUpdateClauseFiltersError,
    isSuccess: isUpdateClauseFiltersSuccess,
  } = updateClauseFiltersResults;

  useEffect(() => {
    if (isDeleteClauseSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(clauseName ?? '')} Clause deleted successfully.`,
      );
    }
    if (isDeleteClauseError) {
      showToast(ERROR, 'Delete Clause failed. Please try again.');
    }
  }, [isDeleteClauseSuccess, isDeleteClauseError]);

  useEffect(() => {
    if (isSuspendClauseSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(clauseName ?? '')} Clause suspended successfully.`,
      );
      setAutomationClause({
        ...clause,
        status: AutomationStatusType.SUSPENDED,
      });
    }
    if (isSuspendClauseError) {
      showToast(ERROR, 'Suspend Clause failed. Please try again.');
    }
  }, [isSuspendClauseSuccess, isSuspendClauseError]);

  useEffect(() => {
    if (isUpdateClauseFiltersSuccess) {
      toast({
        status: 'success',
        title: 'Filters updated successfully',
      });
      if (isUpdateClauseFiltersError) {
        toast({
          status: 'danger',
          title: 'Failed to updated filters',
        });
      }
    }
  }, [isUpdateClauseFiltersSuccess, isUpdateClauseFiltersError]);

  useEffect(() => {
    if (isResumeClauseSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(clauseName ?? '')} Clause reactivated successfully.`,
      );
      setAutomationClause({ ...clause, status: AutomationStatusType.ACTIVE });
    }
    if (isResumeClauseError) {
      showToast(ERROR, 'Resume Clause failed. Please try again.');
    }
  }, [isResumeClauseSuccess, isResumeClauseError]);

  const [deleteClauseModal, showDeleteClauseModal] = useModal({
    title: buildClauseModalTitle('Delete', clauseName ?? ''),
    onHide: () => setDeleteConfirm(),
    onCancel: () => setDeleteConfirm(),
    actionButton: {
      disabled: deleteConfirm !== 'DELETE',
      text: 'Permanently delete',
      variant: 'red',
      promise: async () => deleteClause({ name: clauseName }),
    },
    content: (
      <FlexLayout flexDirection="column" space={4}>
        {isFetchingIdf ? (
          <LoadingSpinner size="medium" />
        ) : (
          <>
            <Text variant="body">
              This Clause has {pluralizeText(allSamples?.count, 'Sample')} and{' '}
              {pluralizeText(
                idf?.bulkTaggingTotalIdentifications,
                'Identification',
              )}
              . Deleting this Clause will permanently delete all its Samples and
              their Identifications. You won’t be able to revert this action.
            </Text>
            <Text variant="body">
              You won't be able to revert this action. If you are sure, please
              type "DELETE" in the box below:
            </Text>
            <TextInput
              width="fullWidth"
              value={deleteConfirm}
              onChange={setDeleteConfirm}
            />
          </>
        )}
      </FlexLayout>
    ),
  });

  const [suspendClauseModal, showSuspendClauseModal] = useModal({
    title: buildClauseModalTitle('Suspend', clauseName ?? ''),
    actionButton: {
      disabled: false,
      text: 'Suspend the Clause',
      promise: async () => suspendClause({ name: clauseName }),
    },
    content: (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="body">
          Are you sure you would like Evisort to stop identifying new
          occurrences of this clause?
        </Text>
        <Text variant="body">
          <strong>Note</strong>: All Samples under this clause will be
          suspended.
        </Text>
      </FlexLayout>
    ),
  });

  const [resumeClauseModal, showResumeClauseModal] = useModal({
    title: buildClauseModalTitle('Reactivate', clauseName ?? ''),
    actionButton: {
      disabled: false,
      text: 'Reactivate the Clause',
      promise: async () => resumeClause({ name: clauseName }),
    },
    content: (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="body">
          Are you sure you would like Evisort to resume identifying new
          occurrences of this clause?
        </Text>
        <Text variant="body">
          <strong>Note</strong>: All Samples associated with this clause will be
          reactivated immediately and will run on existing documents. If you'd
          like to control which Samples to reactivate, click{' '}
          <strong>Cancel</strong> and go into the Clause to look into each
          Sample separately.
        </Text>
      </FlexLayout>
    ),
  });

  /***** sample actions *****/
  const [deleteSample, deleteResult] = api.endpoints.deleteSample.useMutation();
  const { isError: isDeleteError, isSuccess: isDeleteSuccess } = deleteResult;

  const [
    suspendSample,
    suspendResult,
  ] = api.endpoints.suspendSample.useMutation();
  const {
    isError: isSuspendError,
    isSuccess: isSuspendSuccess,
  } = suspendResult;

  const [resumeSample, resumeResult] = api.endpoints.resumeSample.useMutation();
  const { isError: isResumeError, isSuccess: isResumeSuccess } = resumeResult;

  useEffect(() => {
    if (isDeleteSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(
          selected?.highlightName ?? '',
        )} Sample deleted successfully.`,
      );
    }
    if (isDeleteError) {
      showToast(ERROR, 'Delete Sample failed. Please try again.');
    }
  }, [isDeleteSuccess, isDeleteError]);

  useEffect(() => {
    if (isSuspendSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(
          selected?.highlightName ?? '',
        )} Sample suspended successfully.`,
      );
    }
    if (isSuspendError) {
      showToast(ERROR, 'Suspend Sample failed. Please try again.');
    }
  }, [isSuspendSuccess, isSuspendError]);

  useEffect(() => {
    if (isResumeSuccess) {
      showToast(
        SUCCESS,
        `${getShortName(
          selected?.highlightName ?? '',
        )} Sample reactivated successfully.`,
      );
    }
    if (isResumeError) {
      showToast(ERROR, 'Resume Sample failed. Please try again.');
    }
  }, [isResumeSuccess, isResumeError]);

  const [deleteModal, showDeleteModal] = useModal({
    title: buildSampleModalTitle('Delete', selected?.highlightName ?? ''),
    onHide: () => setSelected(),
    onCancel: () => setSelected(),
    actionButton: {
      disabled: false,
      text: 'Delete',
      variant: 'red',
      promise: async () => deleteSample({ id: selected.id }),
    },
    content: (
      <FlexLayout flexDirection="column" space={4}>
        {selected?.highlightName ? (
          <>
            <Text variant="body">
              Sample '<strong>{selected.highlightName}</strong>' has identified{' '}
              {pluralizeText(selected?.discoveries ?? 0, 'occurrence')} of
              Clause '<strong>{clauseName}</strong>' in your documents.
            </Text>
            <Text variant="body">
              If you delete <strong>{selected.highlightName}</strong>, then the
              highlighting for <strong>{clauseName}</strong> that{' '}
              <strong>{selected.highlightName}</strong> identified will be
              removed from all documents.
            </Text>
            <Text variant="body">You won't be able to undo this action.</Text>
          </>
        ) : (
          <>
            <Text variant="body">
              This Sample has identified{' '}
              {pluralizeText(selected?.discoveries ?? 0, 'occurrence')} of
              Clause '<strong>{clauseName}</strong>' in your documents.
            </Text>
            <Text variant="body">
              If you delete the Sample, then the highlighting for{' '}
              <strong>{clauseName}</strong> that the Sample identified will be
              removed from all documents.
            </Text>
            <Text variant="body">You won't be able to undo this action</Text>
          </>
        )}
      </FlexLayout>
    ),
  });

  const [suspendModal, showSuspendModal] = useModal({
    title: buildSampleModalTitle('Suspend', selected?.highlightName ?? ''),
    onHide: () => setSelected(),
    onCancel: () => setSelected(),
    actionButton: {
      disabled: false,
      text: 'Suspend the Sample',
      promise: async () => suspendSample({ id: selected.id }),
    },
    content: (
      <Text variant="body">
        Are you sure you would like Evisort to stop identifying new occurrences
        of this Sample?
      </Text>
    ),
  });

  const [resumeModal, showResumeModal] = useModal({
    title: buildSampleModalTitle('Reactivate', selected?.highlightName ?? ''),
    onHide: () => setSelected(),
    onCancel: () => setSelected(),
    actionButton: {
      disabled: false,
      text: 'Reactivate the Sample',
      promise: async () => resumeSample({ id: selected.id }),
    },
    content: (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="body">
          Are you sure you would like Evisort to resume identifying new
          occurrences of this Sample?
        </Text>
        <Text variant="body">
          <strong>Note</strong>: This will run on existing documents.
        </Text>
      </FlexLayout>
    ),
  });

  const onSetStatusFilter = (filters) => {
    const values = filters[0]?.values ?? [];
    setStatus(values.length > 0 ? values : defaultStatus);
  };

  const onCreateNewSample = () => {
    setAutomationQuickAiDataForm({ ...quickAiForm, name: clauseName });
    setAutomationManageStage(AutomationStageType.QuickAiForm);
  };

  const redirectToAnalyzerResults = ({ name }) => {
    const params = new URLSearchParams(
      buildClauseAnalyzerParams(name),
    ).toString();
    redirectToPage(subdomain, `analyzer/results?${params}`);
  };

  const redirectToAdvancedSearch = ({ name }) => {
    const values = [{ provision: name, text_search: [] }];
    const filters = [
      { id: uuid.v4(), fieldId: 'clause', operatorId: 'contains_any', values },
    ];
    const fields = sectionsAndFolders?.fields;
    if (hasAdvancedSearchV3) {
      dispatch(searchV3.actions.setSelectedFilters(filters));
    } else {
      dispatch(searchSlice.actions.setFilters(filters));
      dispatch(
        searchSlice.actions.setQuery({ booleanQuery: '', filters, fields }),
      );
    }

    navigate('/search');
  };

  const redirectToSearchResults = ({ name }) => {
    if (hasAdvancedSearch) {
      redirectToAdvancedSearch({ name });
    } else {
      redirectToAnalyzerResults({ name });
    }
  };

  const onClauseActs = (action) => {
    switch (action) {
      case 'suspend':
        showSuspendClauseModal();
        break;
      case 'reactivate':
        showResumeClauseModal();
        break;
      case 'delete':
        showDeleteClauseModal();
        break;
      default:
        break;
    }
  };

  const fieldGroups = [];

  const fields = {
    [HIGHLIGHT_STATUS_ID]: {
      id: HIGHLIGHT_STATUS_ID,
      label: 'Status',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      overrideOperatorLabels: {
        contains_any: 'includes',
      },
      settings: {
        __testEnableSelectAll: () => false,
        options: HIGHLIGHT_STATUS_OPTIONS,
      },
    },
  };

  const filters = compact([
    {
      id: HIGHLIGHT_STATUS_ID,
      fieldId: HIGHLIGHT_STATUS_ID,
      operatorId: 'contains_any',
      values: status ?? [],
    },
  ]);

  const rowActions = [
    {
      label: 'Suspend',
      onClick: (row) => {
        setSelected(row);
        showSuspendModal();
      },
      condition: (row) => row.status === AutomationStatusType.ACTIVE,
    },
    {
      label: 'Reactivate',
      onClick: (row) => {
        setSelected(row);
        showResumeModal();
      },
      condition: (row) => row.status === AutomationStatusType.SUSPENDED,
    },
    {
      label: 'Delete',
      onClick: (row) => {
        setSelected(row);
        showDeleteModal();
      },
    },
  ];

  const columns = [
    {
      key: 'text',
      title: 'Samples',
      width: 'xl',
      disableSortBy: !enableQuickAiTableSorting,
      renderCell: ({ text, highlightName }) => (
        <FlexLayout sx={{ width: '95%' }}>
          <SampleContentCell text={text} sampleName={highlightName} />
        </FlexLayout>
      ),
    },
    {
      key: 'createdOn',
      cellType: 'datetime',
      title: 'Created On',
      width: 'm',
      disableSortBy: !enableQuickAiTableSorting,
      mapCellProps: ({ createdOn }) => ({
        datetime: createdOn ? new Date(createdOn) : createdOn,
      }),
    },
    {
      key: 'createdBy',
      cellType: 'user',
      title: 'Created by',
      width: 'm',
      disableSortBy: !enableQuickAiTableSorting,
      mapCellProps: ({ createdBy }) => ({
        user: resolvedUsers?.[createdBy],
      }),
    },
    {
      key: 'lastDiscovery',
      title: 'Last Identification',
      cellType: 'datetime',
      width: 'm',
      disableSortBy: !enableQuickAiTableSorting,
      mapCellProps: ({ lastDiscovery }) => ({
        datetime: lastDiscovery ? parseDate(lastDiscovery) : null,
        format: 'iso',
      }),
    },
    {
      key: 'discoveries',
      cellType: 'number',
      title: 'Identifications',
      width: 'm',
      disableSortBy: !enableQuickAiTableSorting,
      mapCellProps: ({ discoveries }) => ({
        number: isNil(discoveries) ? 0 : discoveries,
      }),
    },
    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',
      width: 'm',
      disableSortBy: !enableQuickAiTableSorting,
      mapCellProps: ({ status }) => {
        const isActive = status === AutomationStatusType.ACTIVE;
        const statusChip = {
          text: isActive ? 'Active' : 'Suspended',
          status: isActive ? 'success' : 'inactive',
        };
        return { chips: [statusChip] };
      },
    },
  ];

  if (!dataExists) {
    return (
      <LoadingContainer isLoading={isFetchingAllSamples}>
        <FlexLayout flexDirection="column" alignItems="center" mt={4} space={4}>
          <EmptyPage
            preset="new-clause"
            resolveAction={{
              variant: 'primary',
              icon: 'plus',
              iconPosition: 'left',
              text: 'New Quick AI',
              onClick: () =>
                setAutomationManageStage(AutomationStageType.QuickAiForm),
            }}
          />
        </FlexLayout>
      </LoadingContainer>
    );
  }

  if (dataExists && statusHasNoData && !isFetching) {
    showToast(
      SUCCESS,
      `Because you have no ${toLowercaseTrimmed(status[0])} samples,
       we reset your filters to show you all samples.`,
    );
    onSetStatusFilter([{ values: ALL_STATUSES }]);
  }

  const hasExistingfilters = idf?.filters.length > 0;
  const activeFilters = selectedFilters.filter(isActiveFilter);

  const handleOnSaveFilters = () => {
    updateClauseFilters({
      filters: activeFilters,
      clauseName: clause.name,
    });
  };

  return (
    <FlexLayout flexDirection="column" space={4}>
      {enableQuickAiFilters && (
        <SearchFiltersPreview
          filters={selectedFilters}
          isLoading={isFetchingIdf}
          title="Document Filters"
          description="Filters will be used to select documents that contain this exact clause. Changing filters will impact all tagged samples."
          defaultFilterIds={
            hasExistingfilters
              ? undefined
              : ['folder', 'Contract Type', 'Effective Date']
          }
          onUpdateFilters={(updatedFilters) => {
            setSelectedFilters(updatedFilters);
          }}
          restrictFilterIds={restrictedClauseFilterIds}
          optional={false}
          editMode={true}
          disableDefaultLabel
          onSaveFilters={handleOnSaveFilters}
        />
      )}
      <FlexLayout
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* eslint-disable-next-line react/jsx-pascal-case -- deprecating */}
        <Filters_DEPRECATED
          fields={fields}
          filters={filters}
          groups={fieldGroups}
          options={filtersOptions}
          onChange={onSetStatusFilter}
        />
        <FlexLayout space={4}>
          <Button
            variant="action"
            icon="plus"
            iconPosition="left"
            text="New Sample"
            onClick={onCreateNewSample}
          />
          <Button
            isLoading={isFetchingSections}
            variant="action"
            icon="view"
            iconPosition="left"
            text={`View ${
              idf?.bulkTaggingTotalIdentifications ?? 0
            } Identifications`}
            onClick={() => redirectToSearchResults({ name: clauseName })}
            disabled={(idf?.bulkTaggingTotalIdentifications ?? 0) === 0}
          />
          <FlexLayout>
            <ActionsMenu
              actions={compact([
                clause.status !== AutomationStatusType.SUSPENDED && {
                  label: 'Suspend all',
                  value: 'suspend',
                },
                clause.status === AutomationStatusType.SUSPENDED && {
                  label: 'Reactivate all',
                  value: 'reactivate',
                },
                {
                  label: 'Delete all',
                  value: 'delete',
                  isDanger: true,
                },
              ])}
              name="more actions"
              onActionClick={onClauseActs}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <LoadingContainer
        isLoading={isFetching || isFetchingIdf || isFetchingUsers}
      >
        {!error && !!data && (
          <PersistedTable
            context={TableContextType.AutomationHub}
            name="automation-clause-samples"
            columns={columns}
            totalCount={data.count}
            data={data.results}
            rowActions={rowActions}
            state={pagination}
            onUpdate={setAutomationSamplePagination}
            onPageSizeChange={(pageSize) =>
              setAutomationSamplePageSize({ pageSize })
            }
            options={{
              enableManageColumns: true,
              enableExportXlsx: false,
              enableSelectRows: false,
              enablePageSizeSelect: true,
            }}
          />
        )}
      </LoadingContainer>
      {deleteModal}
      {suspendModal}
      {resumeModal}
      {deleteClauseModal}
      {suspendClauseModal}
      {resumeClauseModal}
    </FlexLayout>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  quickAiForm: automation.quickAiForm,
  pagination: automation.sample.pagination,
});

export default connect(mapStateToProps, {
  setAutomationClause: actions.setAutomationClause,
  setAutomationQuickAiDataForm: actions.setAutomationQuickAiDataForm,
  setAutomationManageStage: actions.setAutomationManageStage,
  setAutomationSamplePageSize: actions.setAutomationSamplePageSize,
  setAutomationSamplePagination: actions.setAutomationSamplePagination,
})(AutomationHubQuickAiPage);
