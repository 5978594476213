import { sylvanus } from '~/services';

import { GetModelSummaryForClientApiV1ModelsClientClientIdSummaryGetApiResponse } from '../../types/sylvanus/platformAdmin';

type Response = GetModelSummaryForClientApiV1ModelsClientClientIdSummaryGetApiResponse;
interface Request {
  clientId: string;
}

export const getModelsQuotaSummary = async ({
  clientId,
}: Request): Promise<Response> => {
  const response: Response = await sylvanus.get(
    `/models/client/${clientId}/summary`,
  );
  return response;
};
