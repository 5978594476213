import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { Dashboard } from '~/redux/api/methods';
import { Chart } from '~/types';

interface Props {
  entity?: Dashboard['entity'];
  id: Chart['id'];
  filterType: Chart['filterType'];
  query: any[];
  interval?: string;
}

export function useGetChartData({
  entity,
  id,
  filterType,
  query,
  interval,
}: Props) {
  const hasDeprecatePilotSearch = useFlag(FlagType.DeprecatePilotSearch);
  const shouldUseV2Endpoint =
    entity === 'ticket' || entity === 'workflow' || hasDeprecatePilotSearch;

  const bucket_size = filterType === 'date' ? 12 : 10;

  const endpoint = shouldUseV2Endpoint ? 'getChartDataV2' : 'getChartData';

  const response = api.endpoints[endpoint].useQuery({
    uuid: id,
    query,
    bucket_size,
    entity,
    interval,
  });

  return {
    ...response,
  };
}
