import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import aiExample from '~/assets/images/x-ray/hover-ai-example.png';
import userExample from '~/assets/images/x-ray/hover-user-example.png';
import {
  CardSelect,
  types as edsTypes,
  FormField,
  Image,
  Layout,
  Text,
} from '~/eds';
import { actions } from '~/redux';
import { FieldAiState } from '~/redux/types';

import { DropdownOptionsSelect } from './DropdownOptionsSelect';
import { NotApplicableOptionSelect } from './NotApplicableOptionSelect';

interface Prop {
  config: FieldAiState['configuration'];
  readOnly: boolean;
}

export const ConfigureDropdownOptions = ({ config, readOnly }: Prop) => {
  const dispatch = useDispatch();

  const initialOptions: edsTypes.Option<string>[] = useMemo(
    () => config.fieldClassification?.options ?? [],
    [],
  );
  const [availableOptions, setAvailableOptions] = useState(initialOptions);
  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  // Default to close-ended if not specified in config.
  const isCloseEnded = !config.fieldClassification?.isOpenEnded;
  const disableOptionsSelect = !isCloseEnded;

  const configureDropdownOptions = [
    {
      disabled: readOnly,
      value: true,
      label: 'Create your own options',
      statusText: {
        text: '(Default)',
        status: 'info',
        variant: 'tiny',
      },
      content: (
        <Layout preset="form-fields" pl={6} w="100%">
          <FormField<edsTypes.Option<string>, true>
            description="Organize data into options you create."
            disabled={disableOptionsSelect}
            input={DropdownOptionsSelect}
            inputProps={{
              options: availableOptions,
              onAddOption: (newOption: edsTypes.Option<string>) => {
                const updatedSelectedOptions = [...selectedOptions, newOption];
                setAvailableOptions([...availableOptions, newOption]);
                setSelectedOptions(updatedSelectedOptions);
                dispatch(actions.setFieldAiOptions(updatedSelectedOptions));
              },
            }}
            label=""
            name="dropdown-options-select-form-field"
            value={selectedOptions}
            onChange={(newOptions) => {
              if (newOptions) {
                setSelectedOptions(newOptions);
                dispatch(actions.setFieldAiOptions(newOptions));
              }
            }}
          />
          <FormField<edsTypes.Option<string>, false>
            description="If none of the options above apply:"
            disabled={disableOptionsSelect}
            input={NotApplicableOptionSelect}
            label=""
            name="not-applicable-dropdown-option-select-form-field"
            value={config.fieldClassification?.defaultOption ?? null}
            onChange={(newOption) => {
              dispatch(actions.setFieldAiDefaultOption(newOption));
            }}
          />
        </Layout>
      ),
    },
    {
      disabled: readOnly,
      value: false,
      label: 'Create with AI',
      description: 'Use AI to find values to populate your dropdown field.',
    },
  ];

  return (
    <FormField<boolean, false>
      isCollapsible
      example={{
        content: (
          <Layout preset="subsections">
            <Text variant="body-medium">
              Examples of AI populating the dropdown field with values it found
              in your documents.
            </Text>
            <Text>"Extract the SKU’s in the document"</Text>
            <Image alt="hover-ai-example" src={aiExample} />
            <Text variant="body-medium">
              Examples of sorting data into options you define.
            </Text>
            <Text>"Is this a mutual or unilateral NDA?"</Text>
            <Image alt="hover-user-example" src={userExample} />
          </Layout>
        ),
        label: 'How it works',
      }}
      input={CardSelect}
      inputProps={{
        options: configureDropdownOptions,
      }}
      label="Configure Dropdown Options"
      name="configure-dropdown-options"
      tooltip={readOnly ? 'You are in read-only mode.' : undefined}
      value={isCloseEnded}
      onChange={(isCloseEnded) =>
        dispatch(actions.setFieldAiIsOpenEnded(!isCloseEnded))
      }
    />
  );
};
