import { api } from '../createApi';
import { TagType } from '../TagType';

export const TAG_USERS_BY_LIST = { type: TagType.Users, id: 'LIST' };
export const TAG_USERS_IMPORT_STATUS = {
  type: TagType.Users,
  id: 'IMPORT_STATUS',
};

api.enhanceEndpoints({
  addTagTypes: [TagType.Users as TagType],
  endpoints: {
    getUsers: {
      providesTags: [TAG_USERS_BY_LIST],
    },
    createUser: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    createSuperAdmin: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    deactivateUser: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    deactivateSuperAdmin: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    reactivateUser: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    cancelUserInvite: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    updateUser: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
    getUsersImportStatus: {
      providesTags: [TAG_USERS_IMPORT_STATUS],
    },
    cancelImportJob: {
      invalidatesTags: [TAG_USERS_IMPORT_STATUS],
    },
    updateExternalUser: {
      invalidatesTags: [TAG_USERS_BY_LIST],
    },
  },
});
