import evisortAvatarLogo from '~/assets/images/evisort-avatar-logo.svg';
import { types } from '~/eds';

export const EVISORT_AI: types.User = {
  id: '0',
  firstName: 'Evisort',
  lastName: 'AI',
  email: '',
  avatar: evisortAvatarLogo,
};

export const EVISORT: types.User = {
  id: '0',
  firstName: 'Evisort',
  lastName: '',
  email: '',
  avatar: evisortAvatarLogo,
};

export const WORKDAY_ADMIN_DISABLED_ACTION_MESSAGE =
  'This is managed in Workday. Contact your Workday admin for assistance';

export const WORKDAY_ADMIN_MANAGED_TEXT =
  'This is managed in Workday by your Workday Admin';
