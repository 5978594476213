import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Divider, Layout } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';

import AribaFooter from '../AribaFooter';
import FieldMapping from './FieldMapping';

function FieldMappingSetup({
  // connected,
  activeSyncPair,
  integrationsSetManageSyncPairStage,
  manageSyncPairStage,
}) {
  const { provider } = activeSyncPair;
  const areAllFieldsMapped =
    activeSyncPair[manageSyncPairStage]?.areAllFieldsMapped;

  const mainActionDisabled = useMemo(() => {
    if (!areAllFieldsMapped) {
      return true;
    }
  }, [areAllFieldsMapped]);

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FolderAriba);
  };

  const handleCreateNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.SummaryAriba);
  };

  return (
    <Layout align="center" direction="column" spacing={8} w="100%">
      <Layout align="center">
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout preset="form-fields">
        <FieldMapping />
      </Layout>
      <AribaFooter
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
        mainAction={{
          text: 'Next',
          onClick: handleCreateNext,
          disabled: mainActionDisabled,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(FieldMappingSetup);
