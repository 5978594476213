import { ParsedType } from '~/redux/api/transformers';
import { UpdateExternalUserRequest } from '~/redux/api/types/rolodex/user';
import { rolodex } from '~/services';

type updateExternalUserRequest = ParsedType<UpdateExternalUserRequest>;
type requestParams = {
  userId: string | number;
  body: updateExternalUserRequest;
};

export const updateExternalUser = async ({ userId, body }: requestParams) => {
  await rolodex.put<any, any, UpdateExternalUserRequest>(
    `/user/external/${userId}`,
    {
      role_uuid: body.roleUuid,
      pilot_department_ids: body.pilotDepartmentIds,
    },
  );
};
