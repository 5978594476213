import { useState } from 'react';

import { Modal, pluralize, useToast } from '~/eds';
import { DeleteAction } from '~/features/document-bulk-actions';
import { api } from '~/redux';
import { PilotId } from '~/types';

interface Props {
  isVisible: boolean;
  documentIds: PilotId[];
  onHide: () => void;
  onSuccess?: () => void;
}

type DeleteDataType = Pick<
  React.ComponentProps<typeof DeleteAction>,
  'reason' | 'comment'
>;

export const DeleteActionModal = ({
  documentIds,
  isVisible,
  onHide,
  onSuccess,
}: Props) => {
  const { toast } = useToast();
  const [{ reason, comment }, setDeleteData] = useState<DeleteDataType>({
    reason: null,
    comment: '',
  });
  const hasDuplicates = documentIds.length > 1;
  const [deleteDocument, { isLoading }] = api.endpoints[
    hasDuplicates ? 'searchV2BulkDelete' : 'deleteDocument'
  ].useMutation();

  const handleDelete = async () => {
    try {
      await deleteDocument({
        reason: reason ?? '',
        extraDetails: comment,
        documentId: documentIds[0],
        documentHandlerIds: documentIds,
        isAllSelected: false,
        searchAPI: 'v3',
      }).unwrap();
      toast({
        status: 'success',
        message: `${pluralize(
          documentIds.length,
          'Document',
        )} deleted successfully.`,
      });
      onSuccess?.();
      onHide();
    } catch {
      toast({
        status: 'danger',
        message: `An error occurred while deleting the ${pluralize(
          documentIds.length,
          'Document',
        )}.`,
      });
    }
  };

  return (
    <Modal
      title={`Delete ${pluralize(documentIds.length, 'Document')}`}
      isVisible={isVisible}
      onHide={onHide}
      primaryAction={{
        text: 'Delete',
        level: 'danger',
        icon: 'trash',
        onClick: handleDelete,
        isLoading,
      }}
    >
      <DeleteAction
        shouldShowDuplicateWarning={documentIds.length > 1}
        reason={reason}
        comment={comment}
        onChange={setDeleteData}
        selectedCount={1}
        type="document"
      />
    </Modal>
  );
};
