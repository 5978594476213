import React, { memo } from 'react';

import { MenuActions, SharedButtonProps, UserAction } from '../../types';
import { ActionsMenu } from '../ActionsMenu';
import { ActionsPopover } from '../ActionsPopover';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { SplitButton } from '../SplitButton';

interface Props {
  /** Primary actions (buttons) */
  actions: UserAction[];
  /** Direction of listed actions */
  direction?: 'column' | 'row';
  /** CSS Flex */
  flex?: 'auto' | 'none';
  /** CSS Flex-justify */
  justify?: string;
  /** Additional actions rendered under an ActionsMenu */
  moreActions?: UserAction[];
  /** this props will control the position of moreActions menu */
  moreActionsPosition?: 'left' | 'right';
  /** Popover action */
  morePopover?: {
    content: React.ReactNode;
  };
  /** Size configuration (if appropriate) */
  size?: SharedButtonProps['size'];
  /** Mode configuration for actions */
  mode?: 'chip';
  /** When set to `true`, indicates the actions should wrap inside their container. */
  wrap?: boolean;
}

/**
 * This component implements possible related user actions through a friendly simplified `actions`-based interface.  It is composed with the `Button`, `IconButton`, `SplitButton`, `ActionsMenu` components respectively.
 *
 * TODO : deprecate `<Layout preset="buttons" />` components in favor of this component.
 */
export const Actions = memo(
  ({
    actions,
    direction,
    flex,
    justify,
    moreActions,
    moreActionsPosition = 'right',
    morePopover,
    size,
    wrap,
    mode: groupMode,
  }: Props) => {
    const actionsMenu = moreActions?.length ? (
      <ActionsMenu
        enablePortal
        actions={toMenuActions(moreActions)}
        name="more actions"
      />
    ) : null;

    return (
      <Layout
        preset="buttons"
        direction={direction}
        flex={flex}
        justify={justify}
        wrap={wrap}
        wrapSpacing={wrap ? 2 : undefined}
      >
        {actionsMenu && moreActionsPosition === 'left' && actionsMenu}
        {actions.map((action, i) => {
          const {
            actions: splitActions,
            mode,
            level: variant,
            text,
            id,
            tooltip,
            ...rest
          } = action;
          const isIconMode = mode === 'icon';
          const isSubmitMode = mode === 'submit';
          const ButtonComponent = isIconMode ? IconButton : Button;

          return splitActions ? (
            <SplitButton
              {...action}
              key={i}
              enablePortal
              actions={toMenuActions(splitActions)}
              name="more actions"
              text={text}
              tooltip={tooltip}
              variant={variant as 'primary' | 'secondary'}
            />
          ) : (
            <ButtonComponent
              {...rest}
              key={i}
              size={size}
              text={text}
              mode={groupMode ?? action.mode}
              tooltip={isIconMode ? tooltip ?? text : tooltip}
              type={isSubmitMode ? 'submit' : undefined}
              variant={variant}
              PRIVATE_variant={variant as 'action' | 'primary' | 'secondary'}
              id={id}
            />
          );
        })}
        {actionsMenu && moreActionsPosition === 'right' && actionsMenu}
        {morePopover && (
          <ActionsPopover {...morePopover} icon="more" name="more contents" />
        )}
      </Layout>
    );
  },
);

const toMenuActions = (actions: UserAction[]): MenuActions<undefined> => {
  return actions.map((action) => {
    const { mode: _mode, actions: groupedActions, ...menuActionProps } = action; // unpack unused non-menu action props

    const menuAction = {
      ...menuActionProps,
      label: action.text,
      value: undefined,
    };

    if (groupedActions) {
      // @ts-expect-error -- see `MenuActions.d.ts` on the union nature of this interface, which is impractical to establish here without significantly increasing typing complexity.
      menuAction.options = toMenuActions(groupedActions);
    }
    return menuAction;
  });
};
