import { types } from '~/eds';
import { DocumentHandler } from '~/features/document-handler';
import { pilot } from '~/services';

import { testIsAxiosError } from '../../utils';
import { DocumentServerResponse } from '../document';
import { PilotError } from '../types';
import { mapResponse } from './mapper';

interface RenameParams {
  documentId: types.PilotId;
  folderId: types.PilotId;
  name: string;
}

export const renameDocumentHandler = async ({
  documentId,
  folderId,
  name,
}: RenameParams): Promise<DocumentHandler> => {
  try {
    const response: DocumentServerResponse = await pilot.put(
      `/document/rename/${documentId}`,
      { document_name: name, folder: folderId },
    );

    return mapResponse(response);
  } catch (error: unknown) {
    if (
      testIsAxiosError<PilotError>(error) &&
      error.response?.data?.non_field_errors
    ) {
      throw new Error(error.response?.data?.non_field_errors[0]);
    }

    throw new Error('An error occurred while renaming the document');
  }
};
