import React from 'react';
import { useStyles } from 'uinix-ui';

import { Box, CollapsibleText, Layout } from '~/eds';
import { ClauseContent, Nullable } from '~/types';

interface Props {
  activeClauseId?: Nullable<number>;
  clauseName: string;
  clauses: ClauseContent[];
  onClauseClick?: (
    activeClause: ClauseContent,
    clauseGroup: ClauseContent[],
  ) => void;
}

export const Clause = ({
  activeClauseId,
  clauseName,
  clauses,
  onClauseClick,
}: Props) => {
  const styles = useStyles();
  return (
    <Layout direction="column">
      {clauses.map((clause) => (
        <Box
          styles={
            activeClauseId === clause.id
              ? [
                  styles.variants['highlight']['ai'],
                  styles.interactions[':hover:focus:active'],
                ]
              : []
          }
          key={clause.id}
          px={6}
          py={4}
          onClick={() =>
            onClauseClick?.({ ...clause, name: clauseName }, clauses)
          }
        >
          <CollapsibleText key={clause.id} text={clause.content} limit={180} />
        </Box>
      ))}
    </Layout>
  );
};
