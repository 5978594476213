import { keyBy, union } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { NAV_AND_SUBNAV_HEIGHT } from '~/constants/page';
import { LoadingShimmer } from '~/eds';
import { TableContextType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useGroupDocumentsBrowser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { LoadingContainer, Text } from '~/ui';
import { renderCellValue } from '~/utils/table';

import { PersistedTable } from '../Shared/PersistedTable';
import { NameCell } from './NameCell';
import { SeeMoreFiles } from './SeeMoreFiles';

function GroupDocumentsTable(
  {
    autoSelectOnlyOption = false,
    columnWidths = {},
    dataFields,
    documentGroupIds,
    enableSelectRows = true,
    groupId,
    isLoading,
    isMultiSelectRows = true,
    onNameClick,
    onLoadView,
    onSaveView,
    onSetColumnOrder,
    onShowLinkToParent,
    onUnlinkFromParent,
    onUpdate,
    rowActions,
    stickyColumnOrder,
    tableState,
    numberOfDocs,
    queryFields,
    enableManageColumns = true,
    enableStickyHeader = false,
    openTarget,
  },
  ref,
) {
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState(
    tableState.selectedRowIds,
  );
  const docTreeBrowser = useGroupDocumentsBrowser({
    id: groupId,
    fields: queryFields,
    documentGroupIds,
  });

  const { tableSettings } = useTableSettings(TableContextType.GroupDocuments);
  const fieldValueMigrationDeferredFeaturesBypass = useFlag(
    FlagType.FieldValueMigrationDeferredFeaturesBypass,
  );
  const disableDocumentTableSorting = useFlag(
    FlagType.DisableDocumentTableSorting,
  );
  const isSortByDisabled =
    fieldValueMigrationDeferredFeaturesBypass || disableDocumentTableSorting;

  useEffect(() => {
    if (tableSettings && !isSortByDisabled) {
      docTreeBrowser.setSortBy(tableSettings.sortBy);
    }
  }, [tableSettings]);

  useImperativeHandle(ref, () => ({
    clearSelectedRows: () => {
      setSelectedRowIds([]);
    },
  }));

  const handleExpand = (document) => {
    docTreeBrowser.expand(document.id);
  };
  const handleCollapse = (document) => {
    docTreeBrowser.collapse(document.id);
  };
  const handleLoadMore = (parentId) => {
    return docTreeBrowser.fetchNextPage(parentId);
  };
  const isExpanded = (document) => {
    return docTreeBrowser.isExpanded(document.id);
  };

  const handleNameClick = (document) => {
    onNameClick?.(document);
  };

  const keyToColumn = (key) => {
    switch (key) {
      case 'name':
        return {
          key,
          title: 'Name',
          // minWidth: 's',
          rawMinWidth: docTreeBrowser.maxLevelLoaded * 40 + 140,
          cellLayoutProps: { ml: 10 },
          renderCell: (document) => {
            const level = docTreeBrowser.getLevel(document.id);

            if (document.isLoadMore) {
              return (
                <SeeMoreFiles
                  indent={level}
                  isLoading={docTreeBrowser.isLoading(document.parentId)}
                  onClick={() => handleLoadMore(document.parentId)}
                />
              );
            }

            return (
              <NameCell
                document={document}
                hasChildren={!!document.number_of_children}
                indent={level}
                isExpanded={isExpanded(document)}
                openTarget={openTarget}
                onClick={handleNameClick}
                onCollapse={handleCollapse}
                onExpand={handleExpand}
              />
            );
          },
        };

      default:
        break;
    }
  };

  const list = docTreeBrowser.getList();

  const getBulkActions = ({ selectedRowIds, isAllRowsSelected }) => {
    const mappedSelectedRows = list.filter((row) =>
      selectedRowIds.includes(row.id.toString()),
    );
    const disabledAttachToParent =
      selectedRowIds?.length === numberOfDocs || isAllRowsSelected;
    const disabledDetachFromParent = mappedSelectedRows.every(
      (item) => item.parent_id === null,
    );

    return [
      {
        label: 'Attach to parent file',
        tooltip: disabledAttachToParent
          ? 'Cannot attach files to a parent because all files are selected'
          : null,
        onClick: onShowLinkToParent,
        disabled: disabledAttachToParent,
      },
      {
        label: 'Detach from parent file',
        tooltip: disabledDetachFromParent ? 'Cannot detach parent files' : null,
        onClick: onUnlinkFromParent,
        disabled: disabledDetachFromParent,
      },
    ];
  };

  const hardcodedColumns = stickyColumnOrder.map(keyToColumn);
  const hardcodedColumnsMapByKey = keyBy(hardcodedColumns, 'key');
  const hardcodedColumnsMapByTitle = keyBy(hardcodedColumns, 'title');

  const wasNotHardcodedAlready = (field) =>
    !hardcodedColumnsMapByKey[field.id] &&
    !hardcodedColumnsMapByTitle[field.name];

  const fieldToColumn = (field) => ({
    key: String(field.id),
    field,
    title: field.label,
    info: field.help_text,
    renderCell: (document) => {
      if (document.isLoadMore) {
        return (
          <>
            {docTreeBrowser.isLoading(document.parentId) && <LoadingShimmer />}
          </>
        );
      } else {
        return (
          <Text shouldTruncate={true} color="gray-900" variant="s-dense">
            {renderCellValue(field, document.selected_fields)}
          </Text>
        );
      }
    },
  });

  const fieldsToColumns = (fields) => {
    return fields.map(fieldToColumn);
  };
  let columns = dataFields
    ? [
        ...hardcodedColumns,
        ...fieldsToColumns(dataFields.filter(wasNotHardcodedAlready)),
      ]
    : hardcodedColumns;

  columns = columns.map((column) => ({
    ...column,
    rawWidth: columnWidths[column.key],
    disableSortBy: isSortByDisabled,
  }));

  const sectionsResult = api.endpoints.getFilterSections.useQuery(undefined);

  const { data: filterSections } = sectionsResult;

  const columnGroups = useMemo(() => {
    if (filterSections?.fieldGroups) {
      return filterSections?.fieldGroups
        .map((group) => ({
          name: group.id,
          label: group.label,
          columns: group.fieldIds,
        }))
        .filter((group) => group.columns?.length);
    }
    return undefined;
  }, [filterSections]);

  const autoSelectTheOnlyRow = () => {
    const ids = [list[0].id];
    setSelectedRowIds(ids);
    onUpdate?.(
      { selectedRowIds: ids },
      { id: ids[0], type: 'toggleRowSelected' },
    );
  };

  useEffect(() => {
    if (
      autoSelectOnlyOption &&
      list.length === 1 &&
      list[0].id !== selectedRowIds?.[0]
    ) {
      autoSelectTheOnlyRow();
    }
  }, [list]);

  const state = useMemo(() => {
    return {
      ...tableState,
      sortBy: [docTreeBrowser.sortBy],
      selectedRowIds,
      isAllRowsSelected,
    };
  }, [
    tableState,
    docTreeBrowser.sortBy,
    list,
    isAllRowsSelected,
    selectedRowIds,
  ]);

  const totalCount = numberOfDocs || list.length;

  const handleUpdate = (state, action) => {
    if (action?.type === 'toggleAllRowsSelected') {
      setIsAllRowsSelected(state.isAllRowsSelected);
      if (!state.isAllRowsSelected) {
        setSelectedRowIds([]);
      }
    }
    if (
      action?.type === 'toggleAllPageRowsSelected' ||
      action?.type === 'toggleRowSelected'
    ) {
      setSelectedRowIds(state.selectedRowIds);
      setIsAllRowsSelected(state.isAllRowsSelected);
    }

    if (action?.type === 'toggleMultipleRowsSelected') {
      setSelectedRowIds(union(selectedRowIds, state.selectedRowIds));
    }

    if (action?.type === 'toggleSortBy' && !isSortByDisabled) {
      docTreeBrowser.setSortBy(state.sortBy[0]);
    }

    onUpdate?.(state, action);
  };

  const stickyHeaderOptions = enableStickyHeader
    ? { stickyHeaderOffset: NAV_AND_SUBNAV_HEIGHT, enableStickyHeader: true }
    : {};

  return (
    <LoadingContainer isLoading={isLoading}>
      {!docTreeBrowser.isLoading(docTreeBrowser.rootId) && (
        <PersistedTable
          context={TableContextType.GroupDocuments}
          name="group documents"
          getBulkActions={isMultiSelectRows ? getBulkActions : null}
          totalCount={totalCount}
          columnGroups={columnGroups}
          columns={columns}
          data={list}
          state={state}
          onLoadView={onLoadView}
          onSaveView={onSaveView}
          onUpdate={handleUpdate}
          onSetColumnOrder={onSetColumnOrder}
          options={{
            enableExportXlsx: false,
            enablePagination: false,
            enablePageSizeSelect: false,
            enableSelectRows,
            enableManageColumns,
            isMultiSelectRows,
            ...stickyHeaderOptions,
          }}
          reactTableOptions={{
            autoResetSortBy: false,
            disableSortRemove: true,
            manualSortBy: true,
          }}
          rowActions={rowActions}
        />
      )}
    </LoadingContainer>
  );
}

export default forwardRef(GroupDocumentsTable);
