import { get, noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import EcBooleanTextSearchPopup from '~/components/Shared/EcBooleanTextSearchPopup';
import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { Box, Layout, SearchInput, Text, useToggle } from '~/eds';
import { Nullable } from '~/types';
import { debounce } from '~/utils/debounce';

type Props = {
  disabled?: boolean;
  isSubmitting?: boolean;
  includeOperatorButton?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  validateInput: (value: string) => Promise<string>;
  onValidationError?: (message: string) => void;
};

const BaseSearchBar = ({
  disabled,
  isSubmitting,
  includeOperatorButton = true,
  placeholder = 'Search filenames and content',
  value,
  onChange,
  onSubmit,
  validateInput,
  onValidationError = noop,
}: Props) => {
  const [
    showSyntaxPopup,
    _toggle,
    showOperatorsInfo,
    hideOperatorsInfo,
  ] = useToggle(false);
  const [error, setError] = useState('');
  const isInputValueInvalid = value && error;

  useEffect(() => {
    onValidationError(error);
  }, [error]);

  const validateQuery = useCallback(
    debounce(async (query) => {
      try {
        const error = await validateInput(query);
        setError(error);
      } catch (e) {
        const errorMessage = get(
          e,
          'response.data.detail',
          'Something went wrong, please try again later.',
        );
        setError(errorMessage);
      }
    }, SEARCH_DEBOUNCE_MS),
    [],
  );
  useEffect(() => {
    if (value) {
      validateQuery(value);
    }
  }, [value]);

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isInputValueInvalid) {
      onSubmit();
    }
  };

  const renderSearchInputAppendix = () => {
    if (!isInputValueInvalid && includeOperatorButton && value.length) {
      const keyToPressName =
        navigator.platform.toUpperCase().indexOf('MAC') >= 0
          ? 'Return'
          : 'Enter';
      return (
        <Box mt={1}>
          <Text color="text.secondary" variant="tiny">
            Press <strong>{keyToPressName}</strong> to run search.
          </Text>
        </Box>
      );
    } else {
      return null;
    }
  };

  const handleChange = (value: Nullable<string>) => {
    onChange(value ?? '');
  };

  return (
    <Box w="100%">
      <Layout direction="column">
        <Layout>
          <Box w="100%">
            <SearchInput
              info={
                includeOperatorButton
                  ? { onClick: showOperatorsInfo, tooltip: 'Operators' }
                  : undefined
              }
              name="boolean-search"
              error={isInputValueInvalid}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onKeyDown={handleOnKeyUp}
              onBlur={() => {
                if (value === '') {
                  onSubmit();
                }
              }}
              disabled={disabled || isSubmitting}
            />
          </Box>
        </Layout>
        {renderSearchInputAppendix()}
      </Layout>
      {showSyntaxPopup && (
        <EcBooleanTextSearchPopup handleOnCloseClick={hideOperatorsInfo} />
      )}
    </Box>
  );
};

export default BaseSearchBar;
