import { useEffect } from 'react';

import { RecentSearch } from '~/features/advanced-search';
import { api } from '~/redux';
import { ContextType } from '~/redux/api/methods';

export const useRecentSearches = (context: ContextType) => {
  const [
    getRecentSearches,
    recentSearchesResult,
  ] = api.endpoints.getRecentSearches.useLazyQuery();
  const { data, isLoading: isLoadingRecentSearches } = recentSearchesResult;
  const [
    updateRecentSearches,
  ] = api.endpoints.updateRecentSearches.useMutation();

  const pushRecentSearch = async (recentSearch: RecentSearch) => {
    if (!recentSearch.query?.length) return;

    const recentSearches = await getRecentSearches({ context }).unwrap();

    const isDuplicated = recentSearches?.data?.some(
      (search) =>
        JSON.stringify(search.query) === JSON.stringify(recentSearch.query),
    );
    if (isDuplicated) return;

    const newRecentSearches = [
      recentSearch,
      ...(recentSearches?.data ?? []).slice(0, 4),
    ];

    updateRecentSearches({
      context,
      recentSearches: newRecentSearches,
    });
  };

  useEffect(() => {
    getRecentSearches({ context });
  }, []);

  return {
    recentSearches: data?.data ?? [],
    isLoadingRecentSearches,
    pushRecentSearch,
  };
};
