// TODO EKP-28256
import { matchPath as DEPRECATED_matchPath } from 'react-router-dom';

import { SearchQuery } from '~/features/advanced-search';
import { matchPath } from '~/routing';
import { FolderTree } from '~/types';
import { findFolder } from '~/ui/components/FolderTree/util';

import type { EntityContextItem } from '../types';

export const DEFAULT_SEARCH_ID = 'evisort-search';
export const DEFAULT_DOCUMENTS_ID = 'ask-anything-documents-id';
export const DEFAULT_CHAT_CONTEXT: EntityContextItem = {
  query: [],
  selectedIds: [],
  label: 'All Documents',
  title: 'All Documents',
  icon: 'field-folder',
  searchEntity: 'document',
  id: DEFAULT_DOCUMENTS_ID,
};

export const ROUTES = {
  folder: '/documents/:id',
  document: '/document/:id',
  dashboard: '/dashboards/:id',
  search: '/search',
  savedSearch: '/search/:id',
  documentGroup: '/document-group/:id',
  reviewerPage: '/workflow/tickets/:id/reviewer/:versionNumber',
  ticketViewPage: '/workflow/tickets/:id',
};

export const pathMatch = (pathname: string, pattern: string) =>
  DEPRECATED_matchPath<{ id: string }>(pathname, { path: pattern })?.params.id;

export const getFolderContext = (
  folderId: number,
  folderTree?: FolderTree,
  primary?: boolean,
) => {
  const folder = findFolder(folderId, (folderTree?.children as any) ?? []);
  if (!folder) return undefined;
  if (folder) {
    const context: EntityContextItem = {
      label: 'This Folder',
      query: getFolderQuery(folderId),
      title: folder.name,
      icon: 'field-folder',
      primary,
      searchEntity: 'document',
      selectedIds: [],
      id: folderId.toString(),
    };
    return context;
  }
};

export const testIsInADocViewerPage = (pathname: string) => {
  return (
    matchPath(ROUTES.document, pathname) ||
    matchPath(ROUTES.reviewerPage, pathname)
  );
};

export const testIsPreSig = (pathname: string) => {
  return (
    matchPath(ROUTES.ticketViewPage, pathname) ||
    matchPath(ROUTES.reviewerPage, pathname)
  );
};

export const getFolderQuery = (folderId: number) => {
  if (!folderId) return [];
  return [
    {
      type: 'filter',
      id: 'folder',
      operator: 'in_folder',
      value: { value_list: [folderId], value_meta: {} },
    },
  ] as SearchQuery;
};

export const getDocumentGroupQuery = (groupId: string) => {
  if (!groupId) return [];
  return [
    {
      type: 'filter',
      id: 'document_group_id',
      operator: 'contains_any',
      value: {
        value_list: [groupId],
        value_meta: {
          multi_select_data: {
            is_all_selected: false,
            length: 1,
            search: '',
            selected_values_map: { [groupId]: true },
          },
        },
      },
    },
  ] as SearchQuery;
};
