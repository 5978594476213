import Pusher, * as PusherTypes from 'pusher-js';
import React, { createContext, useContext, useRef } from 'react';

import AuthenticationStore from '~/auth';
import { getEnvVariable } from '~/dev';
import { LOGIN_CONSTANTS } from '~/features/login';
import Configuration from '~/services/configuration';

const authStore = AuthenticationStore();
type SubscribeCallbacks = {
  success: (data: PusherTypes.Members) => void;
  error?: (error: SubscriptionError) => void;
};
type ChannelsTable = {
  [index: string]: PusherTypes.Channel | PusherTypes.PresenceChannel;
};
interface Context {
  getPusher: () => PusherTypes.default;
  /** Function to subscribe to presence and private channels. callbacks
   * will be called after events of success or error are received after
   * trying to authenticate to pusher.
   */
  subscribe: (
    pusher: PusherTypes.default,
    channelName: string,
    callbacks: SubscribeCallbacks,
  ) => PusherTypes.Channel;
  getChannels: (pusher: PusherTypes.default) => ChannelsTable;
  getPresenceChannelMembers: (
    pusher: PusherTypes.default,
    channelName: string,
    shouldUnsubscribeAfter: boolean,
  ) => Promise<PusherTypes.Members>;
}

interface SubscriptionError {
  type: string;
  error: string;
  status: number;
}
const createPusherInstance = () =>
  new Pusher(getEnvVariable('PUSHER_APP_KEY'), {
    cluster: getEnvVariable('PUSHER_CLUSTER'),
    channelAuthorization: {
      endpoint: `${Configuration.authEndpoint}/v1/auth/pusher-auth`,
      transport: 'ajax',
      customHandler: async (payload, callback) => {
        const { socketId, channelName } = payload;
        const body = new URLSearchParams({
          socket_id: socketId,
          channel_name: channelName,
        });
        try {
          const response = await fetch(
            `${Configuration.authEndpoint}/v1/auth/pusher-auth`,
            {
              method: 'post',
              body,
              credentials: 'include',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(authStore.getCsrf()
                  ? {
                      [LOGIN_CONSTANTS.HEADERS.CSRF_TOKEN]: authStore.getCsrf(),
                    }
                  : {}),
                ...(authStore.getRefreshToken()
                  ? {
                      Authorization: `Bearer ${authStore.getRefreshToken()}`,
                    }
                  : {}),
              },
            },
          );
          const token = await response.json();
          callback(null, token);
        } catch (error: any) {
          callback(error, null);
        }
      },
    },
  });

const PusherContext = createContext<Context>({
  getPusher: () => {
    throw new Error('Pusher not initialized');
  },
  subscribe: () => {
    throw new Error('Pusher not initialized');
  },
  getChannels: () => {
    throw new Error('Pusher not initialized');
  },
  getPresenceChannelMembers: () => {
    throw new Error('Pusher not initialized');
  },
});

export const PusherProvider = ({ children }: { children: React.ReactNode }) => {
  const pusherRef = useRef<PusherTypes.default | undefined>();

  const getPusher = () => {
    if (!pusherRef.current) {
      pusherRef.current = createPusherInstance();
    }
    return pusherRef.current;
  };

  function subscribe(
    pusher: PusherTypes.default,
    channelName: string,
    { success, error }: SubscribeCallbacks,
  ) {
    const channel = pusher.subscribe(channelName);
    channel.bind(
      'pusher:subscription_succeeded',
      (data: PusherTypes.Members) => {
        success?.(data);
      },
    );
    channel.bind(
      'pusher:subscription_error',
      (errorData: SubscriptionError) => {
        error?.(errorData);
      },
    );

    return channel;
  }

  function getPresenceChannelMembers(
    pusher: PusherTypes.default,
    channelName: string,
    shouldUnsubscribeAfter = false,
  ): Promise<PusherTypes.Members> {
    return new Promise((resolve, reject) => {
      subscribe(pusher, channelName, {
        success: (data) => {
          shouldUnsubscribeAfter && pusher.unsubscribe(channelName);
          resolve(data);
        },
        error: (error) => reject(error),
      });
    });
  }

  const context: Context = {
    getPusher,
    subscribe,
    getChannels: (pusher: PusherTypes.default) => pusher.channels.channels,
    getPresenceChannelMembers,
  };

  return (
    <PusherContext.Provider value={context}>{children}</PusherContext.Provider>
  );
};

export const usePusherContext = () => {
  const context = useContext(PusherContext);

  return context;
};
