import { useMemo } from 'react';

import { Bucket, ChartType } from '~/types';

import { BarChart } from './BarChart';
import { DEFAULT_CHART_WIDTH } from './ChartContainer';
import { DonutChart } from './DonutChart';
import { LineChart } from './LineChart';

interface Props {
  buckets: Bucket[];
  type: ChartType;
  width?: number;
}

export const SimpleChart = ({
  buckets,
  type,
  width = DEFAULT_CHART_WIDTH,
}: Props) => {
  return useMemo(() => {
    switch (type) {
      case 'donut':
        return <DonutChart buckets={buckets} width={width} />;

      case 'vertical_bar':
        return (
          <BarChart orientation="vertical" buckets={buckets} width={width} />
        );

      case 'line':
        return <LineChart buckets={buckets} width={width} />;

      default:
        return <BarChart buckets={buckets} width={width} />;
    }
  }, [buckets, type, width]);
};
