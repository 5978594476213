import { FieldValue } from './types';

export const DEFAULT_FIELD_VALUE: FieldValue = {
  type: 'string',
  value: null,
  highlights: undefined,
};
export const DEFAULT_OPTION_TEXT = 'Not Applicable';
export const OPERATORS_WITH_NO_VALUES = new Set([
  'is_blank',
  'is_not_blank',
  'is_true',
  'is_false',
]);
