import { LibraryModelVersion } from '~/features/x-ray/library/types';
import { sylvanus } from '~/services';

import { LibraryPublishResponse } from '../../types/sylvanus/library';

type Response = LibraryPublishResponse;

export const getLibraryModelsPublishStatus = async (): Promise<
  Partial<LibraryModelVersion>[]
> => {
  const response: Response = await sylvanus.get('/library/model/publish');

  return response.publishes.map((modelPublish) => ({
    libraryModelId: modelPublish.library_model_id,
    promptModelId: modelPublish.prompt_model_id,
    publishState: modelPublish.state,
    publishErrorMessage: modelPublish.error_message ?? undefined,
    version: modelPublish.library_model_version_number,
  }));
};
