import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  Chip,
  Layout,
  MarkedText,
  Text,
  TruncateText,
  types,
  useToast,
} from '~/eds';
import { slices } from '~/redux';

import { LibraryModelVersion } from '../types';
import {
  getCategoryChips,
  getChip,
  getEntityTypeLabel,
  testIsModelPublished,
} from './utils';

interface Props {
  model: LibraryModelVersion;
  search?: string;
}

export const ModelCard = ({ model, search }: Props) => {
  const { toast } = useToast();
  const dispatch = useDispatch();

  const category = useSelector(slices.xRayLibrary.selectors.selectCategory);
  const chip = getChip(model.promptModelPublishStatus);
  const isPublished = testIsModelPublished(model);
  const alsoInChips = getCategoryChips(model, category);
  const entityLabel = getEntityTypeLabel(model);
  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);
  const isInCart = Boolean(
    modelsInCart.find(
      (cartModel) => cartModel.libraryModelId === model.libraryModelId,
    ),
  );
  const modelActions = useMemo(() => {
    const actions: types.UserAction[] = [];
    if (isPublished) {
      actions.push({
        icon: 'chevron-right' as const,
        iconPosition: 'right' as const,
        level: 'tertiary' as const,
        text: 'View Model',
        onClick: () =>
          dispatch(slices.xRayLibrary.actions.setModelPreview(model)),
      });
    }
    if (!isInCart && !isPublished && category) {
      actions.push({
        icon: 'plus-circle' as const,
        iconPosition: 'left' as const,
        level: 'secondary' as const,
        text: 'Add',
        onClick: () => {
          dispatch(
            slices.xRayLibrary.actions.addModelToCart({
              model,
              category,
            }),
          );
          toast({
            message: 'Added to cart.',
            status: 'success',
          });
        },
      });
    }
    if (isInCart) {
      actions.push({
        icon: 'trash' as const,
        iconPosition: 'left' as const,
        level: 'tertiary' as const,
        text: 'Remove',
        onClick: () => {
          dispatch(slices.xRayLibrary.actions.removeModelFromCart(model));
          toast({
            message: 'Removed from cart.',
            status: 'success',
          });
        },
      });
    }
    return actions;
  }, [category, isPublished, isInCart]);

  return (
    <Card
      mode="bordered"
      key={`library-model-card-${model.libraryModelId}`}
      name={`library-model-card-${model.libraryModelId}`}
      footer={{ actions: modelActions }}
      onClick={() =>
        dispatch(slices.xRayLibrary.actions.setModelPreview(model))
      }
    >
      <Layout direction="column" spacing={2}>
        <Layout justify="space-between">
          <TruncateText variant="body-bold" fontSize="16" lineHeight="28px">
            <MarkedText text={model.label} search={search} />
          </TruncateText>
          {chip && <Chip {...chip} />}
        </Layout>
        <Text preset="description">{entityLabel}</Text>
        <TruncateText>
          <MarkedText text={model.description} search={search} />
        </TruncateText>
        {alsoInChips}
      </Layout>
    </Card>
  );
};
