import { Highlight as HighlightType } from '~/types';

type HighlightWithoutBoundingRect = Omit<HighlightType, 'position'> & {
  position: Omit<HighlightType['position'], 'boundingRect'>;
};

/** Function to keep Backward compatibility between highlights created on ~/features/document-viewer
 * and this component.
 */
export const mapPdfHighlights = (
  highlight: HighlightType | HighlightWithoutBoundingRect,
): HighlightType => {
  if (testIsLegacyHighlight(highlight)) return highlight;
  const { position } = highlight;
  return {
    ...highlight,
    position: {
      ...position,
      boundingRect: {
        pageNumber: 0,
        height: 0,
        width: 0,
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      },
    },
  };
};

const testIsLegacyHighlight = (
  highlight: HighlightWithoutBoundingRect | HighlightType,
): highlight is HighlightType =>
  !!(highlight as HighlightType).position.boundingRect;
