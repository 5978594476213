import { LOCAL_DOMAIN } from '~/constants/urls';
import { User } from '~/types';
import { addScript } from '~/utils/addScript';
import { domainsEnvCheck } from '~/utils/domainCheck';

export function loadCanduScript() {
  const domain = window.location.hostname;
  const clientToken = getEnvToken(domain);

  let _resolve: (value?: unknown) => void;

  const onLoad = () => {
    _resolve?.();
  };

  const promise = new Promise((resolve) => {
    _resolve = resolve;
  });

  if (
    window &&
    domainsEnvCheck([
      'clients.evisort.com',
      'clients.release.evisort.dev',
      'clients.main.evisort.dev',
      LOCAL_DOMAIN,
    ])
  ) {
    addScript({
      src: 'https://cdn.candu.ai/sdk/latest/candu.umd.js?token=' + clientToken,
      type: 'module',
      async: true,
      onLoad,
    });
  }
  return promise;
}

export function initCandu(user: User) {
  const companyId = user.client ? user.client.toString() : '';

  window.Candu.init({
    // Required: your client token
    clientToken: getEnvToken(window.location.hostname),
    // Optional: provide a User Id
    userId: user.id,
    // Optional: Traits provide a way to pass information about your users for segmentation and variables
    traits: {
      companyId,
    },
    // Optional: Only used for "secret" variables as they are never logged to a server.
    variables: { customerSecret: 'my variable secret' },
    // Optional: callbacks are used to add interactivity to your documents. See below for guide.
    callbacks: { onButtonClick: () => 'callback' },
  });
}

const getEnvToken = (domain: string) => {
  if (domain === 'clients.main.evisort.dev') {
    return 'P1oP8KUSF8';
  }
  // local
  if (domain === LOCAL_DOMAIN) {
    return 'QkD562FUq2';
  }
  if (domain === 'clients.release.evisort.dev') {
    return 'XhvbqebNA9';
  }
  if (domain === 'clients.evisort.com') {
    return 'FFiBW67jQM';
  }
};
