import PT from 'prop-types';
import React, { useContext } from 'react';

import { IconButton, Select } from '~/eds';
import { FlexLayout, SingleSelect } from '~/ui';
import { getOptions } from '~/utils/array';

import ExpressionContext from './ExpressionContext';
import MultiValueInput from './MultiValueInput';
import SingleValueInput from './SingleValueInput';

function FieldNode({ node, onChange, onRemove }) {
  const { fields, fieldDefinitions, operatorDefinitions } = useContext(
    ExpressionContext,
  );
  const fieldOptions = getOptions(Object.values(fields), 'id', 'name');
  const { fieldId, operator, values } = node;

  const field = fields[fieldId];
  const operatorDefinition = operatorDefinitions[operator];
  const isInvalidField = fieldId && !field;
  const isInvalidOperator = operator && !operatorDefinition;

  let dataType;
  let fieldDefinition;
  let fieldValueOptions = [];
  let operators = [];
  let valueCardinality = 0;
  if (field) {
    fieldDefinition = fieldDefinitions[field.type];
    fieldValueOptions = field.options;
    dataType = fieldDefinition.dataType;
    if (fieldDefinition) {
      operators = fieldDefinition.operators;
    }
  }
  if (operatorDefinition) {
    valueCardinality = operatorDefinition.valueCardinality;
  }

  const operatorOptions = operators.map((operator) => {
    const { label, value } = operatorDefinitions[operator] || {};
    return {
      label,
      value,
    };
  });

  const fieldError = isInvalidField
    ? 'The field refered to does not exist.'
    : undefined;
  const operatorError = isInvalidOperator ? 'Invalid operator.' : undefined;
  function handleChange(data) {
    onChange({ ...node, ...data });
  }

  const FieldInput = (
    <div>
      <Select
        enableErrorMessage
        enablePortal={true}
        error={fieldError}
        isSearchable
        options={fieldOptions}
        placeholder="Select Field"
        value={isInvalidField ? 'Select Field' : fieldId}
        width="input.m.width"
        name="Select Field"
        onChange={(updatedFieldId) => {
          handleChange({
            fieldId: updatedFieldId,
            operator: '',
            values: [],
          });
        }}
      />
    </div>
  );

  const OperatorInput = (
    <SingleSelect
      isSearchable
      disabled={operatorError || fieldError}
      disableErrorMessage={!operatorError}
      error={operatorError || fieldError}
      options={operatorOptions}
      placeholder="..."
      value={operator}
      width="m"
      onChange={(updatedOperator) => {
        handleChange({
          operator: updatedOperator,
          values: [],
        });
      }}
      name="Select Operator"
    />
  );

  let ValueInputs;
  if (valueCardinality === Infinity) {
    const valuesError =
      (operator && !values) || values.length === 0
        ? 'Provide a value'
        : undefined;
    ValueInputs = (
      <MultiValueInput
        dataType={dataType}
        disabled={fieldError}
        error={fieldError || valuesError}
        options={fieldValueOptions}
        values={values}
        name="Provide a value"
        onChange={(updatedValues) => {
          handleChange({ values: updatedValues });
        }}
      />
    );
  } else if (valueCardinality > 0) {
    ValueInputs = (
      <>
        {Array.from({ length: valueCardinality }).map((_, i) => {
          const value = values[i];
          const valueError =
            operator && (value === undefined || value === null)
              ? 'Provide a value'
              : undefined;
          return (
            <SingleValueInput
              key={i}
              dataType={dataType}
              disabled={fieldError}
              error={fieldError || valueError}
              options={fieldValueOptions}
              value={value}
              name="Provide a value"
              onChange={(updatedValue) => {
                const updatedValues = Object.assign([], values, {
                  [i]: updatedValue,
                });
                handleChange({ values: updatedValues });
              }}
            />
          );
        })}
      </>
    );
  }

  return (
    <FlexLayout alignItems="center" space={4} role="group">
      <IconButton icon="trash" onClick={onRemove} />
      {FieldInput}
      {OperatorInput}
      {ValueInputs}
    </FlexLayout>
  );
}

const fieldNodePropType = PT.shape({
  fieldId: PT.string,
  id: PT.string.isRequired,
  operator: PT.string,
  values: PT.arrayOf(PT.any.isRequired),
});

FieldNode.propTypes = {
  node: fieldNodePropType.isRequired,
  onChange: PT.func.isRequired,
  onRemove: PT.func.isRequired,
};

export default FieldNode;
