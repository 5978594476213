import { User } from '~/components/Shared/User';
import { Table } from '~/eds';
import { TableContextType } from '~/enums';
import { Balance } from '~/features/billing';
import { Uuid } from '~/types';

interface Props {
  pageIndex: number;
  totalCount: number;
  transactions: Balance[];
  setPanel: (panelName: string, id?: Uuid) => void;
}

export const CreditsLedger = ({
  totalCount,
  transactions,
  setPanel,
}: Props) => {
  const columns = [
    {
      key: 'eventDate',
      cellType: 'datetime',
      title: 'Event Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.eventDate,
      }),
    },
    {
      key: 'addedBy',
      cellType: 'user',
      disableSortBy: true,
      title: 'Added By',
      mapCellProps: (transaction: Balance) => ({
        asyncUser: {
          id: transaction.createdBy,
          render: User,
        },
        mode: 'name',
      }),
    },
    {
      key: 'effectiveDate',
      cellType: 'datetime',
      title: 'Effective Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.effectiveDate,
      }),
    },
    {
      key: 'expirationDate',
      cellType: 'datetime',
      title: 'Expiration Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.expirationDate,
      }),
    },
    {
      key: 'renewalDate',
      cellType: 'datetime',
      title: 'Renewal Date',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.renewalDate,
      }),
    },
    {
      key: 'entryType',
      cellType: 'text',
      title: 'Entry Type',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        text: transaction.entryType,
      }),
    },
    {
      key: 'credits',
      cellType: 'number',
      title: 'Credits',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        number: transaction.credits,
      }),
    },
  ];

  return (
    <Table
      rowActions={[
        {
          label: 'Edit Balance',
          onClick: async (balance: Balance) => {
            if (balance.renewalDate) {
              setPanel('recurring-credits');
            } else {
              setPanel('one-time-credits', balance.id);
            }
          },
        },
      ]}
      name={TableContextType.CreditLedger}
      data={transactions}
      columns={columns}
      options={{
        enableManageColumns: false,
        enableSelectRows: false,
        enablePagination: false,
        enableExportXlsx: true,
      }}
      totalCount={totalCount}
    />
  );
};
