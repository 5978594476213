import { isValid } from 'date-fns';
import { isEmpty } from 'lodash';
import uuid from 'uuid';

import { AND } from '~/constants/openSearch';
import { formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import {
  ClauseValue,
  DatePeriod,
  DateValue,
  DurationUnit,
  FieldId,
  FieldType,
  Filter,
  FilterViewType,
  OperatorId,
  operatorLabelsByFieldType,
  operators,
  testIsEmptyFilter,
} from '~/evifields';
import {
  ApiSearchFilter,
  APISearchSuggestion,
  BuildQueryParams,
  ExportExcelField,
  SearchDocumentItem,
  SearchDocumentsMeta,
  SearchDocumentsResult,
  SearchFilterRecord,
  SearchFilterType,
} from '~/features/advanced-search';
import { createBooleanTextSearchFilter } from '~/features/filters/searchFiltersUtils';
import {
  testIsBooleanTextSearch,
  testIsFilter,
} from '~/features/search/utils/queryUtils';
import {
  PilotSearchQuery,
  toProvisionContains,
} from '~/redux/api/methods/search';
import { pilotV3 } from '~/services';
import {
  BooleanQueryEntity,
  ClauseFilterQueryEntity,
  ClauseMeta,
  ClauseTextSearchOperator,
  ColumnSortOrder,
  EntityQuery,
  EnumSetFilterQueryEntity,
  FilterQueryEntity,
  MultiSelectMeta,
  Nullable,
  QueryOperatorEntity,
  SearchFilter,
  SectionEntity,
} from '~/types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import { createResource, JsonApiListResponse } from '../ApiTypes';
import { ChartData, CreateChartParams } from '../types/dashboard';
import { toSortParam } from '../utils';

interface ListResponse<T> {
  data: T[];
  links: any; //TBD
  meta: any; //TBD
}

type FilterResponseType = {
  type: 'filter';
  attributes: ApiSearchFilter;
};

type SuggestionsResponseMeta = {
  page: { current: number };
  select_all_count: string;
  total_count: number;
};

interface SuggestionsParams {
  filterId: FieldId;
  query: string;
}

interface SearchDocumentsParams {
  query: Array<any>;
  filters?: Array<string | number>;
  sortBy?: ColumnSortOrder;
  page: number;
  pageSize: number;
  store_as_recent_search?: boolean;
}

interface SearchDocumentsResponseType {
  type: 'document';
  attributes: SearchDocumentItem;
}

type ExportOptionsResponse = {
  data: {
    attributes: {
      fields: ExportExcelField[];
      clauses: Array<{
        label: string;
        value: string;
      }>;
      tables: Array<{
        label: string;
        value: string;
      }>;
    };
    type: 'excel';
  };
};

type ExportExcelParams = {
  fields: Nullable<string[]>;
  clauses: Nullable<string[]>;
  tables: Nullable<string[]>;
  includeDuplicates: boolean;
  query: PilotSearchQuery;
};

interface ApiBucket {
  label: string;
  count: number;
}
interface ApiChartAttributes {
  bucketCount: number;
  buckets: ApiBucket[];
}

export const getFilters: () => Promise<SearchFilterRecord> = async () => {
  const response: ListResponse<FilterResponseType> = await pilotV3.get(
    '/search/filters/',
  );
  const list = response?.data?.map((filterItem) =>
    toSearchFilter(filterItem.attributes),
  );
  list.push(createBooleanTextSearchFilter());

  const filtersRecord: SearchFilterRecord = {};
  list.forEach((searchFilter) => {
    filtersRecord[searchFilter.id] = {
      ...searchFilter,
      operators: searchFilter.operators.map((op) =>
        `${op.cardinality}` === 'infinity'
          ? { ...op, cardinality: Number.POSITIVE_INFINITY }
          : op,
      ),
    };
  });
  return filtersRecord;
};

export const getFilterSuggestions: ({
  filterId,
  query,
}: SuggestionsParams) => Promise<
  JsonApiListResponse<
    'suggestion',
    APISearchSuggestion,
    SuggestionsResponseMeta,
    void
  >
> = ({ filterId, query }) =>
  pilotV3.get(`/search/filters/${filterId}/suggestions/`, {
    params: { query },
  });

export const searchDocuments: (
  params: SearchDocumentsParams,
) => Promise<SearchDocumentsResult> = async ({
  page,
  pageSize,
  sortBy = DEFAULT_TABLE_SORT_COLUMN_ORDER,
  query,
  filters = [],
  store_as_recent_search = true,
}) => {
  const response: ListResponse<SearchDocumentsResponseType> = await pilotV3.post(
    '/search/',
    {
      data: {
        type: 'document',
        attributes: {
          query,
          filters,
          store_as_recent_search,
        },
      },
    },
    {
      params: {
        'page[number]': page,
        'page[size]': pageSize,
        sort: toSortParam(sortBy),
      },
    },
  );

  return {
    results: response?.data?.map((searchItem) => searchItem.attributes),
    meta: response?.meta as SearchDocumentsMeta,
  };
};

export const fetchSearchExportOptions: (
  query: PilotSearchQuery,
) => Promise<ExportOptionsResponse> = (query) => {
  return pilotV3.post('/search/export-options/', {
    data: {
      attributes: {
        query,
      },
    },
  });
};

export const exportExcelV2 = ({
  fields = [],
  clauses = [],
  tables = [],
  includeDuplicates,
  query,
}: ExportExcelParams) => {
  return pilotV3.post(
    '/search/export/',
    {
      data: {
        type: 'excel',
        attributes: {
          fields,
          clauses,
          tables,
          include_dup_docs: includeDuplicates,
          query,
        },
      },
    },
    {
      responseType: 'arraybuffer',
      // @ts-ignore RTKQ missing mapping of this property
      fullResponse: true,
    },
  );
};
export const toSearchFilter: (
  apiSearchFilter: ApiSearchFilter,
) => SearchFilter = (apiSearchFilter) => {
  const { clause_text_search } = apiSearchFilter;
  const settings = clause_text_search
    ? { textSearchFilter: clause_text_search }
    : {};
  return {
    ...apiSearchFilter,
    settings,
    type: toEviFilterType(apiSearchFilter.type),
  };
};

export const toEviFilterType = (type: SearchFilterType) => {
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
    case 'document_group_id':
      return 'enum_set';
    case DataFieldType.ARRAY_SINGLE:
      return 'enum';
    case DataFieldType.AGE:
      return 'age';
    case DataFieldType.ATTACHMENT:
      return 'file';
    case DataFieldType.BOOLEAN:
      return 'boolean';
    case DataFieldType.CLAUSE:
    case 'clause':
      return 'clause';
    case DataFieldType.DATE:
      return 'date';
    case DataFieldType.NUMBER:
      return 'number';
    case DataFieldType.STRING:
    case DataFieldType.TEXT_AREA:
      return 'text';
    case 'folder':
      return 'folder';
    default:
      return type;
  }
};

const mapFiltersAndOperators = (
  value: any,
  searchFilters: Record<string, SearchFilter>,
) => {
  const operator = value.operator;
  const fields = value.filters.filter((f: any) => !testIsEmptyFilter(f));

  const results = fields.reduce((acc: any[], v: any) => {
    const searchFilter = searchFilters[v.fieldId];
    const filter = toEntity(v, searchFilter.type);
    if (operator) {
      return acc.concat(filter, operator);
    } else {
      return acc.concat(filter);
    }
  }, []);

  if (results[results.length - 1]?.type === 'operator') {
    results.pop();
  }
  return results;
};

export const buildComplexQueryWithCrossFilters = (
  queryBuilderData: any[],
  crossFilters: Filter[],
  searchFilters: Record<string, SearchFilter>,
): any => {
  const query = [...queryBuilderData];
  crossFilters.forEach((crossFilter) => {
    const searchFilter = searchFilters[crossFilter.fieldId];
    if (searchFilter) {
      query.push(AND);
      query.push(toEntity(crossFilter, searchFilter.type));
    }
  });
  return query;
};

export const buildQuery = (payload?: BuildQueryParams): any => {
  if (!payload) {
    return [];
  }

  const {
    booleanQuery,
    crossFilters = [],
    filters = [],
    queryBuilder = [],
    searchFilters = {},
  } = payload;

  const query:
    | SectionEntity[]
    | Array<
        FilterQueryEntity<any, any> | BooleanQueryEntity | QueryOperatorEntity
      > = booleanQuery
    ? [{ type: 'bool_text_search', value: booleanQuery }]
    : [];

  if (queryBuilder?.length) {
    const result: any = [];
    if (booleanQuery) {
      query.push(AND);
    }
    queryBuilder.forEach((qb) => {
      if (qb.type === 'operator') {
        result.push(qb);
      } else if (qb.value) {
        const section = {
          type: 'section',
          value: mapFiltersAndOperators(qb.value, searchFilters),
        };
        return result.push(section);
      }
      return result;
    });

    query.push(...result.flat());
  }

  if (filters.length || crossFilters.length) {
    if (booleanQuery) {
      query.push(AND);
    }

    filters.forEach((filter) => {
      const searchFilter = searchFilters[filter.fieldId];
      if (searchFilter) {
        query.push(toEntity(filter, searchFilter.type));
        query.push(AND);
      }
    });

    crossFilters.forEach((crossFilter) => {
      const searchFilter = searchFilters[crossFilter.fieldId];
      if (searchFilter) {
        query.push(toEntity(crossFilter, searchFilter.type));
        query.push(AND);
      }
    });

    query.pop();
  }

  return query;
};

const toEnumSetEntity = <T>(filter: Filter<T>) => {
  const filterEntity: EnumSetFilterQueryEntity<T> = {
    type: 'filter',
    id: filter.fieldId,
    operator: filter.operatorId!,
    value: {
      value_list: [],
      value_meta: {},
    },
  };
  filterEntity.value = {
    value_list: filter.values,
    value_meta: {
      multi_select_data: filter.asyncValue
        ? {
            is_all_selected: filter.asyncValue.isAllSelected,
            length: filter.asyncValue.length,
            search: filter.asyncValue.search,
            selected_values_map: filter.asyncValue.selectedValuesMap,
          }
        : undefined,
      filterView: filter.filterView,
    },
  };
  return filterEntity;
};

const toClauseEntity = (filter: Filter<ClauseValue>) => {
  const filterEntity: ClauseFilterQueryEntity = {
    type: 'filter',
    id: filter.fieldId,
    operator: filter.operatorId!,
    value: {
      value_list: [],
      value_meta: {},
    },
  };
  const clauseValue = filter.values[0];
  if (clauseValue) {
    filterEntity.value = {
      value_list: [clauseValue.provision],
      value_meta: {},
    };
    const clauseTextSearch = clauseValue.text_search[0];
    if (clauseTextSearch?.text) {
      filterEntity.value = {
        ...filterEntity.value,
        value_meta: {
          clause_text_search: {
            id: 'text_search',
            operator: clauseTextSearch?.scope.value as ClauseTextSearchOperator, // This is to patch the type Filter<ClauseValue> so we don't have to define a ClauseValueV2 - TODO?
            value: {
              value_list: [clauseTextSearch?.text],
              value_meta: {},
            },
          },
        },
      };
    }
  }
  return filterEntity;
};

type DateQueryValue = string | number | DurationUnit;

const toDateEntity = (filter: Filter<DateValue>) => {
  const filterEntity: FilterQueryEntity<DateQueryValue> = {
    type: 'filter',
    id: filter.fieldId,
    operator: filter.operatorId!,
    value: {
      value_list: [],
      value_meta: {},
    },
  };
  const dateValue = filter.values[0]! as DatePeriod;
  if (dateValue?.unit) {
    filterEntity.value = {
      value_list: [dateValue.value!, dateValue.unit],
      value_meta: {},
    };
  } else {
    filterEntity.value = {
      value_list: filter.values.map((date) => formatDate(date as Date)),
      value_meta: {},
    };
  }
  return filterEntity;
};

const toEntity = (filter: Filter, type: FieldType) => {
  switch (type) {
    case 'enum_set':
      return toEnumSetEntity(filter);
    case 'clause_v2':
    case 'clause':
      return toClauseEntity(filter as Filter<ClauseValue>);
    case 'date':
      return toDateEntity(filter as Filter<DateValue>);
    case 'bool_text_search':
      return {
        type: 'bool_text_search',
        value: filter.values[0],
      } as BooleanQueryEntity;
    default:
      const filterEntity: FilterQueryEntity = {
        type: 'filter',
        id: filter.fieldId,
        operator: filter.operatorId!,
        value: {
          value_list: [],
          value_meta: {},
        },
      };

      filterEntity.value = {
        value_list: filter.values,
        value_meta: {},
      };
      return filterEntity;
  }
};

const toTextSearchScopeV3 = (operatorId: OperatorId) => {
  const op = operatorLabelsByFieldType.text_search;

  switch (operatorId) {
    case operators.text_contains_all_of_these_words.id:
      return {
        value: operators.text_contains_all_of_these_words.id,
        label: op.text_contains_all_of_these_words,
      };
    case operators.text_contains_any_of_these_words.id:
      return {
        value: operators.text_contains_any_of_these_words.id,
        label: op.text_contains_any_of_these_words,
      };
    case operators.text_contains_exact_phrase.id:
      return {
        value: operators.text_contains_exact_phrase.id,
        label: op.text_contains_exact_phrase,
      };
    case operators.text_not_contains_all_of_these_words.id:
      return {
        value: operators.text_not_contains_all_of_these_words.id,
        label: op.text_not_contains_all_of_these_words,
      };
    case operators.text_not_contains_any_of_these_words.id:
      return {
        value: operators.text_not_contains_any_of_these_words.id,
        label: op.text_not_contains_any_of_these_words,
      };
    default:
      return {
        value: operators.text_not_contains_exact_phrase.id,
        label: op.text_not_contains_exact_phrase,
      };
  }
};

export const toSearchV3Filters = (
  query: EntityQuery[],
  searchFilters: SearchFilterRecord,
) => {
  const fieldsInQuery = query.filter(
    (
      queryEntity,
    ): queryEntity is
      | FilterQueryEntity
      | ClauseFilterQueryEntity
      | BooleanQueryEntity =>
      testIsFilter(queryEntity) || testIsBooleanTextSearch(queryEntity),
  );

  return fieldsInQuery.map(
    (field: FilterQueryEntity | ClauseFilterQueryEntity | BooleanQueryEntity) =>
      toSearchV3Filter(field, searchFilters),
  );
};

export const toSearchV3Filter = (
  field: FilterQueryEntity | BooleanQueryEntity,
  searchFilters: SearchFilterRecord,
): Filter => {
  if (testIsBooleanTextSearch(field)) {
    return {
      asyncValue: undefined,
      fieldId: 'bool_text_search',
      fieldType: 'bool_text_search',
      hasError: false,
      operatorId: null,
      id: uuid.v4(),
      values: [field.value],
    };
  }
  const type = searchFilters[field.id]?.type;
  let asyncValue, values: unknown[];
  let filterView: FilterViewType | undefined;
  switch (type) {
    case 'clause_v2':
    case 'clause':
      const meta = field.value.value_meta as ClauseMeta;
      const cts = meta.clause_text_search;

      const clauseValue: any = {
        provision: field.value.value_list[0],
        text_search: [],
      };

      if (cts) {
        clauseValue.text_search.push({
          contains: toProvisionContains(field.operator)!,
          scope: toTextSearchScopeV3(cts.operator),
          text: cts.value.value_list[0],
        });
      }

      values = [clauseValue];
      break;
    case 'date':
      const dateArray = field.value.value_list;
      const isAllValidDates = dateArray.every((dateString) =>
        isValid(new Date(dateString as string)),
      );
      const datesValues = isAllValidDates
        ? dateArray.map((dateString) => {
            return parseDate(dateString as string);
          })
        : [
            {
              unit: dateArray[1],
              value: dateArray[0],
            },
          ];
      values = field.value.value_list.length ? datesValues : [];
      break;
    case 'enum_set':
      const metaData =
        field.value.value_meta[
          'multi_select_data' as keyof typeof field.value.value_meta
        ] || null;

      if (!isEmpty(metaData)) {
        asyncValue = {
          isAllSelected: metaData['is_all_selected'],
          length: metaData['length'],
          search: metaData['search'],
          selectedValuesMap: metaData['selected_values_map'],
        };
      } else {
        asyncValue = undefined;
      }
      filterView = (field.value.value_meta as MultiSelectMeta).filterView;
      values = [...field.value.value_list];
      break;
    default:
      values = [...field.value.value_list];
  }

  return {
    asyncValue,
    fieldId: field.id as string,
    fieldType: type,
    hasError: false,
    id: uuid.v4(),
    filterView,
    operatorId: field.operator,
    values,
  };
};

const createChart: createResource<
  'chart',
  ApiChartAttributes,
  CreateChartParams
> = ({ uuid, query, bucket_size = 10, interval }) =>
  pilotV3.post(`/search/chart/${uuid}/`, {
    data: {
      attributes: {
        query,
        bucket_size,
        interval,
      },
    },
  });

export const getChartData = async (
  params: CreateChartParams,
): Promise<ChartData> => {
  const response = await createChart(params);

  const { buckets: apiBuckets, bucketCount } = response!.data.attributes;
  return {
    bucketCount,
    buckets: apiBuckets.map((b) => ({ label: b.label, value: b.count })),
    meta: response?.meta,
  };
};

// TODO: Will potentially replace buildQuery once we get it working with query builder and cross filter as well.
export const buildFilterQuery = (payload?: BuildQueryParams): any => {
  if (!payload) {
    return [];
  }

  const { booleanQuery, crossFilters = [], filters = [] } = payload;

  const query:
    | SectionEntity[]
    | Array<
        FilterQueryEntity<any, any> | BooleanQueryEntity | QueryOperatorEntity
      > = booleanQuery
    ? [{ type: 'bool_text_search', value: booleanQuery }]
    : [];

  if (filters.length || crossFilters.length) {
    if (booleanQuery) {
      query.push(AND);
    }

    filters.forEach((filter) => {
      if (filter.fieldType) {
        query.push(toEntity(filter, filter.fieldType));
        query.push(AND);
      }
    });

    query.pop();
  }

  return query;
};
