import { FileExtensionType, FileMimeType } from '~/enums';

export const DEFAULT_ACCEPTED_FILES = {
  [FileMimeType.Pdf]: [FileExtensionType.Pdf],
  [FileMimeType.Doc]: [FileExtensionType.Doc],
  [FileMimeType.Docx]: [FileExtensionType.Docx],
  [FileMimeType.Jpeg]: [FileExtensionType.Jpeg, FileExtensionType.Jpg],
  [FileMimeType.Png]: [FileExtensionType.Png],
  [FileMimeType.Tif]: [FileExtensionType.Tif],
  [FileMimeType.Tiff]: [FileExtensionType.Tiff],
  [FileMimeType.Csv]: [FileExtensionType.Csv],
  [FileMimeType.Html]: [FileExtensionType.Html],
  [FileMimeType.Txt]: [FileExtensionType.Txt],
  [FileMimeType.Webp]: [FileExtensionType.Webp],
  [FileMimeType.Xls]: [FileExtensionType.Xls],
  [FileMimeType.Xlsx]: [FileExtensionType.Xlsx],
  [FileMimeType.DocUnofficial]: [FileExtensionType.Doc],
  [FileMimeType.DocxUnofficial]: [FileExtensionType.Docx],
  [FileMimeType.JpegUnofficial]: [FileExtensionType.Jpeg],
  [FileMimeType.JpgUnofficial]: [FileExtensionType.Jpg],
  [FileMimeType.PngUnofficial]: [FileExtensionType.Png],
  [FileMimeType.TifUnofficial]: [FileExtensionType.Tif],
  [FileMimeType.TiffUnofficial]: [FileExtensionType.Tiff],
};
