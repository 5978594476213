import { identity } from 'lodash';
import React, { useMemo } from 'react';

import { Select, types, useThrottledState } from '~/eds';
import { AribaFieldSubType, DataFieldType } from '~/enums';
import { DataField, getIconFromFieldType } from '~/features/fields';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';
import { sortByStringValue } from '~/utils/array';

type Props = {
  value: Nullable<PilotId>;
  onChange: (field: Nullable<DataField>) => void;
  disabled?: boolean;
  aribaFieldType: AribaFieldSubType;
  footerAction?: types.UserAction;
  filterOption?: (option: types.Option<PilotId>) => boolean;
  isLoading?: boolean;
  name?: string;
  mapOption?: (
    option: types.Option<PilotId, DataField>,
  ) => types.Option<PilotId, DataField>;
  sortOption?: (
    a: types.Option<PilotId, DataField>,
    b: types.Option<PilotId, DataField>,
  ) => number;
};

export const FieldMappingSelect = ({
  disabled,
  filterOption,
  footerAction,
  isLoading: overrideIsLoading,
  name = 'field-select',
  mapOption = identity,
  onChange,
  sortOption = sortByStringValue('label'),
  value,
}: Props) => {
  const [throttledSearch, _search, setSearch] = useThrottledState('');
  const {
    data: searchableFields,
    error: searchableFieldsError,
    isFetching: isFetchingSearchableFields,
  } = api.endpoints.getFields.useQuery({
    limit: 100,
    search: throttledSearch,
  });

  const {
    data: resolvedFields,
    error: resolvedFieldsError,
    isFetching: isFetchingResolvedFields,
  } = api.endpoints.getFields.useQuery(
    {
      limit: 1,
      ids: value ? [value] : undefined,
    },
    {
      skip: !value,
    },
  );

  const error =
    resolvedFieldsError || searchableFieldsError
      ? 'Unable to load fields'
      : undefined;
  const isLoading =
    overrideIsLoading || isFetchingSearchableFields || isFetchingResolvedFields;
  const syntheticFields = [
    {
      id: -1, // can't be zero, but must exist. Real fields will have positive IDs.
      label: 'Document URL',
      type: DataFieldType.STRING,
      helpText: 'The URL of the document in Evisort',
      isSmart: true, // makes this read-only
    } as DataField,
  ];

  const options = useMemo(() => {
    const fields = [
      ...(resolvedFields?.results ?? []),
      ...(searchableFields?.results ?? []),
      ...syntheticFields,
    ];
    // the field ariba messages was added to show errors, so should not be mapped
    const excludedFields = ['ariba messages', 'ariba primary document'];
    return fields
      .map((field) => ({
        data: field,
        label: field.label,
        leftIcon: getIconFromFieldType(field.type),
        value: field.id,
      }))
      .map(mapOption)
      .filter(
        (field) => !excludedFields.includes(field.label.toLocaleLowerCase()),
      )
      .sort(sortOption);
  }, [resolvedFields, searchableFields, mapOption, sortOption]);

  const optionsMap: Record<PilotId, DataField> = useMemo(
    () =>
      Object.fromEntries(options.map((option) => [option.value, option.data!])),
    [options],
  );

  const handleChange = (value: Nullable<PilotId>) => {
    onChange(value === null ? null : optionsMap[value]);
  };

  return (
    <Select
      disabled={disabled}
      error={error}
      filterOption={filterOption}
      footerAction={footerAction}
      isClearable={false}
      isLoading={isLoading}
      isMulti={false}
      name={name}
      options={options}
      placeholder="Search for a field…"
      value={value}
      onChange={handleChange}
      onSearch={setSearch}
    />
  );
};
