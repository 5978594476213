import { noop } from 'lodash';
import React from 'react';

import { FormField, Layout, useToggle } from '~/eds';
import { Filter } from '~/evifields';
import { SearchFilters } from '~/features/filters';
import { PilotId, Uuid } from '~/types';

import { SearchFiltersScope } from './SearchFiltersScope';

interface Props {
  filters: Filter[];
  onUpdateFilters: (updatedFilters: Filter[]) => void;
  title: string;
  defaultFilterIds?: Array<string>;
  /**Disable the default Filters label*/
  disableDefaultLabel?: boolean;
  description?: string;
  descriptionLink?: {
    linkText: string;
    pathname: string;
    additionalText?: string;
    tooltip?: string;
  };
  docsInScope?: boolean;
  isLoading?: boolean;
  optional?: boolean;
  required?: boolean;
  readOnly?: boolean;
  whitelistFieldIds?: Array<PilotId | Uuid>;
  restrictFilterIds?: Array<PilotId | Uuid>;
  editMode?: boolean;
  onDocsInScopeFound?: (docCount: number) => void;
  onSaveFilters?: () => void;
  onCancel?: () => void;
}

export const SearchFiltersPreview = ({
  description,
  descriptionLink,
  filters,
  isLoading,
  defaultFilterIds = [],
  docsInScope = false,
  onUpdateFilters,
  optional = true,
  required = false,
  readOnly = false,
  whitelistFieldIds,
  restrictFilterIds,
  title,
  editMode,
  disableDefaultLabel,
  onDocsInScopeFound,
  onSaveFilters,
  onCancel,
}: Props) => {
  const viewDocumentCount = filters.length > 0 && docsInScope;

  const [
    isEditing,
    _toggleIsEditing,
    enableIsEditing,
    disableIsEditing,
  ] = useToggle();

  return (
    <FormField<Filter[], true>
      actions={
        editMode
          ? !isEditing
            ? [
                {
                  icon: 'edit',
                  text: 'Edit Filters',
                  level: 'action',
                  onClick: enableIsEditing,
                },
              ]
            : [
                {
                  icon: 'x',
                  text: 'Cancel',
                  level: 'action',
                  onClick: () => {
                    onCancel?.();
                    disableIsEditing();
                  },
                },
                {
                  icon: 'check',
                  text: 'Save Changes',
                  level: 'action',
                  onClick: () => {
                    onSaveFilters?.();
                    disableIsEditing();
                  },
                },
              ]
          : []
      }
      optional={optional}
      required={required}
      description={description}
      descriptionLink={descriptionLink}
      input={null}
      label={title}
      name="search-filters-preview"
      onChange={noop}
      value={null}
    >
      <Layout preset="subsections">
        <SearchFilters
          defaultFilterIds={defaultFilterIds}
          disableDefaultLabel={disableDefaultLabel}
          filters={filters}
          isLoading={isLoading}
          onFilterChange={onUpdateFilters}
          readOnly={readOnly || (editMode && !isEditing)}
          whitelistFieldIds={whitelistFieldIds}
          restrictFilterIds={restrictFilterIds}
        />
        {viewDocumentCount && (
          <SearchFiltersScope
            filters={filters}
            isVisible={viewDocumentCount}
            onDocsInScopeFound={onDocsInScopeFound}
          />
        )}
      </Layout>
    </FormField>
  );
};
