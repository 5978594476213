import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getSortedCategoryModels } from '~/features/x-ray/library/ModelLibrary/utils';
import { LibraryModelVersion, Tag } from '~/features/x-ray/library/types';

import { SliceType } from '../types';
import { LibraryState as State } from './types';

export const initialState: State = {
  modelPreview: null,
  modelsInCart: [],
  selectedCategory: null,
  categoryModels: new Map(),
  filters: {
    globalFilters: [],
    categoryFilters: {},
  },
};

export default createSlice({
  name: SliceType.XRayLibrary,
  initialState,
  selectors: {
    selectCart: (state) => state.modelsInCart,
    selectCategory: (state) => state.selectedCategory,
    selectModelPreview: (state) => state.modelPreview,
    selectCategoryModels: (state) =>
      getSortedCategoryModels(state.modelsInCart),
    selectFilters: (state) => state.filters,
  },
  reducers: {
    clearCart: (state) => {
      state.modelsInCart = [];
    },
    addAllModelsToCart: (
      state,
      action: PayloadAction<{
        models: LibraryModelVersion[];
        category: Tag;
      }>,
    ) => {
      // Add all models that are not already in the cart or are in a different category
      const modelsToAdd = action.payload.models.filter(
        (model) =>
          !state.modelsInCart.find(
            (m) => model.libraryModelId === m.libraryModelId,
          ) ||
          state.modelsInCart.find(
            (m) =>
              model.libraryModelId === m.libraryModelId &&
              m.tags[0]?.label !== action.payload.category.label,
          ),
      );
      state.modelsInCart = [
        ...state.modelsInCart.filter(
          (model) =>
            !modelsToAdd.find((m) => m.libraryModelId === model.libraryModelId),
        ),
        ...modelsToAdd.map((model) => ({
          ...model,
          tags: [action.payload.category],
        })),
      ];
    },
    addModelToCart: (
      state,
      action: PayloadAction<{ model: LibraryModelVersion; category: Tag }>,
    ) => {
      const existingModel = state.modelsInCart.find(
        (model) => model.libraryModelId === action.payload.model.libraryModelId,
      );
      if (existingModel) {
        if (existingModel.tags[0]?.label === action.payload.category.label) {
          return;
        } else {
          // Remove it from the previous category before adding it to the new one
          state.modelsInCart = state.modelsInCart.filter(
            (model) => model.libraryModelId !== existingModel.libraryModelId,
          );
        }
      }
      state.modelsInCart = [
        ...state.modelsInCart,
        { ...action.payload.model, tags: [action.payload.category] },
      ];
    },
    removeModelFromCart: (
      state,
      action: PayloadAction<LibraryModelVersion>,
    ) => {
      const index = state.modelsInCart.findIndex(
        (model) => model.libraryModelId === action.payload.libraryModelId,
      );
      if (index === -1) {
        return;
      }
      state.modelsInCart.splice(index, 1);
    },
    setCategory: (state, action: PayloadAction<State['selectedCategory']>) => {
      state.selectedCategory = action.payload;
    },
    setModelPreview: (state, action: PayloadAction<State['modelPreview']>) => {
      state.modelPreview = action.payload;
    },
    setFilters: (state, action: PayloadAction<State['filters']>) => {
      state.filters = action.payload;
    },
  },
});
