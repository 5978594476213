import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tree, types } from '~/eds';
import { slices } from '~/redux';

export const ModelCart = () => {
  const dispatch = useDispatch();

  const cartModels = useSelector(slices.xRayLibrary.selectors.selectCart);

  const categoryModels = useSelector(
    slices.xRayLibrary.selectors.selectCategoryModels,
  );

  const [tree, setTree] = useState<types.TreeNode[]>([]);

  useEffect(() => {
    const initialTree: types.TreeNode[] = [];
    categoryModels.forEach((models, label) => {
      initialTree.push({
        children: models.map((model) => ({
          key: model.libraryModelId,
          name: model.label,
          parentKey: label,
          actions: [
            {
              icon: 'trash',
              mode: 'icon',
              text: '',
              tooltip: 'Remove from cart',
              onClick: () => {
                dispatch(slices.xRayLibrary.actions.removeModelFromCart(model));
              },
            },
          ],
          children: [],
          onClick: () => {
            dispatch(slices.xRayLibrary.actions.setModelPreview(model));
          },
        })),
        key: label,
        name: label,
        isExpanded: tree.find((node) => node.key === label)?.isExpanded ?? true,
      });
    });
    setTree(initialTree);
  }, [cartModels]);

  return (
    <Tree
      name="model-library-shopping-cart-tree"
      tree={tree}
      title="Selected Models"
      totalCount={cartModels.length}
      onUpdate={(updatedTree) => {
        if (!isEqual(updatedTree, tree)) {
          setTree(updatedTree);
        }
      }}
    />
  );
};
