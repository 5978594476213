import { bindActionCreators } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import pluralize from 'pluralize';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { analyzerResetData } from '~/actions';
import { exportExcel } from '~/api';
import {
  analyzerBulkCopy,
  analyzerBulkDelete,
  analyzerBulkEdit,
  analyzerBulkEditData,
  analyzerBulkMove,
  getDocument,
  SearchResultsItem,
  SearchResultsSidebar,
  sortOptions,
  styles,
  transformSelectedItemsForQueryParam,
} from '~/components/AnalyzerResultsPage';
import DeleteDocumentWithReasonModal from '~/components/Modals/DeleteDocumentWithReasonModal';
import SaveSearch from '~/components/SearchV2/SaveSearch';
import { checkSearchQueryHasUnsupportedEntity } from '~/components/SearchV2/utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import EcCheckbox from '~/components/Shared/EcCheckbox';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import EcSelect from '~/components/Shared/EcSelect';
import { showToast } from '~/components/Shared/EcToast';
import EcTooltip from '~/components/Shared/EcTooltip';
import ExportExcelLimitWarning, {
  EXPORT_EXCEL_LIMIT,
} from '~/components/Shared/ExportExcelLimitWarning';
import withHover from '~/components/Shared/HOCs/withHover';
import AnalyzerResultsEmptyStateIcon from '~/components/Shared/Icons/AnalyzerResultsEmptyStateIcon';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import CopyToClipboardIcon from '~/components/Shared/Icons/CopyToClipboardIcon';
import EditIcon from '~/components/Shared/Icons/EditIcon';
import FilterIcon from '~/components/Shared/Icons/FilterIcon';
import FolderIcon from '~/components/Shared/Icons/FolderIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import TreeIcon from '~/components/Shared/Icons/TreeIcon';
import SearchLimitWarning from '~/components/Shared/SearchLimitWarning';
import SearchReIndexInProgressWarning from '~/components/Shared/SearchReIndexInProgressWarning';
import SelectDeselectAllMenu from '~/components/Shared/SelectDeselectAllMenu';
import {
  BULK_COPY_LIMIT,
  BULK_DELETE_LIMIT,
  BULK_EDIT_LIMIT,
  BULK_MOVE_LIMIT,
  SEARCH_RESULTS_LIMIT,
} from '~/constants/max_lengths';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { Button, formatDate } from '~/eds';
import {
  FeatureFlagType,
  FileMimeType,
  HttpStatusCodeType,
  QueryEntityType,
  QueryParamType,
} from '~/enums';
import { convertQueryV2ToQueryV3 } from '~/features/search';
import { FlagType, withFlags } from '~/flags';
import { testHasFlag } from '~/permissions';
import {
  getAlertSections,
  getSearchHandlersByPosition,
  getSearchPositionByHandler,
  testIsSupportedFilterType,
  toFilters,
} from '~/redux/api/methods';
import { transformFieldSections } from '~/redux/api/transformers/search';
import { initContext } from '~/redux/slices/documentsNavigation';
import search from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { withRouting } from '~/routing';
import {
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP,
  MODAL_EXCEL_EXPORT,
  MODAL_FOLDER_TREE,
  MODAL_MULTI_EDIT,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { parseNavigationResponse } from '~/utils';
import { getUniqueObjectsInArrayByKey } from '~/utils/array';
import { testContainsGhostSearchResult } from '~/utils/document.utils';
import { coercePageIndex, getMaximumByLimit } from '~/utils/number';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { featureFlagIncluded } from '~/utils/user';

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleOnBackClick = this.handleOnBackClick.bind(this);
    this.handleOnPageClick = this.handleOnPageClick.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleOnSelectPageClick = this.handleOnSelectPageClick.bind(this);
    this.handleOnSelectAllClick = this.handleOnSelectAllClick.bind(this);
    this.handleOnDocumentSelectClick = this.handleOnDocumentSelectClick.bind(
      this,
    );
    this.onBulkMoveCopy = this.onBulkMoveCopy.bind(this);
    this.onBulkDelete = this.onBulkDelete.bind(this);

    this.state = {
      page: 1,
      loading: true,
      errorLoading: false,
      documents: [],
      resultCount: 0,
      hasAsyncExportInProgress: false,
      exporting: false,
      currentModal: null,
      bulkAction: null,
      expanded: false,
      isPageSelected: false,
      isAllSelected: false,
      searchQuery: [],
      selectedItems: [],
      sortBy: sortOptions[0],
    };
  }

  componentDidMount() {
    this.searchWithQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && prevProps.data !== this.props.data) {
      const { data } = this.props;
      updatePageSearchQuery({
        [QueryParamType.IsAllSelected]: false,
        [QueryParamType.Page]: PAGE_START,
        [QueryParamType.Query]: data,
        [QueryParamType.SelectedItems]: [],
      });
      this.searchWithQuery();
    }
  }

  searchWithQuery() {
    const { currentUser, data, flags } = this.props;
    const expanded =
      getPageSearchQueryByKey(QueryParamType.Expanded, false) === 'true';
    const isAllSelected =
      getPageSearchQueryByKey(QueryParamType.IsAllSelected, false) === 'true';
    const selectedItems = getPageSearchQueryByKey(
      [QueryParamType.SelectedItems],
      [],
    );
    const page = getPageSearchQueryByKey([QueryParamType.Page], PAGE_START);
    const sortedBy = getPageSearchQueryByKey(
      QueryParamType.SortedColumn,
      'name-asc',
    );
    const sortByField =
      sortOptions.find((obj) => obj.value === sortedBy) || sortOptions[1];
    const queryObject = getPageSearchQueryByKey(QueryParamType.Query, data);

    const hasSearchV2Flag = testHasFlag(FeatureFlagType.SearchV2)(currentUser);
    const shouldRedirectToSearch =
      hasSearchV2Flag && flags[FlagType.SearchPageResultsForAnalyzer];
    if (shouldRedirectToSearch) {
      this.parseQueryToSearchPage(queryObject);
    } else {
      this.setState(
        {
          loading: false,
          expanded,
          isAllSelected,
          page,
          searchQuery: queryObject,
          selectedItems,
          sortBy: sortByField,
        },
        () => {
          this.getSearchResults();
          updatePageSearchQuery({
            [QueryParamType.Expanded]: expanded,
            [QueryParamType.IsAllSelected]: isAllSelected,
            [QueryParamType.Page]: page,
            [QueryParamType.Query]: queryObject,
            [QueryParamType.SelectedItems]: selectedItems,
            [QueryParamType.SortedColumn]: sortByField.value || 'name-asc',
          });
        },
      );
    }
  }

  async parseQueryToSearchPage(queryObject = []) {
    const { dispatch, navigate } = this.props;
    const isComplexSearch = checkSearchQueryHasUnsupportedEntity(queryObject);
    dispatch(search.actions.reset());
    dispatch(searchV3.actions.reset());
    if (isComplexSearch) {
      dispatch(search.actions.setUnsupportedQuery(queryObject));
      dispatch(
        searchV3.actions.setQueryBuilder(convertQueryV2ToQueryV3(queryObject)),
      );

      // For some reason, the navigate function isn’t ready when it’s called immediately.
      setTimeout(() => {
        navigate('/search');
      }, 1000);
    } else {
      try {
        const sectionsResponse = await getAlertSections();
        const supportedFieldEntities = queryObject.filter((filter) =>
          testIsSupportedFilterType(filter),
        );
        const filtersSupported = toFilters(supportedFieldEntities);
        const booleanQuery =
          queryObject.find(
            (filter) => filter.entity === QueryEntityType.BoolTextSearch,
          )?.query || '';

        dispatch(
          search.actions.setQuery({
            booleanQuery,
            filters: filtersSupported,
            fields: transformFieldSections(sectionsResponse),
          }),
        );
        dispatch(search.actions.setBooleanQuery(booleanQuery));
        dispatch(search.actions.setFilters(filtersSupported));
        dispatch(search.actions.setPage(1));

        dispatch(searchV3.actions.setSearchText(booleanQuery));
        dispatch(searchV3.actions.setSelectedFilters(filtersSupported));
        dispatch(searchV3.actions.setPage(1));

        navigate('/search');
      } catch (error) {
        showToast(
          ERROR,
          'An error occurred while processing the search query.',
        );
        this.setState({ loading: false });
      }
    }
  }

  componentWillUnmount() {
    const path = this.props.location.pathname;

    if (path !== '/analyzer' && !path.includes('/document/')) {
      this.props.analyzerResetData();
    }
  }

  getSearchResults() {
    this.setState({ loading: true });
    const { page, searchQuery, sortBy } = this.state;

    const requestData = { query: searchQuery };

    getDocument(requestData, sortBy.value, page, PAGE_SIZE)
      .then((res) => {
        this.setState({
          documents: res.results,
          resultCount: res.count,
          totalDocsIncludingDups: res.totalDocsIncludingDups,
          fullyIndexed: res.fullyIndexed,
          docCountIndexing: res.docCountIndexing,
          loading: false,
          errorLoading: false,
          hasAsyncExportInProgress: res.isDuplicateExport,
        });
      })
      .catch(() => this.setState({ loading: false, errorLoading: true }));
  }

  exportFromLastSavedSetting = () => {
    this.onExport(null);
  };

  onExport = (exportSetting) => {
    const { searchQuery, sortBy } = this.state;
    const requestData = { query: searchQuery };

    this.setState({ exporting: true });
    trackSegment('Exporting excel', requestData);
    // according to the documentation for getTimezoneOffset(), pt (-7) would be 7 instead
    const offsetFromUTCInHour = -(new Date().getTimezoneOffset() / 60);
    const formData = exportSetting
      ? { ...requestData, sortBy, offsetFromUTCInHour, exportSetting }
      : { ...requestData, sortBy, offsetFromUTCInHour };
    exportExcel(formData)
      .then((res) => {
        let excelMessage = 'Your results have started exporting. ';
        if (res.status === HttpStatusCodeType.Ok) {
          showToast(SUCCESS, excelMessage);
          const blob = new Blob([res.data], { type: FileMimeType.Xlsx });
          const dateString = formatDate(new Date(), 'iso_datetime');
          saveAs(blob, `${dateString} analyzer.xlsx`);
        } else if (res.status === HttpStatusCodeType.Accepted) {
          showToast(
            SUCCESS,
            excelMessage +
              'You will receive an email when the export is complete.',
          );
          this.setState({ hasAsyncExportInProgress: true });
        }
        this.setState({ exporting: false });
        this.handleHideModal();
      })
      .catch(() => {
        showToast(
          ERROR,
          'Error exporting data, contact support@evisort for help.',
        );
        this.setState({ exporting: false });
        this.handleHideModal();
      });
  };

  handleCollapseExpandClick = (expanded) => {
    this.setState({ expanded }, () => {
      updatePageSearchQuery({
        [QueryParamType.Expanded]: expanded,
      });
    });
  };

  handleSortChange(selectedOption) {
    this.setState({ page: PAGE_START, sortBy: selectedOption }, () => {
      updatePageSearchQuery({
        [QueryParamType.Page]: PAGE_START,
        [QueryParamType.SortedColumn]: selectedOption.value,
      });
      this.getSearchResults();
    });
  }

  handleOnPageClick(clickedPage) {
    const { isAllSelected, selectedItems } = this.state;
    const selectedPage = clickedPage.selected + 1;

    this.setState({ page: selectedPage }, () => {
      updatePageSearchQuery(
        {
          [QueryParamType.Page]: selectedPage,
          [QueryParamType.SelectedItems]: selectedItems,
          [QueryParamType.IsAllSelected]: isAllSelected,
        },
        true,
      );
      this.getSearchResults();
    });
  }

  handleShowModal(currentModal, bulkAction = null) {
    this.setState({ currentModal, bulkAction });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleOnBackClick() {
    this.props.navigate(-1);
  }

  updateSelectItemsInUrl(selectedDocs, isAllSelected) {
    updatePageSearchQuery({
      [QueryParamType.IsAllSelected]: isAllSelected,
      [QueryParamType.SelectedItems]: selectedDocs,
    });
  }

  handleOnSelectPageClick(isSelected) {
    const { documents, selectedItems } = this.state;
    const items = isSelected
      ? getUniqueObjectsInArrayByKey([...documents, ...selectedItems], 'id')
      : [];
    const selectedDocs = transformSelectedItemsForQueryParam(items);

    this.setState(
      {
        isPageSelected: isSelected,
        isAllSelected: false,
        selectedItems: selectedDocs,
      },
      () => this.updateSelectItemsInUrl(selectedDocs, false),
    );
  }

  handleOnSelectAllClick(isAllSelected) {
    this.setState({ isAllSelected });
    updatePageSearchQuery({ [QueryParamType.IsAllSelected]: true });
  }

  handleOnDocumentSelectClick(document) {
    const { isPageSelected, documents, selectedItems } = this.state;
    let newIsPageSelected = isPageSelected;
    let newSelectedDocuments = [...selectedItems];

    if (newSelectedDocuments.filter((d) => d.id === document.id).length) {
      newSelectedDocuments = newSelectedDocuments.filter(
        (d) => d.id !== document.id,
      );
      newIsPageSelected = false;
    } else {
      const docObj = {
        document_handlers: document.document_handlers.map((item) => item.id),
        id: document.id,
        is_movable: document.is_movable,
      };
      newSelectedDocuments.push(docObj);
      if (newSelectedDocuments.length === documents.length)
        newIsPageSelected = true;
    }

    this.setState(
      {
        isPageSelected: newIsPageSelected,
        isAllSelected: false,
        selectedItems: newSelectedDocuments,
      },
      () => this.updateSelectItemsInUrl(newSelectedDocuments, false),
    );
  }

  onBulkMoveCopy(location) {
    const { bulkAction, searchQuery } = this.state;
    const [document_ids, select_all] = this.setBulkParams();

    const analyzerBulkAction =
      bulkAction === 'Move' ? analyzerBulkMove : analyzerBulkCopy;

    analyzerBulkAction(location.id, document_ids, searchQuery, select_all)
      .then((res) => showToast(SUCCESS, res.detail))
      .catch((err) =>
        showToast(ERROR, err?.response?.data?.detail || 'An error occurred.'),
      )
      .finally(() =>
        this.setState({ bulkAction: null }, this.getSearchResults),
      );
  }

  onBulkDelete(reason, extraDetails) {
    const { searchQuery } = this.state;
    const [document_ids, select_all] = this.setBulkParams();

    analyzerBulkDelete(
      document_ids,
      searchQuery,
      select_all,
      reason,
      extraDetails,
    )
      .then((res) => showToast(SUCCESS, res.detail))
      .catch((err) =>
        showToast(
          ERROR,
          err?.response?.data?.detail ||
            'An error occurred while deleting the documents.',
        ),
      )
      .finally(() =>
        this.setState({ selectedItems: [] }, this.getSearchResults),
      );
  }

  setBulkParams() {
    const { selectedItems, isAllSelected } = this.state;

    let document_ids = [];
    let select_all = false;

    if (isAllSelected) {
      select_all = true;
    } else {
      selectedItems.forEach((document) =>
        document.document_handlers.forEach((id) => document_ids.push(id)),
      );
    }

    return [document_ids, select_all];
  }

  renderBulkDeleteModalText() {
    const { selectedItems, isAllSelected, resultCount } = this.state;
    const display_count = isAllSelected ? resultCount : selectedItems.length;

    return (
      <Fragment>
        Are you sure you want to delete &nbsp;
        <span>
          {display_count} {pluralize('document', display_count)}
        </span>
        &nbsp; you have selected? You won’t be able to undo this action.
      </Fragment>
    );
  }

  renderDeleteBulkModal() {
    const {
      selectedItems,
      isAllSelected,
      resultCount,
      currentModal,
    } = this.state;
    const { currentUser } = this.props;
    const documentDeleteReasonRequired = !!currentUser?.client_config
      ?.document_delete_reason_required;
    const selectedDocumentsCount = isAllSelected
      ? resultCount
      : selectedItems.length;

    if (documentDeleteReasonRequired) {
      return (
        <DeleteDocumentWithReasonModal
          type="document"
          isVisible={currentModal === MODAL_DELETE}
          count={selectedDocumentsCount}
          hideModal={this.handleHideModal}
          onConfirmDelete={this.onBulkDelete}
        />
      );
    }

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title={`Delete ${pluralize('Document', selectedDocumentsCount)}?`}
        text={this.renderBulkDeleteModalText()}
        confirmButtonText="Delete"
        deleteItem={this.onBulkDelete}
        hideModal={this.handleHideModal}
        overLimit={selectedDocumentsCount > BULK_DELETE_LIMIT}
      />
    );
  }

  renderMultiEditBulkModal() {
    const {
      searchQuery,
      isAllSelected,
      resultCount,
      selectedItems,
      sortBy,
    } = this.state;
    const [document_ids, select_all] = this.setBulkParams();
    const selectedDocumentsCount = isAllSelected
      ? resultCount
      : selectedItems.length;

    return (
      <EcModal
        modalType={MODAL_MULTI_EDIT}
        width="800px"
        hideModal={this.handleHideModal}
        getModalData={() =>
          analyzerBulkEditData(document_ids, searchQuery, select_all)
        }
        overLimit={selectedDocumentsCount > BULK_EDIT_LIMIT}
        update={(fieldsData) =>
          analyzerBulkEdit({
            fields: fieldsData,
            query: isAllSelected ? searchQuery : undefined,
            document_handler_ids: document_ids,
            order_by: sortBy.value,
          })
        }
        onUpdateComplete={() => this.getSearchResults()}
      />
    );
  }

  renderFolderTreeBulkModal() {
    const {
      bulkAction,
      isAllSelected,
      resultCount,
      selectedItems,
    } = this.state;
    const selectedDocumentsCount = isAllSelected
      ? resultCount
      : selectedItems.length;
    const limitCountPerActionType =
      bulkAction === 'Move' ? BULK_MOVE_LIMIT : BULK_COPY_LIMIT;

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        width="540px"
        title={`${bulkAction} to Folder`}
        folderIdsSelected={[]}
        handleUploadLocationChange={this.onBulkMoveCopy}
        confirmButtonText={`${bulkAction}`}
        hideModal={this.handleHideModal}
        bulkAction={bulkAction}
        overLimit={selectedDocumentsCount > limitCountPerActionType}
      />
    );
  }

  renderAddToGroupModal() {
    const { selectedItems, searchQuery, isAllSelected } = this.state;

    return (
      <EcModal
        modalType={MODAL_DOCUMENT_GROUP}
        width="660px"
        title="Add to Group"
        documentIds={selectedItems.map((doc) => doc.id)}
        searchQuery={isAllSelected ? searchQuery : undefined}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderExportSettingModal() {
    const { resultCount, searchQuery, totalDocsIncludingDups } = this.state;

    return (
      <EcModal
        width="800px"
        modalType={MODAL_EXCEL_EXPORT}
        analyzerSearchQuery={searchQuery}
        title="Search Result Excel Export Settings"
        totalDocs={resultCount}
        totalDups={totalDocsIncludingDups}
        onExport={this.onExport}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderContentPagination() {
    const { classes } = this.props;
    const { page, resultCount } = this.state;

    if (resultCount <= PAGE_SIZE) return null;

    const resultsToShow = getMaximumByLimit(resultCount, SEARCH_RESULTS_LIMIT);

    return (
      <div className={classes.paginationWrapper}>
        <EcPaginate
          onPageChange={this.handleOnPageClick}
          pageCount={Math.ceil(resultsToShow / PAGE_SIZE)}
          forcePage={coercePageIndex(page)}
        />
      </div>
    );
  }

  initializeNavigationContext = ({ id: handlerId }) => {
    const { searchQuery, sortBy } = this.state;
    const { location } = this.props; //ensure this is provided by the React Router's <Route> because it is immutable, unlike window.location or history.location which is mutable
    // search is empty in react router because we are using window.history.replaceState
    // TODO: update react router history in updatePageSearchQuery
    const fromLocation = { ...location, search: window.location.search };

    const navigationAPI = (position) => {
      return getSearchHandlersByPosition({
        query: searchQuery,
        order_by: sortBy.value,
        position,
      });
    };

    const getPosition = (handler_id) => {
      return getSearchPositionByHandler({
        handler_id,
        order_by: sortBy.value,
        query: searchQuery,
      });
    };

    const initOptions = {
      handlerId,
      api: navigationAPI,
      getPosition,
      name: 'search results',
      parseResponse: parseNavigationResponse,
      fromLocation,
    };
    this.props.initContext(initOptions);
  };

  renderContentList() {
    const { currentUser } = this.props;
    const { documents, expanded, isAllSelected, selectedItems } = this.state;

    return documents
      ? documents.map((document) => (
          <SearchResultsItem
            user={currentUser}
            key={document.id}
            document={document}
            showExpanded={expanded}
            isSelected={
              !!selectedItems.filter((d) => d.id === document.id).length ||
              isAllSelected
            }
            onSelect={this.handleOnDocumentSelectClick}
            onNavigate={this.initializeNavigationContext}
          />
        ))
      : null;
  }

  renderSortFilter() {
    const { sortBy } = this.state;

    return (
      <EcSelect
        value={sortOptions.find((item) => item.value === sortBy.value)}
        options={sortOptions}
        onChange={(newValue) => {
          this.handleSortChange(newValue);
        }}
        width="250px"
        isSearchable={false}
        isClearable={false}
      />
    );
  }

  renderViewFilter() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.viewFilter}>
        <button
          aria-pressed={expanded}
          className={expanded ? classes.viewUnSelected : classes.viewSelected}
          onClick={
            expanded ? () => this.handleCollapseExpandClick(false) : null
          }
        >
          Collapse All
        </button>

        <span className={classes.viewFilterSeparator} />
        <button
          aria-pressed={expanded}
          className={expanded ? classes.viewSelected : classes.viewUnSelected}
          onClick={expanded ? null : () => this.handleCollapseExpandClick(true)}
        >
          Expand All
        </button>
      </div>
    );
  }

  getMovableDocuments = () => {
    const { selectedItems } = this.state;
    return selectedItems.filter((document) => document.is_movable);
  };

  renderHeaderDeleteAction() {
    const { selectedItems } = this.state;

    const movableDocuments = this.getMovableDocuments();
    const disabled = selectedItems.length !== movableDocuments.length;
    const disabledTooltip = disabled
      ? 'You do not have privileges to delete one or more of the items you have selected.'
      : undefined;

    return (
      <Button
        variant="action"
        text="Delete"
        onClick={() => this.handleShowModal(MODAL_DELETE)}
        id="analyzer-results-bulk-delete"
        aria-haspopup={true}
        disabled={disabled}
        tooltip={disabledTooltip}
      />
    );
  }

  renderHeaderEditAction() {
    const { classes } = this.props;
    const { selectedItems } = this.state;

    const movableDocuments = this.getMovableDocuments();

    return selectedItems.length === movableDocuments.length ? (
      <button
        aria-haspopup={true}
        id="analyzer-results-bulk-edit"
        className={classes.bulkActionEdit}
        onClick={() => this.handleShowModal(MODAL_MULTI_EDIT)}
      >
        <EditIcon blue size="20" />
        Edit
      </button>
    ) : (
      <Fragment>
        <div
          className={classes.bulkActionEditForbidden}
          data-tip
          data-for="helpEditTooltip"
        >
          <EditIcon blue size="20" />
          Edit
        </div>
        <EcTooltip id="helpEditTooltip" width="240px" place="left">
          You do not have privileges to edit one or more of the items you have
          selected.
        </EcTooltip>
      </Fragment>
    );
  }

  renderHeaderCopyAction() {
    const { classes } = this.props;
    const { selectedItems } = this.state;

    const movableDocuments = this.getMovableDocuments();

    return selectedItems.length === movableDocuments.length ? (
      <button
        aria-haspopup={true}
        id="analyzer-results-bulk-copy"
        className={classes.bulkActionCopy}
        onClick={() => this.handleShowModal(MODAL_FOLDER_TREE, 'Copy')}
      >
        <CopyToClipboardIcon blue size="20" />
        Copy
      </button>
    ) : (
      <Fragment>
        <div
          className={classes.bulkActionCopyForbidden}
          data-tip
          data-for="helpCopyTooltip"
        >
          <CopyToClipboardIcon blue size="20" />
          Copy
        </div>
        <EcTooltip id="helpCopyTooltip" width="240px" place="left">
          You do not have privileges to copy one or more of the items you have
          selected.
        </EcTooltip>
      </Fragment>
    );
  }

  renderAddToGroupAction() {
    const { classes } = this.props;
    const { selectedItems } = this.state;

    const onClick = () => this.handleShowModal(MODAL_DOCUMENT_GROUP);

    return renderAddToGroupAction({ classes, onClick, selectedItems });
  }

  renderHeaderMoveAction() {
    const { classes } = this.props;
    const { selectedItems } = this.state;

    const movableDocuments = this.getMovableDocuments();

    return selectedItems.length === movableDocuments.length ? (
      <button
        aria-haspopup={true}
        id="analyzer-results-bulk-move"
        className={classes.bulkActionMove}
        onClick={() => this.handleShowModal(MODAL_FOLDER_TREE, 'Move')}
      >
        <FolderIcon blue size="20" />
        Move
      </button>
    ) : (
      <Fragment>
        <div
          className={classes.bulkActionMoveForbidden}
          data-tip
          data-for="helpMoveTooltip"
        >
          <FolderIcon blue size="20" />
          Move
        </div>
        <EcTooltip id="helpMoveTooltip" width="240px" place="left">
          You do not have privileges to move one or more of the items you have
          selected.
        </EcTooltip>
      </Fragment>
    );
  }

  renderContentActions() {
    const { classes } = this.props;
    const {
      isAllSelected,
      isPageSelected,
      documents,
      selectedItems,
      resultCount,
    } = this.state;

    const selectedDocCount = isAllSelected
      ? resultCount
      : selectedItems?.length;

    return (
      <div className={classes.contentActions}>
        <div className={classes.selectContainer}>
          <div
            id="analyzer-results-select-all-checkbox"
            className={classes.checkboxContainer}
          >
            <EcCheckbox
              checked={isAllSelected || isPageSelected}
              id="analyzerResultsSelectAllCheckboxContainer"
              partiallyChecked={
                selectedItems.length > 0 &&
                selectedItems.length < documents.length
              }
              onClick={() => this.handleOnSelectPageClick(!isPageSelected)}
            />
            <label
              className="screenReaderText"
              htmlFor="analyzerResultsSelectAllCheckboxContainer"
            >
              select all {documents.length} documents
            </label>
          </div>
          <SelectDeselectAllMenu
            name="search results"
            onChange={this.handleOnSelectPageClick}
          />
          <div className={classes.documentCount}>
            {selectedDocCount.length || selectedItems.length ? (
              <Fragment>
                <Fragment>{`${selectedDocCount} ${pluralize(
                  'document',
                  selectedDocCount,
                )} selected`}</Fragment>
                {isPageSelected &&
                  !isAllSelected &&
                  resultCount > PAGE_SIZE && (
                    <button
                      className={classes.selectAllContent}
                      onClick={() => this.handleOnSelectAllClick(true)}
                    >{`Select all ${resultCount} ${pluralize(
                      'document',
                      resultCount,
                    )}`}</button>
                  )}
              </Fragment>
            ) : (
              <Fragment>{`${resultCount} ${pluralize(
                'document',
                resultCount,
              )} found`}</Fragment>
            )}
          </div>
        </div>
        {!selectedItems.length ? (
          <div className={classes.bulkActionsContainer}>
            {this.renderAddToGroupAction()}
            {this.renderHeaderMoveAction()}
            {this.renderHeaderCopyAction()}
            {this.renderHeaderEditAction()}
            {this.renderHeaderDeleteAction()}
          </div>
        ) : (
          <div className={classes.filterContainer}>
            {this.renderViewFilter()}
            {this.renderSortFilter()}
          </div>
        )}
      </div>
    );
  }

  renderSearchLimitWarning = () => {
    const { resultCount } = this.state;
    return <SearchLimitWarning resultCount={resultCount} />;
  };

  renderSearchReIndexInProgressWarning = () => {
    const { fullyIndexed, docCountIndexing } = this.state;
    return (
      <SearchReIndexInProgressWarning
        fullyIndexed={fullyIndexed}
        docCountIndexing={docCountIndexing}
      />
    );
  };

  renderExportExcelLimitWarning = () => {
    const { resultCount } = this.state;
    return <ExportExcelLimitWarning resultCount={resultCount} />;
  };

  renderEmptyContent() {
    const { classes } = this.props;
    const { errorLoading } = this.state;

    if (errorLoading) return null;

    return (
      <div className={classes.emptyResultsList}>
        <AnalyzerResultsEmptyStateIcon />
        <span className={classes.emptyResultsListTitle}>
          No documents found.
        </span>
        <span className={classes.emptyResultsListSubtitle}>
          Try expanding your search criteria.
        </span>
      </div>
    );
  }

  renderResultsContent() {
    const { classes } = this.props;
    const { loading, resultCount } = this.state;

    return loading ? (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    ) : (
      <div className={classes.content}>
        {this.renderSearchReIndexInProgressWarning()}
        {resultCount === 0 ? (
          this.renderEmptyContent()
        ) : (
          <Fragment>
            {this.renderContentActions()}
            {this.renderSearchLimitWarning()}
            {this.renderExportExcelLimitWarning()}
            {this.renderContentList()}
            {this.renderContentPagination()}
          </Fragment>
        )}
      </div>
    );
  }

  renderResultsSidebar() {
    const { classes } = this.props;
    const { searchQuery } = this.state;

    return (
      <div className={classes.sidebar}>
        <SearchResultsSidebar data={searchQuery} />
      </div>
    );
  }

  renderPageContent() {
    const { classes } = this.props;
    const { errorLoading } = this.state;

    if (errorLoading) {
      return (
        <div className={classes.errorLoadingMessage}>
          An error occurred while loading this page.
        </div>
      );
    }

    return (
      <div className={classes.contentContainer}>
        {this.renderResultsSidebar()}
        {this.renderResultsContent()}
      </div>
    );
  }

  renderLoadingState() {
    const { classes } = this.props;

    return (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  renderPageHeader() {
    const { classes, currentUser } = this.props;
    const {
      documents,
      exporting,
      hasAsyncExportInProgress,
      resultCount,
    } = this.state;

    const BackIconWithHover = withHover(BackIcon);
    const hasExportInProgress = exporting || hasAsyncExportInProgress;
    const disableExport =
      resultCount > EXPORT_EXCEL_LIMIT ||
      !documents.length ||
      hasExportInProgress;
    let defaultExportMessage =
      'This export is already processing. It may take a few minutes.';

    return (
      <div className={classes.headerContainer}>
        <button
          className={classes.headerBackAction}
          title="Go back to Analyzer page"
          onClick={this.handleOnBackClick}
        >
          <Fragment>
            <BackIconWithHover />
          </Fragment>
        </button>
        <h2 className={classes.pageName}>Search Results</h2>
        <div className={classes.headerActions}>
          <SaveSearch queryProvided={this.state.searchQuery} />
          {featureFlagIncluded(currentUser, 'EXPORT_EXCEL') && (
            // TODO EKP-2727: update ButtonGroup to support this
            <span className={classes.exportButtonGroup}>
              <span data-tip data-for="exportTooltip">
                <button
                  className={classes.exportButton}
                  onClick={this.exportFromLastSavedSetting}
                  disabled={disableExport}
                >
                  {hasExportInProgress ? 'Exporting...' : 'Export Excel'}
                </button>
              </span>
              {hasExportInProgress && (
                <EcTooltip id="exportTooltip" width="150px" place="bottom">
                  {hasAsyncExportInProgress
                    ? defaultExportMessage +
                      ' You will receive an email when the export is complete.'
                    : defaultExportMessage}
                </EcTooltip>
              )}
              <button
                aria-haspopup="true"
                disabled={disableExport}
                title="Export filtered results"
                onClick={() => this.handleShowModal(MODAL_EXCEL_EXPORT)}
                className={classes.exportSettingButton}
              >
                <FilterIcon />
              </button>
            </span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { loading, currentModal } = this.state;

    return (
      <div className="analyzerResultsPage">
        {this.renderPageHeader()}
        {loading ? this.renderLoadingState() : this.renderPageContent()}
        {currentModal === MODAL_DOCUMENT_GROUP && this.renderAddToGroupModal()}
        {currentModal === MODAL_FOLDER_TREE && this.renderFolderTreeBulkModal()}
        {currentModal === MODAL_MULTI_EDIT && this.renderMultiEditBulkModal()}
        {currentModal === MODAL_DELETE && this.renderDeleteBulkModal()}
        {currentModal === MODAL_EXCEL_EXPORT && this.renderExportSettingModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const data = state.analyzer.filters;

  return { data: data, currentUser: state.currentUser };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ analyzerResetData, initContext }, dispatch),
  };
}

Page = connect(mapStateToProps, mapDispatchToProps)(withRouting(Page));

export default injectSheet(styles)(withFlags(Page));

export const renderAddToGroupAction = ({
  classes,
  onClick,
  selectedItems = [],
}) => {
  const shouldDisable = testContainsGhostSearchResult(selectedItems);

  return shouldDisable ? (
    <Fragment>
      <div
        className={classes.bulkActionAddToGroupForbidden}
        data-tip
        data-for="helpAddToGroupTooltip"
      >
        <TreeIcon blue size="20" />
        Add to Group
      </div>
      <EcTooltip id="helpAddToGroupTooltip" width="240px" place="left">
        You do not have privileges to add one or more of the items you have
        selected to a group.
      </EcTooltip>
    </Fragment>
  ) : (
    <button
      aria-haspopup={true}
      id="analyzer-results-bulk-add-to-group"
      className={classes.bulkActionAddToGroup}
      onClick={onClick}
    >
      <TreeIcon blue size="20" />
      Add to Group
    </button>
  );
};
