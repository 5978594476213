import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, Layout, ListItems, Text } from '~/eds';
import { Filter } from '~/evifields';
import { Filters } from '~/features/advanced-search/filters';
import { attachFilterType, getDefaultFilters } from '~/features/filters/utils';
import { api, slices } from '~/redux';

import { INITIAL_FILTERS } from './constants';
import { useXraySearchFilters } from './hooks';
import { getModelTitle } from './utils';

export const CategoryScope = () => {
  const dispatch = useDispatch();

  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);
  const categoryFilters = filters.categoryFilters;

  const categoryModels = useSelector(
    slices.xRayLibrary.selectors.selectCategoryModels,
  );

  const categoryModelsArray = Array.from(
    categoryModels.entries(),
  ).map(([label, value]) => ({ label, value }));

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const searchFiltersList = useXraySearchFilters({
    searchFilters,
  });

  const parsedDefaultFilters = getDefaultFilters(
    INITIAL_FILTERS,
    searchFilters,
  );

  const allEmptyCategories = Object.keys(categoryFilters).length === 0;
  const hasEmptyCategory = categoryModelsArray.some(
    ({ label }) => categoryFilters[label]?.length === 0,
  );

  useEffect(() => {
    const updatedCategoryFilters = { ...categoryFilters };

    if ((allEmptyCategories || hasEmptyCategory) && searchFilters) {
      const filtersWithTypes = attachFilterType(
        parsedDefaultFilters,
        searchFilters,
      );

      const newCategoryFilters = categoryModelsArray.reduce(
        (acc, { label }) => ({
          ...acc,
          [label]:
            updatedCategoryFilters[label]?.length > 0
              ? updatedCategoryFilters[label]
              : filtersWithTypes ?? [],
        }),
        {},
      );

      if (allEmptyCategories) {
        dispatch(
          slices.xRayLibrary.actions.setFilters({
            ...filters,
            categoryFilters: categoryModelsArray.reduce(
              (acc, { label }) => ({
                ...acc,
                [label]: filtersWithTypes ?? [],
              }),
              {},
            ),
          }),
        );
      } else if (hasEmptyCategory) {
        dispatch(
          slices.xRayLibrary.actions.setFilters({
            ...filters,
            categoryFilters: newCategoryFilters,
          }),
        );
      }
    }
  }, [categoryFilters, searchFilters]);

  return (
    <>
      <Text variant="section">Custom Scope </Text>
      <Accordion
        isIndented
        chipPosition="left"
        border="vertical"
        items={categoryModelsArray.map((categoryModel) => {
          const { label, value: models } = categoryModel;

          return {
            key: label,
            title: getModelTitle(label, models),
            content: (
              <Layout preset="subsections">
                <Filters
                  isLoading={isFetchingFilters || searchFiltersList.isLoading}
                  searchFilters={searchFiltersList.searchFilters}
                  selectedFilters={filters.categoryFilters[label] ?? []}
                  onChange={(updatedFilters: Filter[]) => {
                    const filtersWithTypes = attachFilterType(
                      updatedFilters,
                      searchFilters,
                    );
                    dispatch(
                      slices.xRayLibrary.actions.setFilters({
                        ...filters,
                        categoryFilters: {
                          ...filters.categoryFilters,
                          [label]: filtersWithTypes,
                        },
                      }),
                    );
                  }}
                />

                <Accordion
                  border="none"
                  items={[
                    {
                      title: 'Included models',
                      content: (
                        <ListItems
                          as="ul"
                          items={models.map((model) => ({
                            title: model.label,
                            icon: 'view' as const,
                            iconColor: 'inherit' as const,
                            onClick: () => {
                              dispatch(
                                slices.xRayLibrary.actions.setModelPreview(
                                  model,
                                ),
                              );
                            },
                          }))}
                        />
                      ),
                    },
                  ]}
                />
              </Layout>
            ),
          };
        })}
      />
    </>
  );
};
