import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  EmailInput,
  INPUT_DEBOUNCE_MS,
  Layout,
  Panel_DEPRECATED,
  Select,
  testIsEmailValid,
  Text,
  TextInput,
  types,
  useDebounce,
} from '~/eds';
import { RoleType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import {
  CreateUserResponse,
  RoleAttributes,
  UserAttributes,
} from '~/redux/api/methods';
import { ParsedType } from '~/redux/api/transformers';
import { Nullable, PilotId } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { testIsSuperAdmin } from '~/utils/user';

import { default as DepartmentSelect } from '../Shared/DepartmentSelect';
import { showToast } from '../Shared/EcToast';

type Props = {
  visible: boolean;
  onHide: () => void;
  user: Nullable<ParsedType<UserAttributes>>;
  clientId?: PilotId;
  isExternalUser?: boolean;
};

export const UserFormPanel = ({
  isExternalUser,
  visible,
  onHide,
  user,
  clientId,
}: Props) => {
  const [
    getRoles,
    { isFetching: isFetchingRoles, data: roles, isLoading: isLoadingRoles },
  ] = api.endpoints.getRolesSimple.useLazyQuery();
  const currentUser = useCurrentUser();

  const isRolodexDataSourceEnabled = useFlag(FlagType.RolodexAsUserDataSource);

  const [createUser, createUserResult] = api.endpoints.createUser.useMutation();
  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate,
    data: dataCreate,
    error: errorCreate,
  } = createUserResult;
  const [updateUser, updateUserResult] = api.endpoints.updateUser.useMutation();
  const {
    isLoading: isUpdating,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
    error: errorUpdate,
    data: dataUpdate,
  } = updateUserResult;
  const [updateExternalUser] = api.endpoints.updateExternalUser.useMutation();
  const [sendInvitationEmail] = api.endpoints.sendInvitationEmail.useMutation();

  const [firstName, setFirstName] = useState<Nullable<string>>('');
  const [lastName, setLastName] = useState<Nullable<string>>('');
  const [email, setEmail] = useState<Nullable<string>>('');
  const [jobTitle, setJobTitle] = useState<Nullable<string>>('');
  const [departments, setDepartments] = useState<Nullable<number[]>>([]);
  const [role, setRole] = useState<Nullable<string>>('');
  const [emailInstructions, setEmailInstructions] = useState<Nullable<boolean>>(
    true,
  );
  const roleOptions = useMemo(
    () => roles?.results.map(roleAsSelectOption) || [],
    [roles?.results],
  );

  const groupedItems: Array<types.Option<RoleType, unknown>> = useMemo(
    () =>
      Object.values(
        roleOptions.reduce((acc: Record<RoleType, types.Option>, item) => {
          const { description, label, value } = item;
          if (!acc[description]) {
            acc[description] = {
              label: description,
              options: [],
              value: `${description}-${value}`,
            };
          }
          acc[description].options!.push({ label, value });
          return acc;
        }, {} as Record<RoleType, types.Option>),
      ) as Array<types.Option<RoleType, unknown>>,
    [roleOptions],
  );

  const reset = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setJobTitle('');
    setDepartments([]);
    setEmailInstructions(true);
    setRole(null);
  };

  const handleHide = () => {
    reset();
    onHide();
  };
  const handleSubmit = () => {
    if (user && isExternalUser) {
      updateExternalUser({
        userId: user.id,
        body: {
          roleUuid: role!,
          pilotDepartmentIds: departments || [],
        },
      })
        .unwrap()
        .then(() => handleSuccess(undefined, successMessage))
        .catch((err: Error) => showErrorMessage(err.message));
    } else if (user) {
      updateUser({
        userId: user.id,
        body: {
          first_name: firstName!,
          last_name: lastName!,
          email: email!,
          job_title: jobTitle,
          departments: departments || [],
          role_uuid: role!,
          client: Number(clientId) ?? currentUser?.client,
        },
        enableRolodex: isRolodexDataSourceEnabled,
      });
    } else {
      createUser({
        body: {
          first_name: firstName!,
          last_name: lastName!,
          email: email!,
          job_title: jobTitle,
          departments: departments || [],
          role_uuid: role!,
          client: Number(clientId) ?? currentUser?.client,
        },
        enableRolodex: isRolodexDataSourceEnabled,
      });
    }
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setJobTitle(user.jobTitle);
      setDepartments(
        (user.departments || []).map((department) => department.departmentId),
      );
      setRole(user.roleId);
    }
  }, [user]);

  const handleSearchRole = useDebounce((search: string) => {
    getRoles({
      page: 1,
      pageSize: 100,
      name: search,
      client_id: clientId,
    });
  }, INPUT_DEBOUNCE_MS);

  useEffect(() => {
    getRoles({
      page: 1,
      pageSize: 100,
      client_id: clientId,
    });
  }, []);

  const handleSuccess = (
    successData?: CreateUserResponse,
    successMessage?: string,
  ) => {
    showToast(SUCCESS, successMessage);
    const isEmailUpdated = user?.email !== email;
    if (
      (emailInstructions && !user) ||
      (user?.id && isEmailUpdated && user?.status === 'Pending')
    ) {
      sendInvitationEmail({
        userId: get(successData, 'data.id', ''),
        enableRolodex: isRolodexDataSourceEnabled,
      });
    }
    handleHide();
  };

  const successMessage = user
    ? `User ${firstName} updated successfully!`
    : `User ${firstName} created successfully!`;
  useEffect(() => {
    if (isSuccessCreate) {
      handleSuccess(dataCreate, successMessage);
    }
  }, [isSuccessCreate, dataCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      handleSuccess(dataUpdate, successMessage);
    }
  }, [isSuccessUpdate, dataUpdate]);

  const showErrorMessage = (
    error: unknown,
    message = 'Something went wrong. Please try again later.',
  ) => {
    const errorMessage = get(
      error,
      'response.data.non_field_errors[0]',
      get(error, 'response.data.email[0]', message),
    );
    showToast(ERROR, errorMessage);
  };

  useEffect(() => {
    if (isErrorCreate) {
      showErrorMessage(errorCreate);
    }
  }, [isErrorCreate, errorCreate]);

  useEffect(() => {
    if (isErrorUpdate) {
      showErrorMessage(errorUpdate);
    }
  }, [isErrorUpdate, errorUpdate]);

  const isSubmitDisabled =
    !firstName || !lastName || !testIsEmailValid(email) || !role;
  const title = user ? `Edit “${firstName} ${lastName}”` : 'New User';
  const subtitle = user
    ? 'Edit user profile and permissions.'
    : 'Create a new user in your organization.';

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      width="m"
      isVisible={visible}
      title={title}
      onHide={handleHide}
      isPadded={true}
      mode="blocking"
      isLoading={isLoadingRoles}
      footer={
        <Layout spacing="2" justify="flex-end" borderTop="border" p="3">
          <Button variant="tertiary" text="Cancel" onClick={handleHide} />
          <Button
            variant="primary"
            text="Save"
            onClick={handleSubmit}
            isLoading={isLoadingCreate || isUpdating}
            disabled={isSubmitDisabled}
          />
        </Layout>
      }
    >
      <Layout direction="column" spacing="2">
        <Text>{subtitle}</Text>

        <Text variant="section">General Information</Text>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">First name</Text>
          <TextInput
            disabled={isExternalUser}
            placeholder="First name"
            name="firstName"
            value={firstName}
            onChange={setFirstName}
          ></TextInput>
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">Last name</Text>
          <TextInput
            disabled={isExternalUser}
            placeholder="Last name"
            name="lastName"
            value={lastName}
            onChange={setLastName}
          ></TextInput>
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">Email</Text>
          <EmailInput
            disabled={isExternalUser}
            placeholder="Email"
            name="email"
            value={email}
            onChange={setEmail}
          ></EmailInput>
          {!user && (
            <Checkbox
              name="sign-in-instructions'"
              value={emailInstructions}
              onChange={setEmailInstructions}
              option={{
                label: 'Email sign-in instructions',
                value: 'sign-in-instructions',
              }}
            />
          )}
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">
            Job title{' '}
            <Text variant="tiny" color="text.secondary">
              (Optional)
            </Text>
          </Text>
          <TextInput
            disabled={isExternalUser}
            placeholder="Job title"
            name="jobTitle"
            value={jobTitle}
            onChange={setJobTitle}
          ></TextInput>
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">
            Department{' '}
            <Text variant="tiny" color="text.secondary">
              (Optional)
            </Text>
          </Text>
          <Text color="text.secondary">
            Select a department for organizing purposes only. Use groups to
            grant access to documents and workflows.
          </Text>
          <DepartmentSelect
            value={departments}
            onChange={setDepartments}
            isMulti={true}
            width="fullWidth"
            clientId={testIsSuperAdmin(currentUser) ? clientId : undefined}
          />
        </Layout>
      </Layout>

      <Layout direction="column" spacing="2" mt="6">
        <Text variant="section">Permissions</Text>

        <Layout
          direction="column"
          spacing="1"
          aria-labelledby="role-field-label"
        >
          <Text variant="body-bold" id="role-field-label">
            Role
          </Text>
          <Text color="text.secondary">
            Assign a role to the user to grant permissions in Evisort.
          </Text>
          <Select
            placeholder="Select a role"
            isLoading={isFetchingRoles}
            options={groupedItems}
            menuPlacement="top"
            name="role"
            value={role}
            onChange={setRole}
            onSearch={handleSearchRole}
            isMulti={false}
            isSearchable={true}
          />
        </Layout>
      </Layout>
    </Panel_DEPRECATED>
  );
};

const roleAsSelectOption = (role: ParsedType<RoleAttributes>) => ({
  label: role.name,
  value: role.id,
  description: role.roleType,
});
