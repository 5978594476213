import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { analyzerResetData, analyzerSetData } from '~/actions';
import { validateBooleanQuery } from '~/api/search';
import { evisortBlue } from '~/assets/shared-styles/general';
import { togglePinFilterField } from '~/components/AlertsPage/AlertsPage.data';
import {
  AnalyzerFilters,
  AnalyzerForm,
  AnalyzersQueryTable,
  deleteSavedQuery,
  getAnalyzerFiltersData,
  getAnalyzerSearchQueries,
  getSavedQueries,
  preprocessQueryData,
  renameSavedQuery,
  updateFilterValues,
} from '~/components/AnalyzerPage';
import { updateFilterPinnedProperty } from '~/components/Modals/AddFilterModal/AddFilterModal.utils';
import { validateFiltersForm } from '~/components/Modals/AlertModal/AlertModal.utils';
import {
  checkSearchQueryHasUnsupportedEntity,
  testIsSearchV3ComplexQuery,
} from '~/components/SearchV2/utils';
import { showToast } from '~/components/Shared/EcToast';
import ExpiringIcon from '~/components/Shared/Icons/ExpiringIcon';
import InboxIcon from '~/components/Shared/Icons/InboxIcon';
import { ContentContainer, Layout, PageLayout } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { convertQueryV2ToQueryV3 } from '~/features/search';
import { FlagType, withFlags } from '~/flags';
import { testHasFlag } from '~/permissions';
import {
  testIsSupportedFilterType,
  testIsSupportedSearchV3FilterType,
  toFilters,
} from '~/redux/api/methods';
import { transformFieldSections } from '~/redux/api/transformers/search';
import search from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { withRouting } from '~/routing';
import { ERROR, SUCCESS } from '~/types/toast.types';

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleSavedSearchesPageChange = this.handleSavedSearchesPageChange.bind(
      this,
    );

    this.state = {
      loadingAnalyzerFields: true,
      loadingAnalyzerFieldsError: false,
      loadingAnalyzerQueries: true,
      loadingAnalyzerQueriesError: false,
      filters: [],
      filterOptions: [],
      provisionOptions: [],
      recentQueries: [],
      savedQueries: [],
      savedQueriesCount: 0,
      booleanQuery: '',
    };
  }

  componentDidMount() {
    const { flags, navigate } = this.props;
    if (flags[FlagType.DeprecateAnalyzer]) {
      setTimeout(() => {
        navigate('/search');
      }, 250);
    }
    this.loadAnalyzerFiltersData();
    this.loadAnalyzerSearchQueries();
  }

  componentWillUnmount() {
    const { analyzerResetData, location } = this.props;

    if (
      location.pathname !== '/analyzer/results' &&
      location.pathname !== '/analyzer'
    ) {
      analyzerResetData();
    }
  }

  loadAnalyzerSearchQueries = () => {
    this.setState({
      loadingAnalyzerQueries: true,
      loadingAnalyzerQueriesError: false,
    });

    getAnalyzerSearchQueries(
      (recentQueries, savedQueries, savedQueriesCount) =>
        this.setState({
          recentQueries,
          savedQueries,
          savedQueriesCount,
          loadingAnalyzerQueries: false,
        }),
      () =>
        this.setState({
          loadingAnalyzerQueries: false,
          loadingAnalyzerQueriesError: true,
        }),
    );
  };

  loadAnalyzerFiltersData = () => {
    const { filters } = this.props;

    this.setState({
      loadingAnalyzerFields: true,
      loadingAnalyzerFieldsError: false,
    });

    getAnalyzerFiltersData(
      (alertSectionsResp, provisionOptions) => {
        // Current workaround for 'Document Id' field

        const newSections = alertSectionsResp.sections.map((sec) => {
          if (sec.title === 'Other Fields') {
            return { ...sec };
          }
          return sec;
        });

        function removeFieldValues(sections) {
          return sections.map((section) => {
            section.fields = section.fields.map((field) => {
              delete field.values;
              return field;
            });
            return section;
          });
        }

        // this is to update the filters with section data array "values" suggestions
        const allFields = newSections.reduce((array, section) => {
          return array.concat(section.fields);
        }, []);
        const newFilters = updateFilterValues(allFields, filters);

        // set filters
        this.setState({
          fieldSections: transformFieldSections(alertSectionsResp),
          filters: newFilters,
          filterOptions: removeFieldValues(newSections),
          provisionOptions: provisionOptions.suggestions,
          loadingAnalyzerFields: false,
        });
      },
      () =>
        this.setState({
          loadingAnalyzerFields: false,
          loadingAnalyzerFieldsError: true,
        }),
    );
  };

  handleSavedSearchesPageChange(page) {
    getSavedQueries(page).then((savedQueries) => {
      this.setState({
        savedQueries: savedQueries.results,
        savedQueriesCount: savedQueries.count,
      });
    });
  }

  handleSearchFilters = (search) => {
    const { analyzerSetData, navigate } = this.props;

    analyzerSetData({ filters: search.query });
    navigate('/analyzer/results');
  };

  handleUpdateFilters = (filters, scrollToLast) => {
    // set filters
    this.setState({ filters }, () => {
      if (scrollToLast)
        document
          .getElementById('analyzerFormActions')
          .scrollIntoView({ block: 'center' });
      this.handleUpdateBooleanQuery('');
    });
  };

  handleUpdateFiltersFromTableQuery = (filters, scrollToLast) => {
    const { filterOptions } = this.state;
    const allFields = filterOptions.reduce((acc, val) => {
      return acc.concat(val.fields);
    }, []);
    const newFilters = updateFilterValues(allFields, filters);

    this.handleUpdateFilters(newFilters, scrollToLast);
  };

  handleUpdateFilterOptions = (filterId) => {
    this.updateFilterOptionPinned(filterId);

    togglePinFilterField(filterId).catch(() => {
      showToast(ERROR, "There's been an error with pinning this filter.");
      this.updateFilterOptionPinned(filterId); // revert the toggle if failed
    });
  };

  handleUpdateBooleanQuery = (booleanQuery) => {
    this.setState({ booleanQuery });
  };

  updateFilterOptionPinned(filterId) {
    const { filterOptions } = this.state;

    const newFilterOptions = updateFilterPinnedProperty(
      filterId,
      filterOptions,
    );
    this.updateFilterOptions(newFilterOptions);
  }

  updateFilterOptions(filterOptions) {
    this.setState({ filterOptions });
  }

  runSearchInAdvancedSearch() {
    const { dispatch, flags } = this.props;
    const { booleanQuery, fieldSections, filters } = this.state;
    const isComplexSearch = flags[FlagType.SearchV3]
      ? testIsSearchV3ComplexQuery(filters)
      : checkSearchQueryHasUnsupportedEntity(filters);

    dispatch(search.actions.reset());
    dispatch(searchV3.actions.reset());

    if (isComplexSearch) {
      dispatch(search.actions.setUnsupportedQuery(filters));
      dispatch(
        searchV3.actions.setQueryBuilder(convertQueryV2ToQueryV3(filters)),
      );
    } else {
      const supportedFieldEntities = filters.filter(
        flags[FlagType.SearchV3]
          ? testIsSupportedSearchV3FilterType
          : testIsSupportedFilterType,
      );
      const filtersSupported = toFilters(supportedFieldEntities);

      dispatch(search.actions.setBooleanQuery(booleanQuery));
      dispatch(search.actions.setFilters(filtersSupported));

      dispatch(searchV3.actions.setSearchText(booleanQuery));
      dispatch(searchV3.actions.setSelectedFilters(filtersSupported));

      dispatch(
        search.actions.setQuery({
          booleanQuery,
          filters: filtersSupported,
          fields: fieldSections,
        }),
      );
    }
    dispatch(search.actions.setPage(1));
    dispatch(searchV3.actions.setPage(1));
    this.props.navigate('/search');
  }

  handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { analyzerSetData, currentUser, flags, navigate } = this.props;
    const { filters, booleanQuery } = this.state;
    const { filtersFormValid, validatedFilters } = validateFiltersForm(filters);

    const hasSearchV2Flag = testHasFlag(FeatureFlagType.SearchV2)(currentUser);
    const shouldRedirectToSearch =
      hasSearchV2Flag && flags[FlagType.SearchPageResultsForAnalyzer];

    validateBooleanQuery(booleanQuery)
      .then(() => {
        if (filtersFormValid) {
          if (shouldRedirectToSearch) {
            this.runSearchInAdvancedSearch();
          } else {
            const requestFilters = filters.length
              ? filters
              : [{ entity: 'bool_text_search', query: booleanQuery }];

            analyzerSetData({ filters: preprocessQueryData(requestFilters) });
            navigate('/analyzer/results');
          }
        } else {
          // set filters
          this.setState({ filters: validatedFilters });
          showToast(
            ERROR,
            'Please make sure that none of the sections or selected filters are blank or not properly set.',
          );
        }
      })
      .catch(() => {
        showToast(
          ERROR,
          'An error occurred while validating a boolean expression.',
        );
      });
  };

  handleQueryDelete = (itemToDelete) => {
    const successMessage = `"${itemToDelete.name}" search query has been successfully deleted.`;

    deleteSavedQuery(itemToDelete.id)
      .then(() => {
        this.handleSavedSearchesPageChange(1);
        showToast(SUCCESS, successMessage);
      })
      .catch(() =>
        showToast(ERROR, 'Something went wrong with query deletion.'),
      );
  };

  handleQueryRename = (itemToRename, newItemName) => {
    const { savedQueries } = this.state;
    const successMessage = `"${itemToRename.name}" search query has been successfully renamed.`;

    renameSavedQuery(itemToRename.id, newItemName)
      .then(() => {
        const newResults = savedQueries.map((item) => {
          if (item.id === itemToRename.id) {
            item.name = newItemName;
            return item;
          }
          return item;
        });

        this.setState({ savedQueries: newResults });
        showToast(SUCCESS, successMessage);
      })
      .catch(() => showToast(ERROR, 'Something went wrong with query rename.'));
  };

  render() {
    const { currentUser } = this.props;
    const {
      loadingAnalyzerFields,
      loadingAnalyzerFieldsError,
      filterOptions,
      provisionOptions,
      filters,
      recentQueries,
      savedQueries,
      savedQueriesCount,
      booleanQuery,
      loadingAnalyzerQueries,
      loadingAnalyzerQueriesError,
    } = this.state;

    const hasSearchV2Flag = testHasFlag(FeatureFlagType.SearchV2)(currentUser);

    return (
      <PageLayout title="Analyzer">
        <Layout direction="row" spacing={8}>
          <AnalyzerFilters
            loading={loadingAnalyzerFields}
            loadingError={loadingAnalyzerFieldsError}
            filters={filters}
            filterOptions={filterOptions}
            provisionOptions={provisionOptions}
            loadAnalyzerFiltersData={this.loadAnalyzerFiltersData}
            updateFilters={(filters) => this.handleUpdateFilters(filters, true)}
            updateFilterOptions={this.handleUpdateFilterOptions}
          />
          <Layout direction="column" spacing={4} w="100%">
            <AnalyzerForm
              booleanQuery={booleanQuery}
              filters={filters}
              filterOptions={filterOptions}
              provisionOptions={provisionOptions}
              updateFilters={this.handleUpdateFilters}
              updateFilterOptions={this.handleUpdateFilterOptions}
              updateBooleanQuery={this.handleUpdateBooleanQuery}
              handleSubmit={this.handleSubmit}
            />
            <Layout>
              <ContentContainer
                loadingContent={{ isLoading: loadingAnalyzerQueries }}
                placeholderContent={
                  loadingAnalyzerQueriesError
                    ? {
                        action: {
                          text: 'Try Again',
                          level: 'primary',
                          icon: 'reload',
                          onClick: this.loadAnalyzerSearchQueries,
                        },
                        title: 'Failed to load searches.',
                        message:
                          'There was an error fetching your searches. Please try again.',
                      }
                    : undefined
                }
              >
                {!hasSearchV2Flag && recentQueries.length > 0 ? (
                  <AnalyzersQueryTable
                    disableApplyBtn={!filterOptions.length}
                    sectionTitle="Recent Searches"
                    sectionSubtitle="These are some recent searches you’ve run."
                    sectionIcon={<ExpiringIcon size="32" color={evisortBlue} />}
                    nameColumnHeader="FILTERS"
                    dateColumnHeader="DATE OF SEARCH"
                    listItems={recentQueries}
                    applyFilters={(recentSearches) =>
                      this.handleUpdateFiltersFromTableQuery(
                        recentSearches,
                        true,
                      )
                    }
                    searchFilters={this.handleSearchFilters}
                  />
                ) : null}
                {!hasSearchV2Flag && (
                  <AnalyzersQueryTable
                    disableApplyBtn={!filterOptions.length}
                    sectionTitle="Saved Searches"
                    sectionSubtitle="Re–run one of your previously saved searches."
                    sectionIcon={<InboxIcon size="32" color={evisortBlue} />}
                    nameColumnHeader="NAME"
                    dateColumnHeader="DATE CREATED"
                    listItems={savedQueries}
                    count={savedQueriesCount}
                    applyFilters={(recentSearches) =>
                      this.handleUpdateFiltersFromTableQuery(
                        recentSearches,
                        true,
                      )
                    }
                    searchFilters={this.handleSearchFilters}
                    renameQuery={this.handleQueryRename}
                    deleteQuery={this.handleQueryDelete}
                    onPageChange={this.handleSavedSearchesPageChange}
                    savedSearches
                  />
                )}
              </ContentContainer>
            </Layout>
          </Layout>
        </Layout>
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ currentUser, analyzer }) => ({
  currentUser,
  filters: analyzer.filters,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ analyzerSetData, analyzerResetData }, dispatch),
  };
}
Page = connect(mapStateToProps, mapDispatchToProps)(withRouting(Page));

export default withFlags(Page);
