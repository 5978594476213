import React, { useEffect, useState } from 'react';

import { ContentContainer, Layout, Text, useToast } from '~/eds';
import { Filter } from '~/evifields';

import { useGetDocumentCount } from '../hooks';

interface Props {
  fieldId: number | null;
  filters: Filter[];
}

export const DocumentsInScope = ({ fieldId, filters }: Props) => {
  const { toast } = useToast();

  const {
    docsInScope,
    isLoadingDocsInScope,
    errorDocsInScope,
  } = useGetDocumentCount({ fieldId, filters });

  const [defaultTotal, setDefaultTotal] = useState(0);

  useEffect(() => {
    setDefaultTotal(docsInScope);
  }, [filters, docsInScope, fieldId]);

  if (errorDocsInScope) {
    toast({
      status: 'danger',
      message: 'Failed to get documents in scope',
    });
  }

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isLoadingDocsInScope,
        shimmer: { height: '2rem', width: '100%' },
      }}
    >
      <Layout preset="xs">
        <Text>Documents in Scope: </Text>
        <Text color="status.active">{defaultTotal}</Text>
      </Layout>
    </ContentContainer>
  );
};
