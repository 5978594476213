import { relationValueToOperatorId } from '~/components/SearchV2/SearchV2.utils';
import { types } from '~/eds';
import {
  DataFieldOperators,
  DataFieldType,
  QueryEntityType as SearchV2QueryEntityType,
} from '~/enums';
import { OperatorId } from '~/evifields';
import { SearchQuery } from '~/features/advanced-search';
import {
  PilotDateUnit,
  PilotSearchQuery,
  BooleanTextSearchEntity as SearchV2BooleanTextEntity,
  ClauseEntity as SearchV2ClauseEntity,
  FieldEntity as SearchV2FieldEntity,
  FolderSearchEntity as SearchV2FolderSearchEntity,
  OperatorEntity as SearchV2OperatorEntity,
  QueryEntity as SearchV2QueryEntity,
  SectionEntity as SearchV2SectionEntity,
  TextSearchEntity as SearchV2TextSearchEntity,
} from '~/redux/api/methods';
import { EntityQuery, Meta, SectionEntity } from '~/types';

import {
  testHasMixedOperators,
  testHasOrOperator,
  testIsBooleanTextSearch,
  testIsFilter as testIsSearchV3Filter,
  testIsSection as testIsSearchV3Section,
} from './queryUtils';

const testIsField = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2FieldEntity =>
  queryEntity.entity === SearchV2QueryEntityType.Field;

const testIsSection = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2SectionEntity =>
  queryEntity.entity === SearchV2QueryEntityType.Section;

const testIsClause = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2ClauseEntity =>
  queryEntity.entity === SearchV2QueryEntityType.Clause;

const testIsOperator = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2OperatorEntity =>
  queryEntity.entity === SearchV2QueryEntityType.Operator;

const testIsBoolTextSearch = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2BooleanTextEntity =>
  queryEntity.entity === SearchV2QueryEntityType.BoolTextSearch;

const testIsTextSearch = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2TextSearchEntity =>
  queryEntity.entity === SearchV2QueryEntityType.TextSearch;

const testIsFolder = (
  queryEntity: SearchV2QueryEntity,
): queryEntity is SearchV2FolderSearchEntity =>
  queryEntity.entity === SearchV2QueryEntityType.Folder;

const getValueMeta = (
  queryEntity: SearchV2FieldEntity | SearchV2ClauseEntity,
): Meta => {
  if (
    testIsField(queryEntity) &&
    queryEntity.type === DataFieldType.ARRAY_MULTIPLE
  ) {
    return {
      multi_select_data: queryEntity.multi_select_data,
    };
  }
  return {};
};

const getTextSearchOperator = (
  textSearchEntity: SearchV2TextSearchEntity,
): OperatorId => {
  const innerOperator =
    textSearchEntity.contains.value === 'contains'
      ? 'contains'
      : 'not_contains';

  return `text_${innerOperator}_${textSearchEntity.scope.value}`;
};

const convertV2DateUnitToV3 = (unit: PilotDateUnit): types.DurationUnit => {
  switch (unit) {
    case 'Day':
      return 'days';
    case 'Week':
      return 'weeks';
    case 'Month':
      return 'months';
    case 'Year':
      return 'years';
    default:
      return 'days';
  }
};

const getValueList = (fieldEntity: SearchV2FieldEntity) => {
  if (!fieldEntity.first_value) {
    return [];
  }
  if (fieldEntity.type === DataFieldType.BOOLEAN) {
    return [];
  }

  const value = Array.isArray(fieldEntity.first_value)
    ? [...fieldEntity.first_value]
    : [fieldEntity.first_value];

  if (fieldEntity.second_value) {
    let secondValue = fieldEntity.second_value;
    if (
      fieldEntity.relation?.value === DataFieldOperators.Next ||
      fieldEntity.relation?.value === DataFieldOperators.Last
    ) {
      secondValue = convertV2DateUnitToV3(secondValue);
    }
    value.push(secondValue);
  }

  return value;
};

export const convertSearchV2EntityToSeachV3Entity = (
  searchV2QueryEntity: SearchV2QueryEntity,
): EntityQuery => {
  if (testIsField(searchV2QueryEntity)) {
    return {
      id: searchV2QueryEntity.id,
      operator: relationValueToOperatorId(
        searchV2QueryEntity.relation?.value ?? '',
      ) as OperatorId,
      type: 'filter',
      value: {
        value_list: getValueList(searchV2QueryEntity),
        value_meta: getValueMeta(searchV2QueryEntity),
      },
    };
  } else if (testIsSection(searchV2QueryEntity)) {
    return {
      type: 'section',
      value: convertQueryV2ToQueryV3(
        searchV2QueryEntity.section,
        false,
      ) as SectionEntity['value'],
    };
  } else if (testIsOperator(searchV2QueryEntity)) {
    return {
      type: 'operator',
      value: searchV2QueryEntity.operator,
    };
  } else if (testIsClause(searchV2QueryEntity)) {
    return {
      type: 'filter',
      id: 'clause',
      operator:
        searchV2QueryEntity.contains.value === 'contains'
          ? 'contains_any'
          : 'not_contains_any',
      value: {
        value_list: [searchV2QueryEntity.provision],
        value_meta: getValueMeta(searchV2QueryEntity),
      },
    };
  } else if (testIsBoolTextSearch(searchV2QueryEntity)) {
    return {
      type: 'bool_text_search',
      value: searchV2QueryEntity.query,
    };
  } else if (testIsTextSearch(searchV2QueryEntity)) {
    return {
      id: searchV2QueryEntity.area?.value ?? '',
      type: 'filter',
      operator: getTextSearchOperator(searchV2QueryEntity),
      value: {
        value_list: [searchV2QueryEntity.text],
        value_meta: {},
      },
    };
  } else if (testIsFolder(searchV2QueryEntity)) {
    return {
      id: 'folder',
      type: 'filter',
      operator: 'in_folder',
      value: {
        value_list: searchV2QueryEntity.folder_ids,
        value_meta: {},
      },
    };
  }
  // unexpected type.
  return {
    id: '',
    operator: 'contains_any',
    type: 'filter',
    value: {
      value_list: [],
      value_meta: {},
    },
  };
};

const wrapFiltersInSections = (queryEntity: EntityQuery): EntityQuery => {
  if (
    testIsSearchV3Filter(queryEntity) ||
    testIsBooleanTextSearch(queryEntity)
  ) {
    return {
      type: 'section',
      value: [queryEntity],
    };
  }
  return queryEntity;
};

export const convertQueryV2ToQueryV3 = (
  searchV2Query: PilotSearchQuery,
  root = true,
): SearchQuery => {
  const searchV3Query = searchV2Query.map(convertSearchV2EntityToSeachV3Entity);
  /**
   * our current query builder component doesn't support filters outside a section. the next step
   * will find those filter (only in the root level) and wrap then in a section.
   */
  const needsWrapping =
    root &&
    (searchV3Query.some(testIsSearchV3Section) ||
      testHasMixedOperators(searchV3Query) ||
      testHasOrOperator(searchV3Query));

  return needsWrapping
    ? searchV3Query.map(wrapFiltersInSections)
    : searchV3Query;
};
