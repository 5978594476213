import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Filter } from '~/evifields';

interface Props {
  filters: Filter[];
  onChange: (updatedFilters: Filter[]) => void;
}

export const ModelLibraryFilters = ({ filters, onChange }: Props) => {
  const fields: Record<string, Field> = {
    modelState: {
      id: 'modelState',
      label: 'Model State',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: [
          { label: 'Draft', value: 'draft' },
          { label: 'Error', value: 'error' },
          { label: 'In Progress', value: 'in_progress' },
          { label: 'Published', value: 'published' },
          { label: 'Suspended', value: 'suspended' },
          { label: 'Not published', value: null },
        ],
      },
    },
  };

  return (
    /* eslint-disable-next-line react/jsx-pascal-case */
    <Filters_DEPRECATED
      fields={fields}
      filters={filters}
      onChange={onChange}
      options={{
        disableClearFilters: true,
        disableManageFilters: true,
        disableRemoveFilter: true,
      }}
    />
  );
};
