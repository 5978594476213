import { SearchQuery } from '~/features/advanced-search';
import { SavedSearch } from '~/features/search';
import { pilotV3 } from '~/services';

import { JsonApiErrorResponse, JsonApiGetResponse } from '../../ApiTypes';
import { testIsAxiosError } from '../../utils';
import { SavedSearchAttributes, SavedSearchMeta } from './types';
import { mapSavedSearchResponseToSavedSearch } from './utils';

type PatchSavedSearch = Pick<
  SavedSearch,
  | 'name'
  | 'filters'
  | 'userIds'
  | 'departmentIds'
  | 'resultTable'
  | 'visibility'
> & {
  query: SearchQuery;
};

type Response = JsonApiGetResponse<
  'saved-search',
  SavedSearchAttributes,
  void,
  SavedSearchMeta
>;
export const putSavedSearch = async ({
  id,
  savedSearch,
}: {
  id: SavedSearch['id'];
  savedSearch: Partial<PatchSavedSearch>;
}): Promise<SavedSearch> => {
  try {
    const response: Response = await pilotV3.put(`/saved-search/${id}/`, {
      data: {
        type: 'saved-search',
        attributes: {
          name: savedSearch.name,
          query: savedSearch.query,
          filters: savedSearch.filters,
          search_visibility: savedSearch.visibility,
          user_ids: savedSearch.userIds,
          department_ids: savedSearch.departmentIds,
          result_table: savedSearch.resultTable?.map((column) => ({
            column_index: column.columnIndex,
            filter_id: column.filterId,
          })),
        },
      },
    });

    return mapSavedSearchResponseToSavedSearch(response);
  } catch (error) {
    if (testIsAxiosError<JsonApiErrorResponse>(error) && error.response) {
      throw new Error(error.response.data.errors[0].title);
    }
    throw new Error('An error occured while creating a saved search.');
  }
};
