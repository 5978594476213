import { LOCAL_DOMAIN } from '~/constants/urls';
import { FeatureFlagType, UserRoleType } from '~/enums';

import { sortByStringValue } from './array';

export const featureFlagIncluded = (user, flag) =>
  Object.keys(user).length !== 0 &&
  user.feature_flags &&
  user.feature_flags.includes(flag);

/**
 * Returns the user's name
 * @param {User} user: user object
 * @returns {string} user name (firstName + lastName)
 */
export function getUserName(user) {
  // TODO: figure out exact specs for removed and unnamed user.  Or even better, never receive this scenario and we can remove the checks here.
  if (!user) {
    return 'Removed user';
  }
  const parts = [user.firstName, user.lastName].filter((part) => part);
  return parts.length > 0 ? parts.join(' ') : 'Unnamed user';
}

/**
 * Returns the user's initials
 * @param {User} user: user object
 * @returns {string} user initials (firstName[0] + lastName[0])
 */
export function getUserInitials(user = {}) {
  return user.firstName && user.lastName
    ? `${user.firstName[0]}${user.lastName[0]}`
    : 'N/A';
}

/**
 * Formats user info (firstName, lastName, email) as `firstName lastName <email>`.
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @returns {string} formatted user name and email. Empty info is ommitted. If all info is missing, returns `Unknown`.
 */
export const formatUserNameAndEmail = (firstName, lastName, email) => {
  const name = `${firstName || ''} ${lastName || ''}`.trim();
  const displayEmail = email ? `<${email}>` : '';
  return `${name} ${displayEmail}`.trim() || 'Unknown';
};

/**
 * Returns user options from users object state
 * @param {Record<import('~/types').PilotId, import('~/types').User>} users: users object state (usually from redux)
 */
export function getUserOptions(users) {
  return Object.values(users)
    .map((user) => ({
      label: getUserName(user),
      value: user.id,
    }))
    .sort(sortByStringValue('label'));
}

/**
 * Returns automatically generated password for the user
 * @returns {string} with 9 characters, containing at least one capital letter (A-Z), at least one
            number (0-9), and at least one special characters
 * */
export function createPassword() {
  const special_chars = [
    '@',
    '*',
    '_',
    '-',
    '.',
    '!',
    '&',
    "'",
    '!',
    '`',
    '#',
    '$',
    '=',
    '{',
    '}',
    '<',
    '>',
    '?',
    '~',
  ];
  const array = new Uint32Array(10);
  // msCrypto is for IE 11 support
  const crypto = window.crypto || window.msCrypto;
  let password =
    btoa(crypto.getRandomValues(array)).slice(0, 8) +
    special_chars[Math.floor(Math.random() * special_chars.length)];
  if (!(/[A-Z]/.test(password) && /\d/.test(password))) {
    password = createPassword();
  }
  return password;
}

export function getClientInfo(user) {
  return user?.client;
}

export function getClientIdWithEnvPrefix(user) {
  const siteDomain = window.location.hostname;
  const isProd = siteDomain.indexOf('clients.evisort') > -1;
  const isLocal = siteDomain.includes(LOCAL_DOMAIN);
  if (isProd || isLocal) {
    return user?.client;
  } else {
    return `${siteDomain.split('.').slice(-3)[0]}-${user?.client}`;
  }
}

export function getUserClientInfo(user) {
  return `${user.id}_${user.client}`;
}

export function getUserClientId(user) {
  return user?.client;
}

export function testIsAdmin(user) {
  return [UserRoleType.Admin].includes(user.role) || user.is_platform_admin;
}

export function testIsEverestAdmin(user) {
  return testIsAdmin(user) || user.is_workflow_admin;
}

export function testIsSuperAdmin(user) {
  return !!user.is_platform_admin;
}

export function testIsReviewer(user) {
  return [UserRoleType.Reviewer].includes(user.role);
}

export function externalUserToUser(externalUser) {
  // this will also catch the case where name and email are an empty string (e.g '')
  const name = externalUser.name || 'External User';
  const email = externalUser.email || 'Unknown Email';
  const [firstName, lastName] = name.split(' ');
  return { email, firstName, lastName, id: '' };
}

export function getClientDomainWindowLocation(user) {
  return `${window.location.origin}/${user.client_config?.sub_domain}`;
}

/**
 * Returns the users' ids
 * @param emails: array of email strings
 * @param users: dict of User objects
 * @returns array of user's ext_ids
 */
export function getUserIdsFromEmails(emails, users) {
  let usersIds = [];
  if (emails.length > 0) {
    usersIds = Object.values(users)
      .filter((user) => emails.includes(user.email))
      .map((user) => user.id);
  }
  return usersIds;
}

export const withEdsUserFields = (currentUser) => {
  return {
    ...currentUser,
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
    avatar: currentUser.avatar,
  };
};

export const getUserProfileMenuItems = (currentUser) => {
  const settingsMenuItem = {
    label: 'Settings',
    value: 'profileSettings',
  };
  const myDeletedItemsMenuItem = {
    label: 'My Deleted Items',
    value: 'myDeletedItems',
  };
  const logoutMenuItem = {
    label: 'Log Out',
    value: 'logOut',
  };

  let options = [];
  if (featureFlagIncluded(currentUser, FeatureFlagType.Settings)) {
    options.push(settingsMenuItem);
  }
  options.push(myDeletedItemsMenuItem);
  options.push(logoutMenuItem);
  return options;
};

export const isEvisortUser = (user) => {
  return !!user?.email?.endsWith('@evisort.com');
};

export const getUserNameFromFullname = (fullname) => {
  if (!fullname) {
    return { firstName: '', lastName: '' };
  }

  const parts = fullname.split(' ');

  if (parts.length > 1) {
    return { firstName: parts[0], lastName: parts[parts.length - 1] };
  }

  return { firstName: fullname, lastName: '' };
};
