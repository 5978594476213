import { debounce } from 'lodash';
import { useCallback } from 'react';

import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { FormField, Layout, TextArea } from '~/eds';
import { api } from '~/redux';
import { Nullable } from '~/types';

import { Filter, TextValue } from '../types';

interface Props {
  filter: Filter<TextValue>;
  onChange: (_updatedValues: TextValue[]) => void;
}

export const BooleanTextValue = ({ filter, onChange }: Props): JSX.Element => {
  const { values } = filter;
  const [value = ''] = values;
  const [
    validateBooleanQuery,
    { data },
  ] = api.endpoints.validateBooleanQuery.useLazyQuery();

  const debouncedValidate = useCallback(
    debounce((query) => {
      validateBooleanQuery(query);
    }, SEARCH_DEBOUNCE_MS),
    [validateBooleanQuery],
  );

  const handleChange = (updatedValue: Nullable<string>) => {
    onChange(updatedValue ? [updatedValue] : []);
    debouncedValidate(updatedValue);
  };

  return (
    <Layout w={250} maxW={250} direction="column">
      <FormField<string, false>
        label=""
        input={TextArea}
        error={data?.detail ? data.detail : undefined}
        name="boolean-text-search"
        placeholder="Boolean text search"
        value={value}
        onChange={handleChange}
      />
    </Layout>
  );
};
