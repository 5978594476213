import React from 'react';

import { Icon, types } from '~/eds';

import { FieldType } from '../../../evifields';

interface Props {
  fieldType: FieldType;
}

export const FieldIcon = ({ fieldType }: Props) => {
  const getFieldIcon = (fieldtype: FieldType): types.IconType => {
    let fieldIcon = 'field-';
    switch (fieldtype) {
      case 'age':
      case 'enum':
        fieldIcon += 'dropdown';
        break;
      case 'enum_set':
        fieldIcon += 'multiple-choice';
        break;
      case 'text':
      case 'text_search':
      case 'bool_text_search':
        fieldIcon += 'short-response';
        break;
      default:
        fieldIcon += fieldtype;
        break;
    }

    return fieldIcon as types.IconType;
  };

  return (
    <Icon
      color="currentColor"
      icon={getFieldIcon(fieldType)}
      label={`${fieldType} field`}
    />
  );
};
