import React, { useEffect, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { MAX_DASHBOARD_NAME_LENGTH } from '~/constants/max_lengths';
import { Box, Layout, TextInput, useModal, useToast } from '~/eds';
import { SearchQuery } from '~/features/advanced-search';
import { SaveActionButton } from '~/features/search';
import { useCurrentUser } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import {
  Dashboard as DashboardEntity,
  generateDashboardToSave,
} from '~/redux/api/methods/dashboardV2';
import { useRouting } from '~/routing';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

type Props = {
  dashboardData: DashboardEntity;
  query: SearchQuery;
  isDashboardDirty: boolean;
  hasSavePermission: boolean;
  onSaveSucess: () => void;
};

const SaveDashboard = ({
  dashboardData,
  query,
  hasSavePermission,
  isDashboardDirty,
  onSaveSucess,
}: Props) => {
  const { navigate } = useRouting();
  const user = useCurrentUser();
  const { toast } = useToast();
  const [
    saveAsDashboard,
    saveDashboardResults,
  ] = api.endpoints.saveAsDashboard.useMutation();
  const {
    data,
    error: savingError,
    isError: isSavingError,
    isLoading: isSaving,
    isSuccess: isSavingSuccess,
  } = saveDashboardResults;

  const [
    saveDashboardChanges,
  ] = api.endpoints.saveDashboardChanges.useMutation();

  const placeholderName = `${dashboardData.name} (1)`;
  const [name, setName] = useState<Nullable<string>>(placeholderName);

  const handleSubmit = () => {
    const dashboard = generateDashboardToSave(
      dashboardData,
      dashboardData.id,
      query,
    );
    dashboard.attributes = { ...dashboard.attributes, name: name as string };
    const { id, ...dashboardToSaveAs } = dashboard;
    trackSegment('saveAsDashboard', {
      dashboard_id: id,
      dashboard_name: name,
      user_id: user.id,
      client_id: user.client,
    });
    saveAsDashboard(dashboardToSaveAs);
  };

  const handleHide = () => {
    setName(placeholderName);
  };

  useEffect(() => {
    if (isSavingError) {
      const resp = coerceRtkqError(savingError)?.response || {};
      const msg = resp.data?.errors?.[0];
      showToast(
        ERROR,
        msg?.title || 'An error occurred while saving the dashboard.',
      );
    }
  }, [isSavingError]);

  useEffect(() => {
    if (isSavingSuccess) {
      showToast(SUCCESS, 'Dashboard has been saved successfully.');

      navigate(`/dashboards/${data?.data.id}`);
    }
  }, [isSavingSuccess]);

  const getTooltip = () => {
    if (!name?.trim()) {
      return 'Please enter a name.';
    } else if (name?.length > MAX_DASHBOARD_NAME_LENGTH) {
      return `A dashboard name can not exceed ${MAX_DASHBOARD_NAME_LENGTH} characters.`;
    } else {
      return '';
    }
  };

  const handleSave = async () => {
    const dashboard = generateDashboardToSave(
      dashboardData,
      dashboardData.id,
      query,
    );

    trackSegment('selectEditDashboard', {
      id: dashboard.id,
      name: dashboard.attributes.name,
      user_id: user.id,
      client_id: user.client,
    });
    try {
      await saveDashboardChanges({
        uuid: dashboard.id ?? '',
        data: dashboard,
      }).unwrap();
      onSaveSucess();
      toast({
        message: 'Dashboard has been saved successfully.',
        status: 'success',
      });
    } catch {
      toast({
        message: 'An error occurred while saving the dashboard.',
        status: 'danger',
      });
    }
  };

  const [modal, showModal] = useModal({
    children: (
      <Layout direction="column" h="100%" spacing={6}>
        <Box flex="auto" overflowY="auto">
          <TextInput
            autoFocus={true}
            disabled={isSaving}
            name="save as dashboard"
            placeholder="Enter a dashboard name"
            value={name}
            onChange={setName}
          />
        </Box>
      </Layout>
    ),
    primaryAction: {
      disabled:
        !name?.trim() || name?.length > MAX_DASHBOARD_NAME_LENGTH || isSaving,
      isLoading: isSaving,
      text: 'Save',
      tooltip: getTooltip(),
      onClick: handleSubmit,
    },
    title: 'Save as New Dashboard',
    width: 'm',
    onHide: handleHide,
  });

  const isOverwriteDisabled =
    dashboardData.is_default || !isDashboardDirty || !hasSavePermission;

  return (
    <>
      <SaveActionButton
        entity="Dashboard"
        saveAsNewAction={{ onClick: showModal }}
        overwriteAction={
          !dashboardData.is_default
            ? {
                onClick: handleSave,
                tooltip: hasSavePermission
                  ? ''
                  : 'You do not have privileges to save this dashboard.',
                disabled: isOverwriteDisabled,
              }
            : undefined
        }
      />
      {modal}
    </>
  );
};

export default SaveDashboard;
