import { useMemo } from 'react';

import { Actions, Box, Button, Layout, StatusText, types } from '~/eds';

import { BaseDocumentViewerProps } from './types';

interface Props
  extends Pick<
    BaseDocumentViewerProps,
    | 'activeHighlightId'
    | 'navigatorLabel'
    | 'onClear'
    | 'onHighlightClick'
    | 'highlights'
  > {}

export const HighlightNavigator = ({
  activeHighlightId,
  highlights = [],
  navigatorLabel,
  onClear,
  onHighlightClick,
}: Props) => {
  const currentIndex = useMemo(() => {
    return highlights.findIndex((h) => h.id === activeHighlightId);
  }, [activeHighlightId, highlights]);

  const total = highlights.length;
  const next = () => {
    const nextIndex = currentIndex + 1;
    const nextHighlight = highlights[nextIndex];
    if (nextHighlight) {
      onHighlightClick?.callback?.(nextHighlight);
    }
  };
  const previous = () => {
    const prevIndex = currentIndex - 1;
    const prevHighlight = highlights[prevIndex];
    if (prevHighlight) {
      onHighlightClick?.callback?.(prevHighlight);
    }
  };

  const actions: types.UserAction[] = [
    {
      icon: 'chevron-left',
      mode: 'icon',
      onClick: previous,
      text: 'Previous',
      level: `action`,
      disabled: currentIndex === 0,
    },
    {
      icon: 'chevron-right',
      mode: 'icon',
      onClick: next,
      text: 'Next',
      level: `action`,
      disabled: currentIndex === total - 1,
    },
  ];

  return (
    <Layout
      as="nav"
      borderRadius="pill"
      px={4}
      styles={[styles.container]}
      color="brand"
      align="center"
      bg="background"
      spacing={3}
    >
      <StatusText
        variant="tiny"
        iconSpacing="m"
        icon="book-sparkle"
        text={`${currentIndex + 1} of ${total}${
          navigatorLabel ? ` for ${navigatorLabel}` : ''
        }`}
      />
      <Actions actions={actions} size="xs" />
      <Box styles={styles.floatingContainer}>
        <Button
          mode="round"
          icon="x"
          size="xs"
          level="primary"
          onClick={onClear}
        />
      </Box>
    </Layout>
  );
};

const styles = {
  container: {
    border: 'border',
    borderColor: 'status.active',
    position: 'relative',
  },
  floatingContainer: { position: 'absolute', right: -10 },
};
