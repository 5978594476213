import { SearchFilter } from '~/types';

export const createBooleanTextSearchFilter = (): SearchFilter => {
  return {
    id: 'bool_text_search',
    label: 'Boolean Text Search',
    type: 'bool_text_search',
    operators: [],
    section: 'More Filters',
    is_chartable: false,
    is_requestable_column: false,
    settings: {},
  };
};
