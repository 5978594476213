import { trimStart } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { aribaPasswordAdapterChoices, getInternalFolders } from '~/api';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import {
  Checkbox,
  DateInput,
  Divider,
  FormField,
  HtmlEntityType,
  Layout,
  Select,
  Text,
  TextInput,
} from '~/eds';
import { AribaSyncType, ManageSyncPairStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { api } from '~/redux';
import { FolderInput } from '~/ui';

import { DEFAULT_FILE_TYPES } from '../../constants';
import AribaFooter from '../AribaFooter';

function AribaMappingSetup({
  // connected,
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { provider, syncPair, allowedSyncFileTypes } = activeSyncPair;
  const stageProperties = activeSyncPair[manageSyncPairStage] ?? {};
  const { evisortFolder } = stageProperties;
  const mappingData = stageProperties['fieldsMapping'] ?? [];
  const { sinceDate = null, cwCount = undefined } = stageProperties;
  const taskDiscoveryComplete = activeSyncPair.taskDiscovery?.enabled
    ? !!activeSyncPair.taskDiscovery.approverId &&
      !!activeSyncPair.taskDiscovery.passwordAdapter
    : true;

  const {
    data: initialEvisortFieldsResponse,
  } = api.endpoints.getFields.useQuery({
    limit: 3,
    search: 'Renewal',
  });

  const getCountMessage = (totalCW) => {
    if (totalCW != null && totalCW >= 0) {
      return {
        message: `We found **${totalCW}** workspaces that can be synced based on the modified date. If a contract workspace doesn't contain any eligible documents, we will skip it to avoid creating an empty folder in Evisort.`,
      };
    } else if (totalCW === null) {
      return {
        message: `We were not able to get the number of Ariba workspaces.
          You can safely continue with the configuration or try again later if you need to see the number of Ariba workspaces.`,
      };
    }
  };

  const [countMessage, setCountMessage] = useState(getCountMessage(cwCount));
  const nextButtonDisabled = !(evisortFolder && taskDiscoveryComplete);

  const setMappingData = (mapping) => {
    const updatedValues = {
      ...stageProperties,
      fieldsMapping: mapping,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };
  const processableDocumentTypes = useFlag(FlagType.ProcessableDocumentTypes);

  useEffect(() => {
    if (initialEvisortFieldsResponse && !mappingData.length) {
      setMappingData(
        initialEvisortFieldsResponse.results.map((evisortField) => ({
          evisort: evisortField.id,
          sync: AribaSyncType.OUTBOUND,
          ariba: null,
          meta: {
            evisortField,
          },
        })),
      );
    }
  }, [initialEvisortFieldsResponse]);

  const [
    getAribaCounts,
    { isFetching: isAribaCountLoading },
  ] = api.endpoints.getAribaWorkspaceCount.useLazyQuery();

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FileConfig);
  };

  const handleCreateNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FieldMapping);
  };

  const getAribaCount = async (selectedDate) => {
    selectedDate.setHours(0, 0, 0, 0);
    const updatedValues = {
      ...stageProperties,
      sinceDate: selectedDate,
    };
    let totalCW = 0;
    try {
      const response = await getAribaCounts({
        sinceDate: selectedDate,
        syncPair,
      });
      totalCW = response.data.workspaces?.totalRecords;
    } catch (e) {
      totalCW = null;
    } finally {
      setCountMessage(getCountMessage(totalCW));
      updatedValues.cwCount = totalCW;
      integrationsSetActiveSyncPair({
        ...activeSyncPair,
        [manageSyncPairStage]: updatedValues,
      });
    }
  };

  const { isLoading: isLoadingFolders, response: folders = [] } = useAsync(
    getInternalFolders,
    {},
    {
      condition: true,
      errorToastMessage:
        'There is an error fetching Evisort folders, please try refreshing the page.',
    },
  );

  const setAllowedSyncFileTypes = (types) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      allowedSyncFileTypes: types.map((type) => '.' + trimStart(type, '.')),
    });
  };

  const fileTypeOptions = allowedSyncFileTypes.map((fileExtensionType) => {
    const option = {
      value: fileExtensionType,
      label: trimStart(fileExtensionType, '.'),
    };
    if (DEFAULT_FILE_TYPES.includes(fileExtensionType)) {
      option.isDefault = true;
    }
    return option;
  });

  const addOption = (value) => {
    return value;
  };

  const setFolder = (evisortFolder) => {
    const updatedValues = {
      ...stageProperties,
      evisortFolder,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  const isUsingTaskDiscovery = () => {
    return Boolean(activeSyncPair.taskDiscovery?.enabled || false);
  };

  const setIsUsingTaskDiscovery = (value) => {
    const proto = {
      enabled: false,
      approverId: '',
      passwordAdapter: '',
    };
    const taskDiscovery = activeSyncPair.taskDiscovery ?? proto;

    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      taskDiscovery: value
        ? {
            ...taskDiscovery,
            enabled: value,
          }
        : proto,
    });
  };

  const setTaskDiscoveryApproverId = (value) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      taskDiscovery: {
        ...activeSyncPair.taskDiscovery,
        approverId: value,
      },
    });
  };

  const setTaskDiscoveryPasswordAdapter = (value) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      taskDiscovery: {
        ...activeSyncPair.taskDiscovery,
        passwordAdapter: value,
      },
    });
  };

  return (
    <Layout align="center" direction="column" spacing={8}>
      <Layout align="center" py={2} spacing={6}>
        <ProviderLogo provider="evisort" />
        <Text color="text.quiet">{HtmlEntityType.DoubleDash}</Text>
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout preset="form-fields">
        <FormField
          label="Add a timeframe to search for contract workspace"
          description="Apply the modified date."
          input={DateInput}
          inputProps={{ isLoading: isAribaCountLoading }}
          statusMessage={countMessage}
          value={sinceDate}
          onChange={getAribaCount}
        />
        <FormField
          required
          label="Select an Evisort Folder"
          description="This is the destination folder"
          input={FolderInput}
          value={evisortFolder}
          onChange={setFolder}
          isLoading={isLoadingFolders}
          inputProps={{
            modalTitle: 'Select an Evisort folder',
            folder: { ...evisortFolder },
            folderTreeProps: {
              label: 'Select...',
              folders: folders,
            },
          }}
        />
        <FormField
          required
          onChange={setAllowedSyncFileTypes}
          input={Select}
          description={
            <span>
              Selected file types will show in your Evisort folders, but only{' '}
              <strong>
                {Array.isArray(processableDocumentTypes) &&
                processableDocumentTypes.length > 0
                  ? processableDocumentTypes.join(', ') + ' '
                  : '.pdf, .docx, .doc '}
              </strong>
              will be processed by Evisort's AI.
            </span>
          }
          label="Synchronize the following file types"
          value={allowedSyncFileTypes}
          inputProps={{
            enableErrorMessage: true,
            error:
              allowedSyncFileTypes.length === 0
                ? 'You have not added any file types. Please include at least one file type'
                : null,
            isMulti: true,
            noOptionsMessage:
              'Enter an extension for a file type you want to add',
            placeholder: 'Add a file extension',
            width: 'fullWidth',
            options: fileTypeOptions,
            onAddOption: addOption,
          }}
        />
        <FormField
          label="Use Ariba Task for Faster Data Sync"
          description="This will enable faster processing of Evisort field updates into Ariba. It requires extra admin configuration in Ariba."
          input={Checkbox}
          inputProps={{
            option: {
              label: 'Use Ariba Task',
            },
            value: isUsingTaskDiscovery(),
            onChange: setIsUsingTaskDiscovery,
          }}
        />
        <FormField
          label="Ariba Task User"
          description="This is the Ariba User ID that is set on the Ariba task."
          input={TextInput}
          inputProps={{
            disabled: !isUsingTaskDiscovery(),
            value: activeSyncPair.taskDiscovery?.approverId || '',
            onChange: setTaskDiscoveryApproverId,
          }}
        />
        <FormField
          label="Ariba Task User Authentication Method"
          input={Select}
          inputProps={{
            disabled: !isUsingTaskDiscovery(),
            options: aribaPasswordAdapterChoices,
            value: activeSyncPair.taskDiscovery?.passwordAdapter || '',
            onChange: setTaskDiscoveryPasswordAdapter,
          }}
        />
      </Layout>
      <AribaFooter
        mainAction={{
          text: 'Next',
          onClick: handleCreateNext,
          isLoading: isLoadingFolders,
          disabled: nextButtonDisabled,
        }}
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(AribaMappingSetup);
