import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import * as allApis from '~/api';

import * as allTypedApis from './methods';
import { TagType } from './TagType';
import * as transformers from './transformers';
import { createQueryFn } from './utils';

export const api = createApi({
  reducerPath: 'API Service',
  baseQuery: fetchBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: Object.values(TagType),
  endpoints: (build) => ({
    // auth
    getSsoProvider: build.query({
      queryFn: createQueryFn(allTypedApis.getSsoProvider),
    }),

    // users
    getAllUsers: build.query({
      queryFn: createQueryFn(allApis.getAllUsers),
    }),
    getFieldValues: build.query({
      queryFn: createQueryFn(allApis.getFieldValues),
    }),
    getUserImportJob: build.query({
      queryFn: createQueryFn(allApis.getUserImportJobsData),
    }),
    getUsersImportStatus: build.query({
      queryFn: createQueryFn(allTypedApis.getUsersImportStatus),
    }),
    acknowledgeUserImport: build.mutation({
      queryFn: createQueryFn(allTypedApis.acknowledgeUserImport),
    }),
    getDataFieldSections: build.query({
      queryFn: createQueryFn(allApis.getDataFieldSections),
    }),
    findUsersByNameOrEmail: build.query({
      queryFn: createQueryFn(allTypedApis.findUsersByNameOrEmail),
    }),
    exportUsersExcelFile: build.query({
      queryFn: createQueryFn(allTypedApis.exportUsersExcelFile),
    }),
    downloadImportResultsExcelFile: build.query({
      queryFn: createQueryFn(allTypedApis.downloadImportResultsExcelFile),
    }),
    startImportUsersListProcessing: build.mutation({
      queryFn: createQueryFn(allTypedApis.startImportUsersListProcessing),
    }),
    importUsersListExcelFile: build.mutation({
      queryFn: createQueryFn(allTypedApis.importUsersListExcelFile),
    }),
    cancelImportJob: build.mutation({
      queryFn: createQueryFn(allTypedApis.cancelImportJob),
    }),
    getUser: build.query({
      queryFn: createQueryFn(transformers.getUser),
    }),
    getIsExternalUser: build.query({
      queryFn: createQueryFn(transformers.getIsExternalUser),
    }),
    updateExternalUser: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateExternalUser),
    }),

    //clients
    updateClientAdmin: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateClientAdmin),
    }),
    getClients: build.query({
      queryFn: createQueryFn(allTypedApis.getClients),
    }),
    copyWorkflow: build.mutation({
      queryFn: createQueryFn(allTypedApis.copyWorkflow),
    }),
    getClientFeatureFlags: build.query({
      queryFn: createQueryFn(allTypedApis.getClientFeatureFlags),
    }),

    // Clause Library
    clauseLibraryCreateClause: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryCreateClause),
    }),
    clauseLibraryListClauses: build.query({
      queryFn: createQueryFn(allTypedApis.clauseLibraryListClauses),
    }),
    clauseLibrarySearchClauses: build.query({
      queryFn: createQueryFn(allTypedApis.clauseLibrarySearchClauses),
    }),
    clauseLibraryGetClause: build.query({
      queryFn: createQueryFn(allTypedApis.clauseLibraryGetClause),
    }),
    clauseLibraryEditClause: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryEditClause),
    }),
    clauseLibraryDeleteClause: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryDeleteClause),
    }),
    clauseLibraryCreateVariation: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryCreateVariation),
    }),
    clauseLibraryEditVariation: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryEditVariation),
    }),
    clauseLibraryDeleteVariation: build.mutation({
      queryFn: createQueryFn(allTypedApis.clauseLibraryDeleteVariation),
    }),

    // Clause Library V2
    getClauseTypes: build.query({
      queryFn: createQueryFn(transformers.getClauseTypes),
    }),

    // Automation Hub
    getClientClause: build.query({
      queryFn: createQueryFn(allApis.getClientClause),
    }),
    getClauses: build.query({
      queryFn: createQueryFn(allTypedApis.getClauses),
    }),
    suspendClause: build.mutation({
      queryFn: createQueryFn(allApis.suspendClause),
    }),
    resumeClause: build.mutation({
      queryFn: createQueryFn(allApis.resumeClause),
    }),
    deleteClause: build.mutation({
      queryFn: createQueryFn(allApis.deleteClause),
    }),
    cancelTraining: build.mutation({
      queryFn: createQueryFn(allApis.cancelTraining),
    }),
    renameClause: build.mutation({
      queryFn: createQueryFn(allApis.renameClause),
    }),
    updateClause: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateClause),
    }),
    getIdentifications: build.query({
      queryFn: createQueryFn(allTypedApis.getIdentifications),
    }),
    getSamples: build.query({
      queryFn: createQueryFn(allApis.getSamples),
    }),
    createSample: build.mutation({
      queryFn: createQueryFn(allTypedApis.createSample),
    }),
    suspendSample: build.mutation({
      queryFn: createQueryFn(allApis.suspendSample),
    }),
    resumeSample: build.mutation({
      queryFn: createQueryFn(allApis.resumeSample),
    }),
    updateSample: build.mutation({
      queryFn: createQueryFn(allApis.updateSample),
    }),
    deleteSample: build.mutation({
      queryFn: createQueryFn(allApis.deleteSample),
    }),

    // SmartClause
    createTraining: build.mutation({
      queryFn: createQueryFn(allApis.createTraining),
    }),
    updateTrainingLabels: build.mutation({
      queryFn: createQueryFn(allApis.updateTrainingLabels),
    }),
    createRetraining: build.mutation({
      queryFn: createQueryFn(allApis.createRetraining),
    }),
    getTrainingStatus: build.query({
      queryFn: createQueryFn(allApis.getTrainingStatus),
    }),
    getFiltersData: build.query({
      queryFn: createQueryFn(allApis.getFiltersData),
    }),
    publishTrainedModel: build.mutation({
      queryFn: createQueryFn(allApis.publishTrainedModel),
    }),
    getPredictionStatus: build.query({
      queryFn: createQueryFn(allApis.getPredictionStatus),
    }),
    updatePredictionFilters: build.mutation({
      queryFn: createQueryFn(allApis.updatePredictionFilters),
    }),
    deleteTrainingModel: build.mutation({
      queryFn: createQueryFn(allApis.deleteTrainingModel),
    }),
    updateDescription: build.mutation({
      queryFn: createQueryFn(allApis.updateDescription),
    }),
    getFolderFilterOptions: build.query({
      queryFn: createQueryFn(allApis.getFolderFilterOptions),
    }),

    // Workflow
    getNewTicketsData: build.query({
      queryFn: createQueryFn(allApis.getNewTickets),
    }),
    getAvailableWorkflows: build.query({
      queryFn: createQueryFn(allApis.getAvailableWorkflows),
    }),
    getWorkflows: build.query({
      queryFn: createQueryFn(allTypedApis.getWorkflows),
    }),
    publishWorkflow: build.mutation({
      queryFn: createQueryFn(allTypedApis.publishWorkflow),
    }),
    unpublishWorkflow: build.mutation({
      queryFn: createQueryFn(allTypedApis.unpublishWorkflow),
    }),
    deleteWorkflow: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteWorkflow),
    }),
    getWorkflowShareSettings: build.query({
      queryFn: createQueryFn(allTypedApis.getWorkflowShareSettings),
    }),
    updateWorkflowShareSettings: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateWorkflowShareSettings),
    }),

    // ApiKey
    getApiKeys: build.query({
      queryFn: createQueryFn(allTypedApis.getApiKeys),
    }),
    createApiKey: build.mutation({
      queryFn: createQueryFn(allTypedApis.createApiKey),
    }),
    updateApiKey: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateApiKey),
    }),
    deleteApiKey: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteApiKey),
    }),

    // Client
    getClientBySubdomain: build.query({
      queryFn: createQueryFn(allApis.getClientBySubdomain),
    }),
    getClientContractTypes: build.query({
      queryFn: createQueryFn(allTypedApis.getClientContractTypes),
      keepUnusedDataFor: 60,
    }),

    // Dashboard
    getDashboards: build.query({
      queryFn: createQueryFn(allTypedApis.getDashboards),
    }),
    getDashboard: build.query({
      queryFn: createQueryFn(allTypedApis.getDashboard),
    }),

    // Departments
    getClientDepartmentsTree: build.query({
      queryFn: createQueryFn(allApis.getClientDepartmentsTree),
    }),
    getAllDepartments: build.query({
      queryFn: createQueryFn(allTypedApis.getAllDepartments),
    }),
    findDepartmentsByName: build.query({
      queryFn: createQueryFn(allTypedApis.findDepartmentsByName),
    }),
    listDepartmentMembers: build.query({
      queryFn: createQueryFn(allTypedApis.listDepartmentMembers),
    }),

    // Document Groups
    getAllDocumentGroups: build.query({
      queryFn: createQueryFn(allTypedApis.getAllDocumentGroups),
    }),
    getDocumentGroup: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentGroup),
    }),
    addDocumentsToGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.addDocumentsToGroup),
    }),
    addDocumentsToGroupByFolder: build.mutation({
      queryFn: createQueryFn(allTypedApis.addDocumentsToGroupByFolder),
    }),
    addDocumentsToGroupLinkParent: build.mutation({
      queryFn: createQueryFn(allTypedApis.addDocumentsToGroupLinkParent),
    }),
    linkDocumentsToGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.linkDocumentsToGroup),
    }),
    unlinkDocumentsFromGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.unlinkDocumentsFromGroup),
    }),
    createDocumentGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.createDocumentGroup),
    }),
    removeDocumentInGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.removeDocumentInGroup),
    }),
    editDocumentGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.editDocumentGroup),
    }),
    deleteDocumentGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteDocumentGroup),
    }),

    // Document Restore
    getDeletedDocuments: build.query({
      queryFn: createQueryFn(allTypedApis.getDeletedDocuments),
    }),
    getDeletedDocumentsForCurrentUser: build.query({
      queryFn: createQueryFn(allTypedApis.getDeletedDocumentsForCurrentUser),
    }),
    permanentlyDeleteDocuments: build.mutation({
      queryFn: createQueryFn(allTypedApis.permanentlyDeleteDocuments),
    }),
    permanentlyDeleteDocumentsForCurrentUser: build.mutation({
      queryFn: createQueryFn(
        allTypedApis.permanentlyDeleteDocumentsForCurrentUser,
      ),
    }),
    restoreDeletedDocuments: build.mutation({
      queryFn: createQueryFn(allTypedApis.restoreDeletedDocuments),
    }),
    restoreDeletedDocumentsForCurrentUser: build.mutation({
      queryFn: createQueryFn(
        allTypedApis.restoreDeletedDocumentsForCurrentUser,
      ),
    }),
    getDeletedDocumentsOriginalFolder: build.query({
      queryFn: createQueryFn(allTypedApis.getDeletedDocumentsOriginalFolder),
    }),
    getDeletedDocumentsOriginalFolderForCurrentUser: build.query({
      queryFn: createQueryFn(
        allTypedApis.getDeletedDocumentsOriginalFolderForCurrentUser,
      ),
    }),
    getDeletedDocumentsDeletedByUsers: build.query({
      queryFn: createQueryFn(allTypedApis.getDeletedDocumentsDeletedByUsers),
    }),

    // Evisync
    getExternalId: build.query({
      queryFn: createQueryFn(allApis.getExternalId),
    }),
    getEvisortFolders: build.query({
      queryFn: createQueryFn(allApis.getInternalFolders),
    }),
    createS3TokenId: build.mutation({
      queryFn: createQueryFn(allApis.createS3TokenId),
    }),
    updateSyncPair: build.mutation({
      queryFn: createQueryFn(allApis.updateSyncPair),
    }),
    updateSyncPairTokens: build.mutation({
      queryFn: createQueryFn(allApis.upddateTokensSyncPair),
    }),
    createAribaTokenId: build.mutation({
      queryFn: createQueryFn(allApis.authenticateAriba),
    }),
    createAribaMultiTokens: build.mutation({
      queryFn: createQueryFn(allApis.authenticateMultiTokensAriba),
    }),
    getSyncPairsAriba: build.query({
      queryFn: createQueryFn(allApis.getSyncPairsAriba),
    }),
    getAribaLocations: build.query({
      queryFn: createQueryFn(allApis.getAribaLocations),
    }),
    getAribaWorkspaceCount: build.query({
      queryFn: createQueryFn(allApis.getAribaWorkspaceCount),
    }),
    getAribaFields: build.query({
      queryFn: createQueryFn(allApis.getAribaFields),
    }),
    validateAribaDocumentIdFile: build.mutation({
      queryFn: createQueryFn(allApis.validateAribaDocumentIdFile),
    }),
    validateFieldMappingCsv: build.mutation({
      queryFn: createQueryFn(allApis.validateFieldMappingCsv),
    }),
    processFieldMappingCsv: build.mutation({
      queryFn: createQueryFn(allApis.processFieldMappingCsv),
    }),
    validateRealm: build.mutation({
      queryFn: createQueryFn(allApis.validateRealm),
    }),
    updateAribaFileSyncPair: build.mutation({
      queryFn: createQueryFn(allApis.updateAribaFileSyncPair),
    }),
    getSyncPairsAribaFailures: build.query({
      queryFn: createQueryFn(allApis.getSyncPairsAribaFailures),
    }),
    getSyncPairsSFDC: build.query({
      queryFn: createQueryFn(allApis.getSyncPairsSFDC),
    }),

    // search
    searchDocuments: build.query({
      queryFn: createQueryFn(allTypedApis.searchDocuments),
    }),
    pilotSearchDocuments: build.query({
      queryFn: createQueryFn(allTypedApis.pilotSearchDocuments),
    }),
    exportExcel: build.query({
      queryFn: createQueryFn(allTypedApis.exportSearchResults),
    }),
    validateBooleanQuery: build.query({
      queryFn: createQueryFn(allTypedApis.validateBooleanQuery),
    }),
    getFilterSections: build.query({
      queryFn: createQueryFn(transformers.getFilterSections),
      keepUnusedDataFor: 60,
    }),
    getClauseSuggestions: build.query({
      queryFn: createQueryFn(allTypedApis.getClauseSuggestions),
    }),
    getFolderTree: build.query({
      queryFn: createQueryFn(allTypedApis.getFolderTree),
    }),
    getFolders: build.query({
      queryFn: createQueryFn(allTypedApis.getFolders),
    }),
    saveSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.saveSearch),
    }),
    getSavedSearches: build.query({
      queryFn: createQueryFn(allTypedApis.getSavedSearches),
    }),
    deleteSavedSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteSavedSearch),
    }),
    updateSavedSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateSavedSearch),
    }),
    checkDownloadExportReportById: build.query({
      queryFn: createQueryFn(allTypedApis.checkDownloadExportReportById),
    }),
    getQuickSearchRecords: build.query({
      queryFn: createQueryFn(allTypedApis.getQuickSearchRecords),
    }),
    quickSearchDocuments: build.mutation({
      queryFn: createQueryFn(allTypedApis.quickSearchDocuments),
    }),
    updatePinnedField: build.mutation({
      queryFn: createQueryFn(allTypedApis.updatePinnedField),
    }),

    // table views
    getCurrentTableView: build.query({
      queryFn: createQueryFn(allTypedApis.getCurrentTableView),
    }),
    getTableViews: build.query({
      queryFn: createQueryFn(allTypedApis.getTableViews),
    }),
    createTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.createTableView),
    }),
    updateTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateTableView),
    }),
    loadTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.loadTableView),
    }),
    loadDefaultTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.loadDefaultTableView),
    }),
    getDefaultTableView: build.query({
      queryFn: createQueryFn(allTypedApis.getDefaultTableView),
    }),
    renameTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.renameTableView),
    }),
    deleteTableView: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteTableView),
    }),

    // documentMetadata
    getDocumentMetadataImportJob: build.query({
      queryFn: createQueryFn(allApis.getDocumentMetadataImportJobsData),
    }),

    // provisions
    getProvisions: build.query({
      queryFn: createQueryFn(allTypedApis.getProvisions),
    }),
    getMigratedProvisions: build.query({
      queryFn: createQueryFn(allTypedApis.getMigratedProvisions),
    }),
    getAllProvisions: build.query({
      queryFn: createQueryFn(allTypedApis.getAllProvisions),
    }),
    getAllProvisionsGrouped: build.query({
      queryFn: createQueryFn(transformers.getAllProvisionsGrouped),
    }),
    updateProvisionContent: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateProvisionContent),
    }),
    deleteProvision: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteProvision),
    }),

    // comments
    getComments: build.query({
      queryFn: createQueryFn(allTypedApis.comments.getComments),
    }),
    createComment: build.mutation({
      queryFn: createQueryFn(allTypedApis.comments.createComment),
    }),
    updateComment: build.mutation({
      queryFn: createQueryFn(allTypedApis.comments.updateComment),
    }),
    deleteComment: build.mutation({
      queryFn: createQueryFn(allTypedApis.comments.deleteComment),
    }),
    resolveComment: build.mutation({
      queryFn: createQueryFn(allTypedApis.comments.resolveComment),
    }),
    unresolveComment: build.mutation({
      queryFn: createQueryFn(allTypedApis.comments.unresolveComment),
    }),
    getCommentImportStatus: build.query({
      queryFn: createQueryFn(allTypedApis.comments.getCommentImportStatus),
    }),

    //highlights
    getHighlights: build.query({
      queryFn: createQueryFn(allTypedApis.getHighlights),
    }),
    createHighlight: build.mutation({
      queryFn: createQueryFn(allTypedApis.postHighlight),
    }),

    // permission
    getEntityPermissions: build.query({
      queryFn: createQueryFn(allTypedApis.acl.getEntityPermissions),
    }),

    // navigation
    getSearchPositionByHandler: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchPositionByHandler),
    }),
    getSearchHandlersByPosition: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchHandlersByPosition),
    }),

    //Departments
    getDepartmentChildren: build.query({
      queryFn: createQueryFn(allTypedApis.getDepartmentChildren),
    }),

    // ticket document version
    downloadTicketDocumentVersionEmailAttachment: build.query({
      queryFn: createQueryFn(
        allTypedApis.downloadTicketDocumentVersionEmailAttachment,
      ),
    }),
    getTicketDocumentVersionEditorKey: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketDocumentVersionEditorKey),
    }),

    // User Preferences
    getPinnedFilters: build.query({
      queryFn: createQueryFn(allTypedApis.getPinnedFilters),
    }),
    updatePinnedFilters: build.mutation({
      queryFn: createQueryFn(allTypedApis.updatePinnedFilters),
    }),

    getRecentSearches: build.query({
      queryFn: createQueryFn(allTypedApis.getRecentSearches),
    }),
    updateRecentSearches: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateRecentSearches),
    }),

    // Ticket Reviewer
    getDocumentDetailClauses: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentDetailClauses),
    }),

    //Document
    getDocument: build.query({
      queryFn: createQueryFn(allTypedApis.getDocument),
    }),
    getDocumentHandler: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentHandler),
    }),

    //documents
    getPdfDocumentOcr: build.query({
      queryFn: createQueryFn(allTypedApis.downloadOcrPdf),
    }),
    convertPdfToDocx: build.mutation({
      queryFn: createQueryFn(allTypedApis.convertPdfToDocx),
    }),
    getDocumentByTag: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentByTag),
    }),
    getDocumentFromVersion: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentFromVersion),
      keepUnusedDataFor: 60,
    }),
    getDocumentOriginal: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentOriginal),
    }),
    renameDocument: build.mutation({
      queryFn: createQueryFn(allTypedApis.renameDocumentHandler),
    }),
    deleteDocument: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteDocumentHandler),
    }),
    convertContentToSfdt: build.mutation({
      queryFn: createQueryFn(allTypedApis.convertContentToSfdt),
    }),
    getDocumentInformation: build.query({
      queryFn: createQueryFn(transformers.getDocumentInformation),
    }),
    getDocumentSfdt: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentSfdt),
    }),
    compareDocumentsSfdt: build.query({
      queryFn: createQueryFn(allTypedApis.compareDocumentsSfdt),
    }),
    saveDocumentSfdt: build.mutation({
      queryFn: createQueryFn(allTypedApis.saveDocumentSfdt),
    }),
    backupDocumentSfdt: build.mutation({
      queryFn: createQueryFn(allTypedApis.backupDocumentSfdt),
    }),
    tokenizeSfdtContent: build.mutation({
      queryFn: createQueryFn(allTypedApis.tokenizeSfdtContent),
    }),
    tokenizeRiskContent: build.query({
      queryFn: createQueryFn(allTypedApis.tokenizeRiskContent),
    }),
    getDocumentContent: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentContent),
    }),
    getDocumentAlgoStatus: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentAlgoStatus),
    }),
    getWorkflowFile: build.query({
      queryFn: createQueryFn(allApis.getWorkflowFile),
    }),
    searchV2BulkDelete: build.mutation({
      queryFn: createQueryFn(allTypedApis.searchV2BulkDelete),
    }),
    searchV2BulkCopy: build.mutation({
      queryFn: createQueryFn(allTypedApis.searchV2BulkCopy),
    }),
    searchV2BulkMove: build.mutation({
      queryFn: createQueryFn(allTypedApis.searchV2BulkMove),
    }),
    searchV2BulkEditClause: build.mutation({
      queryFn: createQueryFn(allTypedApis.searchV2BulkEditClause),
    }),
    searchV2BulkEditData: build.query({
      queryFn: createQueryFn(allTypedApis.searchV2BulkEditData),
    }),
    searchV2BulkAddReplaceFields: build.mutation({
      queryFn: createQueryFn(allTypedApis.documentBulkAddReplaceFields),
    }),
    searchV2BulkEditFields: build.mutation({
      queryFn: createQueryFn(allTypedApis.documentBulkEditFields),
    }),
    //dashboardV2
    createDefaultDashboards: build.query({
      queryFn: createQueryFn(allTypedApis.createDefaultDashboards),
    }),
    getDashboardV2: build.query({
      queryFn: createQueryFn(transformers.getDashboardV2),
    }),
    getDashboardsV2: build.query({
      queryFn: createQueryFn(transformers.getDashboardsV2),
    }),
    saveDashboardChanges: build.mutation({
      queryFn: createQueryFn(allTypedApis.saveDashboardChanges),
    }),
    saveAsDashboard: build.mutation({
      queryFn: createQueryFn(allTypedApis.saveAsDashboard),
    }),
    updateDashboard: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateDashboard),
    }),
    deleteDashboard: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteDashboard),
    }),

    // fields
    createField: build.mutation({
      queryFn: createQueryFn(allTypedApis.createField),
    }),
    getFields: build.query({
      queryFn: createQueryFn(allTypedApis.getFields),
      keepUnusedDataFor: 60,
    }),
    getFieldSections: build.query({
      queryFn: createQueryFn(allTypedApis.getFieldSections),
      keepUnusedDataFor: 60,
    }),

    //search v3
    getChartData: build.query({
      queryFn: createQueryFn(allTypedApis.getChartData),
    }),
    getFilters: build.query({
      queryFn: createQueryFn(allTypedApis.getFilters),
      keepUnusedDataFor: 60,
    }),
    getFilterSuggestions: build.query({
      queryFn: createQueryFn(allTypedApis.getFilterSuggestions),
    }),
    searchV3Documents: build.query({
      queryFn: createQueryFn(allTypedApis.searchV3Documents),
    }),
    getSearchExportOptions: build.query({
      queryFn: createQueryFn(transformers.getSearchExportOptions),
    }),
    exportExcelV2: build.mutation({
      queryFn: createQueryFn(allTypedApis.exportExcelV2),
    }),
    getSearchV3SavedSearches: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchV3SavedSearches),
    }),
    deleteSearchV3SavedSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteSearchV3SavedSearch),
    }),
    postSavedSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.postSavedSearch),
    }),
    getSavedSearch: build.query({
      queryFn: createQueryFn(allTypedApis.getSavedSearch),
    }),
    putSavedSearch: build.mutation({
      queryFn: createQueryFn(allTypedApis.putSavedSearch),
    }),
    getSearchV3PositionByHandler: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchV3PositionByHandler),
    }),
    getSearchV3HandlersByPosition: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchV3HandlersByPosition),
    }),

    //roles
    getRoles: build.query({
      queryFn: createQueryFn(transformers.getRoles),
    }),
    getRolesSimple: build.query({
      queryFn: createQueryFn(transformers.getRolesSimple),
    }),
    createRole: build.mutation({
      queryFn: createQueryFn(allTypedApis.createRole),
    }),
    updateRole: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateRole),
    }),
    validateRoleName: build.query({
      queryFn: createQueryFn(allTypedApis.validateRoleName),
    }),
    deleteRole: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteRole),
    }),
    getPermissions: build.query({
      queryFn: createQueryFn(transformers.getPermissions),
    }),
    getUsers: build.query({
      queryFn: createQueryFn(transformers.getUsers),
    }),
    createUser: build.mutation({
      queryFn: createQueryFn(allTypedApis.createUser),
    }),
    sendInvitationEmail: build.mutation({
      queryFn: createQueryFn(allTypedApis.sendInvitationEmail),
    }),
    updateUser: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateUser),
    }),
    deactivateUser: build.mutation({
      queryFn: createQueryFn(allTypedApis.deactivateUser),
    }),
    reactivateUser: build.mutation({
      queryFn: createQueryFn(allTypedApis.reactivateUser),
    }),
    cancelUserInvite: build.mutation({
      queryFn: createQueryFn(allTypedApis.cancelUserInvite),
    }),
    resendReactivatedUserInvite: build.mutation({
      queryFn: createQueryFn(allTypedApis.resendReactivatedUserInvite),
    }),
    checkPermission: build.query({
      queryFn: createQueryFn(allTypedApis.checkPermission),
    }),

    //ticket
    cancelTicket: build.mutation({
      queryFn: createQueryFn(allTypedApis.cancelTicket),
    }),
    getTicketDocument: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketDocument),
    }),
    getTicketCurrentStage: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketCurrentStage),
    }),
    getTicketIntakeForm: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketIntakeForm),
    }),
    getTicketSummary: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketSummary),
    }),
    getActivities: build.query({
      queryFn: createQueryFn(allTypedApis.getActivities),
    }),
    createActivityLogEvent: build.mutation({
      queryFn: createQueryFn(allTypedApis.createActivityLog),
    }),
    updateRecipients: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateRecipients),
    }),
    deleteRecipients: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteRecipients),
    }),
    bumpVersion: build.mutation({
      queryFn: createQueryFn(allTypedApis.bumpVersion),
    }),
    addTicketParticipants: build.mutation({
      queryFn: createQueryFn(allTypedApis.addTicketParticipants),
    }),
    getTicketParticipants: build.query({
      queryFn: createQueryFn(allTypedApis.getTicketParticipants),
    }),
    collectSignatures: build.query({
      queryFn: createQueryFn(allTypedApis.collectSignatures),
    }),
    updateEnvelope: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateEnvelope),
    }),
    reassignApproval: build.mutation({
      queryFn: createQueryFn(allApis.reassignApproval),
    }),

    // Turn Tracking
    getTurnTrackingHistory: build.query({
      queryFn: createQueryFn(allTypedApis.getTurnTrackingHistory),
    }),
    getTurnTrackingParties: build.query({
      queryFn: createQueryFn(allTypedApis.getTurnTrackingParties),
    }),
    updateTurnTrackingEntry: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateTurnTrackingEntry),
    }),
    createTurnTrackingEntry: build.mutation({
      queryFn: createQueryFn(allTypedApis.createTurnTrackingEntry),
    }),

    // Conversational AI
    acceptLegalDisclaimer: build.query({
      queryFn: createQueryFn(allTypedApis.acceptLegalDisclaimer),
    }),
    getConversations: build.query({
      queryFn: createQueryFn(transformers.getConversations),
    }),
    createConversation: build.mutation({
      queryFn: createQueryFn(transformers.createConversation),
    }),
    getConversation: build.query({
      queryFn: createQueryFn(transformers.getConversation),
    }),
    postUserMessage: build.mutation({
      queryFn: createQueryFn(transformers.postUserMessage),
    }),
    postUserFeedback: build.mutation({
      queryFn: createQueryFn(transformers.postUserFeedback),
    }),
    interruptAiMessage: build.mutation({
      queryFn: createQueryFn(allTypedApis.interruptAiMessage),
    }),

    // sylvanus
    checkPromptModelConfig: build.mutation({
      queryFn: createQueryFn(allTypedApis.checkPromptModelConfig),
    }),
    createPromptModelTestCase: build.mutation({
      queryFn: createQueryFn(allTypedApis.createPromptModelTestCase),
    }),
    createPromptModelVersion: build.mutation({
      queryFn: createQueryFn(allTypedApis.createPromptModelVersion),
    }),
    deletePromptModel: build.mutation({
      queryFn: createQueryFn(allTypedApis.deletePromptModel),
      invalidatesTags: () => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelSummary,
          id: 'LIST',
        },
      ],
    }),
    executePromptModelTestCases: build.mutation({
      queryFn: createQueryFn(allTypedApis.executePromptModelTestCases),
    }),
    getPromptModelProgress: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelProgress),
      providesTags: (_result, _error, arg) => [
        {
          type: TagType.PromptModelProgress,
          id: arg.modelId,
        },
      ],
    }),
    getPromptModelOptimizeResults: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelOptimizeResults),
    }),
    getPromptModelOptimizeTestCases: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelOptimizeTestCases),
    }),
    optimizePromptModel: build.mutation({
      queryFn: createQueryFn(allTypedApis.optimizePromptModel),
      invalidatesTags: () => [
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
      ],
    }),
    getPromptModelOptimizeSummary: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelOptimizeSummary),
    }),
    publishPromptModel: build.mutation({
      queryFn: createQueryFn(allTypedApis.publishPromptModel),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelProgress,
          id: arg.modelId,
        },
        {
          type: TagType.PromptModelSummary,
          id: 'LIST',
        },
      ],
    }),
    suggestPromptModelInstructions: build.query({
      queryFn: createQueryFn(allTypedApis.suggestPromptModelInstructions),
    }),
    suspendPromptModel: build.mutation({
      queryFn: createQueryFn(allTypedApis.suspendPromptModel),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelProgress,
          id: arg.modelId,
        },
        {
          type: TagType.PromptModelSummary,
          id: 'LIST',
        },
      ],
    }),
    tagAnswerForPromptModelTestCase: build.mutation({
      queryFn: createQueryFn(allTypedApis.tagAnswerForPromptModelTestCase),
    }),
    updatePromptModelProgress: build.mutation({
      queryFn: createQueryFn(allTypedApis.updatePromptModelProgress),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: TagType.PromptModelProgress,
          id: arg.modelId,
        },
      ],
    }),
    updatePromptModelTestCase: build.mutation({
      queryFn: createQueryFn(allTypedApis.updatePromptModelTestCase),
    }),

    getModelSummary: build.query({
      queryFn: createQueryFn(allTypedApis.getModelSummary),
      providesTags: () => [
        {
          type: TagType.PromptModelSummary,
          id: 'LIST',
        },
      ],
    }),

    getModelsQuotaSummary: build.query({
      queryFn: createQueryFn(allTypedApis.getModelsQuotaSummary),
    }),

    // Document X-Ray
    getDocsInScope: build.query({
      queryFn: createQueryFn(allTypedApis.getDocsInScope),
    }),
    getPromptModel: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModel),
      providesTags: (_result, _error, arg) => [
        {
          type: TagType.PromptModel,
          id: arg.modelId,
        },
      ],
    }),
    DEPRECATED_getPromptModels: build.query({
      queryFn: createQueryFn(allTypedApis.DEPRECATED_getPromptModels),
      providesTags: () => [
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
      ],
    }),
    getPromptModelResults: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelResults),
      providesTags: () => [
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
      ],
    }),
    getPromptModelTestCase: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelTestCase),
    }),
    getPromptModelTestCases: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelTestCases),
    }),
    getPromptModelVersion: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelVersion),
      keepUnusedDataFor: 600,
    }),
    DEPRECATED_getPromptModelVersion: build.query({
      queryFn: createQueryFn(allTypedApis.DEPRECATED_getPromptModelVersion),
    }),
    getPromptModelFieldIds: build.query({
      queryFn: createQueryFn(allTypedApis.getPromptModelFieldIds),
    }),
    resolvePromptModelTargetEntityDetails: build.query({
      queryFn: createQueryFn(
        allTypedApis.resolvePromptModelTargetEntityDetails,
      ),
    }),
    savePromptModelDraft: build.mutation({
      queryFn: createQueryFn(allTypedApis.savePromptModelDraft),
      invalidatesTags: (_result, _error, arg) =>
        arg.modelId // TODO: API method should be atomic instead of having nullable modelId
          ? [
              {
                type: TagType.PromptModel,
                id: arg.modelId,
              },
            ]
          : [],
    }),

    // Document X-Ray Prebuilt Models Library
    getLibraryModel: build.query({
      queryFn: createQueryFn(allTypedApis.getLibraryModel),
      keepUnusedDataFor: 600,
      providesTags: (_result, _error, arg) => [
        {
          type: TagType.LibraryModel,
          id: arg.libraryModelId,
        },
      ],
    }),
    getLibraryModels: build.query({
      queryFn: createQueryFn(allTypedApis.getLibraryModels),
      keepUnusedDataFor: 600,
      providesTags: () => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
      ],
    }),
    getLibraryModelsPublishStatus: build.query({
      queryFn: createQueryFn(allTypedApis.getLibraryModelsPublishStatus),
      keepUnusedDataFor: 0, // disable cache to ensure fresh status
    }),
    publishLibraryModels: build.mutation({
      queryFn: createQueryFn(allTypedApis.publishLibraryModels),
      invalidatesTags: () => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
        {
          type: TagType.PromptModelResult,
          id: 'LIST',
        },
      ],
    }),

    // Document X-Ray Prebuilt Models Admin
    saveLibraryModel: build.mutation({
      queryFn: createQueryFn(allTypedApis.saveLibraryModel),
      invalidatesTags: (_result, _error, arg) => [
        {
          type: TagType.LibraryModels,
          id: 'LIST',
        },
        {
          type: TagType.LibraryModel,
          id: arg.libraryModelId,
        },
      ],
    }),

    // Gen AI
    getGenClauseResult: build.query({
      queryFn: createQueryFn(allTypedApis.getGenClauseResult),
    }),
    getGenClauseResults: build.query({
      queryFn: createQueryFn(allTypedApis.getGenClauseResults),
    }),

    // resolvers
    resolveDocument: build.query({
      queryFn: createQueryFn(allTypedApis.resolveDocument),
      keepUnusedDataFor: 600,
    }),
    resolveUsers: build.query({
      queryFn: createQueryFn(allTypedApis.resolveUsers),
    }),
    resolveUser: build.query({
      queryFn: createQueryFn(allTypedApis.resolveUser),
    }),
    resolveCitation: build.query({
      queryFn: createQueryFn(allTypedApis.resolveCitation),
      keepUnusedDataFor: 30,
    }),

    //evisearch
    search: build.query({
      queryFn: createQueryFn(allTypedApis.search),
    }),
    getFiltersV2: build.query({
      queryFn: createQueryFn(transformers.getFiltersV2),
    }),
    getFilterSuggestionsV2: build.query({
      queryFn: createQueryFn(allTypedApis.getFilterSuggestionsV2),
    }),
    getChartDataV2: build.query({
      queryFn: createQueryFn(allTypedApis.getChartDataV2),
    }),
    getSearchExportOptionsV2: build.query({
      queryFn: createQueryFn(allTypedApis.getSearchExportOptionsV2),
    }),
    exportExcelV3: build.mutation({
      queryFn: createQueryFn(allTypedApis.exportExcelV3),
    }),
    validateBooleanQueryEvisearch: build.query({
      queryFn: createQueryFn(allTypedApis.validateBooleanQueryEvisearch),
    }),
    //post sig document
    getDocumentsViewsetFields: build.query({
      queryFn: createQueryFn(allTypedApis.getDocumentsViewsetFields),
    }),
    getFolderData: build.query({
      queryFn: createQueryFn(allTypedApis.getFolderData),
    }),

    // upload
    getUploadFiles: build.query({
      queryFn: createQueryFn(allTypedApis.getUploadFiles),
      providesTags: () => [
        {
          type: TagType.UploadFiles,
          id: 'LIST',
        },
      ],
    }),
    addUploadFiles: build.mutation({
      queryFn: createQueryFn(allTypedApis.addUploadFiles),
      invalidatesTags: () => [
        {
          type: TagType.UploadFiles,
          id: 'LIST',
        },
      ],
    }),
    getFolderVisibility: build.query({
      queryFn: createQueryFn(allTypedApis.getFolderVisibility),
    }),

    // process documents
    getAlgorithmMetrics: build.query({
      queryFn: createQueryFn(allTypedApis.getAlgorithmMetrics),
    }),
    reprocessAlgorithms: build.mutation({
      queryFn: createQueryFn(allTypedApis.reprocessAlgorithms),
    }),
    stopReprocessAlgorithms: build.mutation({
      queryFn: createQueryFn(allTypedApis.stopReprocessAlgorithms),
    }),

    // Manage Party Lists
    getParties: build.query({
      queryFn: createQueryFn(allTypedApis.getParties),
    }),
    updateParty: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateParty),
    }),
    deleteParty: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteParty),
    }),
    addParty: build.mutation({
      queryFn: createQueryFn(allTypedApis.addParty),
    }),
    uploadParty: build.mutation({
      queryFn: createQueryFn(allTypedApis.uploadParty),
    }),
    deleteParties: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteParties),
    }),
    // Super Admin
    createSuperAdmin: build.mutation({
      queryFn: createQueryFn(allTypedApis.createSuperAdmin),
    }),
    deactivateSuperAdmin: build.mutation({
      queryFn: createQueryFn(allTypedApis.deactivateSuperAdmin),
    }),

    // Wopi
    getWopiSetup: build.query({
      queryFn: createQueryFn(allTypedApis.getWopiSetup),
    }),
    getWopiStaticFile: build.query({
      queryFn: createQueryFn(allTypedApis.getWopiStaticFiles),
    }),

    // CodeCash
    createOneTimeCreditBalance: build.mutation({
      queryFn: createQueryFn(allTypedApis.createOneTimeCreditBalance),
    }),
    editOneTimeCreditBalance: build.mutation({
      queryFn: createQueryFn(allTypedApis.editOneTimeCreditBalance),
    }),
    getCreditSummary: build.query({
      queryFn: createQueryFn(allTypedApis.getCreditSummary),
    }),
    getCreditEstimation: build.query({
      queryFn: createQueryFn(allTypedApis.getCreditEstimation),
    }),
    getCreditLedger: build.query({
      queryFn: createQueryFn(allTypedApis.getCreditLedger),
    }),
    updateRecurringCredits: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateRecurringCredits),
    }),

    // Audit Logs
    getAuditLogs: build.query({
      queryFn: createQueryFn(allTypedApis.getAuditLogs),
    }),

    // Question & Question groups
    getQuestion: build.query({
      queryFn: createQueryFn(allTypedApis.getQuestion),
    }),
    getQuestions: build.query({
      queryFn: createQueryFn(allTypedApis.getQuestions),
    }),
    createQuestion: build.mutation({
      queryFn: createQueryFn(allTypedApis.createQuestion),
    }),
    updateQuestion: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateQuestion),
    }),
    deleteQuestion: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteQuestion),
    }),
    resolveQuestions: build.query({
      queryFn: createQueryFn(allTypedApis.resolveQuestions),
    }),

    getQuestionGroups: build.query({
      queryFn: createQueryFn(allTypedApis.getQuestionGroups),
    }),
    createQuestionGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.createQuestionGroup),
    }),
    updateQuestionGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateQuestionGroup),
    }),
    getQuestionGroupQuestions: build.query({
      queryFn: createQueryFn(allTypedApis.getQuestionGroupQuestions),
    }),
    resolveQuestionGroups: build.query({
      queryFn: createQueryFn(allTypedApis.resolveQuestionGroups),
    }),
    deleteQuestionGroup: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteQuestionGroup),
    }),

    // External
    getExternalDocument: build.query({
      queryFn: createQueryFn(allTypedApis.getExternalDocument),
    }),
    // Webhooks
    getEndpoints: build.query({
      queryFn: createQueryFn(allTypedApis.getEndpoints),
      providesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    createEndpoint: build.mutation({
      queryFn: createQueryFn(allTypedApis.createEndpoint),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    deleteEndpoint: build.mutation({
      queryFn: createQueryFn(allTypedApis.deleteEndpoint),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    suspendEndpoint: build.mutation({
      queryFn: createQueryFn(allTypedApis.suspendEndpoint),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    activateEndpoint: build.mutation({
      queryFn: createQueryFn(allTypedApis.activateEndpoint),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    updateEndpoint: build.mutation({
      queryFn: createQueryFn(allTypedApis.updateEndpoint),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),

    rotateEndpointKey: build.mutation({
      queryFn: createQueryFn(allTypedApis.rotateEndpointKey),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    getConfigurationEvents: build.query({
      queryFn: createQueryFn(allTypedApis.getConfigurationEvents),
    }),

    getEventSamplePayload: build.query({
      queryFn: createQueryFn(allTypedApis.getEventSamplePayload),
    }),

    getEventFields: build.query({
      queryFn: createQueryFn(allTypedApis.getEventFields),
    }),

    getAttempts: build.query({
      queryFn: createQueryFn(allTypedApis.getAttempts),
      providesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    getAttemptsExport: build.query({
      queryFn: createQueryFn(allTypedApis.getAttemptsExport),
    }),
    archiveAttempt: build.mutation({
      queryFn: createQueryFn(allTypedApis.archiveAttempt),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),
    resendAttempt: build.mutation({
      queryFn: createQueryFn(allTypedApis.resendAttempt),
      invalidatesTags: () => [
        {
          type: TagType.Webhooks,
          id: 'LIST',
        },
      ],
    }),

    // Managed Entities
    getEntityInstanceByClient: build.query({
      queryFn: createQueryFn(allTypedApis.getEntityInstanceByClient),
    }),
  }),
});

export const endpointNames = Object.keys(api.endpoints);
