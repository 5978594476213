import { useMemo } from 'react';

import { DEFAULT_MAX_LIMIT } from '~/constants/max_lengths';
import { useFieldsWithLazyLoad } from '~/features/filters';
import { createWhiteListFilters } from '~/features/filters/utils';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';

export const useXraySearchFilters = ({
  searchFilters,
}: {
  searchFilters: any;
}) => {
  const enableFiltersEnhancement = useFlag(
    FlagType.DocumentXRayEnhancedFilters,
  );

  const {
    data: smartFieldsResponse,
    isFetching: isLoadingSmartFieldIDs,
  } = api.endpoints.getFields.useQuery(
    {
      isSmart: true,
      limit: DEFAULT_MAX_LIMIT,
    },
    {
      skip: enableFiltersEnhancement,
    },
  );
  const smartFieldIds = useMemo(
    () => (smartFieldsResponse?.results ?? []).map(({ id }) => id),
    [smartFieldsResponse],
  );

  const lazySearchFilters = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters ?? {}),
    fieldValues: {},
  });

  const lazySmartFieldFilters = useFieldsWithLazyLoad({
    filters: searchFilters
      ? Object.values(
          createWhiteListFilters(searchFilters, [
            ...smartFieldIds,
            'folder',
            'clause',
          ]) ?? {},
        )
      : [],
    fieldValues: {},
  });

  return enableFiltersEnhancement
    ? { searchFilters: lazySearchFilters }
    : {
        searchFilters: lazySmartFieldFilters,
        isLoading: isLoadingSmartFieldIDs,
      };
};
