import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '~/eds';
import { api, selectors } from '~/redux';

import BaseSearchBar from '../BaseSearchBar';

type Props = {
  value: string;
  onChange?: (value: string) => void;
  onSubmit: (e: string) => void;
  isSubmitting: boolean;
};

const SearchV3BooleanSearchBar = ({
  value,
  onChange = noop,
  onSubmit,
  isSubmitting,
}: Props) => {
  const queryBuilder = useSelector(selectors.selectQueryBuilder);
  const [search, setSearch] = useState('');

  const [
    validateBooleanQuery,
  ] = api.endpoints.validateBooleanQuery.useLazyQuery();

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const validateInput = async (query: string) => {
    const response = await validateBooleanQuery(query).unwrap();
    return response?.detail ?? '';
  };

  return (
    <Layout spacing={3}>
      <BaseSearchBar
        disabled={!!queryBuilder}
        value={search}
        onChange={(val: string) => {
          setSearch(val);
          onChange(val);
        }}
        onSubmit={() => onSubmit(search)}
        validateInput={validateInput}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};

export default SearchV3BooleanSearchBar;
