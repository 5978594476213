import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AuthenticationStore from '~/auth';
import { Box, Button, Layout, Logo, Text, TextArea } from '~/eds';
import Configuration from '~/services/configuration';

const EmailApproveViewType = {
  Input: 'INPUT',
  Approved: 'APPROVED',
  InvalidLink: 'INVALID_LINK',
};

const AS = AuthenticationStore();

function InputView({
  comment,
  setComment,
  handleSendApproveRequest,
  viewTicket,
}) {
  return (
    <Layout align="center" direction="column" spacing={6}>
      <Text as="h1" variant="subtitle">
        Are you sure you want to approve this item?
      </Text>
      <Box w="60%">
        <Layout direction="column" spacing={6}>
          <TextArea
            name="approve-comment-textarea"
            placeholder="Approved from email"
            rows={6}
            maxLength="4096"
            value={comment}
            onChange={setComment}
          />
          <Layout justify="flex-end" spacing={6}>
            <Layout preset="buttons">
              <Button
                variant="secondary"
                name="view-ticket-button"
                onClick={viewTicket}
                text="View Ticket"
              ></Button>
              <Button
                variant="primary"
                name="action-button"
                onClick={handleSendApproveRequest}
                text="Approve Ticket"
              ></Button>
            </Layout>
          </Layout>
        </Layout>
      </Box>
    </Layout>
  );
}

InputView.propTypes = {
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  handleSendApproveRequest: PropTypes.func.isRequired,
  viewTicket: PropTypes.func.isRequired,
};

function ErrorMessageView() {
  return (
    <Layout align="center" direction="column">
      <Text as="h1" variant="subtitle">
        Invalid link
      </Text>
    </Layout>
  );
}

function ApproveMessageView() {
  return (
    <Layout align="center" direction="column">
      <Text as="h1" variant="subtitle">
        Ticket approved
      </Text>
    </Layout>
  );
}

function Page() {
  const isLoggedIn = AS.isLoggedIn();

  const [comment, setComment] = useState();
  const [view, setView] = useState();

  const formData = new FormData();
  formData.append('payload', JSON.stringify({ comment: comment }));

  const stringArray = window.location.pathname.split('/');
  const [
    _emptyItem,
    clientSubDomain,
    _tickets,
    ticketId,
    ...otherElements
  ] = stringArray;
  const actionUrl = `${
    Configuration.everestEndpoint
  }/tickets/${ticketId}/${otherElements.join('/')}`;
  const ticketUrl = `${window.location.origin}/${clientSubDomain}/workflow/tickets/${ticketId}/`;

  const handleSendApproveRequest = async () => {
    try {
      await axios.patch(actionUrl, formData, 'Content-Type');
      setView(EmailApproveViewType.Approved);
    } catch (e) {
      setView(EmailApproveViewType.InvalidLink);
    }
  };

  const viewTicket = () => {
    window.location.href = ticketUrl;
  };

  function renderView() {
    switch (view) {
      case EmailApproveViewType.Approved:
        return <ApproveMessageView />;
      case EmailApproveViewType.InvalidLink:
        return <ErrorMessageView />;
      default:
        return (
          <InputView
            comment={comment}
            setComment={setComment}
            handleSendApproveRequest={handleSendApproveRequest}
            viewTicket={viewTicket}
          />
        );
    }
  }

  return (
    <Layout
      as="header"
      direction="column"
      spacing={16}
      pt={isLoggedIn ? 16 : 0}
    >
      {isLoggedIn ? null : (
        <Layout
          align="center"
          bg="inverse.background"
          h="header.height"
          justify="space-between"
          px="6"
        >
          <a href="/" title="Evisort login page">
            <Logo theme="dark" />
          </a>
        </Layout>
      )}
      {renderView()}
    </Layout>
  );
}

export default Page;
