import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  integrationsRemoveSyncPair,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { getCrmIntegrationLoginUrl } from '~/api/crms';
import { User } from '~/components/Shared/User';
import {
  Accordion,
  Button,
  ContentContainer,
  Layout,
  Link,
  Table,
  Text,
  useToast,
} from '~/eds';
import { SyncPairStatusType } from '~/enums';
import { useAsync, usePollingRequest } from '~/hooks';
import { api } from '~/redux';
import { useRouting } from '~/routing';

function SalesIntegrations({
  // connected
  integrationsSetSyncPairs,
}) {
  const { params } = useRouting();
  const { clientId } = params;
  const { toast } = useToast();

  const {
    data: syncPairs = [],
    isLoading: isLoadingSyncPairs,
    error: syncPairsError,
  } = usePollingRequest(
    api.endpoints.getSyncPairsSFDC.useQuery,
    { clientId },
    { skip: !clientId, pollingInterval: 20000 },
  );

  useEffect(() => {
    if (syncPairsError) {
      toast({
        message: 'Unable to fetch sync pairs, please try again.',
        status: 'danger',
      });

      integrationsSetSyncPairs([]);
    }

    if (syncPairs) {
      integrationsSetSyncPairs(syncPairs);
    }
  }, [syncPairsError, syncPairs]);

  useEffect(() => {
    if (syncPairsError) {
      toast({
        message: 'Unable to fetch sync pairs, please try again.',
        status: 'danger',
      });

      integrationsSetSyncPairs([]);
    }

    if (syncPairs) {
      integrationsSetSyncPairs(syncPairs);
    }
  }, [syncPairsError, syncPairs]);

  const rowActions = [
    {
      label: 'Delete',
      onClick: () => {
        // TODO add call to delete sync pair
      },
    },
    {
      label: 'Configuration',
      onClick: () => {
        // TODO add call to show configuration modal
      },
    },
  ];

  const columns = [
    {
      key: 'orgId',
      title: 'Org ID',
      cellType: 'text',
      mapCellProps: ({ orgId }) => ({
        text: orgId,
      }),
    },
    {
      key: 'link',
      title: 'Link',
      cellType: 'link',
      renderCell: ({ link }) => {
        return <Link href={`${link}`}>{link}</Link>;
      },
    },
    {
      key: 'createdBy',
      title: 'Created By',
      cellType: 'user',
      mapCellProps: ({ createdBy }) => ({
        asyncUser: {
          id: createdBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'apiUser',
      title: 'API User',
      cellType: 'user',
      mapCellProps: ({ apiUser }) => ({
        asyncUser: {
          id: apiUser,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'dateCreated',
      title: 'Created date',
      cellType: 'datetime',
      mapCellProps: ({ dateCreated }) => ({
        datetime: dateCreated,
        format: 'iso',
      }),
    },
    {
      key: 'dateSynced',
      title: 'Last sync date',
      cellType: 'datetime',
      mapCellProps: ({ dateSynced }) => ({
        datetime: dateSynced,
        format: 'iso',
      }),
    },
    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',
      mapCellProps: (props) => {
        let text;
        let status;
        switch (props.status) {
          case SyncPairStatusType.ConnectionError:
            text = 'Connection error';
            status = 'danger';
            break;
          case SyncPairStatusType.ConfigError:
            text = 'Configuration error';
            status = 'danger';
            break;
          case SyncPairStatusType.DataAccessError:
            text = 'Data access error';
            status = 'warning';
            break;
          case SyncPairStatusType.Active:
            text = 'Connected';
            status = 'new';
            break;
          default:
            text = 'Unknown Error';
            status = 'danger';
        }
        const statusChip = {
          text,
          status,
        };
        return {
          chips: [statusChip],
        };
      },
    },
  ];

  const loadingContent = { isLoading: isLoadingSyncPairs };
  const placeholderContent =
    syncPairs.length === 0 ? { message: 'No connections' } : undefined;

  useEffect(() => {
    if (syncPairsError) {
      toast({
        message: 'Unable to fetch sync pairs, please try again.',
        status: 'danger',
      });

      integrationsSetSyncPairs([]);
    }

    if (syncPairs) {
      integrationsSetSyncPairs(syncPairs);
    }
  }, [syncPairsError, syncPairs]);

  const { executor: fetchIntegrationLoginUrl } = useAsync(
    getCrmIntegrationLoginUrl,
    {
      integrationUrl: window.location.href,
    },
    {
      errorToastMessage:
        'Unable to fetch CRM integration login url, please try again.',
      successHandler: ({ redirectUrl }) => (window.location.href = redirectUrl),
    },
  );

  const handleNewIntegration = () => {
    fetchIntegrationLoginUrl();
  };

  const items = [
    {
      content: (
        <Layout direction="column" spacing={6}>
          <Layout align="left" p={4} justify="space-between">
            <Text preset="description">
              Generate an Evisort API Key to use in your Salesforce
              configuration.{` `}
              <Link
                pathname={`/admin/console/${params.clientId}/integrations`}
                search="?tab=api-management"
              >
                Go to Evisort API Management.
              </Link>
            </Text>
            <Button
              text="Connect a Salesforce Account"
              variant="primary"
              onClick={handleNewIntegration}
            />
          </Layout>
          {
            <ContentContainer
              loadingContent={loadingContent}
              placeholderContent={placeholderContent}
            >
              <Table
                name="storage-integrations"
                columns={columns}
                data={syncPairs}
                options={{
                  enableManageColumns: false,
                  enableExportXlsx: false,
                }}
                rowActions={rowActions}
                isLoading={isLoadingSyncPairs}
              />
            </ContentContainer>
          }
        </Layout>
      ),
      title: 'Evisort Contract Management for Salesforce',
      isExpanded: true,
    },
  ];

  return <Accordion items={items} />;
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsSetSyncPairs,
  integrationsRemoveSyncPair,
})(SalesIntegrations);
