import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useStyles } from 'uinix-ui';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  FieldIcon,
  OperatorSelect,
  TextContainer,
} from '~/components/Shared/Filters_DEPRECATED';
import {
  Box,
  Dropdown,
  DropdownBody,
  DropdownSelectHeader,
  Icon,
  IconButton,
  Layout,
  TruncateText,
  types,
  useToggle,
} from '~/eds';
import {
  AsyncValue,
  Filter,
  FilterViewType,
  getFilterValuesLength,
  OperatorId,
  testIsActiveFilter,
  testIsEmptyFilter,
  Value,
} from '~/evifields';
import { DOCUMENT_ID_FILTER } from '~/features/filters';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { Nullable, SearchFilter } from '~/types';

import Values from '../Values';
import ValuesPreview from '../ValuesPreview';
import {
  clearFilter,
  clearFilterValues,
  updateFilterOperator,
  updateFilterValues,
} from './FilterChip.utils';

type Props = {
  enableFilterViews?: boolean;
  disableRemove: boolean;
  disableEdit?: boolean;
  isPinned?: boolean;
  searchFilter: SearchFilter;
  filter: Filter;
  readOnly?: boolean;
  onChange: (_updatedFilter: Filter) => void;
  onRemove: (_filterToRemove: Filter) => void;
  hasError?: boolean;
};

const FilterChip = ({
  enableFilterViews = false,
  disableRemove,
  disableEdit,
  isPinned = false,
  searchFilter,
  filter: initialFilter,
  readOnly,
  onChange: onCommitChange,
  onRemove,
  hasError,
}: Props) => {
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => setFilter(initialFilter), [initialFilter]);

  const hasTextDelimitedDocIdFilter = useFlag(
    FlagType.TextDelimitedDocumentIdFilter,
  );

  const [isVisible, _toggle, show, hide] = useToggle();
  const user = useCurrentUser();

  const { label, type, operators } = searchFilter;
  const { operatorId } = filter;

  const hasOperator = Boolean(operatorId);
  const hasValues = getFilterValuesLength(filter) > 0;

  const operator = operators.find((op) => op.id === operatorId);

  const isAllBooleanOperator = operators.every(
    (operator) => operator.cardinality === 0,
  );
  const hasOperators = operators.length >= 1;

  const isFolderField = type === 'folder';
  const isEmpty = testIsEmptyFilter(filter);
  const isBooleanTextSearch = type === 'bool_text_search';

  const isActive = Boolean(
    (operator && testIsActiveFilter(filter, operator, type)) ||
      (isBooleanTextSearch && hasValues),
  );
  const isEmptyValue =
    (operator?.cardinality || 0) > 0 && getFilterValuesLength(filter) === 0;
  const styles = useStyles();

  const handleRemove = () => {
    if (isEmpty || disableEdit) {
      onRemove(filter);
    } else {
      const updateFilter = hasOperators ? clearFilter : clearFilterValues;
      const updatedFilter = updateFilter(filter);
      setFilter(updatedFilter);
      onCommitChange(updatedFilter);
    }
    hide();
  };

  const handleUpdateOperator = (updatedOperatorId: Nullable<OperatorId>) => {
    setFilter(updateFilterOperator(filter, updatedOperatorId));
    const updatedOperator = operators.find((op) => op.id === updatedOperatorId);
    if (updatedOperator?.cardinality === 0) {
      hide();
    }
  };

  const handleUpdateValues = (
    updatedValues: Value[],
    updatedAsyncValue?: AsyncValue<Value>,
  ) => {
    const updatedFilter = updateFilterValues(
      filter,
      updatedValues,
      updatedAsyncValue,
    );
    setFilter(updatedFilter);
    if (
      type === 'enum' ||
      (type === 'enum_set' && filter.filterView === 'text-delimited') ||
      (hasTextDelimitedDocIdFilter &&
        type === 'text' &&
        searchFilter.label === DOCUMENT_ID_FILTER)
    ) {
      hide();
    }
  };

  const handleUpdateFilterView = (
    filterView: types.Nullable<FilterViewType>,
  ) => {
    setFilter({
      ...clearFilterValues(filter),
      filterView: filterView ?? undefined,
    });
  };

  const handleHide = () => {
    const filterToCommit = isEmptyValue ? clearFilter(filter) : filter;
    setFilter(filterToCommit);
    if (
      !isEqual(
        { operatorId: initialFilter.operatorId, values: initialFilter.values },
        {
          operatorId: filterToCommit.operatorId,
          values: filterToCommit.values,
        },
      )
    ) {
      onCommitChange(filterToCommit);
    }

    hide();
  };

  const handleShow = () => {
    if (!disableEdit) {
      if (!hasOperator && !isAllBooleanOperator) {
        const firstOperator = operators[0];
        setFilter(updateFilterOperator(filter, firstOperator.id));
      }
      trackSegment('openFilter', {
        fieldId: filter.fieldId,
        name: searchFilter.label,
        user_id: user.id,
        client_id: user.client,
      });
      show();
    }
  };

  const disableHeader = isAllBooleanOperator || isFolderField || !hasOperators;

  const header = !disableHeader && (
    <DropdownSelectHeader
      select={
        <OperatorSelect
          operators={operators}
          value={operatorId}
          onChange={handleUpdateOperator}
        />
      }
    />
  );

  let contents;
  if (operator?.cardinality || isBooleanTextSearch) {
    contents = (
      <DropdownBody>
        <Values
          field={searchFilter}
          filter={filter}
          onChange={handleUpdateValues}
          onViewChange={enableFilterViews ? handleUpdateFilterView : undefined}
        />
      </DropdownBody>
    );
  } else if (isAllBooleanOperator) {
    contents = (
      <OperatorSelect
        isEmbedded={isAllBooleanOperator}
        operators={operators}
        value={operatorId}
        onChange={handleUpdateOperator}
      />
    );
  }

  const filterText = (
    <Layout spacing={1}>
      <Box minW="min-content">
        <TextContainer isBold={false} text={label} />
      </Box>
      {operator && <strong> {operator.label}</strong>}
      {hasValues && <ValuesPreview field={searchFilter} filter={filter} />}
    </Layout>
  );

  const trigger = (
    <Layout
      align="center"
      styles={[componentStyles.base, readOnly ? null : styles.colors.status]}
      styleProps={{
        status: hasError ? 'danger' : isActive ? 'active' : undefined,
      }}
    >
      <Layout
        align="center"
        as="button"
        borderRadius="m"
        color="inherit"
        h="100%"
        minW={0}
        px={2}
        spacing={2}
        styles={[
          styles.button.unset,
          componentStyles.leftContent,
          disableEdit || readOnly ? componentStyles.disabled : {},
        ]}
        onClick={readOnly ? undefined : handleShow}
      >
        {isPinned ? (
          <Icon icon={hasValues ? 'push-pin-filled' : 'push-pin'} />
        ) : (
          <FieldIcon fieldType={type} />
        )}

        <TruncateText color="text.primary" variant="tiny" whiteSpace="nowrap">
          {filterText}
        </TruncateText>
        {!readOnly && disableRemove && (
          <Icon
            color="currentColor"
            icon={isVisible ? 'chevron-up' : 'chevron-down'}
            label={isVisible ? 'Expanded field' : 'Collapsed field'}
          />
        )}
        {!readOnly && !disableRemove && (
          <IconButton
            icon="x"
            size="s"
            tooltip={isEmpty || disableEdit ? 'Remove filter' : 'Clear values'}
            onClick={handleRemove}
          />
        )}
      </Layout>
    </Layout>
  );

  if (readOnly) {
    return trigger;
  }

  return (
    <Dropdown
      header={header}
      isVisible={isVisible}
      trigger={trigger}
      triggerMode="shrink"
      onClickOutside={hide}
      onHide={handleHide}
      onShow={handleShow}
    >
      {contents}
    </Dropdown>
  );
};

export const componentStyles = {
  base: {
    border: 'border',
    borderRadius: 'm',
    height: 'filter.height',
    outline: 'none',
    maxWidth: '100%',
    width: 'min-content',
  },
  leftContent: {
    borderTopLeftRadius: 'm',
    borderBottomLeftRadius: 'm',
    flex: '0 1 auto',
  },
  disabled: {
    opacity: '50%',
  },
};

export default FilterChip;
