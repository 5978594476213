import {
  testIsBooleanTextSearch,
  testIsFilter,
  testIsOperator,
} from '~/features/search/utils/queryUtils';
import { toSearchV3Filter } from '~/redux/api/methods/searchV3';
import {
  BooleanQueryEntity,
  FilterQueryEntity,
  Nullable,
  SearchFilter,
  SectionEntity,
} from '~/types';

export const toSectionFiltersOperators = (
  query: SectionEntity[],
  searchFilters: Record<string, SearchFilter>,
) => {
  return query.map((item: SectionEntity) => {
    if (item.type === 'operator') {
      return item;
    } else {
      const filters = item.value
        .filter(
          (
            sectionItem,
          ): sectionItem is FilterQueryEntity | BooleanQueryEntity =>
            testIsFilter(sectionItem) || testIsBooleanTextSearch(sectionItem),
        )
        .map((fieldOperator) => toSearchV3Filter(fieldOperator, searchFilters));
      const filterOperator = item.value.filter(testIsOperator)[0];
      let val;
      if (filterOperator) {
        val = { filters, operator: filterOperator };
      } else {
        val = { filters, operator: { type: 'operator', value: 'and' } };
      }
      return {
        type: 'section',
        value: val,
      };
    }
  });
};

export const isBooleanFilter = (operatorId: Nullable<string>) => {
  return (
    operatorId === 'is_true' ||
    operatorId === 'is_false' ||
    operatorId === 'is_blank' ||
    operatorId === 'is_not_blank'
  );
};
