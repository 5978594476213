import React from 'react';

import {
  ClauseValue,
  DateValue,
  EnumValue,
  Field,
  Filter,
  NumberValue,
  TextValue,
} from '../types';
import { BooleanValuesPreview } from './BooleanValuesPreview';
import { ClauseValuesPreview } from './ClauseValuesPreview';
import { DateValuesPreview } from './DateValuesPreview';
import { EnumSetValuesPreview } from './EnumSetValuesPreview';
import { EnumValuesPreview } from './EnumValuesPreview';
import { FileValuesPreview } from './FileValuesPreview';
import { NumberValuesPreview } from './NumberValuesPreview';
import { TextValuesPreview } from './TextValuesPreview';

interface Props {
  field: Field;
  filter: Filter;
}

export const ValuesPreview = ({ field, filter }: Props) => {
  switch (field.type) {
    case 'boolean':
      return <BooleanValuesPreview />;
    case 'clause':
      return <ClauseValuesPreview filter={filter as Filter<ClauseValue>} />;
    case 'date':
      return <DateValuesPreview filter={filter as Filter<DateValue>} />;
    case 'enum':
      return (
        <EnumValuesPreview
          field={field as Field<'enum'>}
          filter={filter as Filter<EnumValue>}
        />
      );
    case 'enum_set':
    case 'folder':
      return (
        <EnumSetValuesPreview
          field={field as Field<'enum_set'>}
          filter={filter as Filter<EnumValue>}
        />
      );
    case 'file':
      return <FileValuesPreview />;
    case 'number':
      return <NumberValuesPreview filter={filter as Filter<NumberValue>} />;
    case 'text':
    case 'text_search':
    case 'bool_text_search':
      return (
        <TextValuesPreview filter={filter as Filter<TextValue>} field={field} />
      );
    default:
      return null;
  }
};
