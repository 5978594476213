import React, { memo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { HtmlDocumentViewer, HtmlHighlightData } from '../HtmlDocumentViewer';
import { BaseDocumentViewerProps, InteractionPosition } from '../types';
import { useHighlightsMap } from '../useHighlightsMap';
import { useInteractionHighlight } from '../useInteractionHighlight';
import { mapActiveElement, mapHtmlTokenHighlights } from './utils';

// TODO: using a deprecated HTMLViewer currently, will be refactored using `DocumentEditor[readonly]` in the near future
export const WordDocumentViewer = memo(
  ({
    activeHighlightId,
    file,
    highlights = [],
    invalidateActiveHighlightId,
    onHighlightClick,
    onHighlightCreate,
  }: BaseDocumentViewerProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const highlightsMap = useHighlightsMap(highlights);

    const {
      renderInteractionHighlightActionsMenu,
      setInteractionHighlight,
    } = useInteractionHighlight({
      containerRef,
      onHighlightClick,
      onHighlightCreate,
    });

    const handleHighlightClick = onHighlightClick
      ? (highlightId: string, position: InteractionPosition) => {
          const highlight = highlightsMap[highlightId];
          onHighlightClick.callback?.(highlight, position);
          setInteractionHighlight({
            highlight,
            interaction: 'click',
            position,
          });
        }
      : undefined;

    const handleHighlightCreate = onHighlightCreate
      ? (highlightData: HtmlHighlightData, position: InteractionPosition) => {
          const { htmlTokens, text } = highlightData;
          const newHighlight = {
            id: uuid(),
            htmlTokens,
            text,
          };
          setInteractionHighlight({
            highlight: newHighlight,
            interaction: 'create',
            position,
          });
          onHighlightCreate.callback?.(newHighlight, position);
        }
      : undefined;

    return (
      <div ref={containerRef} style={componentStyles.container}>
        <HtmlDocumentViewer
          activeElementId={mapActiveElement(highlights, activeHighlightId)}
          containerRef={containerRef}
          file={file}
          highlights={mapHtmlTokenHighlights(highlights)}
          invalidateActiveHighlightId={invalidateActiveHighlightId}
          onHighlightClick={handleHighlightClick}
          onHighlightCreate={handleHighlightCreate}
        />
        {renderInteractionHighlightActionsMenu()}
      </div>
    );
  },
);

const componentStyles = {
  container: {
    overflow: 'auto',
  },
};
