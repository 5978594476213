import { useEffect, useMemo, useState } from 'react';

import { useLimitExceededModal } from '~/components/DocumentsPage/DocumentsRestore';
import {
  BULK_COPY_LIMIT,
  BULK_DELETE_LIMIT,
  BULK_EDIT_CLAUSE_LIMIT,
  BULK_EDIT_LIMIT,
  BULK_MOVE_LIMIT,
} from '~/constants/max_lengths';
import { types, useTableSelection } from '~/eds';
import { BulkActionType } from '~/enums';
import { usePermission } from '~/hooks';
import { PilotSearchQuery } from '~/redux/api/methods';
import { testContainsGhostSearchResult } from '~/utils/document.utils';

import { DocumentHandler } from '../advanced-search';
import { AddSearchResultsToGroup } from './AddSearchResultsToGroup';
import {
  DeleteActionPanel,
  EditClausesActionPanel,
  EditFieldsActionPanel,
  MoveCopyActionPanel,
} from './BulkActionPanels';
import { BaseBulkActionProps } from './types';

type BaseDocumentSearchItem = { document_handlers: DocumentHandler[] };
interface Props extends BaseBulkActionProps {
  resultsCache: Record<types.PilotId, BaseDocumentSearchItem>;
  totalCount: number;
  /** this prop will be passed to MoveCopy panel to disable folders containing sync pair. only applicable when
   * moving folders.
   */
  shouldDisableSyncPairedFolders?: boolean;
}

export const useDocumentsBulkActionsPanel = ({
  isAllSelected,
  resultsCache,
  searchAPI,
  selectedDocumentIds = [],
  shouldDisableSyncPairedFolders = false,
  totalCount,
  query,
  onActionCompleted: onActionCompletedParam,
}: Props) => {
  const [activePanel, setActivePanel] = useState<BulkActionType | undefined>();
  const [hasCheckedLimit, setHasCheckedLimit] = useState(false);
  const closePanel = () => {
    setActivePanel(undefined);
    setHasCheckedLimit(false);
  };

  const hasGroupsEditPermission = usePermission({
    permission: {
      resourceId: 'document_groups',
      resourceType: 'edit',
    },
  });

  const documentHandlerIds = useMemo(() => {
    //only edit clauses action receives a document id list. The rest of the actions receive a doc handler id list.
    return selectedDocumentIds.reduce((acc, current) => {
      const document = resultsCache[current];
      if (document) {
        return acc.concat(
          document.document_handlers?.map(
            (handler) => handler.id as types.PilotId,
          ),
        );
      }
      return acc;
    }, [] as types.PilotId[]);
  }, [selectedDocumentIds, resultsCache]);

  const handleHideLimitExceededModal = () => {
    closeLimitExceededModal();
    closePanel();
  };

  const numberOfAffectedDocuments = isAllSelected
    ? totalCount
    : selectedDocumentIds.length;

  const [
    limitExceededModal,
    showLimitExceededModal,
    closeLimitExceededModal,
  ] = useLimitExceededModal({
    limit: getBulkActionDocumentsLimit(activePanel),
    actionType: activePanel ?? 'copy',
    count: numberOfAffectedDocuments,
    primaryActionHide: handleHideLimitExceededModal,
  });

  const onActionCompleted = () => {
    onActionCompletedParam?.();
    closePanel();
  };

  useEffect(() => {
    if (activePanel) {
      if (
        numberOfAffectedDocuments > getBulkActionDocumentsLimit(activePanel)
      ) {
        showLimitExceededModal();
      } else {
        setHasCheckedLimit(true);
      }
    }
  }, [activePanel, numberOfAffectedDocuments]);

  const baseBulkActionProps: BaseBulkActionProps = {
    isAllSelected,
    onActionCompleted,
    query,
    searchAPI: searchAPI,
    selectedDocumentIds:
      activePanel !== 'edit-clauses' && activePanel !== 'edit'
        ? documentHandlerIds
        : selectedDocumentIds,
  };

  const panelComponent = useMemo(() => {
    switch (activePanel) {
      case 'copy':
      case 'move':
        return (
          <MoveCopyActionPanel
            {...baseBulkActionProps}
            selectedCount={numberOfAffectedDocuments}
            shouldDisableSyncPairedFolders={shouldDisableSyncPairedFolders}
            onHide={closePanel}
            activePanel={activePanel}
          />
        );
      case 'delete':
        return (
          <DeleteActionPanel
            {...baseBulkActionProps}
            onHide={closePanel}
            selectedCount={numberOfAffectedDocuments}
          />
        );
      case 'edit':
        return (
          <EditFieldsActionPanel
            selectedCount={numberOfAffectedDocuments}
            {...baseBulkActionProps}
            onHide={closePanel}
            selectedDocumentHandlerIds={documentHandlerIds}
          />
        );

      case 'edit-clauses':
        return (
          <EditClausesActionPanel
            onHide={closePanel}
            selectedCount={numberOfAffectedDocuments}
            {...baseBulkActionProps}
          />
        );
      case 'add-to-group':
        return (
          <AddSearchResultsToGroup
            query={query as PilotSearchQuery}
            tableSelection={
              {
                selectedIds: selectedDocumentIds as string[],
                isAllSelected: !!isAllSelected,
              } as ReturnType<typeof useTableSelection>
            }
            onHide={closePanel}
            onCompleted={onActionCompleted}
          />
        );
    }
  }, [activePanel]);

  const getBulkActions = ({ selectedRowIds }: types.TableState) => {
    const isAnyNotMovable = false;
    const documents = selectedRowIds?.map((id) => resultsCache[id]);
    const containsGhost = testContainsGhostSearchResult(documents);
    // TODO: Confirm the visible no edit check for bulk actions

    const bulkActions = [
      {
        icon: 'folder' as const,
        label: isAnyNotMovable
          ? 'You do not have privileges to move one or more of the items you have selected.'
          : 'Move',
        onClick: () => setActivePanel('move'),
        disabled: isAnyNotMovable,
        value: null,
      },
      {
        icon: 'clipboard' as const,
        label: isAnyNotMovable
          ? 'You do not have privileges to copy one or more of the items you have selected.'
          : 'Copy',
        onClick: () => setActivePanel('copy'),
        disabled: isAnyNotMovable,
        value: null,
      },
      {
        icon: 'edit' as const,
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Fields',
        onClick: () => setActivePanel('edit'),
        disabled: isAnyNotMovable,
        value: null,
      },
      {
        icon: 'edit' as const,
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Clauses',
        onClick: () => setActivePanel('edit-clauses'),
        disabled: isAnyNotMovable,
        value: null,
      },
      {
        icon: 'trash' as const,
        label: isAnyNotMovable
          ? 'You do not have privileges to delete one or more of the items you have selected.'
          : 'Delete',
        onClick: () => setActivePanel('delete'),
        disabled: isAnyNotMovable,
        value: null,
      },
      {
        icon: 'groups' as const,
        label: containsGhost
          ? 'You do not have privileges to add one or more of the items you have selected to a group.'
          : 'Add to Group',
        onClick: () => setActivePanel('add-to-group'),
        disabled: containsGhost || !hasGroupsEditPermission,
        value: null,
      },
    ];

    return bulkActions;
  };

  return {
    panel: activePanel && hasCheckedLimit ? panelComponent : null,
    limitExceededModal,
    getBulkActions,
    setActivePanel,
  };
};

const getBulkActionDocumentsLimit = (bulkAction?: BulkActionType) => {
  switch (bulkAction) {
    case 'copy':
      return BULK_COPY_LIMIT;
    case 'delete':
      return BULK_DELETE_LIMIT;
    case 'edit':
      return BULK_EDIT_LIMIT;
    case 'edit-clauses':
      return BULK_EDIT_CLAUSE_LIMIT;
    case 'move':
      return BULK_MOVE_LIMIT;
    default:
      return Number.POSITIVE_INFINITY;
  }
};
