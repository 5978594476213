import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { ManageSyncPairStageType } from '~/enums';
import { Box, Divider, FlexLayout, PageLayout, Text } from '~/ui';

function AribaHeader({
  // connected
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const stagesOneTimeImport = [
    {
      label: 'API Configurations',
      value: ManageSyncPairStageType.MultiAPIs,
    },
    {
      label: 'File Configurations',
      value: ManageSyncPairStageType.FileConfig,
    },
    {
      label: 'File Configurations',
      value: ManageSyncPairStageType.FolderAriba,
    },
    {
      label: 'Field Data Mapping',
      value: ManageSyncPairStageType.FieldMapping,
    },
    {
      label: 'Summary',
      value: ManageSyncPairStageType.SummaryAriba,
    },
  ];

  const stagesReauth = [
    {
      label: 'API Configurations',
      value: ManageSyncPairStageType.MultiAPIs,
    },
  ];

  const isReauthenticate = !!activeSyncPair?.isTokenReauthenticate;

  const getStages = () => {
    if (isReauthenticate) {
      return stagesReauth;
    }
    return stagesOneTimeImport;
  };

  const stages = getStages();
  const prefix = activeSyncPair ? `${activeSyncPair.name} - ` : '';
  const title = `${prefix}${
    isReauthenticate ? 'Reauthenticate' : 'New Sync Pair'
  }`;

  const handleClose = () => {
    integrationsSetActiveSyncPair(null);
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
  };
  const centerContent = (
    <FlexLayout alignItems="center" space={3}>
      {stages
        .filter((stage, index, self) => {
          // The purpose of this is to hide the duplicate stages by label
          // and only show the active one.

          const activeStage = manageSyncPairStage;
          const similarStages = self.filter((s) => s.label === stage.label);
          const hasDuplicates = similarStages.length > 1;

          if (hasDuplicates) {
            const isActive = similarStages
              .map((stage) => stage.value)
              .includes(activeStage);

            // If it's active, return the active stage
            if (isActive) return stage.value === activeStage;
            // Since it's for presentation purposes, it's okay to return the first stage
            // The other will remain hidden
            if (!isActive) return stage === similarStages[0];
          }

          return true;
        })
        .map((stage, i) => {
          const isActiveStage = stage.value === manageSyncPairStage;
          return (
            <Fragment key={stage.value}>
              <Text
                color="gray-800"
                disabled={!isActiveStage}
                variant={isActiveStage ? 's-dense-bold' : 's-dense'}
              >
                {stage.label}
              </Text>
              {i < stages.length - 1 && (
                <Box sx={{ width: '24px' }}>
                  <Divider />
                </Box>
              )}
            </Fragment>
          );
        })}
    </FlexLayout>
  );

  return (
    <PageLayout.Header
      title={{
        text: title,
      }}
      centerContent={centerContent}
      onClose={handleClose}
    />
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(AribaHeader);
