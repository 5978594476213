import {
  differenceInHours,
  differenceInMinutes,
  formatDistance,
  formatDistanceToNow,
  format as formatFn,
  formatISO,
  formatISO9075,
} from 'date-fns';

import { DATE_TIME_FORMAT_PARAMS } from '../constants';
import { HtmlEntityType } from '../enums';
import { DateFormat, Nullable } from '../types';

/**
 * Formats a date based on Intl.DateTimeFormat.
 * */
export const formatDate = (
  date: Nullable<Date>,
  format: DateFormat = 'iso',
): string => {
  if (date === null) {
    return HtmlEntityType.Dash;
  }

  const params = DATE_TIME_FORMAT_PARAMS[format];
  switch (format) {
    case 'relative':
      return formatDistance(date, new Date(), { addSuffix: true });
    case 'full':
      return formatFn(date, "yyyy-MM-dd 'at' hh:mm a");
    case 'full_datetime':
      //'MMMM DD, YYYY hh:mm a
      return formatFn(date, 'MMMM dd, yyyy hh:mm a');
    case 'utc':
      // YYYY-MM-DDTHH:MM:SSZ
      return date.toISOString().split('.')[0] + 'Z';
    case 'iso_date':
      return formatISO(date, { representation: 'date' });
    case 'iso_datetime':
      // YYYY-MM-DD HH:MM:SS
      return formatISO9075(date);
    case 'prose':
      // 1st day of January, 2021
      return formatFn(date, "do 'day of' MMMM, yyyy");
    case 'us_date':
      return formatFn(date, 'MM/dd/yyyy');
    case 'eu_date':
      return formatFn(date, 'dd/MM/yyyy');
    case 'iso_complete':
      //'YYYY-MM-DD"T"HH:MM:SS:XXXX';
      return formatISO(date, {
        representation: 'complete',
      });
    case 'duration':
      return formatDateFn(date);
    case 'time':
      return formatFn(date, 'hh:mm a');
    case 'iso':
    default:
      return Intl.DateTimeFormat(...params).format(date);
  }
};

function formatDateFn(datetime: Date) {
  if (differenceInMinutes(new Date(), datetime) <= 1) {
    return formatDistanceToNow(datetime, {
      includeSeconds: true,
      addSuffix: true,
    });
  } else if (differenceInHours(new Date(), datetime) >= 35) {
    return formatDate(datetime, 'full');
  } else {
    return formatDistanceToNow(datetime, { addSuffix: true });
  }
}
