import React from 'react';
import { connect } from 'react-redux';

import { FlagType, useFlag } from '~/flags';
import { FlexLayout } from '~/ui';

import ArchiveLocation from './ArchiveLocation';
import { CycleTimeTracker } from './CycleTimeTracker';
import { DataFieldsMapping } from './DataFieldsMapping';
import IntakeFormLink from './IntakeFormLink';
import Notifications from './Notifications';
import Permissions from './Permissions';
import TicketNamingConventions from './TicketNamingConventions';

function Settings({ workflow }) {
  const isTurnTrackingEnabled = useFlag(FlagType.TurnTracking);
  const isTicketNamingEnabled = useFlag(FlagType.TicketNaming);
  const isWSSDesignated = workflow.wssDesignated;
  return (
    <FlexLayout flexDirection="column" space={10}>
      {isTicketNamingEnabled && <TicketNamingConventions />}
      <ArchiveLocation />
      {isTurnTrackingEnabled && <CycleTimeTracker />}
      <DataFieldsMapping />
      <Notifications />
      <Permissions />
      {!isWSSDesignated && (
        <IntakeFormLink intakeFormLinkId={workflow.permanentLinkId} />
      )}
    </FlexLayout>
  );
}

const mapStateToProps = ({ workflow }) => ({ workflow });

export default connect(mapStateToProps)(Settings);
