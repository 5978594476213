import { isEmpty } from 'lodash';
import uuid from 'uuid';

import { types } from '~/eds';
import { SearchQuery } from '~/features/advanced-search';
// TODO fix circular imports to be able to import from module root.
import {
  Citation,
  CitationEntity,
  postProcessMarkdown,
  SearchEntity,
} from '~/features/ask-anything';
import { Entity, EntityType, Nullable, Uuid } from '~/types';

import {
  createNewConversation,
  getAllConversations,
  getExistingConversation,
  postNewUserMessage,
  postUserRating,
} from '../methods/conversationalAi';
import {
  ConversationMessage,
  ConversationMessageSource,
  ConversationStreamEvent,
  ExternalEntity,
  Conversation as ServerConversation,
  UserRating,
} from '../types/sylvanus/conversation';
import { testIsAxiosError } from '../utils';
import { camelizeKeys, ParsedType } from './utils';

export type Message = ParsedType<ConversationMessage>;
export type Conversation = ParsedType<ServerConversation>;
export type MessageStreamEvent = ParsedType<ConversationStreamEvent>;
export interface ClientConversation {
  id: Uuid;
  created: string;
  createdBy: string;
  userEmail: string;
  messages: types.Chat.Message[];
  metadata?: {
    documentHash: string;
  };
}

export interface MessageContext {
  search_query?: SearchQuery;
  search_entity: SearchEntity;
  selected_ids?: string[];
  entities_in_view?: Entity<CitationEntity>[];
}
export interface PostUserMessageParams {
  conversationId: Uuid;
  message: string;
  context?: MessageContext[];
  metadata?: {
    questionId: Uuid;
    questionGroupId: Uuid;
  };
}

export interface PostUserFeedbackParams {
  conversationId: Uuid;
  messageId: Uuid;
  feedbackDetails: types.Chat.FeedbackDetails;
}

interface ClientPageConversation {
  items: Omit<Conversation, 'messages'>[];
  total: number;
  page?: number;
  size?: number;
  pages?: number;
}

export const getConversations = async (
  params: Entity<EntityType>,
): Promise<ClientPageConversation> => {
  const serverConversations = await getAllConversations({
    entityId: params.id,
    entityType: params.type as ExternalEntity['type'],
  });
  // TODO remove ts-ignore when server response is fixed
  //@ts-ignore
  return camelizeKeys(serverConversations);
};

export const createConversation = async (
  entity: Entity<EntityType>,
): Promise<Uuid | undefined> => {
  try {
    const response = await createNewConversation(entity as ExternalEntity);
    return response.id;
  } catch (error: unknown) {
    if (testIsAxiosError(error))
      throw new Error('Failed to create conversation', {
        cause: error.response?.status,
      });
  }
};

export const getConversation = async ({
  conversationId,
}: {
  conversationId: Uuid;
}): Promise<ClientConversation> => {
  const response = await getExistingConversation(conversationId);
  const conversation = {
    id: response.id,
    created: response.datetime_created,
    createdBy: response.created_by_user_id,
    userEmail: response.created_by_user_email,
    messages: response.messages.map(mapServerMessageToChatMessage),
    metadata: response.metadata,
  };
  // TODO remove ts-ignore when server response is fixed
  //@ts-ignore
  return conversation;
};

export const postUserMessage = async ({
  conversationId,
  message,
  context,
  metadata,
}: PostUserMessageParams): Promise<types.Chat.Message> => {
  const response = await postNewUserMessage({
    conversationId,
    message,
    context,
    metadata,
  });
  return mapServerMessageToChatMessage(response);
};

export const postUserFeedback = async ({
  conversationId,
  messageId,
  feedbackDetails,
}: PostUserFeedbackParams): Promise<types.Chat.Message> => {
  const response = await postUserRating({
    conversationId,
    messageId,
    userRating: mapUserRatingFromFeedbackDetails(feedbackDetails),
  });
  return mapServerMessageToChatMessage(response);
};

export const mapServerMessageToChatMessage = (
  response: ConversationMessage,
): types.Chat.Message => {
  const mappedCitations = mapCitations(response);

  const { citations, text } = postProcessMarkdown(
    response.message_text,
    mappedCitations,
  );
  return {
    choices: response.response_suggestions ?? [],
    id: response.id,
    sources: (response.sources ?? []).map(
      (source: ConversationMessageSource) => ({
        ...camelizeKeys(source),
        id: uuid.v4(),
      }),
    ),
    text,
    citations,
    timestamp: new Date(response.datetime_created),
    userType: response.role === 'user' ? 'current' : 'other',
    feedbackDetails: mapFeedbackDetailsFromUserRating(
      response.user_rating ?? null,
    ),
  };
};

export const parseServerMessage = (message: Message): types.Chat.Message => {
  const mappedCitations = mapCitationsFromParsedMessage(message);

  const { citations, text } = postProcessMarkdown(
    message.messageText,
    mappedCitations,
  );
  const parsedMessage: types.Chat.Message = {
    choices: (message.responseSuggestions ?? []) as string[],
    id: message.id,
    sources: ((message.sources ?? []) as ConversationMessageSource[]).map(
      (source) => ({
        ...camelizeKeys(source),
        id: uuid.v4(),
      }),
    ),
    text,
    timestamp: new Date(message.datetimeCreated),
    userType: message.role === 'user' ? 'current' : 'other',
    feedbackDetails: mapFeedbackDetailsFromUserRating(
      message.userRating ?? null,
    ),
    citations,
  };

  return parsedMessage;
};

const mapFeedbackDetailsFromUserRating = (
  rating: Nullable<UserRating>,
): types.Chat.FeedbackDetails => {
  switch (rating) {
    case 'positive':
      return { value: true };
    case 'negative':
      return { value: false };
    default:
      return { value: null };
  }
};

const mapCitations = (
  message: ConversationMessage,
): Record<string, Citation> => {
  if (!message.citations || isEmpty(message.citations)) return {};

  const citationMap: Record<string, Citation> = Object.fromEntries(
    Object.entries(message.citations ?? {}).map(([key, citation]) => [
      key,
      {
        key,
        highlights: citation.highlights.map((highlight) => ({
          id: uuid.v4(),
          coordinates: highlight.coordinates ?? [],
          htmlTokens: highlight.html_tokens ?? [],
        })),
        originalQuote: citation.original_quote,
        entity: citation.entity,
        link: citation.link,
        tooltip: citation.tooltip,
        entityName: citation.entity_name ?? null,
      },
    ]),
  );
  return citationMap;
};

const mapCitationsFromParsedMessage = (
  message: Message,
): Record<string, Citation> => {
  if (!message.citations || isEmpty(message.citations)) return {};

  const citationMap: Record<string, Citation> = Object.fromEntries(
    Object.entries(message.citations ?? {}).map(([key, citation]) => [
      key,
      {
        key,
        highlights: citation.highlights.map((highlight) => ({
          id: uuid.v4(),
          coordinates: highlight.coordinates ?? [],
          htmlTokens: highlight.htmlTokens ?? [],
        })),
        originalQuote: citation.originalQuote,
        entity: citation.entity,
        link: citation.link,
        tooltip: citation.tooltip,
        entityName: citation.entityName,
      } as Citation,
    ]),
  );
  return citationMap;
};

export const mapUserRatingFromFeedbackDetails = (
  details: types.Chat.FeedbackDetails,
): Nullable<UserRating> => {
  if (!details) return null;
  const { value } = details;
  switch (value) {
    case true:
      return 'positive';
    case false:
      return 'negative';
    default:
      return null;
  }
};
