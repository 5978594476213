import { compact } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import uuid from 'uuid';

import {
  AutomationHubManagement,
  buildClauseAnalyzerParams,
  HELP_CENTER_LINK,
} from '~/components/AutomationHub';
import RenameClauseModal from '~/components/Modals/RenameClauseModal';
import { showToast } from '~/components/Shared/EcToast';
import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import {
  Button,
  DateText,
  EmptyPage,
  Icon,
  Layout,
  LoadingShimmer,
  PageLayout,
  Table,
} from '~/eds';
import {
  AutomationClauseType,
  AutomationStageType,
  AutomationStatusType,
  FeatureFlagType,
  QueryParamType,
} from '~/enums';
import { ModelScope } from '~/features/x-ray';
import { FlagType, useFlag } from '~/flags';
import {
  useClientSubDomain,
  useHasFeatureFlag,
  useResolveUsers,
} from '~/hooks';
import { actions, api } from '~/redux';
import searchSlice from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { RoutePathType, useRouting } from '~/routing';
import { redirectToPage } from '~/services/redirects';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Alert, FlexLayout, LoadingContainer, Text } from '~/ui';
import { getLatestDate } from '~/utils/dates';
import { toLowercaseTrimmed } from '~/utils/strings';

const CLAUSE_STATUS_ID = 'statusFilter';
const CLAUSE_TYPE_ID = 'typeFilter';

const ALL_STATUSES = [
  AutomationStatusType.ACTIVE,
  AutomationStatusType.SUSPENDED,
];
const ALL_TYPES = [
  AutomationClauseType.QUICK_AI,
  AutomationClauseType.GUIDED_AI,
];
const CLAUSE_STATUS_OPTIONS = [
  { label: 'Active', value: AutomationStatusType.ACTIVE },
  { label: 'Suspended', value: AutomationStatusType.SUSPENDED },
];

const CLAUSE_TYPE_OPTIONS = [
  { label: 'Quick AI', value: AutomationClauseType.QUICK_AI },
  { label: 'Guided AI', value: AutomationClauseType.GUIDED_AI },
];

const filtersOptions = {
  disableManageFilters: true,
  disableRemoveFilter: true,
  disableClearFilters: true,
};

const Page = ({
  clause,
  pagination,
  resetAutomationGuidedAiDataForm,
  resetAutomationStates,
  setAutomationClause,
  setAutomationManageStage,
  setAutomationClausePagination,
}) => {
  const hideClauseAffordance = useFlag(FlagType.HideClauseAffordance);
  const dispatch = useDispatch();

  const { location, navigate } = useRouting();

  const subdomain = useClientSubDomain();
  const hasAutomationSmartClauseFull = useHasFeatureFlag(
    FeatureFlagType.AutomationSmartClauseFull,
  );
  const hasAllowClauseRenaming = useHasFeatureFlag(
    FeatureFlagType.AllowClauseRenaming,
  );
  const enableQuickAiFlag = useFlag(FlagType.QuickAiFilters);
  const hasAdvancedSearch = useHasFeatureFlag(FeatureFlagType.SearchV2);
  const hasAdvancedSearchV3 = useFlag(FlagType.SearchV3);

  const CLAUSE_DEFAULT_STATUS_FILTER = [AutomationStatusType.ACTIVE];
  const CLAUSE_DEFAULT_TYPE_FILTER = hasAutomationSmartClauseFull
    ? [AutomationClauseType.QUICK_AI, AutomationClauseType.GUIDED_AI]
    : [AutomationClauseType.QUICK_AI];

  const usingQuickAiFilterOnly = clause?.typeFilter?.every(
    (f) => f === AutomationClauseType.QUICK_AI,
  );
  const usingAllTypeFilters = clause?.typeFilter?.length >= 2;
  const usingSingleStatusFilter = clause?.statusFilter?.length <= 1;

  const [showClauseLimit, setShowClauseLimit] = useState(true);
  const [renameClauseValue, setRenameClauseValue] = useState();

  useEffect(() => {
    resetAutomationGuidedAiDataForm();
  }, []);

  // cleanup and reset the automation state if new location path does not match a subroute of the feature
  useEffect(() => {
    const cleanup = () => {
      const isMatchAutomationHubDetail = RoutePathType.AutomationHubDetail.startsWith(
        location.pathname,
      );
      if (!isMatchAutomationHubDetail) {
        dispatch(resetAutomationStates());
      }
    };

    return cleanup;
  }, [location]);

  const {
    data: allClauses,
    isFetching: isFetchingAllClauses,
  } = api.endpoints.getClauses.useQuery({
    ...pagination,
    clauseType: hasAutomationSmartClauseFull
      ? ALL_TYPES
      : CLAUSE_DEFAULT_TYPE_FILTER,
    status: ALL_STATUSES,
  });

  const dataExists = !!allClauses && allClauses.count > 0;

  const { data, error, isFetching } = api.endpoints.getClauses.useQuery(
    {
      ...pagination,
      clauseType: hasAutomationSmartClauseFull
        ? clause?.typeFilter
        : CLAUSE_DEFAULT_TYPE_FILTER,
      status: clause?.statusFilter,
    },
    { skip: !dataExists },
  );

  const {
    data: sectionsAndFolders,
    isFetching: isFetchingSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const [
    renameClause,
    renameClauseResult,
  ] = api.endpoints.renameClause.useMutation();
  const {
    isError: isRenameClauseError,
    isSuccess: isRenameClauseSuccess,
    error: renameClauseError,
    originalArgs: renameClauseOriginalArgs,
  } = renameClauseResult;

  const choiceHasNoData = !!data && data.count <= 0;

  const clausesUsed = allClauses?.total;
  const clauseLimit = allClauses?.limit;
  const clausesLeft = clauseLimit - clausesUsed;
  const percentageUsed = clausesUsed / clauseLimit;
  const clauseVariant =
    percentageUsed >= 1 ? 'danger' : percentageUsed >= 0.8 ? 'warning' : 'info';

  const {
    users: resolvedUsers,
    isFetching: isResolvingUsers,
  } = useResolveUsers({
    userIds: data?.results?.map((clause) => clause.createdBy).flat(),
  });

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'Failed load Clauses. Please try again.');
    }
  }, [error]);

  useEffect(() => {
    if (isRenameClauseSuccess) {
      setRenameClauseValue('');
      showToast(
        SUCCESS,
        `"${renameClauseOriginalArgs.newName}" has been renamed.`,
      );
    }
    if (isRenameClauseError) {
      showToast(ERROR, 'Rename failed');
    }
  }, [isRenameClauseSuccess, isRenameClauseError]);

  const onSetFilter = (filterValues) => {
    const typeFilterValue = filterValues.find((e) => e.id === CLAUSE_TYPE_ID);

    let { values: typeFilter } = typeFilterValue ?? { values: [] };
    if (typeFilter.length <= 0) typeFilter = CLAUSE_DEFAULT_TYPE_FILTER;

    if (
      !hasAutomationSmartClauseFull ||
      typeFilter.every((f) => f === AutomationClauseType.QUICK_AI)
    ) {
      const statusFilterValue = filterValues.find(
        (e) => e.id === CLAUSE_STATUS_ID,
      );

      let { values: statusFilter } = statusFilterValue ?? { values: [] };
      if (statusFilter.length <= 0) statusFilter = CLAUSE_DEFAULT_STATUS_FILTER;

      setAutomationClause({ ...clause, typeFilter, statusFilter });
    } else {
      setAutomationClause({ ...clause, typeFilter, statusFilter: [] });
    }
  };

  const onClickClause = ({ name, status, clauseId, types }) => {
    setAutomationClause({ ...clause, name, status, types });

    let url = `/automation/detail?${QueryParamType.Clause}=${encodeURIComponent(
      name,
    )}`;

    if (hasAutomationSmartClauseFull && clauseId) {
      url = `${url}&${QueryParamType.clauseId}=${clauseId}`;
    }

    navigate(url);
  };

  const onCreateNewClause = () => {
    if (hasAutomationSmartClauseFull) {
      setAutomationManageStage(AutomationStageType.AutomationTypes);
    } else {
      setAutomationManageStage(AutomationStageType.QuickAiForm);
    }
  };

  const redirectToAnalyzerResults = ({ name }) => {
    const params = new URLSearchParams(
      buildClauseAnalyzerParams(name),
    ).toString();
    redirectToPage(subdomain, `analyzer/results?${params}`);
  };

  const redirectToAdvancedSearch = ({ name }) => {
    const values = [{ provision: name, text_search: [] }];
    const filters = [
      { id: uuid.v4(), fieldId: 'clause', operatorId: 'contains_any', values },
    ];
    const fields = sectionsAndFolders?.fields;
    if (hasAdvancedSearchV3) {
      dispatch(searchV3.actions.setSelectedFilters(filters));
    } else {
      dispatch(searchSlice.actions.setFilters(filters));
      dispatch(
        searchSlice.actions.setQuery({ booleanQuery: '', filters, fields }),
      );
    }
    navigate('/search');
  };

  const redirectToSearchResults = ({ name }) => {
    if (hasAdvancedSearch) {
      redirectToAdvancedSearch({ name });
    } else {
      redirectToAnalyzerResults({ name });
    }
  };
  const tooltip =
    'Displays the number of manually tagged clauses through Quick AI by users in your organization. This does not include clauses identified automatically by Evisort’s standard clause models.';

  const identificationsTitle = (
    <Layout alignItems="center" flexDirection="row" space={1}>
      <Text>Identifications</Text>
      <Icon icon="info" color="status-info" tooltip={tooltip} />
    </Layout>
  );

  const fields = {
    [CLAUSE_TYPE_ID]: {
      id: CLAUSE_TYPE_ID,
      label: 'Type',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      overrideOperatorLabels: {
        contains_any: 'includes',
      },
      settings: {
        __testEnableSelectAll: () => false,
        options: CLAUSE_TYPE_OPTIONS,
      },
    },
    [CLAUSE_STATUS_ID]: {
      id: CLAUSE_STATUS_ID,
      label: 'Status',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      overrideOperatorLabels: {
        contains_any: 'includes',
      },
      settings: {
        __testEnableSelectAll: () => false,
        options: CLAUSE_STATUS_OPTIONS,
      },
    },
  };

  const filters = compact([
    hasAutomationSmartClauseFull && {
      id: CLAUSE_TYPE_ID,
      fieldId: CLAUSE_TYPE_ID,
      operatorId: 'contains_any',
      values: clause?.typeFilter ?? [],
    },
    (!hasAutomationSmartClauseFull || usingQuickAiFilterOnly) && {
      id: CLAUSE_STATUS_ID,
      fieldId: CLAUSE_STATUS_ID,
      operatorId: 'contains_any',
      values: clause?.statusFilter ?? [],
    },
  ]);

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      title: 'Clause',
      minWidth: 'xl',
      align: 'left',
      disableSortBy: true,
      mapCellProps: ({ name, status, types, customModelId }) => ({
        text: name,
        shouldTruncate: true,
        onClick: () =>
          onClickClause({ name, status, types, clauseId: customModelId }),
      }),
    },
    {
      key: 'createdBy',
      title: 'Created by',
      cellType: 'users',
      minWidth: 'l',
      disableSortBy: true,
      mapCellProps: ({ createdBy }) => ({
        users: createdBy?.map((id) => resolvedUsers?.[id] ?? {}),
      }),
    },
    {
      key: 'lastIdentification',
      title: 'Last Identification',
      minWidth: 'l',
      disableSortBy: true,
      renderCell: ({ name }) => {
        const {
          data: d,
          isFetching: i,
        } = api.endpoints.getIdentifications.useQuery({ name });
        if (i) return <LoadingShimmer />;

        const date = getLatestDate(
          d?.bulkTaggingLastIdentification,
          d?.smartClauseLastIdentification,
        );

        return (
          <Text variant="s-dense">
            {date ? <DateText date={date} format="short" /> : '-'}
          </Text>
        );
      },
    },
    {
      key: 'totalIdentifications',
      title: identificationsTitle,
      minWidth: 'l',
      disableSortBy: true,
      align: 'right',
      renderCell: ({ name }) => {
        const {
          data: d,
          isFetching: i,
        } = api.endpoints.getIdentifications.useQuery({ name });
        if (i) return <LoadingShimmer />;

        const bulkTaggingTotalIdf = d?.bulkTaggingTotalIdentifications ?? 0;
        const smartClauseTotalIdf = d?.smartClauseTotalIdentifications ?? 0;

        return (
          <Button
            variant="action"
            text={bulkTaggingTotalIdf + smartClauseTotalIdf}
            disabled={bulkTaggingTotalIdf + smartClauseTotalIdf === 0}
            onClick={() => redirectToSearchResults({ name })}
            isLoading={isFetchingSections}
          />
        );
      },
    },
    ...(enableQuickAiFlag
      ? [
          {
            key: 'filters',
            title: 'Filters',
            disableSortBy: true,
            renderCell: ({ name }) => {
              const {
                data: d,
                isFetching: i,
              } = api.endpoints.getIdentifications.useQuery({ name });
              if (i) return <LoadingShimmer />;

              return <ModelScope readOnly scope={d?.filters ?? []} />;
            },
          },
        ]
      : []),
  ];

  const rowActions = hasAllowClauseRenaming
    ? [
        {
          label: 'Rename',
          onClick: (row) => {
            setRenameClauseValue(row?.name);
          },
        },
      ]
    : [];

  if (
    hasAutomationSmartClauseFull &&
    !isFetching &&
    dataExists &&
    choiceHasNoData
  ) {
    if (usingAllTypeFilters) {
      if (usingSingleStatusFilter) {
        showToast(
          SUCCESS,
          `Because you have no ${toLowercaseTrimmed(
            clause?.statusFilter?.[0] || 'active',
          )}
           clauses, we reset your filters to show you all clauses.`,
        );

        setAutomationClause({ ...clause, statusFilter: ALL_STATUSES });
      }
    } else {
      if (usingQuickAiFilterOnly) {
        if (usingSingleStatusFilter) {
          showToast(
            SUCCESS,
            `Because you have no ${toLowercaseTrimmed(
              clause?.statusFilter?.[0] || 'active',
            )}
             clauses, we reset your filters to show you all clauses.`,
          );

          setAutomationClause({ ...clause, statusFilter: ALL_STATUSES });
        } else {
          showToast(
            SUCCESS,
            'Because you have no Quick AI clauses, we reset your filters to show you all clauses.',
          );

          setAutomationClause({ ...clause, typeFilter: ALL_TYPES });
        }
      } else {
        showToast(
          SUCCESS,
          'Because you have no Guided AI clauses, we reset your filters to show you all clauses.',
        );

        setAutomationClause({ ...clause, typeFilter: ALL_TYPES });
      }
    }
  }

  if (
    !hasAutomationSmartClauseFull &&
    !isFetching &&
    dataExists &&
    choiceHasNoData
  ) {
    if (clause?.statusFilter?.length <= 1) {
      showToast(
        SUCCESS,
        `Because you have no ${toLowercaseTrimmed(
          clause?.statusFilter?.[0] || '',
        )}
         clauses, we reset your filters to show you all clauses.`,
      );

      setAutomationClause({ ...clause, statusFilter: ALL_STATUSES });
    }
  }

  // TODO: Should be moved to their its own component.
  const clauseContent = (
    <Layout direction="column" spacing={8} mt={8}>
      {!hideClauseAffordance && !isFetchingAllClauses && showClauseLimit && (
        <Alert
          enableIcon
          variant={clauseVariant}
          onDismiss={() => setShowClauseLimit(false)}
        >
          <Text variant="tiny">
            {clausesLeft > 0
              ? `You have ${clausesLeft} ${pluralize(
                  'Clause',
                  clausesLeft,
                )} left. `
              : "You've reached your limit! "}
            Your license allows you to create {clauseLimit} Clauses in total.
            Check out{' '}
            <a href={HELP_CENTER_LINK} target="_blank" rel="noreferrer">
              Help Center
            </a>{' '}
            for more info.
          </Text>
        </Alert>
      )}
      {/* eslint-disable-next-line react/jsx-pascal-case -- deprecating */}
      <Filters_DEPRECATED
        fields={fields}
        filters={filters}
        groups={[]}
        options={filtersOptions}
        onChange={onSetFilter}
      />
      <LoadingContainer
        isLoading={isFetching || isFetchingAllClauses || isResolvingUsers}
      >
        {!error &&
          (!!data ? (
            <Table
              name="automation-clauses"
              rowActions={rowActions}
              totalCount={data.count}
              columns={columns}
              data={data.results}
              state={pagination}
              onUpdate={setAutomationClausePagination}
              options={{
                enableManageColumns: false,
                enableExportXlsx: false,
                enableSelectRows: false,
              }}
            />
          ) : (
            <FlexLayout
              flexDirection="column"
              alignItems="center"
              mt={4}
              space={4}
            >
              <EmptyPage
                preset="new-clause"
                resolveAction={{
                  variant: 'primary',
                  icon: 'plus',
                  iconPosition: 'left',
                  text: 'New Clause',
                  onClick: onCreateNewClause,
                }}
              />
            </FlexLayout>
          ))}
      </LoadingContainer>
      <AutomationHubManagement />
      {!!renameClauseValue && (
        <RenameClauseModal
          value={renameClauseValue}
          error={renameClauseError?.response?.data?.error}
          onClose={setRenameClauseValue}
          onSubmit={renameClause}
        />
      )}
    </Layout>
  );

  const noClauseData = (
    <>
      <LoadingContainer isLoading={isFetchingAllClauses}>
        <FlexLayout flexDirection="column" alignItems="center" mt={4} space={4}>
          <EmptyPage
            preset="new-clause"
            resolveAction={{
              variant: 'primary',
              icon: 'plus',
              iconPosition: 'left',
              text: 'New Clause',
              onClick: onCreateNewClause,
            }}
          />
        </FlexLayout>
      </LoadingContainer>
      <AutomationHubManagement />
    </>
  );

  const actions = [
    ...(dataExists
      ? [
          {
            icon: 'plus',
            level: 'primary',
            text: 'New Clause',
            onClick: onCreateNewClause,
          },
        ]
      : []),
  ];

  return (
    <PageLayout actions={actions} title="Automation Hub">
      {dataExists ? clauseContent : noClauseData}
    </PageLayout>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  pagination: automation.clause.pagination,
});

export default connect(mapStateToProps, {
  resetAutomationGuidedAiDataForm: actions.resetAutomationGuidedAiDataForm,
  resetAutomationStates: actions.resetAutomationStates,
  setAutomationClause: actions.setAutomationClause,
  setAutomationManageStage: actions.setAutomationManageStage,
  setAutomationClausePagination: actions.setAutomationClausePagination,
})(Page);
