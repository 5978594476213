import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';

import BaseSearchBar from '~/components/Shared/BaseSearchBar';
import { Layout } from '~/eds';
import { api } from '~/redux';

type Props = {
  disabled?: boolean;
  isSubmitting: boolean;
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  onSubmit: (e: string) => void;
};

export const BooleanSearchBar = ({
  disabled,
  isSubmitting,
  placeholder,
  value,
  onChange = noop,
  onSubmit,
}: Props) => {
  const [search, setSearch] = useState('');

  const [
    validateBooleanQuery,
  ] = api.endpoints.validateBooleanQueryEvisearch.useLazyQuery();

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const validateInput = async (query: string) => {
    const response = await validateBooleanQuery(query).unwrap();
    return response?.detail ?? '';
  };

  return (
    <Layout spacing={3}>
      <BaseSearchBar
        placeholder={placeholder}
        disabled={disabled}
        value={search}
        onChange={(val: string) => {
          setSearch(val);
          onChange(val);
        }}
        onSubmit={() => onSubmit(search)}
        validateInput={validateInput}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};
