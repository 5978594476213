export enum FileMimeType {
  Csv = 'text/csv',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Html = 'text/html',
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpeg',
  Json = 'application/json',
  Md = 'text/markdown',
  Tif = 'image/tif',
  Tiff = 'image/tiff',
  Pdf = 'application/pdf',
  Png = 'image/png',
  Pptx = 'application/vnd.ms-powerpoint',
  Txt = 'text/plain',
  Webp = 'image/webp',
  Xls = 'application/vnd.ms-excel',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // TODO server should deprecate the unofficial mime type
  DocUnofficial = 'application/doc',
  DocxUnofficial = 'application/docx',
  JpegUnofficial = 'application/jpeg',
  JpgUnofficial = 'application/jpg',
  PngUnofficial = 'application/png',
  TifUnofficial = 'application/tif',
  TiffUnofficial = 'application/tiff',
}
