import React, { useEffect, useMemo, useState } from 'react';

import { groupFilterOptions } from '~/components/Shared/Filters/ManageFilters';
import { CheckboxGroups } from '~/eds';
import { FieldId } from '~/evifields';
import { ExportExcelField } from '~/features/advanced-search';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';

import { defaultSelectedOptions } from '../constants';

type Props = {
  fields?: ExportExcelField[];
  search?: string;
  selectedFieldIds: string[];
  value: Nullable<string[]>;
  onChange: (values: string[]) => void;
};

export const Fields = ({
  fields = [],
  search = '',
  selectedFieldIds,
  value,
  onChange,
}: Props) => {
  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );

  const groupsOrder = useMemo(
    () => sections?.fieldGroups.map((group) => group.id),
    [sections?.fieldGroups],
  );

  const groupedFieldOptions = groupFilterOptions({
    searchFilters: fields,
    search,
    groupsOrder: groupsOrder ?? [],
  });

  const [localValue, setValue] = useState<Nullable<Record<PilotId, FieldId[]>>>(
    {},
  );

  const fieldSections = useMemo(() => {
    // sections mapping
    return groupedFieldOptions?.map((fieldGroup) => {
      return {
        label: fieldGroup.title,
        name: fieldGroup.title,
        options: getOptionByFieldGroup(fields, fieldGroup.title),
      };
    });
  }, [sections, fields, selectedFieldIds, search]);

  useEffect(() => {
    if (fieldSections?.length) {
      const newValue: Record<string, string[]> = {};

      fieldSections
        .flatMap((fieldGroup) =>
          fieldGroup.options.map((option) => ({
            ...option,
            sectionName: fieldGroup.label,
          })),
        )
        .filter(
          (field) =>
            value?.includes(field.value) ||
            defaultSelectedOptions.includes(field.value),
        )
        .forEach((field) => {
          newValue[field.sectionName] = newValue[field.sectionName] || [];
          newValue[field.sectionName].push(field.value);
        });
      setValue(newValue);
    }
  }, [value, fieldSections]);

  /*
  In this case we can't avoid filtering the options ourselves since the select-all
  feature requires to know exactly which options are part of the current search
  */
  const filteredSections = useMemo(
    () =>
      fieldSections
        ?.map((fieldSection) => ({
          ...fieldSection,
          options: fieldSection.options.filter(
            (field) =>
              field.label.toLowerCase().indexOf(search.toLowerCase()) >= 0,
          ),
        }))
        .filter((fieldGroup) => fieldGroup.options.length > 0),
    [fieldSections, search],
  );

  return (
    <CheckboxGroups
      enableSelectedGroup
      columns={3}
      groups={filteredSections || []}
      value={localValue}
      onChange={(value) => onChange(Object.values(value || {}).flat())}
    />
  );
};

const getOptionByFieldGroup = (
  fields: ExportExcelField[],
  fieldGroupLabel: string,
) => {
  return fields
    .filter((field) => field.section === fieldGroupLabel)
    .map((field) => ({
      label: field.label,
      value: `${field.id}`,
      disabled: defaultSelectedOptions.includes(field.id),
    }));
};
