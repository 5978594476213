import React, { useMemo, useState } from 'react';
import uuid from 'uuid';

import { IconButton, Layout, StatusText } from '~/eds';
import { Filter } from '~/evifields';
import { useDrag } from '~/features/drag-and-drop';
import { SearchFilter } from '~/types';

import { Operator } from '../operator';
import { OperatorSelect } from '../operator-select';
import { SectionItem } from './section-item';

type Props = {
  index: number;
  filters: Filter[];
  searchFilters: SearchFilter[];
  operator: 'and' | 'or';
  onFilterChange: (index: number, filter: Filter) => void;
  onFilterRemove: (index: number, sectionIndex?: number) => void;
  onFilterAdd: (filter: Filter) => void;
  onOperatorChange: (value: 'and' | 'or') => void;
  onRemove: () => void;
};

export const Section = ({
  index: sectionIndex,
  filters,
  searchFilters,
  operator,
  onFilterChange,
  onFilterRemove,
  onOperatorChange,
  onFilterAdd,
  onRemove,
}: Props) => {
  const [isOver, setIsOver] = useState(false);
  const { draggedItem, handleDragEnd } = useDrag();

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOver(false);
    if (draggedItem?.id) {
      onFilterAdd({
        id: uuid.v4(),
        fieldId: draggedItem.id,
        operatorId: null,
        values: [],
      });
    }
  };

  const handleDropReplace = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOver(false);
    handleDragEnd();
    if (sectionIndex === draggedItem?.payload?.sectionIndex) return;

    if (draggedItem?.payload?.filter) {
      onFilterRemove(
        draggedItem.payload.index,
        draggedItem.payload.sectionIndex,
      );
      onFilterAdd(draggedItem.payload.filter);
    }
  };

  const getOperator = (index: number) => {
    if (filters.length === 1 || index === filters.length - 1) return null;

    return index === 0 ? (
      <OperatorSelect value={operator} onChange={onOperatorChange} />
    ) : (
      <Operator value={operator} />
    );
  };

  const renderFilter = (filter: Filter, index: number) => {
    const searchFilter = searchFilters.find((sf) => sf.id === filter.fieldId);
    const operator = getOperator(index);
    return (
      <Layout
        key={`${filter.fieldId}_${index}`}
        mb={0}
        maxW="100%"
        minW="0"
        spacing={1}
      >
        <SectionItem
          index={index}
          sectionIndex={sectionIndex}
          searchFilter={searchFilter!}
          filter={filter}
          onChange={(updatedFilter) => onFilterChange(index, updatedFilter)}
          onRemove={() => onFilterRemove(index)}
        />
        {operator}
      </Layout>
    );
  };

  const filtersContent = useMemo(() => filters.map(renderFilter), [
    filters,
    searchFilters,
    operator,
  ]);

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        setIsOver(true);
      }}
      onDragLeave={() => setIsOver(false)}
      onDrop={(e) =>
        draggedItem?.payload?.sectionIndex !== undefined
          ? handleDropReplace(e)
          : handleDrop(e)
      }
    >
      <Layout
        p={2}
        pb={0}
        border="border"
        borderRadius="m"
        justify="space-between"
        bg={isOver ? 'brand.light' : 'transparent'}
        style={{
          opacity: draggedItem && !isOver ? 0.5 : 1,
        }}
        spacing={2}
        direction="column"
      >
        <Layout justify="space-between" align="center">
          <StatusText
            text={
              filters.length === 0
                ? 'You can drag and drop filters to create and organize filter groups.'
                : 'Filter By'
            }
            icon="filter"
          />
          <Layout h="100%">
            <IconButton
              icon="x"
              tooltip="Remove Section"
              size="s"
              onClick={onRemove}
            />
          </Layout>
        </Layout>
        <Layout minW="0" align="center" preset="chips">
          {filtersContent}
        </Layout>
      </Layout>
    </div>
  );
};
