import { FieldModelConfigState } from '~/features/x-ray';
import { mapDataFieldTypeToOutputType } from '~/features/x-ray/fields/utils';
import { LibraryModelVersion } from '~/features/x-ray/library/types';
import { sylvanus } from '~/services';
import { Bucket, ChartType, Nullable, Uuid } from '~/types';

import {
  ApiLibraryModelVersion,
  InitialLibraryModelVersionCreate,
  NextLibraryModelVersionCreate,
} from '../../types/sylvanus/library';
import { buildFilterQuery } from '../searchV3';
import {
  mapFieldClassificationConfigAttributes,
  mapLibraryModelVersion,
  mapModelConfigAttributes,
} from './transformers';

interface RequestParams {
  changelog: string;
  config: FieldModelConfigState;
  description: string;
  libraryModelId: Nullable<Uuid>;
  sampleOutputs: { buckets?: Bucket[]; text?: string; type?: ChartType };
  tags: string[];
  updateType: 'major' | 'minor' | 'patch';
}

type Request = InitialLibraryModelVersionCreate | NextLibraryModelVersionCreate;

type Response = ApiLibraryModelVersion;

/** Create a new library model if no `libraryModelId` is provided,
 * otherwise create a new version for the existing library model. */
export const saveLibraryModel = async ({
  changelog,
  config,
  description,
  libraryModelId,
  sampleOutputs,
  tags,
  updateType,
}: RequestParams): Promise<LibraryModelVersion> => {
  const query = buildFilterQuery({
    booleanQuery: '',
    filters: config.filters,
  });

  if (!config.field) {
    throw new Error('Cannot save library model: Missing field.');
  }

  const configuration = {
    field_name: config.field.label,
    instructions: config.instructions,
    internal: config.internal,
    scope: {
      data: {
        attributes: {
          filters: [],
          query,
          store_as_recent_search: false,
        },
      },
      type: 'document',
    },
    ...mapModelConfigAttributes(config),
    ...mapFieldClassificationConfigAttributes(config),
  };

  const initialRequest = {
    changelog_entry: changelog,
    configuration,
    description,
    name: config.field.label,
    sample_outputs: {
      text: sampleOutputs.text,
      dashboard: sampleOutputs.type
        ? { buckets: sampleOutputs.buckets, type: sampleOutputs.type }
        : undefined,
    },
    target: {
      entity_type: 'field',
      value_type: mapDataFieldTypeToOutputType(config.field.type),
    },
    tags: tags.map((tag) => ({ tag_type: tag, tag_value: tag })),
  };

  const endpoint = libraryModelId
    ? `/library/model/${libraryModelId}/version`
    : '/library/model';

  const request: Request = libraryModelId
    ? ({
        ...initialRequest,
        update_type: updateType,
      } as NextLibraryModelVersionCreate)
    : (initialRequest as InitialLibraryModelVersionCreate);

  const response: Response = await sylvanus.post(endpoint, request);

  return mapLibraryModelVersion(response);
};
