import { FileExtensionType } from '~/eds';

type Section = {
  title: string;
  terms: {
    title: string;
    description: string;
    tooltip?: string;
  }[];
};

export const filterSections = (sections: Section[], search: string) => {
  const lowerCasedSearch = search.toLowerCase();
  return sections
    .map((section) => ({
      ...section,
      terms: section.terms.filter(
        (term) =>
          (term.title || '').toLowerCase().indexOf(lowerCasedSearch) >= 0,
      ),
    }))
    .filter((section) => section.terms.length > 0);
};

export const testUsesHtmlToken = (fileType?: FileExtensionType) =>
  fileType &&
  [FileExtensionType.Doc, FileExtensionType.Docx].includes(fileType);
