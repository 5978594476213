/*eslint import/namespace: ['error', { allowComputed: true }]*/
import { v4 } from 'uuid';

import * as apiServices from '~/services';
import { bytesToBlob } from '~/utils/files';

const { pilot } = apiServices;

export async function uploadAttachment(file) {
  const formData = new FormData();
  formData.append('file', file);
  return await pilot.post('/attachment/', formData);
}

// TODO: Switch these and uploadAtttachment(s) in api/tickets.js to not accept callbacks here
export const uploadAttachments = (files, callback, errorHandler) => {
  const promises = files.map((file) => uploadAttachment(file));
  const promisesResolved = promises.map((promise) =>
    promise.catch(() => {
      errorHandler(promise);
    }),
  );
  return pilot
    .concurrentRequests(promisesResolved)
    .then((data) => callback(data));
};

export async function downloadAttachment(attachmentId) {
  const config = { responseType: 'blob' };

  let original;
  try {
    original = await pilot.get(`/attachment/${attachmentId}`, config);
  } catch {
    original = null;
  }

  return bytesToBlob(original, original.type);
}

export async function downloadDocumentAttachment(
  documentHandlerId,
  attachmentId,
) {
  const config = { responseType: 'blob' };

  let original;
  try {
    original = await pilot.get(
      `document/${documentHandlerId}/attachment/${attachmentId}`,
      config,
    );
  } catch {
    original = null;
  }

  return bytesToBlob(original, original.type);
}

export function uploadFile({ service, method = 'post', url, payload, config }) {
  return apiServices[service][method](url, payload, config);
}

export const uploadNewVersion = async ({ documentId, file }) => {
  try {
    const payload = new FormData();
    payload.append('id', v4());
    payload.append('file', file);
    payload.append('filename', file.name);
    payload.append('size_in_bytes', file.size);

    return await pilot.post(`/version/document/${documentId}/`, payload);
  } catch {
    throw new Error('Failed to upload new version');
  }
};
