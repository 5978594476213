import React from 'react';
import { connect } from 'react-redux';

import {
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
} from '~/actions';
import { Box, Card, Layout, StatusText } from '~/eds';
import { TicketStageType } from '~/enums';

import SkipStageAccordion from '../../shared/SkipStageAccordion';

const Edit = ({
  // connected
  conditionRule,
  disabled,
  isSkipped,
  isWSSDesignated,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
}) => {
  const handleUpdateStageConditionRule = (updatedConditionRule) => {
    workflowSetStageConditionRule({
      conditionRule: updatedConditionRule,
      stage: TicketStageType.Edit,
    });
  };

  const handleToggleEnableStage = (updatedIsSkipped) => {
    workflowToggleSkipStage({
      isSkipped: updatedIsSkipped,
      stage: TicketStageType.Edit,
    });
  };

  return (
    <>
      <Layout direction={'column'} spacing={3}>
        {isWSSDesignated && (
          <Card mode={'bordered'} size={'s'}>
            <Layout flex="auto" spacing={3}>
              <StatusText
                variant="xs-dense"
                icon={'info'}
                text={
                  'Strategic Sourcing workflows must include the Edit stage.'
                }
                status={'info'}
                iconSpacing={'s'}
              ></StatusText>
            </Layout>
          </Card>
        )}
        <Box disabled={isWSSDesignated || disabled}>
          <SkipStageAccordion
            conditionRule={conditionRule}
            isSkipped={isSkipped}
            onChange={handleUpdateStageConditionRule}
            onToggle={handleToggleEnableStage}
          />
        </Box>
      </Layout>
    </>
  );
};

const mapStateToProps = ({ builder, workflow }) => {
  const stage = workflow.stages[TicketStageType.Edit];
  return {
    disabled: !builder.shouldEnableAllComponents,
    conditionRule: stage.conditionRule,
    isSkipped: !stage.isEnabled,
    isWSSDesignated: workflow.wssDesignated,
  };
};

export default connect(mapStateToProps, {
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
})(Edit);
