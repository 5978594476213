import { isEmpty } from 'lodash';

import { Layout, Text } from '~/eds';

import { Citation, Message } from '../../../types';
import { Citations } from './Citations';
import { Sources } from './Sources';

interface Props {
  message: Message;
  onSelectSource?: (message: Message, sourceIndex: number) => void;
  onSelectCitation: (citation: Citation) => void;
}
export const SourcesList = ({
  message,
  onSelectCitation,
  onSelectSource,
}: Props) => {
  const { sources: originalSources = [], citations } = message;

  if (!originalSources.length && (!citations || isEmpty(citations)))
    return null;

  return (
    <Layout direction="column" spacing={1}>
      <Text preset="help">Sources </Text>
      {!!originalSources.length ? (
        <Sources message={message} onSelectSource={onSelectSource} />
      ) : (
        <Citations message={message} onSelectCitation={onSelectCitation} />
      )}
    </Layout>
  );
};
