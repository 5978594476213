import React from 'react';

import { DateText, Tooltip } from '../../../components';
import { DateFormat } from '../../../types';

interface Props {
  /** JS Date */
  datetime?: Date;
  /** Date format */
  format?: DateFormat;
  /** Tooltip text */
  tooltip?: string;
}

export const DatetimeCell = ({ datetime, format, tooltip }: Props) => {
  return (
    <Tooltip tooltip={tooltip}>
      <DateText date={datetime} format={format} />
    </Tooltip>
  );
};
