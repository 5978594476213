import { highlight as highlightVariants } from '../variants/highlight';
import { colors } from './colors';

type HighlightVariants = keyof typeof highlightVariants;

export const highlight = ({
  variant = 'default',
  isActive,
}: {
  variant?: HighlightVariants;
  isActive?: boolean;
}) => {
  const colorStyles = highlightVariants[variant];

  return {
    ...colorStyles,
    ...(isActive === false ? colors.alphaGradient('lighten-50') : {}),
  };
};
