import React, { useMemo } from 'react';

import { Layout, LoadingSpinner } from '~/eds';
import {
  DateIntervalChartData,
  NumberIntervalChartData,
} from '~/features/advanced-search';
import { coerceRtkqError } from '~/redux';
import { Dashboard } from '~/redux/api/methods';
import { Chart, CrossFilter, Nullable } from '~/types';

import { BarChart } from './BarChart';
import { ChartContainer } from './ChartContainer';
import { MAX_BUCKET_LENGTH } from './constants';
import { DonutChart } from './DonutChart';
import { EmptyChart } from './EmptyChart';
import {
  formatAge,
  formatBoolean,
  formatDate,
  formatNumber,
  formatTitleCase,
} from './FilterDataChart.utils';
import { LineChart } from './LineChart';
import { useGetChartData } from './useGetChartData';

interface DataChartProps {
  chart: Chart;
  query: any[];
  selectedBucket?: CrossFilter;
  selectedInterval?: string;
  onBucketSelected?: (bucket: CrossFilter) => void;
  entity?: Dashboard['entity'];
}

export const FilterDataChart = ({
  chart,
  query,
  selectedBucket,
  selectedInterval,
  onBucketSelected,
  entity,
}: DataChartProps) => {
  const { data, error, isError, isFetching } = useGetChartData({
    id: chart.id,
    filterType: chart.filterType,
    query,
    entity,
    interval: selectedInterval,
  });

  const buckets = useMemo(() => {
    if (data && chart.filterType) {
      switch (chart.filterType) {
        case 'age':
          return data?.buckets.map((bucket) =>
            formatAge(bucket, data.meta?.interval_data?.interval),
          );
        case 'boolean':
          return data?.buckets.map(formatBoolean);
        case 'date':
          const {
            interval,
            multiple,
          } = (data as DateIntervalChartData).meta.interval_data!;
          return data?.buckets.map((bucketItem) =>
            formatDate(bucketItem, interval, multiple, selectedInterval),
          );
        case 'enum':
        case 'enum_set':
          return data?.buckets.map(formatTitleCase);
        case 'number':
          return data?.buckets.map((bucketItem) =>
            formatNumber(
              bucketItem,
              (data as NumberIntervalChartData).meta?.interval_data?.interval,
            ),
          );
        default:
          return data?.buckets;
      }
    }
  }, [data, chart.filterType, selectedInterval]);

  if (isFetching) {
    return (
      <Layout justify="center" align="center" h="300px">
        <LoadingSpinner />
      </Layout>
    );
  }

  const renderChartComponent = ({ width }: { width: number }) => {
    const isDataOverload = (buckets?.length || 0) > MAX_BUCKET_LENGTH;
    if (!buckets?.length || isError || isDataOverload) {
      return (
        <EmptyChart
          errorCode={coerceRtkqError(error)?.response?.status}
          chart={chart}
          isError={isError}
          width={width}
          isDataOverload={isDataOverload}
        />
      );
    }

    switch (chart.type) {
      case 'donut':
        return (
          <DonutChart
            buckets={buckets}
            width={width}
            selectedBucket={selectedBucket?.value}
            onBucketSelected={onBucketSelect}
          />
        );

      case 'vertical_bar':
        return (
          <BarChart
            selectedBucket={selectedBucket?.value}
            onBucketSelected={onBucketSelect}
            orientation="vertical"
            buckets={buckets}
            width={width}
          />
        );

      case 'line':
        return (
          <LineChart
            selectedBucket={selectedBucket?.value}
            onBucketSelected={onBucketSelect}
            buckets={buckets}
            width={width}
          />
        );

      default:
        return (
          <BarChart
            selectedBucket={selectedBucket?.value}
            onBucketSelected={onBucketSelect}
            buckets={buckets}
            width={width}
          />
        );
    }
  };

  function onBucketSelect(value: Nullable<string>) {
    if (chart.filterType) {
      onBucketSelected?.({ fieldId: chart.id, value, type: chart.filterType });
    }
  }

  return (
    <Layout direction="column" bg="#fff" overflowX="hidden" overflowY="hidden">
      <ChartContainer renderChart={renderChartComponent} />
    </Layout>
  );
};
