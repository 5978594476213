import React from 'react';

import { ContentContainer, formatNumber, Info } from '~/eds';
import { useClientId } from '~/hooks';
import { api } from '~/redux';

import { usePollModelVersionProgress } from './hooks/usePollModelVersionProgress';
import { ModelScope } from './ModelScope';
import { testIsPublishingStatus } from './utils';

interface Props {
  modelId: string;
  version: number;
  enableTitle?: boolean;
}

export const ModelVersionInfo = ({ enableTitle, modelId, version }: Props) => {
  const clientId = useClientId();

  const {
    modelVersion,
    modelProgress,
    isErrorModelVersion,
    isLoadingModelProgress,
    isLoadingModelVersion,
  } = usePollModelVersionProgress({
    modelId,
    version,
  });

  const {
    data: createdByUser,
    isFetching: isLoadingCreatedByUser,
  } = api.endpoints.resolveUser.useQuery(
    {
      // @ts-expect-error -- rtkq query is skipped correctly
      id: modelVersion?.createdBy,
      params: { clientId },
    },
    {
      skip: !modelVersion,
    },
  );

  const { docsInScope, docsProcessed } = modelProgress;

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isLoadingModelVersion,
        message: 'Loading model details…',
      }}
      placeholderContent={
        isErrorModelVersion
          ? {
              message: 'Something went wrong, please re-open the details panel',
            }
          : undefined
      }
    >
      {modelVersion && (
        <Info
          sections={[
            {
              title: enableTitle ? 'General Information' : '',
              infos: [
                {
                  title: 'Percentage Completed',
                  type: 'progress' as const,
                  props: {
                    isLoading:
                      isLoadingModelProgress ||
                      testIsPublishingStatus(modelProgress.publishStatus),
                    max: docsInScope,
                    name: 'publish progress',
                    status: 'active',
                    statsLabel: {
                      formatLabel: (value: number, max: number) =>
                        `${formatNumber(value / max, {
                          decimal: 1,
                          style: 'percent',
                        })} (${formatNumber(value)} of ${formatNumber(
                          max,
                        )} Documents)`,
                      position: 'bottom',
                    },
                    value: docsProcessed,
                  },
                },
                {
                  title: 'Scope',
                  type: 'content' as const,
                  props: {
                    children: (
                      <ModelScope readOnly scope={modelVersion.scope} />
                    ),
                  },
                },
                {
                  title: 'Documents in Scope',
                  type: 'number' as const,
                  props: {
                    number: docsInScope,
                  },
                },
                {
                  title: 'User activity',
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Modified',
                    date: modelVersion.createdDate,
                    user: createdByUser!,
                    format: 'full',
                  },
                  isLoading: isLoadingCreatedByUser,
                },
                {
                  title: 'Instructions for the Model',
                  type: 'textarea' as const,
                  props: {
                    readOnly: true,
                    name: 'instructions',
                    value: modelVersion.config.instructions ?? '',
                  },
                },
              ],
            },
          ]}
        />
      )}
    </ContentContainer>
  );
};
