import { trimStart } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { getInternalFolders } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import {
  DateInput,
  Divider,
  FileInput,
  Files,
  FormField,
  HtmlEntityType,
  Layout,
  Modal,
  Select,
  Text,
} from '~/eds';
import {
  AribaIntegrationType,
  FileExtensionType,
  FileMimeType,
  ManageSyncPairStageType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { api } from '~/redux';
import { ERROR } from '~/types/toast.types';
import { FolderInput } from '~/ui';

import { DEFAULT_FILE_TYPES } from '../../constants';
import AribaFooter from '../AribaFooter';
import AribaMappingSetup from './AribaMappingSetup';

function FolderAriba({
  // connected,
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { provider, allowedSyncFileTypes, syncPair, syncType } = activeSyncPair;
  const stageProperties = activeSyncPair[manageSyncPairStage] ?? {};
  const { evisortFolder } = stageProperties;
  const docIdsFile = stageProperties['docIdsFile'] ?? [];
  const processableDocumentTypes = useFlag(FlagType.ProcessableDocumentTypes);
  const [sinceDate, setSinceDate] = useState(null);
  const [count, setCount] = useState(null);
  const [localDocFile, setLocalDocFile] = useState(null);

  const [getAribaCounts] = api.endpoints.getAribaWorkspaceCount.useLazyQuery();
  const [
    isValidationAribaDocumentIdFileVisible,
    setIsValidationAribaDocumentIdFileVisible,
  ] = React.useState(false);

  const [
    validateAribaDocumentIdFile,
  ] = api.endpoints.validateAribaDocumentIdFile.useMutation();

  const getAribaCount = async (selectedDate) => {
    const updatedValues = {
      ...stageProperties,
      sinceDate: selectedDate,
    };
    setSinceDate(selectedDate);
    try {
      const response = await getAribaCounts({
        sinceDate: selectedDate,
        syncPair,
      });
      const cwCount = response.data.workspaces?.totalRecords;
      setCount(cwCount);
      updatedValues.cwCount = cwCount;
    } catch (e) {
      showToast(
        ERROR,
        'Something went wrong getting the number of Ariba workspaces',
      );
    } finally {
      integrationsSetActiveSyncPair({
        ...activeSyncPair,
        [manageSyncPairStage]: updatedValues,
      });
    }
  };

  const { isLoading: isLoadingFolders, response: folders = [] } = useAsync(
    getInternalFolders,
    {},
    {
      condition: true,
      errorToastMessage:
        'There is an error fetching Evisort folders, please try refreshing the page.',
    },
  );

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FileConfig);
  };

  const handleCreateNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FieldMapping);
  };

  const setFolder = (evisortFolder) => {
    const updatedValues = {
      ...stageProperties,
      evisortFolder,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  const setSyncPairDocFile = (docIdsFile) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: {
        ...stageProperties,
        docIdsFile,
      },
    });
  };

  const onFileChangeHandler = (docIdsFile) => {
    if (!docIdsFile) {
      return;
    }

    setLocalDocFile(docIdsFile);

    validateAribaDocumentIdFile({ file: docIdsFile[0] })
      .unwrap()
      .then(() => {
        // If the file is valid, update the activeSyncPair with the new file
        setSyncPairDocFile(docIdsFile);
      })
      .catch((error) => {
        const message = error.response?.data?.error;
        if (message.toLowerCase().includes('primary document')) {
          setIsValidationAribaDocumentIdFileVisible(true);
        }
      });
  };

  const selectedFileRendered = docIdsFile.length > 0 && (
    <Files
      title="Uploaded file"
      files={[docIdsFile[0]]}
      onRemoveFile={() => setSyncPairDocFile([])}
    />
  );

  const fileTypeOptions = allowedSyncFileTypes.map((fileExtensionType) => {
    const option = {
      value: fileExtensionType,
      label: trimStart(fileExtensionType, '.'),
    };
    if (DEFAULT_FILE_TYPES.includes(fileExtensionType)) {
      option.isDefault = true;
    }
    return option;
  });

  const addOption = (value) => {
    return value;
  };

  const setAllowedSyncFileTypes = (types) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      allowedSyncFileTypes: types.map((type) => '.' + trimStart(type, '.')),
    });
  };

  const handleWarningCancel = () => {
    setIsValidationAribaDocumentIdFileVisible(false);
    setLocalDocFile([]);
  };

  return syncType === AribaIntegrationType.CONTINUOUS ? (
    <AribaMappingSetup />
  ) : (
    <Layout align="center" direction="column" spacing={8}>
      <Layout align="center" py={2} spacing={6}>
        <ProviderLogo provider="evisort" />
        <Text color="text.quiet">{HtmlEntityType.DoubleDash}</Text>
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout direction="column" spacing={4} pb={4}>
        {syncType !== AribaIntegrationType.CONTINUOUS ? (
          <FormField
            required
            label="Upload Ariba Document IDs to Sync"
            description="Upload a .csv file with Ariba document IDs you would like to sync"
            input={FileInput}
            value={docIdsFile}
            onChange={onFileChangeHandler}
            inputProps={{
              accept: {
                [FileMimeType.Csv]: [FileExtensionType.Csv],
              },
            }}
          />
        ) : (
          <FormField
            label="Add a timeframe to search for contract workspace"
            description="Apply the modified date."
            input={DateInput}
            statusMessage={
              count > 0
                ? {
                    message: `We found **${count}** workspaces that can be synced based on the modified date. If a contract workspace doesn't contain any eligible documents, we will skip it to avoid creating an empty folder in Evisort.`,
                  }
                : undefined
            }
            value={sinceDate}
            onChange={getAribaCount}
          />
        )}
        {selectedFileRendered}
        <FormField
          required
          label="Select an Evisort Folder"
          description="This is the destination folder"
          input={FolderInput}
          value={evisortFolder}
          onChange={setFolder}
          isLoading={isLoadingFolders}
          inputProps={{
            modalTitle: 'Select an Evisort folder',
            folder: { ...evisortFolder },
            folderTreeProps: {
              label: 'Select...',
              folders: folders,
            },
          }}
        />
        <FormField
          required
          onChange={setAllowedSyncFileTypes}
          input={Select}
          description={
            <span>
              Selected file types will show in your Evisort folders, but only{' '}
              <strong>
                {Array.isArray(processableDocumentTypes) &&
                processableDocumentTypes.length > 0
                  ? processableDocumentTypes.join(', ') + ' '
                  : '.pdf, .docx, .doc '}
              </strong>
              will be processed by Evisort’s AI.
            </span>
          }
          label="Synchronize the following file types"
          value={allowedSyncFileTypes}
          inputProps={{
            enableErrorMessage: true,
            error:
              allowedSyncFileTypes.length === 0
                ? 'You have not added any file types. Please include at least one file type'
                : null,
            isMulti: true,
            noOptionsMessage:
              'Enter an extension for a file type you want to add',
            placeholder: 'Add a file extension',
            width: 'fullWidth',
            options: fileTypeOptions,
            onAddOption: addOption,
          }}
        />
      </Layout>
      <AribaFooter
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
        mainAction={{
          text: 'Next',
          onClick: handleCreateNext,
          isLoading: isLoadingFolders,
          disabled:
            !evisortFolder ||
            docIdsFile.length === 0 ||
            allowedSyncFileTypes.length === 0,
        }}
      />
      <Modal
        title="Warning: Missing Primary Document Designation"
        cancelText={''}
        isVisible={isValidationAribaDocumentIdFileVisible}
        onHide={handleWarningCancel}
        primaryAction={{
          text: 'Yes',
          onClick: () => {
            setIsValidationAribaDocumentIdFileVisible(false);
            setSyncPairDocFile(localDocFile);
          },
        }}
        secondaryActions={[
          {
            text: 'No',
            variant: 'danger',
            onClick: handleWarningCancel,
          },
        ]}
      >
        We did not find a Primary Document designation, which is necessary to
        send Evisort data back to Ariba. Continuing without a Primary Document
        designation will not impact your ability to sync files and data into
        Evisort.
        <br />
        <br />
        Do you want to continue?
      </Modal>
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(FolderAriba);
