import { fetchUser } from '~/redux/api/methods';
import { extractResourceAttributes } from '~/redux/api/transformers';

export const getUser = async () => {
  const response = await fetchUser(null);
  const extractedUsers = extractResourceAttributes(response);
  return extractedUsers.results[0];
};

export const getIsExternalUser = async () => {
  const response = await fetchUser(null);
  const extractedUsers = extractResourceAttributes(response);
  return extractedUsers.results[0].isExternal;
};
