export enum CurrencyType {
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Cny = 'CNY',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Thb = 'THB',
  Usd = 'USD',
  Vnd = 'VND',
  Zar = 'ZAR',
}
