import { sylvanus } from '~/services';

import { PromptModelSummary } from '../../types/sylvanus/model';

type Response = PromptModelSummary;

export const getModelSummary = async () => {
  const response: Response = await sylvanus.get(`/models/summary`);

  return response;
};
