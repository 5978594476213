import React, { useState } from 'react';
import { useTheme } from 'uinix-ui';

import { Layout, Select, types, useToggle } from '~/eds';
import { FilterViewType } from '~/evifields';
import { useMaxHeightBasedOnDistance, useTrackSegment } from '~/hooks';
import { Nullable } from '~/types';

import { AsyncSelectComponent } from '../AsyncSelectComponent';
import { TextDelimitedEnumSetValue } from '../subcomponents/TextDelimitedEnumSetValue';
import { AsyncValue, EnumValue, Field, Filter } from '../types';
import { testEnableSearch } from '../utils';

interface Props {
  field: Field<'enum_set'>;
  filter: Filter<EnumValue>;
  onChange: (
    updatedValues: EnumValue[],
    updatedAsyncValue?: AsyncValue<EnumValue>,
  ) => void;
  onViewChange?: (filterView: types.Nullable<FilterViewType>) => void;
}

export const EnumSetValues = ({
  field,
  filter,
  onChange,
  onViewChange,
}: Props) => {
  const theme = useTheme();
  const { render, settings, id: fieldId } = field;
  const { options, __testEnableSelectAll } = settings;
  const { asyncValue, id, values, filterView } = filter;

  const [isMultiselect, _toggle, showMultiselect, showCopyPaste] = useToggle(
    !onViewChange || !filterView || filterView !== 'text-delimited',
  );
  const [selectAllCount, setSelectAllCount] = useState<string | undefined>();

  const maxHUpperLimit = theme.sizes['select.menu.max-height'] as number;
  const [containerRef, maxH] = useMaxHeightBasedOnDistance<HTMLDivElement>({
    currentMaxHeight: maxHUpperLimit,
  });

  const isAsync = Boolean(render);
  const enableSearch = testEnableSearch(isAsync, options?.length || 0);
  const enableSelectAll = __testEnableSelectAll?.(filter);
  const trackSegment = useTrackSegment();
  const handleChange = (
    updatedValues: Nullable<EnumValue[]>,
    updatedAsyncValue?: AsyncValue<EnumValue>,
  ) => {
    if (updatedAsyncValue) {
      if (updatedAsyncValue.isAllSelected) {
        updatedAsyncValue.selectAllLabel = selectAllCount;
      }
      updatedAsyncValue.isAllSelected = !!(
        enableSelectAll && updatedAsyncValue.isAllSelected
      );
    }
    onChange(updatedValues ?? [], updatedAsyncValue);
  };

  const shouldHaveTextDelimitedView =
    onViewChange && !(fieldId === 'folder' || fieldId === 'document_group_id');

  const onCopyPasteClick = () => {
    onViewChange?.('text-delimited');
    showCopyPaste();
    trackSegment('textDelimitedFilterClicked', { fieldId });
  };

  const onBackClick = () => {
    onViewChange?.(null);
    showMultiselect();
  };

  const innerProps = {
    asyncValue,
    enableControl: enableSearch,
    enableSearchIcon: true,
    enableSelectAll,
    enableSorting: enableSearch,
    isClearable: false,
    isEmbedded: true,
    isMulti: true,
    name: id,
    value: values,
    onChange: handleChange,
    onChangeSelectAll: (value?: string) => {
      setSelectAllCount(value);
    },
    footerAction: shouldHaveTextDelimitedView
      ? {
          level: 'action' as const,
          text: 'Copy and Paste Values',
          onClick: onCopyPasteClick,
        }
      : undefined,
    menuMaxHeight: maxH,
  };

  const MultiSelect = (
    <div ref={containerRef}>
      <Layout mt={enableSearch ? undefined : -4} mx={-4}>
        {render?.({
          Component: AsyncSelectComponent,
          innerProps,
        }) || <Select {...innerProps} isMulti options={options} />}
      </Layout>
    </div>
  );

  const content = isMultiselect ? (
    MultiSelect
  ) : (
    <TextDelimitedEnumSetValue
      fieldId={fieldId}
      values={values}
      onChange={onChange}
      onBackClick={onBackClick}
    />
  );

  return content;
};
