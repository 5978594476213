import { EntityType } from '~/enums';
import { FieldModel, FieldModelConfigState } from '~/features/x-ray/fields';
import { sylvanus } from '~/services';
import { Entity, Nullable, Uuid } from '~/types';

import { PromptModel, PromptModelConfig } from '../../types/sylvanus/model';
import { buildFilterQuery, getFilters } from '../searchV3';
import { createPromptModelTestCase } from './createPromptModelTestCase';
import { createPromptModelVersion } from './createPromptModelVersion';
import {
  mapFieldClassificationConfigAttributes,
  mapModelConfigAttributes,
  mapModelVersionToFieldModel,
} from './transformers';

interface Request {
  entity: Entity<EntityType>;
}

type Response = PromptModel;

const createPromptModel = async ({ entity }: Request): Promise<Uuid> => {
  const response = (await sylvanus.post('/model', {
    target_entity: entity,
  })) as Response;

  return response.id;
};

export const savePromptModelDraft = async ({
  modelId,
  config,
}: {
  modelId: Nullable<Uuid>;
  config: FieldModelConfigState;
}): Promise<FieldModel> => {
  const searchFilterRequest = getFilters();
  const { field, filters, instructions, internal } = config;
  if (!field) {
    throw new Error('Cannot save model draft: Missing field.');
  }

  if (!instructions) {
    throw new Error('Cannot save model draft: Missing instructions.');
  }

  if (!modelId) {
    modelId = await createPromptModel({
      entity: {
        id: String(field.id),
        type: EntityType.Field,
      },
    });
  }

  const query = buildFilterQuery({
    booleanQuery: '',
    filters,
  });

  const configParams = {
    field_name: field.label,
    instructions,
    internal,
    scope: {
      data: {
        attributes: {
          filters: [],
          query,
          store_as_recent_search: false,
        },
      },
      type: 'document',
    },
    string_matching_level: config.stringMatchingLevel,
    ...mapModelConfigAttributes(config),
    ...mapFieldClassificationConfigAttributes(config),
  };

  const response = await createPromptModelVersion({
    modelId,
    config: configParams as PromptModelConfig,
  });

  const searchFilters = await searchFilterRequest;

  const modelVersion = mapModelVersionToFieldModel({
    modelId,
    version: response,
    fieldId: field.id,
    searchFilters,
    optimizeState: null,
    publishStatus: null,
  });

  try {
    if (modelVersion.testCases.length === 0) {
      const newTestCase = await createPromptModelTestCase({
        modelId,
        version: response.version_number,
      });
      // Test case execution to be handled by consumer
      modelVersion.testCases.push(newTestCase);
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.detail, { cause: modelId });
    } else {
      throw error;
    }
  }

  return modelVersion;
};
