import { types } from '~/eds';
import { FileExtensionType } from '~/enums';

// Restricted to EDS IconType set

export const getFileIconNameByFileType = (
  type: FileExtensionType,
): types.IconType => {
  switch (type) {
    case FileExtensionType.Docx:
    case FileExtensionType.Doc:
      return 'file-doc';
    case FileExtensionType.Jpeg:
    case FileExtensionType.Jpg:
    case FileExtensionType.Png:
    case FileExtensionType.Tif:
    case FileExtensionType.Tiff:
    case FileExtensionType.Webp:
      return 'file-image';
    case FileExtensionType.Pdf:
      return 'file-pdf';
    case FileExtensionType.Xlsx:
    case FileExtensionType.Xls:
    case FileExtensionType.Csv:
      return 'file-spreadsheet';
    default:
      return 'file';
  }
};
