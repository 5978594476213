import { saveAs } from 'file-saver';
import { get, keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchableFields } from '~/api/dataFields';
import { canDownloadSearchResultItem } from '~/components/AnalyzerResultsPage/SearchResultsItem/utils';
import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import { DocumentDetailsPanel } from '~/components/Shared/DocumentDetailsPanel';
import { showToast } from '~/components/Shared/EcToast';
import ExportExcelLimitWarning from '~/components/Shared/ExportExcelLimitWarning';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import SearchLimitWarning from '~/components/Shared/SearchLimitWarning';
import SearchReIndexInProgressWarning from '~/components/Shared/SearchReIndexInProgressWarning';
import { SEARCH_RESULTS_LIMIT } from '~/constants/max_lengths';
import { NAV_AND_SUBNAV_HEIGHT } from '~/constants/page';
import {
  EXPORT_COMPLETED_TEXT,
  EXPORT_DEFAULT_TEXT,
  EXPORT_DUPLICATED_TEXT,
} from '~/constants/search';
import { Layout } from '~/eds';
import {
  DataFieldType,
  FeatureFlagType,
  TableContextType,
  TableViewContextType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import {
  useClientSubDomain,
  useHasFeatureFlag,
  useTableSettings,
} from '~/hooks';
import { api, selectors } from '~/redux';
import {
  mapFolderFilterIdToFieldId,
  MORE_FILTERS_SECTION_ID,
} from '~/redux/api/transformers';
import search from '~/redux/slices/search';
import { ERROR, WARNING } from '~/types/toast.types';
import { Box, FlexLayout, LoadingContainer, Text } from '~/ui';
import { openInNewTab } from '~/utils/browser';
import {
  testContainsGhostSearchResult,
  testIsAnyDocumentNotMovable,
  testIsGhostSearchResultDocument,
} from '~/utils/document.utils';
import { getMaximumByLimit } from '~/utils/number';
import { FolderValue, HandlerName, renderCellValue } from '~/utils/table';

import DetailsPanel from '../DetailsPanel';
import { default as LoadView } from '../LoadView';
import { default as SaveView } from '../SaveView';
import { getSearchableFields } from '../utils';

const getGroupName = (documentGroups = [], groupId) => {
  return documentGroups.find((group) => group.groupId === groupId)?.name;
};

const _SearchResult = ({
  data,
  error,
  isLoading,
  onPaginate,
  tableSelection,
  page,
  pageSize,
  onViewResult,
  onAddToGroup,
  onCopy,
  onMove,
  onEdit,
  onEditClauses,
  onDelete,
  onExport,
  onSaveView,
  onLoadView,
  onNavigate,
  isExporting,
  resultsCache = {},
  sortBy,
  onSort,
  onPageSizeChange,
}) => {
  const dispatch = useDispatch();
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);

  const isUnifiedDocDetaisPanelEnabled = useFlag(
    FlagType.UnifiedDocumentDetailsPanel,
  );
  const isBulkActionOnSidePanelEnabled = useFlag(
    FlagType.DocumentsBulkActionsOnSidepanel,
  );

  const hardcodedColumns = [
    {
      key: 'name',
      title: 'Name',
      minWidth: 'm',
      renderCell: (document) => {
        const handlers = getHandlersKeyFromSearchResult(document);
        return (
          <HandlerName
            onNavigate={onNavigate}
            handlers={handlers}
            onClick={() => onViewResult(document, false)}
          />
        );
      },
    },
  ];

  const hardcodedColumnsMapByKey = keyBy(hardcodedColumns, 'key');
  const hardcodedColumnsMapByTitle = keyBy(hardcodedColumns, 'title');

  const featuredClauses = useMemo(() => {
    return pilotSearchQuery
      ?.filter(
        (queryItem) =>
          queryItem.provision && queryItem.contains.value === 'contains',
      )
      .map((queryItem) => queryItem.provision);
  }, [pilotSearchQuery]);

  const wasNotHardcodedAlready = (field) =>
    !hardcodedColumnsMapByKey[field.id] &&
    !hardcodedColumnsMapByTitle[field.name];

  const {
    data: documentGroupsData,
    isFetching: isFetchingDocumentGroups,
  } = api.endpoints.getAllDocumentGroups.useQuery(undefined);

  const client = useClientSubDomain();

  const {
    data: currentTableViewSet,
  } = api.endpoints.getCurrentTableView.useQuery({
    context: TableViewContextType.SEARCH,
  });

  const {
    data: filterSections,
    isSuccess: isSuccessFilterSections,
    isFetching: isFetchingFilterSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const [
    getDocumentOriginal,
  ] = api.endpoints.getDocumentOriginal.useLazyQuery();

  const fieldToColumn = (field) => {
    if (field.id === 'document_group_id') {
      return {
        key: SearchableFields.DocumentGroup,
        title: 'Document Group',
        cellType: 'link',
        minWidth: 'm',
        disableSortBy: true,
        mapCellProps: (document) => ({
          icon: 'groups',
          pathname: `/document-group/${document.document_group_id}`,
          text: getGroupName(
            documentGroupsData?.results,
            document.document_group_id,
          ),
        }),
        section: MORE_FILTERS_SECTION_ID,
      };
    } else if (field.id === 'folder_id') {
      return {
        key: SearchableFields.Folder,
        title: 'Folder',
        minWidth: 'm',
        disableSortBy: true,
        renderCell: (document) => {
          return (
            <FolderValue handlers={getHandlersKeyFromSearchResult(document)} />
          );
        },
        section: MORE_FILTERS_SECTION_ID,
      };
    }
    const unsortableTypes = [DataFieldType.STRING, DataFieldType.TEXT_AREA];
    return {
      key: String(field.id),
      field,
      title: field.label,
      disableSortBy:
        unsortableTypes.includes(field.type) ||
        unsortableTypes.includes(field.edit_type),
      mapCellProps: (document) => ({
        text: renderCellValue(field, document.selected_field_values),
      }),
      section: field.section,
      info: field.help_text,
    };
  };

  const fieldsToColumns = (fields) => {
    return fields.map(fieldToColumn);
  };

  const searchableFields = filterSections?.searchableFields;
  let columns = searchableFields
    ? [
        ...hardcodedColumns,
        ...fieldsToColumns(searchableFields.filter(wasNotHardcodedAlready)),
      ]
    : hardcodedColumns;
  const columnWidths = useSelector(selectors.selectSearchColumnWidths);
  columns = columns.map((column) => ({
    ...column,
    rawWidth: columnWidths[column.key],
  }));

  const columnGroups = useMemo(() => {
    if (filterSections?.fieldGroups) {
      return filterSections?.fieldGroups
        .map((group) => ({
          name: group.id,
          label: group.label,
          columns: group.fieldIds.map(mapFolderFilterIdToFieldId),
        }))
        .filter((group) => group.columns?.length);
    }
    return undefined;
  }, [filterSections]);

  const columnOrder = useSelector(selectors.selectSearchColumnOrder);

  const {
    tableSettings = {
      columnOrder,
      columnWidths,
      pageSize,
      sortBy,
    },
    updateTableSettings,
  } = useTableSettings(TableContextType.Search);

  useEffect(() => {
    if (currentTableViewSet && isSuccessFilterSections) {
      dispatch(search.actions.setSearchResultViewSet(currentTableViewSet));
      const hardcodedColumnsOrder = hardcodedColumns.map(
        (column) => column.key,
      );
      const newColumnOrderFromBE = currentTableViewSet
        .map((column) => column.field_id)
        .map(String);
      setColumnOrderAndQueryFields(
        [...hardcodedColumnsOrder, ...newColumnOrderFromBE],
        { action: 'select-option' },
      );

      updateTableSettings({
        ...tableSettings,
        pageSize,
        columnOrder: newColumnOrderFromBE,
      });
    } else {
      // TODO: Some error message
    }
  }, [currentTableViewSet, isSuccessFilterSections]);

  const { isDuplicateExport } = data || {};

  const isExportDisabled = isExporting || isDuplicateExport;

  const exportButtonTooltip = isExportDisabled
    ? isDuplicateExport
      ? `${EXPORT_DUPLICATED_TEXT} ${EXPORT_COMPLETED_TEXT}`
      : EXPORT_DUPLICATED_TEXT
    : EXPORT_DEFAULT_TEXT;

  const excelExportAction = {
    icon: 'file-spreadsheet',
    label: EXPORT_DEFAULT_TEXT,
    tooltip: exportButtonTooltip,
    disabled: isExportDisabled,
    onClick: onExport,
  };
  const hasExcelExportFlag = useHasFeatureFlag(FeatureFlagType.ExportExcel);

  const actions = [];

  if (hasExcelExportFlag) {
    actions.unshift(excelExportAction);
  }

  const testIsAnyNotMovable = (selectedRowIds) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testIsAnyDocumentNotMovable(selectedRows);
  };

  const testContainsGhost = (selectedRowIds) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testContainsGhostSearchResult(selectedRows);
  };

  const getBulkActions = ({ selectedRowIds }) => {
    const isAnyNotMovable = testIsAnyNotMovable(selectedRowIds);
    const containsGhost = testContainsGhost(selectedIds);

    const bulkActions = [
      {
        icon: 'groups',
        label: containsGhost
          ? 'You do not have privileges to add one or more of the items you have selected to a group.'
          : 'Add to Group',
        onClick: onAddToGroup,
        disabled: containsGhost,
      },
      {
        icon: 'folder',
        label: isAnyNotMovable
          ? 'You do not have privileges to move one or more of the items you have selected.'
          : 'Move',
        onClick: onMove,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'clipboard',
        label: isAnyNotMovable
          ? 'You do not have privileges to copy one or more of the items you have selected.'
          : 'Copy',
        onClick: onCopy,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'edit',
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : isBulkActionOnSidePanelEnabled
          ? 'Edit Fields'
          : 'Edit',
        onClick: onEdit,
        disabled: isAnyNotMovable,
      },
      ...(isBulkActionOnSidePanelEnabled
        ? [
            {
              icon: 'edit',
              label: isAnyNotMovable
                ? 'You do not have privileges to edit one or more of the items you have selected.'
                : 'Edit Clauses',
              onClick: onEditClauses,
              disabled: isAnyNotMovable,
            },
          ]
        : []),
      {
        icon: 'trash',
        label: isAnyNotMovable
          ? 'You do not have privileges to delete one or more of the items you have selected.'
          : 'Delete',
        onClick: onDelete,
        disabled: isAnyNotMovable,
      },
    ];

    return bulkActions;
  };

  const handlePaginate = ({ pageIndex }) => {
    if (pageIndex !== page) {
      onPaginate({
        page: pageIndex,
      });
    }
  };

  const hasResults = !error && data?.results.length > 0;
  const noResult = !error && data?.results.length === 0;

  const { selectedIds, isAllSelected, onTableUpdate } = tableSelection;

  const handleUpdate = (state, action) => {
    onTableUpdate(state, action);
    if (action?.type === 'toggleSortBy') {
      onSort(state.sortBy[0]);
    }

    if (action?.type === 'columnDoneResizing') {
      dispatch(
        search.actions.patchColumnWidths(state.columnResizing.columnWidths),
      );
    }
  };

  const setColumnOrderAndQueryFields = (updatedColumnOrder) => {
    dispatch(search.actions.setColumnOrder(updatedColumnOrder));

    const isDataFieldId = (columnKey) =>
      !!filterSections?.fields[columnKey] &&
      SearchableFields.DocumentGroup !== columnKey;

    const toDataField = (columnKey) => filterSections?.fields[columnKey];

    const queryFields = updatedColumnOrder
      .filter(isDataFieldId)
      .map(toDataField);
    dispatch(search.actions.setQueryFields(queryFields));
  };

  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const updateCurrentTableView = (columnOrder) => {
    const context = TableViewContextType.SEARCH;
    const fields = getSearchableFields(columnOrder);

    updateTableView({ context, fields });
  };

  const handleColumnOrderChange = (updatedColumnOrder, actionMeta) => {
    setColumnOrderAndQueryFields(updatedColumnOrder, actionMeta);

    updateCurrentTableView(updatedColumnOrder);
  };

  const [documentDetails, setDocumentDetails] = useState();
  const showDetailsPanel = (rowData) => {
    setDocumentDetails(rowData);
    trackSegment('selectOverlayPanel', {
      name: rowData.id,
    });
  };
  const hideDetailsPanel = () => {
    trackSegment('selectClose', {
      name: documentDetails?.id,
    });
    setDocumentDetails(undefined);
  };
  useEffect(() => {
    if (isLoading) {
      setDocumentDetails(undefined);
    }
  });

  const saveOriginalDocument = async (document) => {
    const documentHandler = get(document, 'document_handlers[0]', {});
    const { id, document_name, file_type } = documentHandler;
    if (canDownloadSearchResultItem(document)) {
      try {
        const response = await getDocumentOriginal(id).unwrap();
        saveAs(response, `${document_name}.${file_type}`);
      } catch (e) {
        showToast(
          ERROR,
          `Something went wrong with downloading ${document_name}`,
        );
      }
    } else {
      showToast(
        WARNING,
        `You don't have permissions to download ${document_name}`,
      );
    }
  };

  const onOpenDocumentClick = () => {
    if (documentDetails.document_handlers?.length > 1) {
      onViewResult(documentDetails, true);
    } else {
      openInNewTab(
        `/${client}/document/${documentDetails.document_handlers[0]?.id}`,
      );
    }
    trackSegment('selectDocument', {
      name: documentDetails.id,
    });
  };

  const currentSelectedIndex = useMemo(() => {
    return data?.results.indexOf(documentDetails);
  }, [data, documentDetails]);

  const next = () => {
    setDocumentDetails(data.results[currentSelectedIndex + 1]);
  };
  const prev = () => {
    setDocumentDetails(data.results[currentSelectedIndex - 1]);
  };

  const getPanelActions = (list, item) => {
    const isFirst = currentSelectedIndex === 0;
    const isLast = currentSelectedIndex === list.length - 1;

    return [
      {
        icon: 'open-in-new',
        label: 'Open Document',
        onClick: onOpenDocumentClick,
      },
      {
        icon: 'download',
        label: 'Download',
        onClick: () => {
          saveOriginalDocument(item);
          trackSegment('selectDownloadDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isFirst,
        icon: 'chevron-left',
        label: 'Previous',
        onClick: () => {
          prev();
          trackSegment('selectPreviousDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isLast,
        icon: 'chevron-right',
        label: 'Next',
        onClick: () => {
          next();
          trackSegment('selectNextDocument', {
            name: item.id,
          });
        },
      },
    ];
  };

  const rowActions = [
    {
      label: 'Add to Group',
      onClick: (doc) => onAddToGroup({ selectedRowIds: [doc.id], data: [doc] }),
      condition: (doc) =>
        !doc.document_group_id && !testIsGhostSearchResultDocument(doc),
    },
    {
      label: 'Move',
      onClick: (doc) => onMove({ selectedRowIds: [doc.id], data: [doc] }),
      condition: (doc) => doc.is_movable,
    },
    {
      label: 'Copy',
      onClick: (doc) => onCopy({ selectedRowIds: [doc.id], data: [doc] }),
      condition: (doc) => doc.is_movable,
    },
    {
      label: 'Edit',
      onClick: (doc) => onEdit({ selectedRowIds: [doc.id], data: [doc] }),
      condition: (doc) => doc.is_movable,
    },
    {
      label: 'Download (original)',
      onClick: (document) => {
        saveOriginalDocument(document);
      },
    },
    {
      label: 'Delete',
      onClick: (doc) => onDelete({ selectedRowIds: [doc.id], data: [doc] }),
      condition: (doc) => doc.is_movable,
    },
  ];

  const currentTableView = useSelector(selectors.selectSearchCurrentTableView);
  const handleLoadView = (id) => {
    dispatch(search.actions.setCurrentTableView(id));

    if (currentTableView === id || !currentTableView) {
      const oldOrder = currentTableViewSet
        .map((column) => column.field_id)
        .map(String);

      setColumnOrderAndQueryFields(oldOrder);
      updateTableSettings({
        ...tableSettings,
        columnOrder: oldOrder,
        pageSize,
      });
    }
  };

  return (
    <Box mt={8}>
      <LoadingContainer
        isLoading={
          isLoading ||
          isFetchingDocumentGroups ||
          isFetchingFilterSections ||
          !columns.length
        }
      >
        {(hasResults || noResult) && (
          <Box mb={8}>
            <SearchReIndexInProgressWarning
              fullyIndexed={data.fullyIndexed}
              docCountIndexing={data.docCountIndexing}
            />
          </Box>
        )}
        {hasResults && (
          <>
            {isUnifiedDocDetaisPanelEnabled ? (
              <DocumentDetailsPanel
                onHide={hideDetailsPanel}
                docHandlerId={documentDetails?.document_handlers[0].id}
                featuredClauses={featuredClauses}
                documentName={
                  documentDetails?.document_handlers[0].document_name
                }
                documentFormatType={
                  documentDetails?.document_handlers[0].file_type
                }
                searchKeyworkMatches={documentDetails?.full_text_matches}
                actionsConfig={{
                  navigate: {
                    onClick: onOpenDocumentClick,
                  },
                  download: {
                    disabled:
                      documentDetails &&
                      !canDownloadSearchResultItem(documentDetails),
                  },
                  next: {
                    onClick: next,
                    disabled:
                      data && currentSelectedIndex === data.results.length - 1,
                  },
                  previous: {
                    onClick: prev,
                    disabled: currentSelectedIndex === 0,
                  },
                }}
              />
            ) : (
              <DetailsPanel
                onHide={hideDetailsPanel}
                document={documentDetails}
                actions={getPanelActions(data.results, documentDetails)}
              />
            )}
            <Layout direction="column" spacing={8}>
              <SearchLimitWarning resultCount={data.count} />
              <ExportExcelLimitWarning resultCount={data.count} />
              <PersistedTable
                context={TableContextType.Search}
                activeRowId={documentDetails?.id}
                name="search result"
                totalCount={getMaximumByLimit(data.count, SEARCH_RESULTS_LIMIT)}
                columnGroups={columnGroups}
                columns={columns}
                data={data.results}
                actions={actions}
                getBulkActions={getBulkActions}
                state={{
                  columnOrder,
                  columnWidths,
                  isAllRowsSelected: isAllSelected,
                  pageIndex: page,
                  pageSize,
                  selectedRowIds: selectedIds,
                  sortBy: [sortBy],
                }}
                onUpdate={handleUpdate}
                onPaginate={handlePaginate}
                onPageSizeChange={onPageSizeChange}
                onLoadView={onLoadView}
                onSaveView={onSaveView}
                rowDetails={{
                  onClick: showDetailsPanel,
                }}
                onSetColumnOrder={handleColumnOrderChange}
                rowActions={rowActions}
                options={{
                  enableExportXlsx: false,
                  enablePageSizeSelect: true,
                  enableStickyHeader: true,
                  stickyHeaderOffset: NAV_AND_SUBNAV_HEIGHT,
                }}
                reactTableOptions={{
                  autoResetSortBy: false,
                  disableSortRemove: true,
                  manualSortBy: true,
                }}
              />
              <SaveView />
              <LoadView onViewSelected={handleLoadView} />
            </Layout>
          </>
        )}
        {noResult && (
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100px' }}
          >
            <Text variant="subtitle">No documents found</Text>
          </FlexLayout>
        )}
      </LoadingContainer>
    </Box>
  );
};

const SearchResult = React.memo(_SearchResult);

export default SearchResult;
