import { SearchFilterRecord } from '~/features/advanced-search';
import type { ModelResult } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Nullable } from '~/types';

import {
  PagePromptModel,
  PromptModel,
  PromptModelStatus,
} from '../../types/sylvanus/model';
import { getFilters } from '../searchV3';
import { mapScopeToFilters } from './transformers';

interface Request {
  pageIndex: number;
  pageSize: number;
  status: Nullable<PromptModelStatus>;
  sortBy?: {
    id: string;
    desc: boolean;
  };
}

interface Response {
  results: ModelResult[];
  totalCount: number;
}

export const getPromptModelResults = async ({
  pageIndex,
  pageSize,
  status,
  sortBy,
}: Request): Promise<Response> => {
  const searchFilters = await getFilters();

  const response: PagePromptModel = await sylvanus.get('/model', {
    params: {
      include_latest_version: true,
      page: pageIndex,
      size: pageSize,
      status,
      sort_by: sortBy?.id,
      reverse: sortBy?.desc,
    },
  });

  return {
    results: response.items.map(mapModelResult(searchFilters)),
    totalCount: response.total ?? 0,
  };
};

const mapModelResult = (searchFilters: SearchFilterRecord) => (
  model: PromptModel,
): ModelResult => {
  const result: ModelResult = {
    currentOptimize: null,
    currentPublishStatus: model.current_publish?.status ?? null,
    id: model.id,
    latestVersion: 1,
    scope: [],
    targetEntity: model.target_entity,
    versions: [],
  };

  const versions = model.version_numbers ?? [];
  const latestVersion = versions.slice(-1)[0];
  const latestModelVersion = model.versions?.[latestVersion];

  if (latestModelVersion) {
    result.currentOptimize =
      model.current_optimize?.version_number === latestVersion
        ? {
            state: model.current_optimize.state ?? null,
            version: model.current_optimize.version_number,
          }
        : null;
    result.latestVersion = latestVersion;
    result.modifiedBy = Number(latestModelVersion.created_by_user_id);
    result.modifiedDate = new Date(model.datetime_updated);
    result.versions = versions;
    if (latestModelVersion.config?.scope) {
      result.scope = mapScopeToFilters(
        latestModelVersion.config.scope,
        searchFilters,
      );
    }
  }

  return result;
};
