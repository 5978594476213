import { FileExtensionType, HttpStatusCodeType } from '~/enums';
import { everest, pilot } from '~/services';
import { Uuid } from '~/types';

import { testIsAxiosError } from '../../utils';
import { getTicketDocument } from '../ticket';

interface Params {
  ticketId: Uuid;
  documentId: Uuid;
  versionId: Uuid;
}
export const resolveTicketDocument = async ({
  documentId,
  ticketId,
  versionId,
}: Params): Promise<{ file: File }> => {
  const ticketDocument = await getTicketDocument({ ticketId });
  let file;
  switch (ticketDocument.fileType) {
    case FileExtensionType.Doc:
    case FileExtensionType.Docx:
      //TODO: Implement fetching SFDT when DocumentViewer supports EJ2 Doc Editor.
      break;
    case FileExtensionType.Jpg:
    case FileExtensionType.Jpeg:
    case FileExtensionType.Pdf:
    case FileExtensionType.Png:
    case FileExtensionType.Tiff:
      file = await getTicketPDFDocument({
        ticketVersionId: versionId,
        documentId,
      });
      break;
    default:
      break;
  }

  if (!file) {
    throw new Error('Failed to download document');
  }

  return {
    file,
  };
};

interface Request {
  ticketVersionId: Uuid;
  documentId: Uuid;
}
export const getTicketPDFDocument = async ({
  ticketVersionId,
  documentId,
}: Request): Promise<File | undefined> => {
  let file: File | undefined = undefined;
  try {
    file = await pilot.get('/document/download/ocr-pdf', {
      params: { tag: ticketVersionId },
      responseType: 'blob',
    });
  } catch (e: unknown) {
    if (
      testIsAxiosError(e) &&
      e.response?.status === HttpStatusCodeType.NotFound
    ) {
      try {
        file = await everest.get(
          `/documents/${documentId}/versions/${ticketVersionId}/download/original`,
          { responseType: 'blob' },
        );
      } catch {
        throw new Error('Failed to download document');
      }
    }
  }
  return file;
};
