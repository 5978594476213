import React, { useMemo } from 'react';

import { flattenFolderTree } from '~/components/SearchV2/SearchV2.utils';
import { Button, formatDate, formatNumber, Layout, Text, Tooltip } from '~/eds';
import {
  FieldType,
  Filter,
  OperatorId,
  operatorLabelsByFieldType,
} from '~/evifields';
import { SearchQuery } from '~/features/advanced-search';
import { api } from '~/redux';
import {
  BooleanQueryEntity,
  DocumentGroup,
  FlattenFolderTree,
  FolderTree,
  QueryOperatorEntity,
  SearchFilter,
} from '~/types';

import {
  mapQuerytoSectionFiltersOperators,
  QueryFilters,
  SectionFilters,
} from './utils';

type Props = {
  isQueryBuilderSupported?: boolean;
  queryBuilder: SearchQuery;
  searchFilters: Record<string, SearchFilter>;
  onClear?: () => void;
  onEdit?: () => void;
};

export const ReadOnlyComplexQuery = ({
  isQueryBuilderSupported = true,
  searchFilters,
  queryBuilder,
  onClear,
  onEdit,
}: Props) => {
  const {
    data: documentGroupsData,
  } = api.endpoints.getAllDocumentGroups.useQuery(undefined);
  const { data: folderTreeData } = api.endpoints.getFolderTree.useQuery(
    undefined,
  );
  const folders = folderTreeData?.children;
  const folderTree = useMemo(
    () => (!folders ? [] : flattenFolderTree(folders as FolderTree[])),
    [folders],
  );

  const queryFilters = mapQuerytoSectionFiltersOperators(
    queryBuilder,
    searchFilters,
  );

  const getValueByType = (opId: string, type: FieldType, value: any) => {
    switch (type) {
      case 'clause':
        if (value[0].text_search[0]) {
          const r = value[0].text_search[0];
          return `${value[0].provision} and ${r.scope.label} ${r.text}`;
        }
        return value[0].provision;
      case 'date':
        if (opId === 'date_in_the_last' || opId === 'date_in_the_next') {
          return `${value[0].value} ${value[0].unit}`;
        } else if (opId === 'is_blank') {
          return '';
        } else {
          return value.map((date: Date) => formatDate(date)).join(' - ');
        }
      case 'enum_set':
        return value.join(' - ');
      case 'number':
        const numericValue = value[0];
        if (numericValue === undefined) return value;
        return formatNumber(value);
      default:
        return value;
    }
  };

  const getValueByFieldTypeValue = (
    fieldId: string,
    opId: OperatorId,
    type: FieldType,
    values: Filter['values'],
  ) => {
    switch (fieldId) {
      case 'document_group_id':
        const groupValues = getValueByType(opId, type, values).split(' - ');
        const groupNames = groupValues.map((item: string) => {
          const result = [];
          const group = documentGroupsData?.results.find(
            (grp: DocumentGroup) => item === grp.groupId.toString(),
          );
          if (group) {
            result.push(group.name);
          }
          return result;
        });
        return groupNames?.join(', ');
      case 'folder':
        const folderValues = getValueByType(opId, type, values);
        const folderNames = folderValues.map((item: string) => {
          const result = [];
          const folder = folderTree?.find(
            (folder: FlattenFolderTree) => item === folder.value,
          );
          if (folder) {
            result.push(folder.display_value);
          }
          return result;
        });
        return folderNames.join(', ');
      default:
        return getValueByType(opId, type, values);
    }
  };

  const testIsSectionFilter = (
    sectionItem: QueryFilters,
  ): sectionItem is SectionFilters =>
    'type' in sectionItem && sectionItem.type === 'section';

  const testIsOperatorFilter = (
    sectionItem: QueryFilters,
  ): sectionItem is QueryOperatorEntity =>
    'type' in sectionItem && sectionItem.type === 'operator';

  const testIsBooleanTextSearch = (
    sectionItem: QueryFilters,
  ): sectionItem is BooleanQueryEntity =>
    'type' in sectionItem && sectionItem.type === 'bool_text_search';

  const renderFieldValueComponent = (
    tooltip: string,
    label: string,
    key: React.Key,
  ) => (
    <Layout align="center" key={key}>
      <Tooltip tooltip={tooltip}>
        <Layout
          p={1}
          styles={{
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
          }}
        >
          <Text variant="tiny">{label}</Text>
        </Layout>
      </Tooltip>
    </Layout>
  );

  const renderFilter = (filter: Filter, index: number) => {
    const type = searchFilters[filter.fieldId].type;
    const opId = filter.operatorId!;
    const operatorValue = operatorLabelsByFieldType[type][opId];
    const value = getValueByFieldTypeValue(
      filter.fieldId,
      opId,
      type,
      filter.values,
    );
    const tooltip = `${
      searchFilters[filter.fieldId].label
    } ${operatorValue} ${value}`;
    const key = `${searchFilters[filter.fieldId].label}-${
      filter.operatorId
    }-${index}`;
    const label = searchFilters[filter.fieldId.toString()].label;
    return renderFieldValueComponent(tooltip, label, key);
  };

  const renderBooleanTextSearch = (
    filter: BooleanQueryEntity,
    index: number,
  ) => {
    const tooltip = filter.value;
    const key = `${filter.value}-${index}`;
    const label = 'Boolean Text Search';
    return renderFieldValueComponent(tooltip, label, key);
  };

  const renderOperator = (operator: QueryOperatorEntity, index: number) => (
    <Layout key={`item_${index}`} align="center" pl={3} pr={3}>
      <Text variant="tiny-bold">{operator.value.toUpperCase()}</Text>
    </Layout>
  );

  const renderEntities = (sectionItem: QueryFilters, index: number) => {
    if (testIsSectionFilter(sectionItem)) {
      return (
        <Layout key={`section_${index}`} align="center">
          <Layout pr={3}>(</Layout>
          {sectionItem.values.map(renderEntities)}
          <Layout pl={3}>)</Layout>
        </Layout>
      );
    } else if (testIsOperatorFilter(sectionItem)) {
      return renderOperator(sectionItem, index);
    } else if (testIsBooleanTextSearch(sectionItem)) {
      return renderBooleanTextSearch(sectionItem, index);
    } else {
      return renderFilter(sectionItem, index);
    }
  };

  const queryMemoized = useMemo(() => queryFilters?.map(renderEntities), [
    queryFilters,
  ]);

  return (
    <Layout align="center" spacing={3} wrap={true} justify="space-between">
      <Layout>
        <Layout>{queryMemoized}</Layout>

        {onEdit && (
          <Button
            text="Edit Query"
            variant="action"
            onClick={onEdit}
            disabled={!isQueryBuilderSupported}
            tooltip={
              !isQueryBuilderSupported
                ? 'Query not supported to be edited by query builder.'
                : ''
            }
          />
        )}
      </Layout>

      {onClear && (
        <Button
          text="Reset Filters"
          icon="reload"
          iconPosition="left"
          variant="action"
          onClick={onClear}
        />
      )}
    </Layout>
  );
};
