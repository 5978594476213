import { noop } from 'lodash';
import React, { memo, useState } from 'react';

import {
  Actions,
  FormField,
  Layout,
  Popover,
  TextArea,
} from '../../components';
import { useToggle } from '../../hooks';
import { Nullable } from '../../types';
import { formatNumber } from '../../utils';

// detail object data provided by consumer
type Details = object;

// submit detail object including `Feedback` internal state
export type SubmitDetails = Details & {
  comment: string;
  value: Nullable<boolean>;
};

interface Props {
  /** Indicates the feedback value:
   * - true -> like
   * - false -> dislike
   * - null -> unspecified
   */
  value: Nullable<boolean>;
  /** Variant of the feedback button component */
  variant?: 'default' | 'subtle';
  /** Captures the updated feedback value */
  onChange?: (updatedValue: Nullable<boolean>) => void;
  /** Submit additional feedback details */
  submitDetails?: {
    /** Required callback to submit the button */
    onSubmit: (submitDetails: SubmitDetails) => void;
    details?: Details;
    /** Disables the submit button */
    disabled?: boolean;
    /** Indicates the submit button is loading */
    isLoading?: boolean;
    /** Render additional details */
    render?: (details: Details) => React.ReactNode;
    /** Callback when cancelling the submission */
    onCancel?: () => void;
  };
}

export const Feedback = memo(
  ({
    value: initialValue,
    variant = 'default',
    submitDetails,
    onChange = noop,
  }: Props) => {
    const [comment, setComment] = useState('');
    const [value, setValue] = useState<Nullable<boolean>>(initialValue);
    const [
      isDetailsVisible,
      _toggleDetails,
      showDetails,
      hideDetails,
    ] = useToggle();

    const handleUpdateValue = (updatedValue: Nullable<boolean>) => () => {
      setValue(updatedValue);
      onChange(updatedValue);
      if (submitDetails) {
        showDetails();
      }
    };

    const handleCancel = () => {
      setComment('');
      hideDetails();
      submitDetails?.onCancel?.();
    };
    const actionLevel = variant === 'subtle' ? 'subtle' : undefined;
    const feedbackActions = (
      <Actions
        actions={[
          {
            icon: 'reaction-thumbsup',
            mode: 'icon',
            status: value === true ? 'active' : undefined,
            text: 'Like',
            tooltip: 'Like',
            level: actionLevel,
            onClick: handleUpdateValue(value === true ? null : true),
          },
          {
            icon: 'reaction-thumbsdown',
            mode: 'icon',
            status: value === false ? 'active' : undefined,
            text: 'Dislike',
            tooltip: 'Dislike',
            level: actionLevel,
            onClick: handleUpdateValue(value === false ? null : false),
          },
        ]}
      />
    );

    if (submitDetails) {
      const {
        details = {},
        disabled,
        isLoading,
        render,
        onSubmit,
      } = submitDetails;

      return (
        <Popover
          isVisible={isDetailsVisible}
          maxWidth="none"
          trigger={feedbackActions}
        >
          <Layout direction="column" spacing={4} w="form.width.m">
            <FormField<string, false>
              footer={`${
                (comment ?? '').length
              }/${formatNumber(MAX_COMMENT_LENGTH, { unit: 'character' })}`}
              input={TextArea}
              label="Enter Feedback Comments"
              name="feedback comments"
              placeholder="Type feedback here"
              value={comment}
              onChange={(updatedComment) => setComment(updatedComment ?? '')}
            />
            {render?.(details)}
            <Layout alignSelf="flex-end">
              <Actions
                actions={[
                  {
                    text: 'Cancel',
                    level: 'tertiary',
                    onClick: handleCancel,
                  },
                  {
                    disabled,
                    isLoading,
                    text: 'Send',
                    onClick: () => {
                      onSubmit({ ...details, comment, value });
                      handleCancel();
                    },
                  },
                ]}
              />
            </Layout>
          </Layout>
        </Popover>
      );
    } else {
      return feedbackActions;
    }
  },
);

const MAX_COMMENT_LENGTH = 1000;
