import React, { memo } from 'react';

import { IconType, SharedButtonProps, StatusType, Theme } from '../../types';
import { resolvePresetProps } from '../../utils';
import { Button } from '../Button';
import { presets } from './presets';

interface Props {
  /** Renders a system icon (should always be provided unless set by a preset.) */
  icon?: IconType;
  /** Tooltip is used as a semantic accessible label for the icon button (should always be provided unless set by a preset.) */
  tooltip?: string;
  /** If the button is disabled */
  disabled?: boolean;
  /** Unique id */
  id?: string;
  /** If the button is loading (will be disabled) */
  isLoading?: boolean;
  preset?: keyof typeof presets;
  /** Size of the icon button */
  size?: SharedButtonProps['size'];
  /** Optional status */
  status?: StatusType;
  /** The accessible (non-display) button text for the icon.  Takes priority over tooltip */
  text?: string;
  /** Light / Dark theme */
  theme?: Theme;
  /** Click handler */
  onClick?: () => void;
  /** PRIVATE APIs */
  PRIVATE_variant?: 'action' | 'primary' | 'secondary' | 'tertiary' | 'subtle';
}

export const IconButton = memo((props: Props) => {
  const {
    disabled,
    icon,
    id,
    isLoading,
    size = 'm',
    status,
    text,
    theme,
    tooltip,
    PRIVATE_variant = 'tertiary',
    onClick,
  } = resolvePresetProps(props, presets);

  return (
    <Button
      disabled={disabled}
      icon={icon}
      id={id}
      isLoading={isLoading}
      mode="icon"
      size={size}
      status={status}
      text={text || tooltip}
      theme={theme}
      tooltip={tooltip}
      onClick={onClick}
      variant={PRIVATE_variant}
    />
  );
});
