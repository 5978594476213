import { saveAs } from 'file-saver';
import { get, groupBy, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionType } from 'react-table';

import { canDownloadSearchResultItem } from '~/components/AnalyzerResultsPage/SearchResultsItem/utils';
// TODO: move or deprecate
import { DetailsPanel } from '~/components/DashboardV2/DashboardV2SearchResults/DetailsPanel';
import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import { DocumentDetailsPanel } from '~/components/Shared/DocumentDetailsPanel';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { ExportExcel } from '~/components/Shared/ExportExcel';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { SEARCH_RESULTS_LIMIT } from '~/constants/max_lengths';
import { NAV_AND_SUBNAV_HEIGHT } from '~/constants/page';
import {
  EXPORT_COMPLETED_TEXT,
  EXPORT_DEFAULT_TEXT,
  EXPORT_DUPLICATED_TEXT,
} from '~/constants/search';
import {
  ContentContainer,
  Layout,
  types,
  useTableSelection,
  useToast,
} from '~/eds';
import { TableContextType } from '~/enums';
import { FieldId } from '~/evifields';
import {
  SearchDocumentItem,
  SearchDocumentsResult,
  SearchQuery,
} from '~/features/advanced-search';
import { FlagType, useFlag } from '~/flags';
import {
  useClientSubDomain,
  usePermission,
  useSearchColumns,
  useSearchNavigation,
} from '~/hooks';
import { api } from '~/redux';
import { TableViewSet } from '~/redux/api/methods';
import { ColumnSortOrder, SearchFilter } from '~/types';
import { openInNewTab } from '~/utils/browser';
import { testContainsGhostSearchResult } from '~/utils/document.utils';
import { getMaximumByLimit } from '~/utils/number';
import { HandlerName } from '~/utils/table';

import { testIsAnyDocumentNotMovable } from '../utils';

type Props = {
  currentTableView?: TableViewSet;
  isLoading: boolean;
  data?: SearchDocumentsResult;
  query: SearchQuery;
  searchFilters: Record<FieldId, SearchFilter>;
  resultsCache?: Record<string, SearchDocumentItem>;
  state: {
    page: number;
    pageSize: number;
    sortBy: ColumnSortOrder;
    columnOrder: string[];
    columnWidths: Record<string, number>;
    featuredClauses: string[];
  };
  tableSelection: ReturnType<typeof useTableSelection>;
  refreshSearch: () => void;
  onMove: () => void;
  onCopy: () => void;
  onEdit: () => void;
  onEditClauses: () => void;
  onDelete: () => void;
  onAddToGroup: () => void;
  onSetColumnOrder: (
    updatedColumnOrder: Array<string>,
    actionMeta?: { action: string },
  ) => void;
  onPaginate: ({ pageIndex }: { pageIndex: number }) => void;
  onPageSizeChange: (pageSize: number) => void;
  onLoadView: (tableState: types.TableState) => void;
  onSaveView: (tableState: types.TableState) => void;
  handleUpdate: (action: ActionType) => void;
};

export const SearchV3Results = ({
  currentTableView,
  isLoading,
  data,
  query,
  searchFilters,
  resultsCache = {},
  state: { page, pageSize, sortBy, columnOrder, columnWidths, featuredClauses },
  tableSelection,
  refreshSearch,
  onMove,
  onCopy,
  onEdit,
  onEditClauses,
  onDelete,
  onAddToGroup,
  onSetColumnOrder,
  onPaginate,
  onPageSizeChange,
  onLoadView,
  onSaveView,
  handleUpdate,
}: Props) => {
  const client = useClientSubDomain();
  const isUnifiedDetailsPanelEnabled = useFlag(
    FlagType.UnifiedDocumentDetailsPanel,
  );
  const [documentDetails, setDocumentDetails] = useState<SearchDocumentItem>();
  const [showExportExcel, setShowExportExcel] = useState(false);

  const [
    getDocumentOriginal,
  ] = api.endpoints.getDocumentOriginal.useLazyQuery();
  const { selectedIds, isAllSelected } = tableSelection;
  const { toast } = useToast();

  const hasExportExcel = useFlag(FlagType.DashboardsExportExcel);
  const hasGroupsEditPermission = usePermission({
    permission: {
      resourceId: 'document_groups',
      resourceType: 'edit',
    },
  });

  const showDetailsPanel = (rowData: any) => {
    setDocumentDetails(rowData);
    trackSegment('selectOverlayPanel', {
      name: rowData.id,
    });
  };
  const hideDetailsPanel = () => {
    trackSegment('selectClose', {
      name: documentDetails?.id,
    });
    setDocumentDetails(undefined);
  };

  const saveOriginalDocument = async (document: SearchDocumentItem) => {
    const documentHandler = get(document, 'document_handlers[0]', {});
    const { id, document_name, file_type } = documentHandler;
    if (canDownloadSearchResultItem(document)) {
      try {
        const response = await getDocumentOriginal(id).unwrap();
        if (response) {
          saveAs(response, `${document_name}.${file_type}`);
        }
      } catch (e) {
        toast({
          message: `Something went wrong with downloading ${document_name}`,
          status: 'danger',
        });
      }
    } else {
      toast({
        message: `You don't have permissions to download ${document_name}`,
        status: 'warning',
      });
    }
  };

  const isDuplicateExport = data?.meta.is_duplicate_export;
  const exportButtonTooltip = isDuplicateExport
    ? `${EXPORT_DUPLICATED_TEXT}  ${EXPORT_COMPLETED_TEXT}`
    : EXPORT_DEFAULT_TEXT;

  const actions = hasExportExcel
    ? [
        {
          icon: 'file-spreadsheet',
          label: EXPORT_DEFAULT_TEXT,
          tooltip: exportButtonTooltip,
          disabled: isDuplicateExport,
          onClick: () => {
            setShowExportExcel(true);
          },
        },
      ]
    : [];

  const testIsAnyNotMovable = (selectedRowIds: string[]) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testIsAnyDocumentNotMovable(selectedRows);
  };

  const testContainsGhost = (selectedRowIds: string[]) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testContainsGhostSearchResult(selectedRows);
  };

  const getBulkActions = ({ selectedRowIds }: types.TableState) => {
    const isAnyNotMovable = testIsAnyNotMovable(selectedRowIds);
    const containsGhost = testContainsGhost(selectedIds);

    const bulkActions = [
      {
        icon: 'folder',
        label: isAnyNotMovable
          ? 'You do not have privileges to move one or more of the items you have selected.'
          : 'Move',
        onClick: onMove,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'clipboard',
        label: isAnyNotMovable
          ? 'You do not have privileges to copy one or more of the items you have selected.'
          : 'Copy',
        onClick: onCopy,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'edit',
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Fields',
        onClick: onEdit,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'edit',
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Clauses',
        onClick: onEditClauses,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'trash',
        label: isAnyNotMovable
          ? 'You do not have privileges to delete one or more of the items you have selected.'
          : 'Delete',
        onClick: onDelete,
        disabled: isAnyNotMovable,
      },
      {
        icon: 'groups',
        label: containsGhost
          ? 'You do not have privileges to add one or more of the items you have selected to a group.'
          : 'Add to Group',
        onClick: onAddToGroup,
        disabled: containsGhost || !hasGroupsEditPermission,
      },
    ];

    return bulkActions;
  };

  const onOpenDocumentClick = () => {
    if (documentDetails) {
      if (documentDetails.document_handlers.length > 1) {
        //@ts-ignore callback type not supported in js hook
        setDocumentSelected(documentDetails);
        //@ts-ignore callback type not supported in js hook
        setOpenDocumentInNewTab(true);
      } else {
        openInNewTab(
          `/${client}/document/${documentDetails.document_handlers[0]?.id}`,
        );
      }
      trackSegment('selectDocument', {
        name: documentDetails.id,
      });
    }
  };

  const currentSelectedIndex = useMemo(() => {
    if (documentDetails) {
      return data?.results.indexOf(documentDetails);
    }
    return undefined;
  }, [data, documentDetails]);

  const prev = () => {
    currentSelectedIndex !== undefined &&
      setDocumentDetails(data?.results[currentSelectedIndex - 1]);
  };

  const next = () => {
    currentSelectedIndex !== undefined &&
      setDocumentDetails(data?.results[currentSelectedIndex + 1]);
  };

  const getPanelActions = (
    list: Array<SearchDocumentItem>,
    item: SearchDocumentItem,
  ) => {
    const isFirst = currentSelectedIndex === 0;
    const isLast = currentSelectedIndex === list.length - 1;

    const actions: types.Action[] = [
      {
        icon: 'open-in-new',
        label: 'Open Document',
        value: null,
        onClick: onOpenDocumentClick,
      },
      {
        icon: 'download',
        label: 'Download',
        value: null,
        onClick: () => {
          saveOriginalDocument(item);
          trackSegment('selectDownloadDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isFirst,
        value: null,
        icon: 'chevron-left',
        label: 'Previous',
        onClick: () => {
          prev();
          trackSegment('selectPreviousDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isLast,
        icon: 'chevron-right',
        label: 'Next',
        value: null,
        onClick: () => {
          next();
          trackSegment('selectNextDocument', {
            name: item.id,
          });
        },
      },
    ];
    return actions;
  };

  /*
   * Navigation
   */

  const [handleNavigate] = useSearchNavigation({ sortBy, query });
  const [
    setDocumentSelected,
    renderMultipleLocationModal,
    setOpenDocumentInNewTab,
  ] = useDocumentMultipleLocationsModal(
    getHandlersKeyFromSearchResult,
    false,
    handleNavigate,
  );

  /*
   * Navigation End
   */

  /*
   * Columns
   */

  const hardcodedColumns = [
    {
      key: 'name',
      title: 'Name',
      minWidth: 'm',
      renderCell: (document: SearchDocumentItem) => {
        const handlers = document.document_handlers;
        return (
          <HandlerName
            onNavigate={handleNavigate}
            handlers={handlers as any}
            onClick={() => {
              //@ts-ignore callback type not supported in js hook
              setDocumentSelected(document);
              //@ts-ignore callback type not supported in js hook
              setOpenDocumentInNewTab(false);
            }}
          />
        );
      },
    },
  ];

  const searchFilterColumns = useMemo(
    () =>
      Object.values(searchFilters).filter(
        (searchFilters) => searchFilters.is_requestable_column,
      ),
    [searchFilters],
  );

  const columns = useSearchColumns({
    columnWidths,
    hardcodedColumns,
    searchFilterColumns,
  });

  const sectionsResult = api.endpoints.getFilterSections.useQuery(undefined);

  const {
    data: filterSections,
    isSuccess: isSuccessFilterSections,
  } = sectionsResult;

  const columnsMap = groupBy(columns, 'section');

  const columnGroups = useMemo(() => {
    if (filterSections?.fieldGroups) {
      return filterSections?.fieldGroups
        .map((group) => ({
          name: group.id,
          label: group.label,
          columns: columnsMap[group.id]?.map((c) => c.key),
        }))
        .filter((group) => group.columns?.length);
    }
    return undefined;
  }, [columnsMap, filterSections]);

  useEffect(() => {
    if (isSuccessFilterSections && currentTableView) {
      const hardcodedColumnKeys = hardcodedColumns.map((col) => col.key);
      const newColumnOrderFromBE = currentTableView.map((col) =>
        String(col.field_id),
      );
      onSetColumnOrder(uniq([...hardcodedColumnKeys, ...newColumnOrderFromBE]));
    }
  }, [currentTableView, isSuccessFilterSections]);

  /*
   * Columns End
   */
  const placeholderContent =
    data && !data.results.length ? { title: 'No documents found' } : undefined;

  return (
    <Layout direction="column" spacing={8}>
      <ContentContainer placeholderContent={placeholderContent}>
        <>
          <PersistedTable
            context={TableContextType.SearchV3}
            name="search result"
            activeRowId={documentDetails?.id}
            totalCount={getMaximumByLimit(
              data?.meta?.total,
              SEARCH_RESULTS_LIMIT,
            )}
            columns={columns}
            columnGroups={columnGroups}
            data={data?.results ?? []}
            actions={actions}
            isLoading={isLoading}
            state={{
              columnOrder: columnOrder,
              pageIndex: page,
              pageSize,
              sortBy: [sortBy],
              isAllRowsSelected: isAllSelected,
              selectedRowIds: selectedIds,
            }}
            getBulkActions={getBulkActions}
            onPaginate={onPaginate}
            onPageSizeChange={onPageSizeChange}
            onSetColumnOrder={onSetColumnOrder}
            onUpdate={handleUpdate}
            onLoadView={onLoadView}
            onSaveView={onSaveView}
            rowDetails={{ onClick: showDetailsPanel }}
            options={{
              enableExportXlsx: false,
              enablePageSizeSelect: true,
              enableSelectRows: true,
              enableStickyHeader: true,
              stickyHeaderOffset: NAV_AND_SUBNAV_HEIGHT,
            }}
            reactTableOptions={{
              autoResetSortBy: false,
              disableSortRemove: true,
              manualSortBy: true,
            }}
          />
          {renderMultipleLocationModal(null)}
        </>
      </ContentContainer>

      {isUnifiedDetailsPanelEnabled ? (
        <DocumentDetailsPanel
          docHandlerId={documentDetails?.document_handlers.map(
            (handler) => handler.id,
          )}
          searchKeyworkMatches={documentDetails?.full_text_matches}
          onHide={hideDetailsPanel}
          featuredClauses={featuredClauses}
          actionsConfig={{
            navigate: {
              onClick: onOpenDocumentClick,
            },
            download: {
              disabled:
                documentDetails &&
                !canDownloadSearchResultItem(documentDetails),
            },
            next: {
              onClick: next,
              disabled:
                data && currentSelectedIndex === data.results.length - 1,
            },
            previous: {
              onClick: prev,
              disabled: currentSelectedIndex === 0,
            },
            delete: {
              onSuccess: refreshSearch,
            },
          }}
        />
      ) : (
        <DetailsPanel
          document={documentDetails}
          onHide={hideDetailsPanel}
          actions={getPanelActions(data?.results || [], documentDetails!)}
        />
      )}
      <ExportExcel
        query={query}
        isVisible={showExportExcel}
        onHide={() => setShowExportExcel(false)}
        totalDocs={data?.meta?.total}
        totalDupsAndDocs={data?.meta?.total_docs_including_dups}
        selectedFieldIds={columnOrder.filter((col) => col !== 'name')}
      />
    </Layout>
  );
};
