import { createSlice } from '@reduxjs/toolkit';
import { get, pick } from 'lodash';

import { toFilter } from '~/components/SearchV2/SearchV2.utils';
import { SEARCH_COUNT_SINGLE_VALUE_ID } from '~/components/Shared/Charts/constants';
import { types } from '~/eds';
import { SliceType } from '~/enums';
import { Field, FieldId, Filter } from '~/evifields';
import { SearchDocumentItem, SearchQuery } from '~/features/advanced-search';
import { SavedSearch } from '~/features/search/types/search';
import {
  testIsBooleanTextSearch,
  testIsQueryV3,
} from '~/features/search/utils/queryUtils';
import { Dashboard, SearchItem } from '~/redux/api/methods';
import {
  Chart,
  ColumnSortOrder,
  DropdownFieldValue,
  EntityQuery,
  SearchFilter,
} from '~/types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import { toSearchV3Filters } from '../api/methods/searchV3';
import { getPersistedState, ReduxPath } from '../persist';

type ColumnWidths = {
  [key: string]: number;
};

const name = SliceType.SearchV3;

const keysToPersist: (keyof SearchV3Slice)[] = ['columnWidths'];

type KeyMapToPersist = Partial<Record<keyof SearchV3Slice, ReduxPath>>;
const keyMapToPersist: KeyMapToPersist = keysToPersist.reduce<KeyMapToPersist>(
  (prev, curr) => {
    prev[curr] = [name, curr];
    return prev;
  },
  {},
);
export const pathsToPersist = Object.values(keyMapToPersist);

const persistedState = {
  columnWidths: getPersistedState(keyMapToPersist.columnWidths!, {}),
};

export type SearchV3Slice = {
  columnOrder: string[];
  columnWidths: ColumnWidths;
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
  intervals: Record<string, string>;
  chartWidths: Record<string, string>;
  page: number;
  pageSize: number;
  searchText?: string;
  searchResultCache: Record<types.PilotId, SearchDocumentItem>;
  queryBuilder?: SearchQuery;
  queryBuilderPanelVisible: boolean;
  queryFields: Field[];
  selectedFilters: Array<Filter>;
  sortBy: ColumnSortOrder;
  savedSearch?: SavedSearch;
  initialValues?: {
    queryBuilder: SearchV3Slice['queryBuilder'];
    selectedFilters: SearchV3Slice['selectedFilters'];
    columns: SearchV3Slice['columnOrder'];
    searchText: SearchV3Slice['searchText'];
  };
};

export const initialState: SearchV3Slice = {
  columnOrder: [],
  fieldValues: {},
  intervals: {},
  chartWidths: {},
  page: 1,
  pageSize: 20,
  searchText: undefined,
  queryBuilder: undefined,
  queryBuilderPanelVisible: false,
  queryFields: [],
  searchResultCache: {},
  sortBy: DEFAULT_TABLE_SORT_COLUMN_ORDER,
  selectedFilters: [],
  savedSearch: undefined,
  initialValues: undefined,
  ...persistedState,
};

export const getNonFilterCharts = (
  dashboard: Dashboard,
): Record<string, Chart> => ({
  [SEARCH_COUNT_SINGLE_VALUE_ID]: {
    id: SEARCH_COUNT_SINGLE_VALUE_ID,
    label: dashboard.name,
    section: 'General Information',
    type: 'single_value',
  },
});

type InitData = {
  selectedFilters?: Array<Filter>;
  columnOrder: string[];
  pageSize: number;
  sortBy: ColumnSortOrder;
};

type InitSavedSearch = {
  searchFilters: Record<FieldId, SearchFilter>;
  pageSize: number;
  sortBy: ColumnSortOrder;
  savedSearch?: SavedSearch;
};

export default createSlice({
  name,
  initialState,
  reducers: {
    init: (state, action: { payload: InitData }) => {
      state.columnOrder = action.payload.columnOrder;
      if (action.payload.selectedFilters) {
        state.selectedFilters = action.payload.selectedFilters;
      }

      state.pageSize = action.payload.pageSize;
      state.sortBy = action.payload.sortBy;

      state.initialValues = {
        columns: state.columnOrder,
        queryBuilder: state.queryBuilder,
        selectedFilters: state.selectedFilters,
        searchText: state.searchText,
      };
    },
    initSavedSearchData: (state, action: { payload: InitSavedSearch }) => {
      const { searchFilters } = action.payload;
      const savedSearch = action.payload.savedSearch ?? state.savedSearch;

      if (savedSearch && testIsQueryV3(savedSearch)) {
        state.columnOrder = savedSearch.resultTable.map((column) =>
          column.filterId.toString(),
        );
        if (savedSearch.isComplexQuery) {
          state.queryBuilder = savedSearch.query;
        } else {
          const booleanTextSearches = savedSearch.query.filter(
            testIsBooleanTextSearch,
          );

          let modifiedQuery = [...savedSearch.query];
          state.searchText = '';
          if (booleanTextSearches.length === 1) {
            state.searchText = booleanTextSearches[0].value;
            modifiedQuery = modifiedQuery.filter(
              (f: EntityQuery) => !testIsBooleanTextSearch(f),
            );
          }
          state.selectedFilters = [
            ...toSearchV3Filters(modifiedQuery, searchFilters),
            ...(savedSearch.filters?.map((filter) => toFilter(filter)) ?? []),
          ];
        }
        state.initialValues = {
          columns: state.columnOrder,
          queryBuilder: state.queryBuilder,
          selectedFilters: state.selectedFilters,
          searchText: state.searchText,
        };
      }
    },
    setSavedSearch: (state, action: { payload: SavedSearch | undefined }) => {
      state.savedSearch = action.payload;
    },
    patchColumnWidths: (state, action: { payload: ColumnWidths }) => {
      state.columnWidths = {
        ...state.columnWidths,
        ...action.payload,
      };
    },
    patchSearchResultsCache: (
      state,
      { payload = [] }: { payload: SearchDocumentItem[] },
    ) => {
      payload.forEach((item) => (state.searchResultCache[item.id] = item));
    },
    patchEvisearchResultsCache: (
      state,
      { payload = [] }: { payload: SearchItem[] },
    ) => {
      payload.forEach(
        (item) =>
          (state.searchResultCache[item.id] = {
            ...item,
            document_handlers: get(
              item,
              'selected_field_values.document_handler_id_Document.value_structured',
              [],
            ),
            // TODO: Map types correctly once the API gets formalized
            permissions: item.permissions as any,
            selected_field_values: item.selected_field_values as any,
            id: item.id as any,
          }),
      );
    },

    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setFieldValues: (
      state,
      {
        payload,
      }: { payload: { fieldId: string; values: DropdownFieldValue[] } },
    ) => {
      state.fieldValues[payload.fieldId] = payload.values;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setQueryBuilder: (state, action) => {
      state.queryBuilder = action.payload;
    },
    setQueryBuilderPanelVisible: (state, action) => {
      state.queryBuilderPanelVisible = action.payload;
    },
    setQueryFields: (state, { payload }) => {
      state.queryFields = payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setSortBy: (state, action) => {
      const newSortBy = { ...action.payload };
      state.sortBy = newSortBy;
    },
    reset: (state) => {
      return {
        ...initialState,
        ...pick(state, ['selectedFilters', 'searchResultCache']),
        filters: [],
        selectedFilters: [],
      };
    },
  },
});
