import { saveAs } from 'file-saver';
import React, { useState } from 'react';

import { Button } from '~/eds';
import { getDocumentOriginal } from '~/redux/api/methods';

import { ERROR } from '../../../types/toast.types';
import { showToast } from '../../Shared/EcToast';

function DownloadMenuButton({ documentData }) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleOnDownloadOriginalClick = () => {
    if (isDownloading) return;

    setIsDownloading(true);
    const { id, document_name, file_type } = documentData;

    getDocumentOriginal(id)
      .then((res) => saveAs(res, `${document_name}${file_type}`))
      .catch(() =>
        showToast(
          ERROR,
          `Something went wrong with downloading ${document_name}`,
        ),
      )
      .finally(() => setIsDownloading(false));
  };

  return (
    <Button
      id={`document_header_downloadActions?document=${documentData.id}`}
      text="Download"
      iconPosition="left"
      icon="download"
      onClick={handleOnDownloadOriginalClick}
      isLoading={isDownloading}
    />
  );
}

export default DownloadMenuButton;
