import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AuthenticationStore from '~/auth';
import { Box, Button, Layout, Logo, Text, TextArea } from '~/eds';
import Configuration from '~/services/configuration';

const EmailRejectViewType = {
  Input: 'INPUT',
  Rejected: 'REJECTED',
  InvalidLink: 'INVALID_LINK',
};

const AS = AuthenticationStore();

function InputView({
  comment,
  setComment,
  handleSendRejectRequest,
  viewTicket,
  disabled,
}) {
  return (
    <Layout align="center" direction="column" spacing={6}>
      <Text as="h1" variant="subtitle">
        Are you sure you want to reject this item?
      </Text>
      <Box w="60%">
        <Layout direction="column" spacing={6}>
          <TextArea
            name="reject-comment-textarea"
            placeholder="Add a comment"
            rows={6}
            maxLength="4096"
            value={comment}
            onChange={setComment}
          />
          <Layout justify="flex-end" spacing={6}>
            <Layout preset="buttons">
              <Button
                variant="secondary"
                name="view-ticket-button"
                onClick={viewTicket}
                text="View Ticket"
              ></Button>
              <Button
                variant="danger"
                name="action-button"
                disabled={disabled}
                onClick={handleSendRejectRequest}
                text="Reject Ticket"
              ></Button>
            </Layout>
          </Layout>
        </Layout>
      </Box>
    </Layout>
  );
}

InputView.propTypes = {
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  handleSendRejectRequest: PropTypes.func.isRequired,
  viewTicket: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

function ErrorMessageView() {
  return (
    <Layout align="center" direction="column">
      <Text as="h1" variant="subtitle">
        Invalid link
      </Text>
    </Layout>
  );
}

function RejectedMessageView() {
  return (
    <Layout align="center" direction="column">
      <Text as="h1" variant="subtitle">
        Ticket rejected
      </Text>
    </Layout>
  );
}

function Page() {
  const isLoggedIn = AS.isLoggedIn();

  const [comment, setComment] = useState();
  const [view, setView] = useState();

  const disabled = comment === undefined || comment === '';

  const formData = new FormData();
  formData.append('payload', JSON.stringify({ comment: comment }));

  const stringArray = window.location.pathname.split('/');
  const [
    _emptyItem,
    clientSubDomain,
    _tickets,
    ticketId,
    ...otherElements
  ] = stringArray;
  const actionUrl = `${
    Configuration.everestEndpoint
  }/tickets/${ticketId}/${otherElements.join('/')}`;
  const ticketUrl = `${window.location.origin}/${clientSubDomain}/workflow/tickets/${ticketId}/`;

  const handleSendRejectRequest = async () => {
    try {
      await axios.patch(actionUrl, formData, 'Content-Type');
      setView(EmailRejectViewType.Rejected);
    } catch (e) {
      setView(EmailRejectViewType.InvalidLink);
    }
  };

  const viewTicket = () => {
    window.location.href = ticketUrl;
  };

  function renderView() {
    switch (view) {
      case EmailRejectViewType.Rejected:
        return <RejectedMessageView />;
      case EmailRejectViewType.InvalidLink:
        return <ErrorMessageView />;
      default:
        return (
          <InputView
            comment={comment}
            setComment={setComment}
            handleSendRejectRequest={handleSendRejectRequest}
            viewTicket={viewTicket}
            disabled={disabled}
          />
        );
    }
  }

  return (
    <Layout
      as="header"
      direction="column"
      spacing={16}
      pt={isLoggedIn ? 16 : 0}
    >
      {isLoggedIn ? null : (
        <Layout
          align="center"
          bg="inverse.background"
          h="header.height"
          justify="space-between"
          px="6"
        >
          <a href="/" title="Evisort login page">
            <Logo theme="dark" />
          </a>
        </Layout>
      )}
      {renderView()}
    </Layout>
  );
}

export default Page;
