import React, { useEffect } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { Modal, Text, useToast } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { UserAttributes } from '~/redux/api/methods';
import { ParsedType } from '~/redux/api/transformers';
import { Nullable } from '~/types';

type Props = {
  user: Nullable<ParsedType<UserAttributes>>;
  visible: boolean;
  onHide: () => void;
};

export const ResetUserPasswordModal = ({ user, visible, onHide }: Props) => {
  const isRolodexDataSourceEnabled = useFlag(FlagType.RolodexAsUserDataSource);

  const [
    sendInvitationEmail,
    sendInvitationEmailResult,
  ] = api.endpoints.sendInvitationEmail.useMutation();
  const { toast } = useToast();
  const { isError, isLoading, isSuccess, error } = sendInvitationEmailResult;
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: `We have sent a reset password link to “${user?.email}”`,
        status: 'success',
      });
      onHide();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const resp = coerceRtkqError(error)?.response || {};
      const msg = resp.data?.errors?.[0];
      toast({
        message:
          msg?.title || 'An error occurred while sending invitation email.',
        status: 'danger',
      });
    }
  }, [isError]);

  return (
    <Modal
      isVisible={visible}
      title="Reset password"
      onCancel={onHide}
      onHide={onHide}
      primaryAction={{
        text: 'Reset Password',
        onClick: () => {
          trackSegment('submitResetUserPassword', {
            id: user!.id,
            user_id: currentUser.id,
            client_id: currentUser.client,
          });
          sendInvitationEmail({
            userId: user!.id,
            enableRolodex: isRolodexDataSourceEnabled,
          });
        },
        isLoading: isLoading,
        variant: 'primary',
      }}
    >
      <Text>
        User will receive an email with a password reset link at{' '}
        <Text variant="body-bold">{user?.email}</Text>
      </Text>
    </Modal>
  );
};
