import { LibraryModelVersion } from '~/features/x-ray/library/types';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { GetLibraryModelResponse } from '../../types/sylvanus/library';
import { mapLibraryModelVersion } from './transformers';

interface RequestParams {
  libraryModelId: Uuid;
}

/**
 * Fetch all the `LibaryModelVersion`s for the `LibraryModel` with provided `libraryModelId`.
 */
export const getLibraryModel = async ({
  libraryModelId,
}: RequestParams): Promise<LibraryModelVersion[]> => {
  const response: GetLibraryModelResponse = await sylvanus.get(
    `/library/model/${libraryModelId}`,
  );
  return response.versions.map((version) => mapLibraryModelVersion(version));
};
