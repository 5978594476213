import { Attempt } from '~/features/webhooks';
import {
  ResendApiArg,
  ResendApiResponse,
} from '~/redux/api/types/courier/deliveryAttempt';
import { courier } from '~/services';

interface Request {
  attempt: Attempt;
}

export const resendAttempt = async ({ attempt }: Request): Promise<boolean> => {
  const params: ResendApiArg = {
    attemptId: attempt.id,
  };

  const response: ResendApiResponse = await courier.patch(
    `/delivery-attempts/${params.attemptId}/resend`,
  );
  return Boolean(response);
};
