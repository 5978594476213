import { Layout, Text } from '~/eds';

interface Props {
  text: string;
  onClick: () => void;
  isActive?: boolean;
}
export const CitationButton = ({ isActive, text, onClick }: Props) => {
  return (
    <Layout
      px="6px"
      h={18}
      as="button"
      onClick={onClick}
      borderRadius="m"
      justify="center"
      align="center"
      styles={{
        border: 'border',
        borderColor: isActive ? 'action.link' : 'border',
      }}
    >
      <Text variant="tiny" color={isActive ? 'action.link' : 'text.secondary'}>
        {text}
      </Text>
    </Layout>
  );
};
