import React, { useMemo } from 'react';

import { Actions, formatNumber, IconButton, Layout, useToggle } from '~/eds';
import { HighlightNavigator } from '~/features/document-viewer/HighlightNavigator';
import { FlagType, useFlag } from '~/flags';

import { BaseDocumentViewerProps } from '../types';
import SearchBar from './SearchBar';

type ZoomScale = 'page-width' | number | undefined;

interface Props
  extends Pick<
    BaseDocumentViewerProps,
    | 'activeHighlightId'
    | 'highlights'
    | 'navigatorLabel'
    | 'onClear'
    | 'onHighlightClick'
  > {
  searchListenOn: React.RefObject<HTMLElement>;
  zoom: {
    scale: ZoomScale;
    onChange: (scale: ZoomScale) => void;
  };
}

export const Toolbar = ({
  activeHighlightId,
  highlights = [],
  navigatorLabel,
  searchListenOn,
  zoom,
  onClear,
  onHighlightClick,
}: Props) => {
  const [isVisible, toggleSearchBar] = useToggle(false);

  const enablePdfViewerZoom = useFlag(FlagType.PdfViewerZoom);
  const enableNavigator = activeHighlightId && highlights.length > 1;
  const zoomActions = useMemo(() => {
    const { scale, onChange } = zoom;

    const isFitPageWidth = scale === 'page-width';
    const zoomScale = Number(scale) || 1;
    const zoomInScale = Math.min(zoomScale + 0.25, 10);
    const zoomOutScale = Math.max(zoomScale - 0.25, 0.25);

    return [
      {
        icon: 'dash' as const,
        mode: 'icon' as const,
        text: 'Zoom out',
        tooltip: `Zoom out (${formatNumber(zoomOutScale, {
          decimal: 0,
          style: 'percent',
        })})`,
        onClick: () => onChange(zoomOutScale),
      },
      {
        icon: 'plus' as const,
        mode: 'icon' as const,
        text: 'Zoom in',
        tooltip: `Zoom in (${formatNumber(zoomInScale, {
          decimal: 0,
          style: 'percent',
        })})`,
        onClick: () => onChange(zoomInScale),
      },
      {
        icon: 'maximize' as const,
        mode: 'icon' as const,
        status: scale === 'page-width' ? ('active' as const) : undefined,
        text: 'Fit page width',
        tooltip: isFitPageWidth ? 'Reset to original size' : 'Fit page width',
        onClick: () => onChange(isFitPageWidth ? 1 : 'page-width'),
      },
    ];
  }, [zoom]);

  return (
    <Layout preset="icons" px={4}>
      <Layout align="center" spacing={2}>
        <IconButton
          icon="search"
          status={isVisible ? 'active' : undefined}
          tooltip="Search"
          onClick={toggleSearchBar}
        />

        <SearchBar
          listenOn={searchListenOn}
          toggleVisiblity={toggleSearchBar}
          isVisible={isVisible}
        />
      </Layout>
      {enablePdfViewerZoom && <Actions actions={zoomActions} />}

      {enableNavigator && (
        <HighlightNavigator
          activeHighlightId={activeHighlightId}
          highlights={highlights}
          navigatorLabel={navigatorLabel}
          onClear={onClear}
          onHighlightClick={onHighlightClick}
        />
      )}
    </Layout>
  );
};
