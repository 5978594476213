import { noop } from 'lodash';

import { DocumentDetailsPanel } from '~/components/Shared/DocumentDetailsPanel';
import { openInNewTab } from '~/utils/browser';

import { useClientSubDomain } from './useClientSubDomain';

type DocumentHanlder = {
  id: number;
  name: string;
  file_type: string;
};

type Props = {
  index: number;
  count: number;
  handlers?: DocumentHanlder[];
  featuredClauses: string[];
  onHide: () => void;
  onNavigate: (index: number) => void;
};

const EMPTY_RETURN = {
  DetailsPanel: null,
  panelProps: {
    onHide: noop,
  },
};

export const useDocumentDetails = ({
  index,
  count,
  handlers = [],
  featuredClauses,
  onHide,
  onNavigate,
}: Props) => {
  const client = useClientSubDomain();
  const handler = (handlers || [])[0];
  if (!handler) return EMPTY_RETURN;

  const isLastDocument = index === count - 1;
  const isFirstDocument = index === 0;

  const onOpenDocumentClick = () => {
    if (handlers.length > 1) {
      // TODO: Implement document navigation hook
    } else {
      openInNewTab(`/${client}/document/${handler.id}`);
    }
  };

  // TODO: implement after [EKP-28190]
  const searchKeyworkMatches: any = null;

  const panelProps: React.ComponentProps<typeof DocumentDetailsPanel> = {
    docHandlerId: handler.id,
    searchKeyworkMatches,
    onHide,
    featuredClauses,
    actionsConfig: {
      navigate: {
        onClick: onOpenDocumentClick,
      },
      next: {
        onClick: () => onNavigate(index + 1),
        disabled: isLastDocument,
      },
      previous: {
        onClick: () => onNavigate(index - 1),
        disabled: isFirstDocument,
      },
    },
  };

  return {
    DetailsPanel: DocumentDetailsPanel,
    panelProps,
  };
};
