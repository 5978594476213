import React from 'react';

import FilterChip from '~/components/Shared/Filters/FilterChip';
import { Filter } from '~/evifields';
import { useDrag } from '~/features/drag-and-drop';
import { FlagType, useFlag } from '~/flags';
import { SearchFilter } from '~/types';

type Props = {
  index: number;
  filter: Filter;
  searchFilter: SearchFilter;
  sectionIndex: number;
  onChange: (filter: Filter) => void;
  onRemove: () => void;
};

export const SectionItem = ({
  index,
  filter,
  searchFilter,
  sectionIndex,
  onChange,
  onRemove,
}: Props) => {
  const enableFilterViews = useFlag(FlagType.TextDelimitedMultiselectFilter);
  const { handleDragStart, handleDragEnd } = useDrag();
  return (
    <div
      draggable
      onDragStart={() =>
        handleDragStart({
          id: searchFilter.id,
          payload: { sectionIndex, index, filter },
        })
      }
      onDragEnd={handleDragEnd}
    >
      <FilterChip
        enableFilterViews={enableFilterViews}
        disableRemove={false}
        searchFilter={searchFilter!}
        filter={filter}
        onChange={onChange}
        onRemove={onRemove}
      />
    </div>
  );
};
