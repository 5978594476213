export default `
<svg
  aria-hidden="true"
  aria-label="book-sparkle"
  width="16"
  height="19"
  viewBox="0 0 16 19"
  fill="none"
>
  <path d="M3.42857 0C1.53571 0 0 1.53571 0 3.42857V14.8571C0 16.75 1.53571 18.2857 3.42857 18.2857H13.7143H14.8571C15.4893 18.2857 16 17.775 16 17.1429C16 16.5107 15.4893 16 14.8571 16V13.7143C15.4893 13.7143 16 13.2036 16 12.5714V1.14286C16 0.510714 15.4893 0 14.8571 0H13.7143H3.42857ZM3.42857 13.7143H12.5714V16H3.42857C2.79643 16 2.28571 15.4893 2.28571 14.8571C2.28571 14.225 2.79643 13.7143 3.42857 13.7143ZM9.42857 7.14286L10.0214 5.75714C10.1214 5.525 10.4464 5.525 10.5464 5.75714L11.1429 7.14286L12.5286 7.73571C12.7607 7.83571 12.7607 8.16072 12.5286 8.26071L11.1429 8.85714L10.55 10.2429C10.45 10.475 10.125 10.475 10.025 10.2429L9.42857 8.85714L8.04286 8.26429C7.81071 8.16429 7.81071 7.83929 8.04286 7.73929L9.42857 7.14286ZM6.02857 2.79643C6.13214 2.58571 6.43571 2.58571 6.53929 2.79643L6.85714 3.42857L7.48929 3.74286C7.7 3.84643 7.7 4.15 7.48929 4.25357L6.85714 4.57143L6.54286 5.20357C6.43929 5.41429 6.13571 5.41429 6.03214 5.20357L5.71429 4.57143L5.08214 4.25714C4.87143 4.15357 4.87143 3.85 5.08214 3.74643L5.71429 3.42857L6.02857 2.79643Z" fill="#0053FF"/>
</svg>
`;
