import React from 'react';

import { ListItem } from '../../types';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface ListItemsProps {
  as: 'ol' | 'ul';
  items: ListItem[];
  enablePadding?: boolean;
}

export const ListItems = ({ as, items, enablePadding }: ListItemsProps) => {
  return (
    <Layout
      as={as}
      spacing={enablePadding ? undefined : '4'}
      direction="column"
      pl="0"
      pr="0"
    >
      {items.map((item, index) => (
        <Layout
          key={`list-item-${index}`}
          direction="column"
          onClick={item.onClick}
          bg={item.isSelected ? 'status.info.secondary' : undefined}
          p={enablePadding ? '4' : undefined}
        >
          <Layout>
            {item.icon && (
              <Box mr="2">
                <Icon
                  color={item.iconColor ?? 'status.info'}
                  icon={item.icon}
                />
              </Box>
            )}
            <Text variant="body-medium">{item.title}</Text>
          </Layout>
          <Text>{item.description}</Text>
        </Layout>
      ))}
    </Layout>
  );
};
