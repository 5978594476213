import React, { useEffect, useMemo } from 'react';

import {
  Accordion,
  ContentContainer,
  scrollElementIntoView,
  types,
} from '~/eds';
import { ClauseContent, DocumentViewClause } from '~/types';

import { Clause } from './Clause';

interface Props {
  activeClauseId?: types.PilotId;
  clauses: DocumentViewClause[];
  search?: string;
  onToggle?: (isExpanded: boolean, clause?: DocumentViewClause) => void;
  /** if true, all accordion items will be expanded */
  isAllExpanded?: boolean;
  /** when provided, the clause will be expanded and will the screen will scroll to the highlight. */
  featuredClauses?: string[];

  onClauseClick?: (clause: ClauseContent) => void;
}

export function ClausesAccordion({
  activeClauseId,
  clauses,
  featuredClauses,
  isAllExpanded = false,
  search = '',
  onClauseClick,
  onToggle,
}: Props) {
  const getClauseContent = (clauses: ClauseContent[]) => (
    <Clause
      clauses={clauses}
      onClick={onClauseClick}
      activeClauseId={activeClauseId}
    />
  );

  const clausesAccordionItems = useMemo(() => {
    return clauses.map((clause) => ({
      content: getClauseContent(clause.content),
      data: clause,
      title: `${clause.name} (${clause.content.length})`,
      isExpanded: isAllExpanded || featuredClauses?.includes(clause.name),
    }));
  }, [clauses, activeClauseId]);

  useEffect(() => {
    if (featuredClauses) {
      const clause = clauses.find((clause) =>
        featuredClauses?.includes(clause.name),
      );
      const title = `${clause?.name} (${clause?.content.length})-header`;
      scrollElementIntoView(title, { block: 'center' });
    }
  }, [featuredClauses, clauses]);

  const placeholderContent = !clauses.length
    ? {
        icon: 'field-clause' as const,
        message: 'No clauses exists for this document.',
      }
    : undefined;

  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <Accordion<DocumentViewClause>
        items={clausesAccordionItems}
        search={search}
        onToggle={(isExpanded, item) => onToggle?.(isExpanded, item.data)}
      />
    </ContentContainer>
  );
}
