import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from 'react';

import { Button, Checkbox, formatDate, Layout, Panel, Text } from '~/eds';
import { FileMimeType, HttpStatusCodeType } from '~/enums';
import { SearchQuery } from '~/features/advanced-search';
import { api } from '~/redux';
import { SearchType } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { formatNumeral } from '~/utils';

import { showToast } from '../EcToast';
import { defaultSelectedOptions } from './constants';
import { useExportSettings } from './useExportSettings';

type Props = {
  query: SearchQuery;
  entity?: SearchType;
  isVisible: boolean;
  totalDocs?: number;
  totalDupsAndDocs?: number;
  selectedFieldIds?: string[];
  onHide: () => void;
};

const getFields = (selectedFieldIds: string[], isPreSig: boolean) => {
  if (isPreSig) return selectedFieldIds;
  const selectedFieldsSets = new Set([
    ...defaultSelectedOptions,
    ...selectedFieldIds,
  ]);
  return [...selectedFieldsSets];
};

export const ExportExcel = ({
  query,
  entity,
  isVisible,
  totalDocs = 0,
  totalDupsAndDocs = 0,
  selectedFieldIds = [],
  onHide,
}: Props) => {
  const isPresign = entity === 'ticket' || entity === 'workflow';
  const [localSettings, setLocalSettings] = useState<
    Record<string, Nullable<string[]>>
  >({
    fields: getFields(selectedFieldIds, isPresign),
  });
  const [includeDuplicates, setIncludeDuplicates] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');

  const [exportExcel, exportResult] = api.endpoints[
    isPresign ? 'exportExcelV3' : 'exportExcelV2'
  ].useMutation();
  const { isLoading: isExporting, isError, isSuccess, data } = exportResult;
  const {
    data: exportSettings,
    isFetching: isFetchingOptions,
    isError: isErrorOptions,
  } = api.endpoints[
    isPresign ? 'getSearchExportOptionsV2' : 'getSearchExportOptions'
  ].useQuery({ query: query as any, type: entity! }, { skip: !isVisible });

  const { fields, clauses, tables } = localSettings;
  const selectedItemsCount =
    (fields?.length || 0) + (clauses?.length || 0) + (tables?.length || 0);

  const metadata = useMemo(
    () => [
      {
        label: 'Selected Items',
        value: selectedItemsCount,
        hidden: false,
      },
      {
        label: isPresign ? 'Total Items' : 'Total Documents',
        value: totalDocs,
        hidden: false,
      },
      {
        label: 'Total Duplicates',
        value: totalDupsAndDocs - totalDocs,
        hidden: isPresign,
      },
      {
        label: 'Including Duplicates',
        value: totalDupsAndDocs,
        hidden: isPresign,
      },
    ],
    [selectedItemsCount, totalDocs, totalDupsAndDocs],
  );

  const handleHide = () => {
    onHide();
    setLocalSettings({
      fields: getFields(selectedFieldIds, isPresign),
      clauses: [],
      tables: [],
    });
    setIncludeDuplicates(false);
  };

  const submitExportRequest = () => {
    exportExcel({
      fields,
      query: query as any, //TODO: Change type to QueryV3
      type: entity!,
      clauses,
      tables,
      includeDuplicates,
    });
  };

  useEffect(() => {
    if (selectedFieldIds.length) {
      setLocalSettings({ fields: getFields(selectedFieldIds, isPresign) });
    }
  }, [selectedFieldIds]);

  useEffect(() => {
    if (isSuccess && data) {
      const excelMessage = 'Your results have started exporting. ';
      if (data.status === HttpStatusCodeType.Ok) {
        showToast(SUCCESS, excelMessage);
        const blob = new Blob([data.data], { type: FileMimeType.Xlsx });
        const dateString = formatDate(new Date(), 'iso_datetime');
        saveAs(blob, `${dateString} Evisort Export.xlsx`);
      } else if (data.status === HttpStatusCodeType.Accepted) {
        showToast(
          SUCCESS,
          excelMessage +
            'You will receive an email when the export is complete.',
        );
      }
      showToast(SUCCESS, 'Successfully exported excel file');

      handleHide();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'Error exporting excel file. Please try again later.');
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorOptions) {
      showToast(ERROR, 'Error on retrieving export options. Try again later.');
    }
  }, [isErrorOptions]);

  const footer = (
    <Layout direction="column">
      <Layout
        justify={isPresign ? 'flex-start' : 'space-between'}
        p={4}
        borderTop="border"
      >
        {metadata
          .filter((metadataItem) => !metadataItem.hidden)
          .map((metadataItem) => (
            <Layout
              key={metadataItem.label}
              direction="column"
              mr={isPresign ? 8 : 0}
            >
              <Text variant="body">{metadataItem.label}:</Text>
              <Text variant="body-bold">
                {formatNumeral()(metadataItem.value)}
              </Text>
            </Layout>
          ))}
      </Layout>
      <Layout
        p={4}
        direction="row"
        align="center"
        justify="flex-end"
        borderTop="border"
      >
        {!isPresign && (
          <Checkbox
            name="duplicates"
            value={includeDuplicates}
            onChange={(value) => setIncludeDuplicates(!!value)}
            option={{
              label: 'Include duplicates',
              value: includeDuplicates,
            }}
          />
        )}

        <Layout ml={4}>
          <Button
            isLoading={isFetchingOptions || isExporting}
            disabled={isFetchingOptions || isExporting || isErrorOptions}
            text="Export Excel"
            variant="primary"
            onClick={submitExportRequest}
          />
        </Layout>
      </Layout>
    </Layout>
  );
  const tabs = useExportSettings({
    data: exportSettings,
    settings: localSettings,
    selectedFieldIds: localSettings.fields ?? [],
    onChange: setLocalSettings,
  });
  const filteredTabs = isPresign
    ? // Show only "all" tab if presign
      [tabs[0]]
    : tabs.filter((tab) => tab.items?.length);

  const panelTabs = {
    selectedTab,
    tabs: filteredTabs,
    onSelectTab: setSelectedTab,
  };

  if (!isVisible) return null;

  return (
    <Panel
      title="Export Results"
      hidden={{ isHidden: false, onHide: handleHide }}
      footer={{ children: footer }}
      tabs={panelTabs}
      loadingContent={{
        isLoading: isFetchingOptions,
      }}
      enableBackdrop
      mode="floating"
      width="l"
      enableContentPadding={false}
    />
  );
};
